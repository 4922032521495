<template>
    <div>
        <div class="create_movie__legend_bar">Холбоотой кино нэмэх/хасах:</div>
        <v-card class="pa-2" outlined>
            <div class="create_movie__artist_block">
                <div class="create_movie__artist_search">
                    <v-autocomplete no-filter v-model="selectedMovie" height="100" :items="searchItems" hide-no-data return-object :search-input.sync="searchMovie" clearable item-text="name" label="Кино" attach solo>
                        <template v-slot:selection="data">
                            <v-avatar left height="75" tile class="mx-3"><img :src="mediaUrl + '/' + data.item['movie_poster']" class="rounded"></v-avatar>
                            <div mx="2">{{ data.item['international_title'] }}</div>
                        </template>
                        <template v-slot:item="data">
                            <template v-if="data.item !== 'object'">
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['movie_poster']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['international_title']"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['movie_poster']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['international_title']"></v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </div>
            </div>
            <v-btn color="blue" block @click="addMovie">Нэмэх</v-btn>
            <br>
            <draggable v-model="movieList" class="generic-list__row" tag="ul" v-bind="dragOptions" @start="drag = true" @end="drag = false">
                <UpdateMovieCard v-for="(movie, index) in movieList" :key="index" :movie="movie" :type="'movie'" v-on:delete-movie="removeMovie(index)"/>
            </draggable>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import UpdateMovieCard from '@/components/generics/movie_serial/GenericCUJournalCard.vue'

export default {
    props: {
        slug: String,
        typeSlug: String,
        subTypeSlug: String
    },
    components: {
        draggable,
        UpdateMovieCard
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            movieList: [],
            searchItems: [],
            loadMore: 0,
            searchMovie: null,
            selectedMovie: '',
            drag: false
        }
    },
    computed:{
        dragOptions(){
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    watch:{
        searchMovie(val){
            if(val){
                axios
                    .get('/movie/api-search-movie-list/', { params: { movie: val, festival: false }}).then(response => { this.searchItems = response.data.movie_list })
            }
        }
    },
    methods:{
        addMovie(){
            if(this.selectedMovie){
                this.movieList.push({ id: this.selectedMovie['id'], movie_poster: this.selectedMovie['movie_poster'], international_title: this.selectedMovie['international_title'] })
            }
            this.selectedMovie = null
            this.searchItems = []
            this.$emit('submit-button', false)
        },
        removeMovie(index){
            this.movieList.splice(index, 1)
        },
        getJournalMovies(){
            axios
                .get('/journal/api-journal-movie-list/', { params: { slug: this.slug, typeSlug: this.typeSlug, subTypeSlug: this.subTypeSlug }})
                .then(response => { this.movieList = response.data.movies })
        },
    },
    created(){
        this.getJournalMovies()
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>