<template>
    <v-container v-if="user.isAuthenticated">
        <div v-if="listSuccess == true">
            <v-alert dense outlined type="success"><strong>{{ listMessage }}</strong></v-alert>
            <v-btn v-if="listSuccess" block color="success" class="mr-4">
                <router-link class="no_decoration" :to="{ name: (switchName ? 'user-movie-list-detail' : 'user-serial-list-detail'), params: { slug: this.user.slug, listSlug: this.listSlugUpdate }}">Жагсаалтруу очих</router-link>
            </v-btn>
        </div>
        <div v-else>
            <v-card class="ma-5 pa-5">
                <v-card-title class="justify-center"><span class="first_letter">{{ switchName ? "Кино " : "Цувралын " }}</span>жагсаалт засах</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitUpdate">
                    <v-checkbox v-model="checkTopList" label="Энэ миний бүх цаг үеийн шилдэг жагсаалт"></v-checkbox>
                    <v-text-field class="mb-2" v-model="movieListName" :counter="50" :rules="rules" label="Жагсаалтын нэр" required></v-text-field>
                    <v-textarea solo name="input-7-4" v-model="movieListAbout" label="Жагсаалтын тухай"></v-textarea>
                    <v-checkbox v-model="checkMovieDesc" :label="(switchName ? 'Кино ' : 'Цуврал ') + ' болгоны тайбарыг үзүүлэхгүй!'"></v-checkbox>
                    <v-card class="pa-2">
                        <v-autocomplete no-filter v-model="selectedMovie" height="100" :items="searchItems" hide-no-data return-object :search-input.sync="searchMovie" clearable item-text="name" label="Кино" attach solo>
                            <template v-slot:selection="data">
                                <v-avatar left height="75" tile class="mx-3"><img :src="mediaUrl + '/' + data.item[switchName ? 'movie_poster' : 'serial_poster']" class="rounded"></v-avatar>
                                <div mx="2">{{ data.item['international_title'] }}</div>
                            </template>
                            <template v-slot:item="data">
                                <template v-if="data.item !== 'object'">
                                    <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item[switchName ? 'movie_poster' : 'serial_poster']" class="rounded"></v-list-item-avatar>
                                    <v-list-item-content v-text="data.item['international_title']"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item[switchName ? 'movie_poster' : 'serial_poster']" class="rounded"></v-list-item-avatar>
                                    <v-list-item-content v-text="data.item['international_title']"></v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-textarea v-model="description" label="Нэмэлт тайлбар"></v-textarea>
                        <v-btn :color="toggleColor" block @click="addMovie">{{ toggleButton }}</v-btn>
                    </v-card>
                    <br>
                    <div class="text-center">
                        <v-btn-toggle v-if="movies != ''" mandatory dense color="#0066b5">
                            <v-btn @click="layout = 'list'"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                            <v-btn @click="layout = 'grid'"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                        </v-btn-toggle>
                    </div>
                    <br>
                    <draggable v-model="movies" :class="layout === 'grid' ? 'drag_list_group toggle_style' : 'drag_list_group'" tag="ul" v-bind="dragOptions" @start="drag = true" @end="drag = false" >
                        <CreateListCard :type="switchName" v-for="(movie, index) in movies" :key="index" :movie="movie" :layout="layout" v-on:delete-movie="removeMovie(index)"/>
                    </draggable>
                    <v-btn :disabled="checkValid" block color="success" class="mt-5 mr-4" @click="validate" type="submit">Хадгалах</v-btn>
                </v-form>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import { mapState } from 'vuex'

import CreateListCard from '@/components/generics/movie_serial/GenericCreateUpdateMSListCard.vue'

export default {
    props: {
        listSlug: String
    },
    components: {
        draggable,
        CreateListCard
    },
    data(){
        return{
            routeName: this.$route.name,
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            searchItems: [],
            loadMore: 0,
            endPoint: '',
            list: '',
            layout: 'list',
            toggleStyle: '',
            toggleButton: "Кино нэмэх",
            toggleColor: "primary",
            searchMovie: null,
            selectedMovie: null,
            description: '',
            movieItems: [],
            drag: false,
            movies: [],
            checkTopList: false,
            checkMovieDesc: false,
            formValid: false,
            movieListName: '',
            movieListAbout: '',
            listMessage: '',
            listSuccess: null,
            listSlugUpdate: '',
            rules: [
                value => !!value || 'Та заавал бөглөнө үү!',
                value => (value && value.length >= 3 && value.length <= 50) || 'Хамгийн багадаа 3 тэмдэгт, хамгийн ихдээ 50 тэмдэгт'
            ]
        }
    },
    computed:{
        dragOptions(){
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        },
        checkValid(){
            if(this.formValid == true && this.movies?.length > 0){
                return false
            } else if(this.formValid == false && this.movies?.length == 0 || this.formValid == true && this.movies?.length == 0 || this.formValid == false && this.movies?.length > 0){
                return true
            }
        },
        switchName(){
            return this.routeName === "movie-list-update" ? true : false
        },
        ...mapState(['user'])
    },
    watch:{
        layout(){
            if(this.layout === 'grid'){
                this.toggleStyle = "display: flex; flex-wrap: wrap;"
            } else{
                this.toggleStyle = ""
            }
        },
        searchMovie(val){
            if(val){
                let searchUrl, searchParams
                if (this.switchName) searchUrl = '/movie/api-search-movie-list/', searchParams = { movie: val, festival: false }
                else searchUrl = '/serial/api-search-serial-list/', searchParams = { serial: val }
                axios
                    .get(searchUrl, { params: searchParams})
                    .then(response => { this.searchItems = this.switchName ? response.data.movie_list : response.data.serial_list })
            }
        }
    },
    methods:{
        addMovie(){
            this.toggleButton = "Кино нэмэх"
            this.toggleColor = "primary"
            this.beingEdited = false
            if(this.selectedMovie && this.movies.findIndex(i => i.id === this.selectedMovie['id']) == -1){
                this.movies.push({  description: this.description,
                                    id: this.selectedMovie['id'],
                                    international_title: this.selectedMovie['international_title'],
                                    ...(this.switchName) && {movie_poster: this.selectedMovie['movie_poster']},
                                    ...(!this.switchName) && {serial_poster: this.selectedMovie['serial_poster']}
                })
            }
            this.selectedMovie = null
            this.description = null
            this.searchItems = []
            this.$emit('submit-button', false)
        },
        removeMovie(index){
            this.movies.splice(index, 1)
        },
        validate(){
            this.$refs.form.validate()
        },
        getMovielistDetail(){
            let searchUrl = this.switchName ? '/user/api/v-user-movielistdetail/' : '/user/api/v-user-seriallistdetail/'
            axios
                .get(searchUrl, { params: { slug: this.user.slug, list: this.listSlug }})
                .then((response) => {   this.list = this.switchName ? response.data.api_user_movie_list_detail : response.data.api_user_serial_list_detail,
                                        this.movieListName = this.list.title,
                                        this.checkTopList = this.list.top_list,
                                        this.movieListAbout = this.list.about,
                                        this.movies = this.switchName ? this.list.movie_list : this.list.serial_list})
        },
        submitUpdate(){
            let searchUrl
            if (this.switchName) searchUrl = '/movie/api-update-movielist/'
            else searchUrl = '/serial/update-seriallist/'

            const updateData = {
                'listId': this.list.id,
                'movieListName': this.movieListName,
                'movieListAbout': this.movieListAbout,
                'checkTopList': this.checkTopList,
                'checkMovieDesc': this.checkMovieDesc,
                'movieList': this.movies
            }

            axios
                .post(searchUrl, updateData)
                .then(response => {
                    if(response.data.success !== false){
                        this.listMessage = response.data.message, this.listSuccess = response.data.success, this.listSlugUpdate = response.data.slug
                        this.$store.commit('SET_SNACK', {message: response.data.message, color: 'success'})
                    } else {
                        this.$store.commit('SET_SNACK', {message: response.data.message, color: 'red'})
                    }
                })
        }
    },
    created(){
        this.getMovielistDetail()
        document.title = this.switchName ? "Кино жагсаалт засах" : "Цувралын жагсаалт засах"
    }
}
</script>

<style scoped>

.legendBar {
    border-bottom: #696969 1px solid;
    opacity: 0.8;
    font-size: 16px;
    margin: auto;
    width: 100%;
}

/* Movie list drag */

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.drag_list_group {
    min-height: 20px;
}

.first_letter {
    margin-right: 5px;
}

.no_decoration {
    text-decoration: none;
    color: white;
}

.toggle_style {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    margin: 0 auto;
}

</style>