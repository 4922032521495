<template>
    <div class="cc-1">
        <v-divider class="mb-2"></v-divider>
        <div class="cc-2">
            <router-link :to="{ name: 'user-feed', params: { slug: review.user_slug } }">
                <v-avatar size="50" class="rounded-circle">
                    <v-img :src="baseUrl + review.user_image"/>
                </v-avatar>
            </router-link>
            <div class="cc-3">
                <v-rating :value="parseInt(review.grade)*0.05" color="#B29600" background-color="#B29600" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="18"></v-rating>
            </div>
            <div class="cc-4">{{ review.date }}</div>
        </div>
        <div v-show="review.edited" class="cc-5">( edited )</div>
        <div class="ma-2">
            <GenericReviewSpoilerCard :spoiler="review.spoiler" :review="review.review"/>
        </div>
        <span v-if="review.critic">
            <v-chip class="cc-6" outlined @click="reviewLink()">шүүмж</v-chip>
        </span>
        <span class="cc-7">
            <button @click="likeReview()">
                <v-icon class="cc-8">{{ reviewHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon> <small>{{ reviewCount }}</small>
            </button>
        </span>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

import GenericReviewSpoilerCard from '@/components/generics/movie_serial/GenericReviewSpoilerCard.vue'

export default {
    props: {
        review: Object,
        type: String,
        movieSlug: String,
        serialSlug: String,
        seasonSlug: String,
        episodeSlug: String
    },
    components: {
        GenericReviewSpoilerCard
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            emptyIcon: 'mdi-star-outline',
            fullIcon: 'mdi-star',
            halfIcon: 'mdi-star-half-full',
            reviewHeart: this.review.user_like,
            reviewCount: this.review.total_likes
        }
    },
    computed: mapState(['user']),
    methods:{
        reviewLink(){
            if (this.type === 'movie') this.$router.push({ name: 'movie-review-detail', params: { userSlug: this.review.user_slug, movieSlug: this.movieSlug } })
            else if (this.type === 'season') this.$router.push({ name: 'season-review-detail', params: { userSlug: this.review.user_slug, serialSlug: this.serialSlug, seasonSlug: this.seasonSlug } })
            else if (this.type === 'episode') this.$router.push({ name: 'episode-review-detail', params: { userSlug: this.review.user_slug, serialSlug: this.serialSlug, seasonSlug: this.seasonSlug, episodeSlug: this.episodeSlug } })
        },
        likeReview(){
            if (this.user.isAuthenticated) {
                if(this.reviewHeart == true){
                    this.reviewHeart = false
                    this.reviewCount--
                } else{
                    this.reviewHeart = true
                    this.reviewCount++
                }

                if (this.type === 'movie') {
                    axios
                        .post('/movie/api-like-movie-review/', { movie: this.movieSlug, review: this.review.id })
                } else if (this.type === 'season') {
                    axios
                        .post('/serial/api-like-season-review/', { serial: this.serialSlug, season: this.seasonSlug, review: this.review.id })
                } else if (this.type === 'episode') {
                    axios
                        .post('/serial/api-like-episode-review/', {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug, episodeSlug: this.episodeSlug, review: this.review.id})
                }
                
            }
        }
    }
}
</script>

<style scoped>

.cc-1 {
    margin-bottom: 10px;
}

.cc-2 {
    display: flex;
}

.cc-3 {
    flex: 50%;
    margin-left: 10px;
}

.cc-4 {
    flex: 50%;
    text-align: right;
    color: grey;
    font-size: 14px;
}

.cc-5 {
    color: grey;
    font-size: 12px;
    font-style: italic;
    margin-bottom: 15px;
}

.cc-6 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
}

.cc-7 {
    float: right;
}

.cc-8 {
    font-size: 20px;
    color: #0066b5;
}

</style>