<template>
    <v-card class="ma-1 journal-card__box">
        <router-link :to="type === 'movie' ? { name: 'movie-review-detail', params: { userSlug: review.user__user_slug, movieSlug: review.movie__movie_slug }} : { name: 'episode-review-detail', params: { userSlug: review.user__user_slug, serialSlug: review.episode__season__serial__serial_slug, seasonSlug: review.episode__season__season_slug, episodeSlug: review.episode__episode_slug }}">
            <v-img :src="baseUrl + '/media/' + ( type === 'movie' ? review.movie__movie_poster : review.episode__season__serial__serial_poster )" class="rounded"/>
        </router-link>
        <div class="list-card__user_box">
            <div class="movie-review-list__box">
                <img class="rounded-circle movie-review-list__user_image" :src="baseUrl + '/media/' + review.user__image"/>
                <div class="movie-review-list__user_name"><strong>{{ review.user__username }}</strong></div>
            </div>
            <div class="movie-review__title_block">
                <div v-show="type === 'movie'" class="movie-review__title_txt">{{ review.title }}</div>
                <div v-show="type === 'serial'">
                    <div class="movie-review__title_txt">
                        {{ review.episode__episode_name }}
                    </div>
                    <div>
                        {{ review.episode__season__season_name }}
                    </div>
                </div>
            </div>
        </div>
        
        
    </v-card>
</template>

<script>
export default {
    props: {
        review: Object,
        type: String
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL
        }
    }    
}
</script>

<style scoped>

.list-card__user_box {
    padding: 0 10px;
    position: relative;
}

.journal-card__box {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.movie-review__title_block {
    color: #424242;
    margin: 35px 5px 10px;
    width: 100%;
}

.movie-review__title_txt {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 18px;
    font-weight: 600;
}

.movie-review-list__box {
    top: -30px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
}

.movie-review-list__user_image {
    border: 3px solid white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.movie-review-list__user_name {
    margin-top: auto;
    padding: 0 10px;
    flex: 100%;
    font-weight: bold;
    font-size: 12px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

</style>
