<template>
    <v-container v-if="userSlug === requestUserSlug">
        <v-card class="pa-5">
            <legend class="legendBar mt-4 mb-4"><strong>Хэрэглэгчийн зургийн сан:</strong></legend>
            <div style="display: flex; flex-wrap: wrap;">
                <UserImageCard v-for="(image, index) in images" :key="index" :image="image" v-on:delete-image="removeImage(index, image.id)"/>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'
import UserImageCard from '@/components/user/UserImageCard.vue'

export default {
    props: {
        slug: String
    },
    components: {
        UserImageCard
    },
    data(){
        return{
            
            isAuth: this.$store.state.user.isAuthenticated,
            user: this.$store.state.user.name,
            userSlug: this.$store.state.user.slug,
            requestUserSlug: this.slug,
            images: [],
            drag: false,
        }
    },
    methods:{
        getUserImages(){
            axios
                .get('/user/api/v-user-userimages/')
                .then((response) => {this.images = response.data.images})
        },
        removeImage(index, id){
            this.images.splice(index, 1)
            
            axios
                .post('/user/api/v-user-userimagedelete/', {id: id})
                .then(this.$store.commit('SET_SNACK', {message: 'Таны зураг устгагдлаа!', color: 'green'}))
        }
    },
    created(){
        this.getUserImages()
        document.title = 'Хэрэглэгчийн оруулсан зургууд'
        if(this.userSlug !== this.requestUserSlug){
            this.$router.push({ name: 'not-found-404'})
        }
    }
}
</script>

<style>

.legendBar {
    border-bottom: #696969 1px solid;
    opacity: 0.8;
    font-size: 16px;
    margin: auto;
    width: 100%;
}

</style>