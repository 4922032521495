<template>
    <v-card class="create_movie__artist_card">
        <v-img :src="mediaUrl + '/' + ( type === 'artist' ? artist.photo : role.actor.photo )" class="rounded"/>
        <v-card-text class="pa-2">
            <strong>{{ type === 'artist' ? artist.pro_name : role.actor.pro_name }}</strong>
            <br>
            {{ type === 'artist' ? artist.job : role.role }}
        </v-card-text>
        <div class="create_movie__artist_edit_btn">
            <v-btn class="mx-2" v-if="!edited" fab dark x-small color="success" v-on:click="$emit(type === 'artist' ? 'edit-artist' : 'edit-role')"><v-icon dark>mdi-pencil</v-icon></v-btn>
        </div>
        <div class="create_movie__artist_del_btn">
            <v-btn class="mx-2" fab dark x-small color="red" v-on:click="$emit(type === 'artist' ? 'delete-artist' : 'delete-role')"><v-icon dark>mdi-minus</v-icon></v-btn>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        type: String,
        artist: Object,
        role: Object,
        edited: Boolean
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL
        }
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>