<template>
    <div class="movieListMargin" v-show="movieList != ''">
        <div class="movie_list__legend_bar"><strong>{{ type === "movie" ? "Кино" : "Цуврал" }} орсон жагсаалтууд</strong></div>
        <div class="row">
            <ListedCard v-for="(list, index) in movieList" :key="index" :type="type" :list="list" :user_like="list.user_like" :total_likes="list.list_likes" :widthGap="widthGap"/>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import ListedCard from '@/components/generics/movie_serial/GenericListedCard.vue'

export default {
    props: {
        slug: String,
        type: String
    },
    components: {
        ListedCard
    },
    data(){
        return{
            movieSlug: this.slug,
            movieList: [],
            widthGap: 8
        }
    },
    methods:{
        listedMovies(){
            if (this.type === "movie") {
                axios
                    .get('/movie/api-listed-movies/', {params: {slug: this.movieSlug}})
                    .then(response => {this.movieList = response.data.listed_movies})
            } else {
                axios
                    .get('/serial/api-listed-serials/', {params: {slug: this.movieSlug}})
                    .then(response => {this.movieList = response.data.listed_serials})
            }
        }
    },
    created(){
        this.listedMovies()
    },
    watch:{
        slug(){
            this.movieSlug = this.slug
            this.movieList = []
            this.listedMovies()
        }
    }
}
</script>

<style scoped>

.movieListMargin {
    margin: 20px 5px 0;
}

.movie_list__legend_bar {
    color: #0066b5;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
}

.loadMoreButton {
    color:#FFFFFF;
    background-image: linear-gradient(#FFFFFF, #696969);
}

.row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100% !important;
    padding: 0.5rem;
    margin: 0 auto;
    gap: 0.5rem;
}

@media (min-width: 481px) {
    .row {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

</style>