<template>
    <div v-if="user.isAuthenticated && user.contributer" class="create_movie">
        <div class="create_movie__title">Та кино засах гэж байна!</div>
        <v-divider class="mx-4"></v-divider>
        <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitUpdate">
            <div class="create_movie__block">
                <div class="create_movie__image_block">
                    <v-card><v-img :src="moviePosterURL"/></v-card>
                    <v-text-field v-model="moviePosterCheck" disabled label="Постер" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="posterDialog" width="600px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Постер нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="posterDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Киноны постер зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 240, height: 360}" :canvas="{width: 240, height: 360}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropPoster">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                    <v-divider class="mt-5 mb-5"></v-divider>
                    <v-card><v-img :src="movieBackgroundURL"/></v-card>
                    <v-text-field v-model="movieBackgroundCheck" disabled label="Киноны сцен зураг" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="backgroundDialog" width="800px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Сцен зураг нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="backgroundDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Киноны сцен зураг зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 1280, height: 720}" :canvas="{width: 1280, height: 720}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropBackground">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="create_movie__title_block">
                    <v-alert prominent dense type="info">
                        <v-row align="center">
                            <v-checkbox v-model="movieFestival" style="font-weight: 700;" label="Энэхүү киног GGIFF2023-д бүртгүүлнэ*"></v-checkbox>
                        </v-row>
                    </v-alert>
                    <v-combobox v-model="movieLanguageValues" :items="movieLanguageItems" label="Хэл*" multiple item-text="language_mongol_name" :return-object="false" item-value="language_mongol_name" :rules="[rules.choice]"></v-combobox>
                    <v-text-field v-model="movieMongolTitle" label="Киноны Монгол нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="movieInternationalTitle" label="Киноны олон улсын нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="movieOriginalTitle" label="Киноны өөрийн хэл дээрх нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-select v-model="movieCategory" :items="movieCategoryItems" label="Төрөл*" item-text="text" :return-object="false" :rules="[rules.choice]"></v-select>
                    <v-select v-model="movieFormat" :items="movieFormatItems" label="Формат*" :rules="[rules.choice]"></v-select>
                    <v-combobox v-model="movieGenreValues" :items="movieGenreItems" label="Жанр*" multiple item-text="genre_mongol_name" :return-object="false" :rules="[checkFormat]" :error-messages="checkFormat ? '' : 'Бичих шаардлагатай!'"></v-combobox>
                    <v-combobox v-model="movieSubGenreValues" :items="movieSubGenreItems" label="Дэд жанр" multiple item-text="sub_genre_mongol_name" :return-object="false"></v-combobox>
                    <v-text-field v-model="movieYear" type="number" label="Он*" min="0" required :rules="[rules.required, rules.yearCheck]"></v-text-field>
                    <v-combobox v-model="movieCountryValues" :items="movieCountryItems" label="Улс*" multiple item-text="country_mongol_name" :return-object="false" :rules="[rules.choice]"></v-combobox>
                    <v-select v-model="movieRating" :items="movieRatingItems" label="Насны ангилал*" :rules="[rules.choice]"></v-select>
                    <v-text-field v-model="movieRuntime" type="number" label="Үргэлжлэх хугацаа(мин)*" min="0" required :rules="[rules.required, rules.number]"></v-text-field>
                </div>
            </div>
            <div class="create_movie__block_content">
                <v-text-field v-model="movieTrailer" label="Трейлэр" required hint="https://www.youtube.com/watch?v=xxxxxxxx оруулана уу!"></v-text-field>
                <v-combobox v-model="movieTagValues" :items="movieTagItems" label="Киноны таг (Хайх тагаа бичнэ үү!)" :search-input.sync="searchTag" item-text="tag_name" chips multiple :return-object="false"></v-combobox>
                <v-combobox v-model="movieCollectionValue" :items="movieCollectionItems" label="Киног фрейнчайзд нэмэх (Хайх фрейнчайзаа бичнэ үү!)" :search-input.sync="searchCollection" item-text="collection_title" :return-object="false"></v-combobox>
                <v-checkbox v-model="movieTheater" label="Кино театруудаар гарч байгаа"></v-checkbox>
                <v-textarea v-model="moviePlot" label="Үйл явдлын товч*" hint="Та 3000 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.plotLength]"></v-textarea>
                <v-textarea v-model="moviePlotEng" label="Үйл явдлын товч (in English)*" hint="Та 3000 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.plotLength]"></v-textarea>
                <UpdateArtist :slug="slug" :type="'movie'" :artistJobItems="artistJobItems" ref="artists"/>
                <br>
                <UpdateRole :slug="slug" :type="'movie'" ref="roles"/>
                <br>
                <v-card-actions class="justify-center">
                    <v-btn :disabled="checkValid" @click="validate" type="submit" class="create_movie__submit_btn" block>Хадгалах</v-btn>
                </v-card-actions>
            </div>
        </v-form>
        <v-divider class="mx-4 ma-5"></v-divider>
        <router-link class="update_movie__no_decoration" :to="{ name: 'movie-detail', params: { slug: slug }}">
            <v-btn class="update_movie__back_btn">Буцах</v-btn>
        </router-link>
    </div>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import { mapState } from 'vuex'
import 'vue-advanced-cropper/dist/style.css'

import UpdateArtist from '@/views/generics/movie_serial/GenericUpdateArtist.vue'
import UpdateRole from '@/views/generics/movie_serial/GenericUpdateRole.vue'

export default {
    components: {
        Cropper,
        UpdateArtist,
        UpdateRole
    },
    props: {
        slug: String
    },
    data(){
        return{
            currentYear: new Date().getFullYear(),
            artistJobItems: ['Найруулагч', 'Туслах найруулагч', 'Гүйцэтгэх продюсер', 'Продюсер', 'Туслах продюсер', 'Зохиолч', 'Зураглаач', 'Хөгжим', 'Дуу чимээ', 'Зургийн дарга', 'Эвлүүлэгч', 'Зураач', 'Хувцас', 'Гэрэлтүүлэг', 'Визуал эффэкт', 'Кино баг'],
            image: "",
            posterDialog: false,
            backgroundDialog: false,
            baseUrl: process.env.VUE_APP_BASEURL,
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                choice: value => value.length > 0 || 'Сонгох шаардлагатай!',
                number: value => /^[0-9]+$/.test(value) || 'Та зөвхөн тоо оруулна уу!',
                imageSize: value => !value || value.size < 1000000 || 'Зургийн хэмжээ 1 MB -с хэтрэхгүй байх шаардлагатай!',
                plotLength: value => value.length <= 3000 || '3000 тэмдэгтээс хэтэрлээ!',
                yearCheck: value => value >= 1900 && value <= this.currentYear || 'Та оноо зөв оруулна уу!'
            },
            movie: {},
            movieLanguageItems: [],
            movieLanguageValues: [],
            movieMongolTitle: '',
            movieInternationalTitle: '',
            movieOriginalTitle: '',
            moviePoster: null,
            moviePosterURL: null,
            moviePosterCheck: null,
            movieBackground: null,
            movieBackgroundURL: null,
            movieBackgroundCheck: null,
            movieCategoryItems: [{text: 'Бүрэн хэмжээний кино', value: 'Бүрэн хэмжээний кино'}, {text: 'Богино хэмжээний кино', value: 'Богино хэмжээний кино'}],
            movieCategory: '',
            movieFormatItems: [{text: 'Уран сайхны кино', value: 'Уран сайхны кино'}, {text: 'Баримтат кино', value: 'Баримтат кино'}, {text: 'Хүүхэлдэйн кино', value: 'Хүүхэлдэйн кино'}, {text: 'Эксперименталь', value: 'Эксперименталь'}],
            movieFormat: 0,
            movieGenreItems: [],
            movieGenreValues: [],
            movieSubGenreItems: [],
            movieSubGenreValues: [],
            moviePlot: '',
            moviePlotEng: '',
            movieYear: 0,
            movieCountryItems: [],
            movieCountryValues: [],
            movieRatingItems: [{text: 'NR - Not Rated', value: 'NR - Not Rated'}, {text: 'G - General Audiences', value: 'G - General Audiences'}, {text: 'PG - Parental Guidance Suggested', value: 'PG - Parental Guidance Suggested'}, {text: 'R - Restricted', value: 'R - Restricted'}],
            movieRating: '',
            movieRuntime: 0,
            movieTrailer: '',
            searchTag: null,
            movieTagItems: [],
            movieTagValues: [],
            searchCollection: null,
            movieCollectionItems: [],
            movieCollectionValue: '',
            movieTheater: false,
            movieFestival: false
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        checkFormat(){
            if(this.movieFormat === "Эксперименталь" || this.movieFormat === "Баримтат кино" || this.movieGenreValues.length > 0){
                return true
            } else{
                return false
            }
        },
        ...mapState(['user'])
    },
    watch:{
        searchTag(val){
            if(val){
                axios
                    .get('/tag/api-tag-list/', { params: { tag: val }}).then(response => {this.movieTagItems = response.data.tag_list})
            }
        },
        searchCollection(val){
            if(val){
                axios
                    .get('/movie/api-movie_collection_list/', { params: { name: val } })
                    .then(response => {this.movieCollectionItems = response.data.collection_list})
            }
        }
    },
    methods:{
        getLanguage(){
            axios
                .get('/language/api-language-list/').then(response => {this.movieLanguageItems = response.data.language_list})
        },
        getGenre(){
            axios
                .get('/genre/api-genre-list/').then(response => {this.movieGenreItems = response.data.genre_list})
        },
        getSubGenre(){
            axios
                .get('/genre/api-subgenre-list/').then(response => {this.movieSubGenreItems = response.data.subgenre_list})
        },
        getCountry(){
            axios
                .get('/country/api-production-country-list/').then(response => {this.movieCountryItems = response.data.country_list})
        },
        validate(){
            this.$refs.form.validate()
        },
        getMovieDetail(){
            axios
                .get('/movie/api-movie-detail/', { params: { slug: this.slug } })
                .then(response => { this.movie = response.data.movie_detail, 
                                    document.title = this.movie['i_title'],
                                    this.movieLanguageValues = this.movie['languages'],
                                    this.movieMongolTitle = this.movie['m_title'],
                                    this.movieInternationalTitle = this.movie['i_title'],
                                    this.movieOriginalTitle = this.movie['o_title'],
                                    this.moviePosterURL = this.baseUrl + this.movie['poster'],
                                    this.moviePosterCheck = 'movie-poster',
                                    this.movieBackgroundURL = this.baseUrl + this.movie['background'],
                                    this.movieBackgroundCheck = 'movie-background',
                                    this.movieCategory = this.movie['category'],
                                    this.movieFormat = this.movie['format'],
                                    this.movieGenreValues = this.movie['genres'],
                                    this.movieSubGenreValues = this.movie['subgenres'],
                                    this.moviePlot = this.movie['plot'],
                                    this.moviePlotEng = this.movie['en_plot'],
                                    this.movieYear = this.movie['year'],
                                    this.movieCountryValues = this.movie['countries'],
                                    this.movieRating = this.movie['rating'],
                                    this.movieRuntime = this.movie['runtime'],
                                    this.movieTrailer = this.movie['trailer'],
                                    this.movieTagValues = this.movie['tags'],
                                    this.movieCollectionValue = this.movie['collection'],
                                    this.movieTheater = this.movie['theater'],
                                    this.movieFestival = this.movie['festival'] })
        },
        submitUpdate(){
            var artists = this.$refs.artists.artistList
            var roles = this.$refs.roles.roleList

            const updateItems = {
                'slug': this.slug,
                'languages': JSON.stringify(this.movieLanguageValues),
                'm_title': this.movieMongolTitle,
                'i_title': this.movieInternationalTitle,
                'o_title': this.movieOriginalTitle,
                'category': this.movieCategory,
                'format': this.movieFormat,
                'genres': JSON.stringify(this.movieGenreValues),
                'subgenres': JSON.stringify(this.movieSubGenreValues),
                'plot': this.moviePlot,
                'en_plot': this.moviePlotEng,
                'year': this.movieYear,
                'countries': JSON.stringify(this.movieCountryValues),
                'rating': this.movieRating,
                'runtime': this.movieRuntime,
                'trailer': this.movieTrailer,
                'tags': JSON.stringify(this.movieTagValues),
                'collection': this.movieCollectionValue,
                'theater': this.movieTheater,
                'festival': this.movieFestival,
                'artists': JSON.stringify(artists),
                'roles': JSON.stringify(roles)
            }

            const updateData = new FormData()

            if(this.moviePoster){
                updateData.append('movie-poster', this.moviePoster, 'movie_poster')
            }

            if(this.movieBackground){
                updateData.append('movie-background', this.movieBackground, 'movie_background')
            }

            for( var key in updateItems ){
                updateData.append(key, updateItems[key])
            }

            axios
                .post('/movie/api-update-movie/', updateData)
                .then((response) => {
                    if (response.data.success === true){
                        this.$store.commit('SET_SNACK', {message: 'Киноны засвар амжилттай хийгдлээ!', color: 'green'})
                        this.$router.push({ name: 'movie-detail', params: { slug: this.slug }})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        },
        cropPoster() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.moviePoster = blob }, "image/jpeg")
            this.moviePosterURL = canvas.toDataURL("jpeg")
            this.moviePosterCheck = 'movie-poster'
            this.posterDialog = false
            this.image = ''
        },
        cropBackground() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.movieBackground = blob }, "image/jpeg")
            this.movieBackgroundURL = canvas.toDataURL("jpeg")
            this.movieBackgroundCheck = 'movie-background'
            this.backgroundDialog = false
            this.image = ''
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])

                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        }
    },
    created(){
        this.getLanguage()
        this.getGenre()
        this.getSubGenre()
        this.getCountry()
        this.getMovieDetail()
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>
