<template>
    <v-container class="d-flex justify-center">
        <v-card v-if="activation === false" class="ma-5" width="400" :disabled="$store.state.isLoading">
            <v-card-title class="justify-center">Системд бүртгүүлэх</v-card-title>
            <div v-if="errors.length" style="text-align: center; color: red;">
                <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
            </div>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-row class="justify-center my-4">
                    <v-form ref="form" style="width: 300px;" v-model="formValid" v-on:submit.prevent="signUp">
                        <v-text-field  v-model="username" name="username" label="Хэрэглэгчийн нэр" required :rules="[rules.required, rules.nameLength, rules.nameCharacter, rules.nameAlphabet]"></v-text-field>
                        <v-text-field v-model="email" name="email" label="И-мэйл хаяг" required :rules="[rules.required, rules.email]"></v-text-field>
                        <v-text-field v-model="phone" name="phone" label="Утасны дугаар" required :rules="[rules.required, rules.phoneRule]"></v-text-field>
                        <v-text-field v-model="password1" name="password1" label="Нууц үг" required type="password" :rules="[rules.required, rules.passwordValidation]"></v-text-field>
                        <small style="max-width: 200px; font-weight: bold;">
                            <p style="margin: 0; font-style: italic;" :class="passMinimum ? 'pass_required' : ''">- Багадаа 10 тэмдэгт</p> 
                            <p style="margin: 0; font-style: italic;" :class="passLowercase ? 'pass_required' : ''">- Жижиг Англи үсэг</p>
                            <p style="margin: 0; font-style: italic;" :class="passUppercase ? 'pass_required' : ''">- Том Англи үсэг</p>
                            <p style="margin: 0; font-style: italic;" :class="passNumber ? 'pass_required' : ''">- Тоо</p>
                            <p style="margin: 0; font-style: italic;" :class="passSpecial ? 'pass_required' : ''">- Тусгай тэмдэгт: @,#,$,!,%,*,?,&</p>
                        </small>
                        <v-text-field class="mt-4" v-model="password2" name="password2" label="Нууц үг батлах" required type="password" :rules="[rules.required, rules.passwordMatch]"></v-text-field>
                        <v-dialog v-model="userTermsAndConditionDialog" width="600px">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <v-checkbox v-model="userTermsAndConditionCheck">
                                        <template v-slot:label>
                                            <span style="font-size: 12px;">
                                                Би mfi.mn кино сан, платформын <span style="color: #0066b5;">үйлчилгээний нөхцлийг</span> зөвшөөрч байна!
                                            </span>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </template>
                            <v-card>
                            <v-card-title>
                                <span>mfi.mn кино сан, платформын үйлчилгээний нөхцөл</span>
                            </v-card-title>
                            <v-card-text>
                                <br>
                                <h3>1.Ерөнхий зүйл</h3>
                                <br>
                                <p>1.1 mfi.mn-ийн аливаа контентийг ашиглах эсвэл шинэ контентийг сайтад илгээхдээ энэхүү нөхцлийг баримтална.</p>
                                <p>1.2 Та сайтын контентийг ашиглах эсвэл сайтад контентийг илгээснээрээ энэхүү үйлчилгээний нөхцлийг бүрэн хүлээн зөвшөөрсөнд тооцогдоно.</p>
                                <p>1.3 Бид энэхүү журмын нөхцөлд нэмэлт, өөрчлөлт оруулах эрхтэй бөгөөд та сайтыг үргэлжлүүлэн ашигласнаараа тэдгээрийг ч хүлээн зөвшөөрсөнд тооцогдоно.</p>
                                <p>1.4 Энэхүү үйлчилгээний нөхцлийн хүчин төгөлдөр хувилбарыг mfi.mn дээр байршуулсан байна.</p>
                                <br>
                                <h3>2. mfi.mn кино сан, платформ түүний эрх үүрэг</h3>
                                <br>
                                <p>2.1 Бид сайтыг хаах, үйл ажиллагааг зогсоох эсвэл таны хэрэглээг цуцлах боломжтой. Бид хэзээд сайтыг өөрчлөх, үйл ажиллагааг бүрмөсөн эсвэл түр хугацаагаар зогсоох эрхтэй бөгөөд ингэхдээ энэ тухайгаа мэдэгдэж эсвэл мэдэгдэхгүй байж болно. Бид сайтыг өөрчлөх, зогсоох, хаахтай холбоотойгоор таны болон өөр аливаа нэг гуравдагч этгээдийн өмнө үүрэг хариуцлага хүлээхгүй.</p>
                                <p>2.2 Мөн mfi.mn болон бидний хэрэглэгчдийн ашиг сонирхолд харшилж байна гэж үзвэл ямар ч шалтгаанаар таны сайтад нэвтрэх эрхийг цуцлах эрхтэй бөгөөд өөр төрлийн арга хэмжээг авах боломжтой.</p>
                                <p>2.3 Бид тодорхой мэдээллийг хууль ёсоор задруулах үүрэг хүлээж болно.</p>
                                <p>2.4 Шүүх, хууль сахиулах болон төрийн байгууллагаас хууль ёсны шаардлага ирүүлсэн тохиолдолд таны зөвшөөрөл эсвэл урьдчилан мэдэгдэлгүйгээр таны IP хаяг, хэрэглэгчийн нэр, нэр, IP байршил, бусад мэдээллийг бид гаргаж өгөх үүрэгтэйг та хүлээн зөвшөөрнө.</p>
                                <p>2.5 Бид хэдийгээр тус сайтад аливаа вирус, worm, trojan horse, бусад төрлийн хор хөнөөлтэй материалуудаас урьдчилан сэргийлэх боломжит бүх арга хэмжээг авах хэдий ч манай сайтаас татаж авах бүх материалд дээрх хортой нөлөөлөл байхгүй байна гэсэн баталгаа гаргахгүй.</p>
                                <p>2.6 Тийм нөхцөл байдал үүссэн тохиолдолд гэм хор, хохирлыг мөн хариуцлахгүй болно. Хэрэглэгч манай сайтад байгаа аливаа материалыг зөвхөн өөрөө эрсдэлийг хариуцах үндсэн дээр хэрэглэнэ.</p>
                                <br>
                                <h3>3. Оюуны өмчлөл</h3>
                                <br>
                                <p>3.1 mfi.mn нь сайтын редакцын бүтээсэн контент (аливаа бичвэр, гэрэл зураг, график, видео, аудио, эдгээрийн холимог) болон редакцын бус зохиогчдын нийтлэл нь зохиогчийн эрхээр хамгаалагдсан бөгөөд mfi.mn болон зохиогч тэрхүү эрхийг эзэмшинэ.</p>
                                <p>3.2 Хэрэглэгч нь сайт дээр байгаа зохиогчийн эрхтэй холбоотой бусад анхааруулга, хэм хэмжээг дагаж мөрдөх үүрэгтэй.</p>
                                <p>3.3 Тусгайлан зөвшөөрөгдсөнөөс бусад тохиолдолд сайтад нийтлэгдсэн аливаа контентийг хуулбарлах, түгээх, нийтлэх, сайтын өгөгдлийн санд нэвтрэх, өөрчлөх, шилжүүлэх эсвэл ашиглахыг хориглоно.</p>
                                <p>3.4 Сайтын контентийг хувийн хэрэгцээнд, ашгийн бус зорилгоор хэвлэх, эшлэх зэргээр зүй ёсоор ашиглаж болно.</p>
                                <p>3.5 Хэрэглэгч ба редакцын бус зохиогч нь өөрийн нийтэлсэн контент болон тэдгээрээс үүсэх үр дагаврыг бүрэн хариуцна.</p>
                                <p>3.6 mfi.mn-д нийтлэгдсэн аливаа агуулга нь зохиогчийн болон бусад эрх зөрчилтэй гэж үзвэл энд дарна уу.</p>
                                <br>
                                <h3>4. Гуравдагч этгээдийн контент</h3>
                                <br>
                                <p>4.1 mfi.mn-ийн редакцын бус зохиогч нар болон хэрэглэгчдийн зохиосон шүүмж, кино тайлбар, нийтлэл, сэтгэгдэл зэрэг дэх үзэл бодол нь тус сайтын редакцын үзэл бодлыг илэрхийлэхгүй, тус сайт нь зохиогч, хэрэглэгчдийн байр суурийн төлөө ямар нэг хариуцлага хүлээхгүй болно.</p>
                                <p>4.2 Гуравдагч этгээдийн бий болгосон контентийн үнэн зөв, бүрэн бүтэн байдлыг тухайн этгээд хариуцах ба mfi.mn энэ талаар ямар нэг баталгаа гаргаж өгөхгүй.</p>
                                <br>
                                <h3>5. Хэрэглэгчийн хариуцлага</h3>
                                <br>
                                <p>5.1 Хэрэглэгч нь дараах нөхцлүүдийг хүлээн зөвшөөрнө:</p>
                                <ul>(1) зохиогчийн эрх, барааны тэмдэг болон нууцлалын эрх, бусад өмчийн холбогдолтой эрх, хувийн нууц, хэвлэн нийтлэх эрх нь гуравдагч этгээдэд хадгалагдаж буй аливаа контентийг тэдний зөвшөөрөлгүйгээр нийтлэх, илгээхгүй байх;</ul>
                                <ul>(2) бидэнд болон аливаа гуравдагч этгээдэд хохирол учруулахуйц худал болон буруу мэдээлэл нийтлэхгүй байх;</ul>
                                <ul>(3) хууль бус, бүдүүлэг, гүтгэсэн, доромжилсон, заналхийлсэн, садар самуун/порнограф; дарамталсан, үзэн ядалтын, арьс өнгө, гарал үүслээр ялгаварласан, гэмт хэргийн шинжтэй эсвэл хууль зөрчсөн үйлдэлд уриалсан контентийг илгээхгүй байх;</ul>
                                <ul>(4) бизнесийн зар, сурталчилгаа байршуулахгүй байх үүрэгтэй. Бид урьдчилан сануулахгүйгээр илгээсэн контентийг нийтлэхгүй байх, устгах эрхтэй.</ul>
                                <p>5.2 Сайт дахь контент, түүнээс үүдэн гарах, тухайлбал (гэхдээ үүгээр хязгаарлагдахгүй) гүтгэлэг, доромжлол, заналхийлэл, хувийн нууц болон хэвлэн нийтлэлийн эрхийн зөрчил, садан самуун/порнограф, бүдүүлэг байдал, хууран мэхлэлт, ташаа мэдээллийн төлөө аливаа үүрэг, хариуцлага хүлээхгүй.</p>
                                <p>5.3 mfi.mn-ийн редакц, ажилтнууд, хамтрагчид, төлөөлөгчид, хувьцаа эзэмшигчид нь аливаа зохисгүй, хууль бус контентийг тогтмол хянах үүрэг хүлээхгүй.</p>
                                <p>5.4 Дээрхээс үл хамааран, mfi.mn нь энэ нөхцлийг зөрчсөн аливаа харилцаа холбоо, нийтлэл, материалыг өөрийн үзэмжээр хэзээд устгах, тодорхой эрхийг цуцлах эрхтэй.</p>
                                <p>5.5 Энэхүү болон mfi.mn-тэй тохирсон бусад нөхцлийг зөрчсөний улмаас mfi.mn, түүний хамтрагч, төлөөлөгч, хувьцаа эзэмшигчдэд учирсан бүх төрлийн хохирол, зардал, гэм хорыг (үүнд хуульч, өмгөөлөгчийн зардлыг оруулна) хохирол учруулсан этгээд хариуцна.</p>
                                <br>
                                <h3>6. Хуулийн хариуцлага</h3>
                                <br>
                                <p>6.1 Медиа платформтой холбоотой аливаа маргааныг эвийн журмаар, боломжгүй тохиолдолд Монгол улсын хуулиар, Монгол улсын шүүхэд шийдвэрлүүлнэ.</p>
                                <p>6.2 Тус үйлчилгээний нөхцөл нь Монгол улсын холбогдох хууль тогтоомжид нийцсэн болно.</p>
                                <p>6.3 Манай mfi.mn платформыг үргэлжүүлэн ашигласнаар та дээрх үйлчилгээний нөхцөлийн хүлээн зөвшөөрсөнд тооцно.</p>
                                <p>6.4 Тус үйлчилгээний нөхцөлийг маргаан шийдвэрлэх зорилгоор цаашид тайлбарлах боломжтой.</p>
                                <br>
                                <h3>7. mfi.mn-д сэтгэгдэл бичих журам</h3>
                                <br>
                                <p>7.1 mfi.mn нь ардчилсан, чөлөөт орон зай бөгөөд мэдээ болон нийтлэл дээр сэтгэгдлээ хуваалцахыг дэмждэг.</p>
                                <p>7.2 Бид хүндэтгэлтэй, мэдээллийн чанар сайтай харилцан ярианууд үүсэхийг хөхиүлэн дэмжих бөгөөд дээрх орчинг бүрдүүлэхийн тулд тодорхой журмыг баримталдаг. Энэхүү журмыг тусгай системийн тусламжтайгаар зохицуулагчдын баг сахиулдаг.</p>
                                <p>7.3 Та сайтын орон зайд нэвтрэн оролцохыг хүсвэл энэхүү журмыг баримтална.</p>
                                <p>7.4 Таны сэтгэгдэл бидний үүсгэхийг зорьж буй орчинд харшилж байвал сэтгэгдлийг устгах цаашлаад таныг вебсаитаас хасах боломжтой.</p>
                                <p>7.5 mfi.mn нь хүндэтгэлтэй, бүтээлч харилцан ярианд хувь нэмэр оруулж байгаа хүн бүрт түүний улс төрийн үзэл баримтлал, идеологи, шашин шүтлэгээс үл хамааран нээлттэй байна.</p>
                                <p>7.6 Харин шууд болон шууд бус дайралт, доромжлол, бусдыг өдөөн хатгасан, айлган сүрдүүлсэн аливаа тохуурхлыг үл тэвчинэ. Энэ төрлийн аливаа сэтгэгдлийг бид сайтаас устгах эрхтэй. Энэ төрлийн сэтгэгдлийг удаа дараа болон санаатай бичсэн этгээдийн сэтгэгдэл бичих эрхийг хасах бөгөөд шаардлагатай гэж үзвэл хэрэглэгчийн эрхийг бүрмөсөн цуцална.</p>
                                <p>7.7 Илгээсэн сэтгэгдлийг нийтлэгдсэний дараа зохицуулагчдын баг уншиж хянах эрхтэй. Хяналт нь сайтын сэтгэгдлийн ачааллаас хамааран янз бүрийн хугацаанд үргэлжлэх боломжтой бөгөөд та бүхэн үүнд тэвчээртэй хандана уу. Сэтгэгдлийг дахин дахин илгээх болон сэтгэгдлийг устгасан гэсэн гомдлыг яаран гаргахгүй байхыг хүсье.</p>
                                <p>7.8 Хүн бүрийн санал үнэ цэнэтэй бас өөрийн гэсэн онцлогтой. Өөр хэн нэгэн шиг байх гэсэн оролдлого тухайн санал сэтгэгдлийн үнэ цэнийг үгүй хийх болно. Тиймээс бусдыг төлөөлөх болон буруу ташаа мэдээлэл тараахаас зайлсхийнэ үү. Бид төлөөллийн аливаа алдааг илрүүлсэн даруйдаа сэтгэгдлийг устгана.</p>
                                <p>7.9 Бид mfi.mn хувь хүн, бүлэг, тэдгээрийн санал сэтгэгдлийн аюулгүй орчин байх ёстой гэж үздэг. Тиймээс бид доромж, дайсагнасан, заналхийлсэн үг хэллэгийг үл тэвчинэ. Хэрэв шууд болон шууд бусаар сайтын хэрэглэгч болон бүлгийн бие болон сэтгэл санаанд заналхийлвэл таныг сайтаас хасах болно. Хэрэв тэрхүү заналхийлэл нь бодитой гэж үзвэл сайтаас хасаад зогсохгүй хууль сахиулах байгууллагад мэдэгдэн шалгуулах болно.</p>
                                <p>7.10 Хувь хүний хувийн мэдээлэл болох бүтэн нэр, гэрийн хаяг, утасны дугаар, цахим шуудангийн хаягийг сэтгэгдэлд бичихийг хориглоно. Эдгээрийн аль нэгийг агуулсан аливаа сэтгэгдлийг мөн устгана.</p>
                            </v-card-text>
                            <v-card-actions>
                                <v-row justify="center">
                                    <v-btn class="ma-5" style="color: white; background-color: green;" block @click="userTermsAndConditionDialog = false; userTermsAndConditionCheck = true;">Зөвшөөрч байна!</v-btn>
                                </v-row>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-card-actions class="justify-center"><v-btn :disabled="checkValid" type="submit" style="color: white; background-color: #0066b5;">бүртгүүлэх</v-btn></v-card-actions>
                    </v-form>
                </v-row>
                <v-divider class="mx-4"></v-divider>
                <v-row class="justify-center mt-2 my-4">
                    <div style="display: block; margin: 10px; margin-top: 20px;">
                        Мартсан нууц үгээ <router-link :to="{ name: 'forgot-password' }" style="font-weight: bold; text-decoration: none; color: #0066b5;">солих</router-link>
                    </div>
                    <div style="display: block; margin: 10px;">
                        Утасны дугаараа <router-link :to="{ name: 'change-phone' }" style="font-weight: bold; text-decoration: none; color: #0066b5;">өөрчлөх</router-link>
                    </div>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card v-else class="ma-5" width="400" :disabled="$store.state.isLoading">
            <v-card-title class="justify-center">Бүртгэл идэвхжүүлэх</v-card-title>
            <div v-if="errors.length" style="text-align: center; color: red;">
                <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
            </div>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-row ref="form" class="justify-center my-4">
                    <v-form style="width: 300px;" v-model="activateFormValid" v-on:submit.prevent="activate">
                        <v-text-field v-model="phone" name="phone" label="Утасны дугаар" required disabled></v-text-field>
                        <div style="text-align: center; margin: 10px;">
                            <span v-show="!codeResend" style="font-style: italic;">Та {{ countDown }} секундын дараа дахин код авах боломжтой!</span>
                            <span v-show="codeResend"><v-btn x-small @click="resendCode()">Дахин код авах</v-btn></span>
                        </div>
                        <span>Нэг удаагийн код оруулах</span>
                        <v-otp-input v-model="code" length="6" @keypress="filter($event)" :rules="[rules.required, rules.codeLength]"></v-otp-input>
                        <v-card-actions class="justify-center"><v-btn :disabled="checkActivateValid" type="submit" color="success">идэвхжүүлэх</v-btn></v-card-actions>
                    </v-form>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Signup',
    data(){
        return{
            userTermsAndConditionDialog: false,
            userTermsAndConditionCheck: false,
            formValid: false,
            activateFormValid: false,
            countDown : 60,
            count: 0,
            codeResend: false,
            username: '',
            email: '',
            phone: '',
            code: '',
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                nameLength: value => value.length >= 3 && value.length <= 20 || 'Хамгийн багадаа 3, ихдээ 20 тэмдэгт оруулна уу!',
                nameCharacter: value => /^[0-9a-zA-Z]+$/.test(value) || 'Та нэрэндээ Англи үсэг болон тооноос өөр тэмдэгт оруулах боломжгүй!',
                nameAlphabet: value => /^([0-9a-zA-Z]*[a-zA-Z]){3}[0-9a-zA-Z]*$/.test(value) || 'Та нэрэндээ багадаа 3 үсэг оруулана уу!',
                phoneRule: value => !isNaN(parseFloat(value)) && value >= 10000000 && value <= 99999999 || 'Утасны дугаараа зөв оруулна уу!',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Та э-мэйл хаягаа зөв оруулна уу!'
                },
                passwordValidation: value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&]).{10,}$/.test(value),
                passwordMatch: value => value === this.password1 || 'Та баталгаажуулах нууц үгээ зөв оруулна уу!',
                codeLength: value => value.length == 6
            },
            password1: '',
            password2: '',
            passMinimum: false,
            passNumber: false,
            passLowercase: false,
            passUppercase: false,
            passSpecial: false,
            errors: [],
            activation: false,
            status: ''
        }
    },
    watch:{
        password1(){
            this.passMinimum = (this.password1.length >= 10)
            this.passNumber = /\d/.test(this.password1)
            this.passLowercase = /[a-z]/.test(this.password1)
            this.passUppercase = /[A-Z]/.test(this.password1)
            this.passSpecial = /[@#$!%*?&]/.test(this.password1)
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true && this.userTermsAndConditionCheck == true){
                return false
            } else {
                return true
            }
        },
        checkActivateValid(){
            if(this.activateFormValid == true){
                return false
            } else {
                return true
            }
        }
    },
    methods:{
        filter: function(evt) {
            evt = (evt) ? evt : window.event
            let expect = evt.target.value.toString() + evt.key.toString()
            
            if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        countDownTimer(){
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            } else {
                this.codeResend = !this.codeResend
                this.count = this.count + 60
                this.countDown = this.count
            }
        },
        async resendCode(){
            this.errors = []

            if(this.phone === ''){
                this.errors.push("Та утасны дугаараа оруулна уу!")
            }

            if(!this.errors.length){
                this.$store.commit('SET_ISLOADING', true)

                this.$store.dispatch('resendCode', { phone: this.phone })
                    .then(() => {
                        this.$store.commit('SET_ISLOADING', false)
                    })

                this.codeResend = !this.codeResend
                this.countDownTimer()
            }
        },
        async signUp(){
            this.errors = []
        
            if(this.username === ''){
                this.errors.push("Та хэрэглэгчийн нэр оруулна уу!")
            } else if(this.username.length <= 3 && this.username.length >= 25){
                this.errors.push("Та хэрэглэгчийн нэрээ 3-25 тэмдэгтэд багтааж оруулна уу!")
            }

            if(this.email === ''){
                this.errors.push("Та и-мэйл хаягаа оруулна уу!")
            } else if(!/.+@.+\..+/.test(this.email)){
                this.errors.push("И-Мэйл хаягаа зөв бөглөнө үү!")
            }

            if(this.phone === ''){
                this.errors.push("Та утасны дугаараа оруулна уу!")
            } else if(this.phone.length != 8 || this.phone <= 10000000 && this.phone >= 99999999){
                this.errors.push("Та утасны дугаараа зөв оруулна уу!")
            }

            if(this.password1 === ''){
                this.errors.push("Та хэрэглэгчийн нууц үг оруулна уу!")
            }

            if(this.password1 !== this.password2){
                this.errors.push("Нууц үгнүүд таарахгүй байна!")
            }

            if(!this.errors.length){
                this.$store.commit('SET_ISLOADING', true)

                const signUpData = {
                    username: this.username,
                    password: this.password1,
                    email: this.email,
                    phone: this.phone
                }

                await axios
                    .post('/v1/users/', signUpData)
                    .then( () => {
                        this.$store.commit('SET_SNACK', {message: 'Таны бүртгэл амжилттай хийгдлээ. Та бүртгэлээ идэвхжүүлэнэ үү!', color: 'success'})
                        this.activation = true
                    })
                    .catch(error => {
                        if(error.response){
                            const errors = Object.values(error.response.data)
                            errors.forEach(element => {
                                if(element[0] === "A user with that username already exists."){
                                    this.errors.push("Бүртгэгдсэн хэрэлэгчийн нэр!")
                                } else if(element[0] === "user with this email already exists."){
                                    this.errors.push("Бүртгэгдсэн имейл хаяг!")
                                } else if(element[0] === "user with this phone already exists."){
                                    this.errors.push("Бүртгэгдсэн утасны дугаар!")
                                } else {
                                    this.errors.push(error.response.data)
                                }
                            })
                        } else if(error.message){
                            this.errors.push('Алдаа гарлаа, дахин оролдож үзнэ үү!')
                        }
                    })

                this.$store.commit('SET_ISLOADING', false)
                this.countDownTimer()
            }
        },
        async activate(){
            this.errors = []

            if(this.phone === ''){
                this.errors.push("Та утасны дугаараа оруулна уу!")
            }

            if(this.code === ''){
                this.errors.push("Та код оруулна уу!")
            }

            if(!this.errors.length){
                this.$store.commit('SET_ISLOADING', true)

                this.$store.dispatch('userActivate', { code: this.code, phone: this.phone })
                    .then(() => {
                        this.$store.commit('SET_ISLOADING', false)
                    })
            }
        }
    },
    created(){
        this.$store.commit('SET_ACTIVATE', false)
        this.$store.commit('SET_PHONE_ACTIVATE', false)
    }
}
</script>
<style scoped>

.pass_required{
    text-decoration: line-through;
    color:#689868;
}

</style>