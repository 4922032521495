<template>
    <div class="movieMargin" v-show="totalMovies != ''">
        <legend class="legend_Bar"><strong>{{ title }}</strong></legend>
        <div class="row">
            <GenericMovieListCard v-for="(movie, index) in totalMovies" :key="index" :movie="movie" :type="type"/>
        </div>
        <div v-show="this.format === 'collected'" class="load-more-btn">
            <v-btn block outlined color="#0066b5" v-show="endPoint" @click="movieListCall(loadMore += 1)">Бусад ангиуд</v-btn>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import GenericMovieListCard from '@/components/generics/movie_serial/GenericMovieListCard.vue'

export default {
    props: {
        title: String,
        type: String,
        format: String,
        slug: String
    },
    components: {
        GenericMovieListCard
    },
    data(){
        return{
            movieSlug: this.slug,
            totalMovies: [],
            movieList: [],
            loadMore: 1,
            endPoint: ''
        }
    },
    methods:{
        movieListCall(){
            if (this.type === "movie") {
                if (this.format === "collected") {
                    axios
                        .get("/movie/api-movie-collection/", { params: {slug: this.movieSlug, loadMore: this.loadMore }})
                        .then(response => { this.movieList = response.data.movies, this.endPoint = response.data.end_point, this.totalMovies = this.totalMovies.concat(this.movieList) })
                } else if (this.format === "similar") {
                    axios
                        .get("/movie/api-similar-movies/", { params: {slug: this.movieSlug }})
                        .then(response => { this.totalMovies = response.data.similar_movies })
                }
                else if (this.format === "suggested") {
                    axios
                        .get("/movie/api-suggested-movies/", { params: { slug: this.movieSlug }})
                        .then(response => { this.totalMovies = response.data.recommended_movies })
                }
            } else if (this.type === "serial") {
                if (this.format === "similar") {
                    axios
                        .get("/serial/api-similar-serials/", { params: {slug: this.movieSlug }})
                        .then(response => { this.totalMovies = response.data.similar_serials })
                }
                else if (this.format === "suggested") {
                    axios
                        .get("/serial/api-suggested-serials/", { params: { slug: this.movieSlug }})
                        .then(response => { this.totalMovies = response.data.recommended_serials })
                }
            }
        }
    },
    created(){
        this.movieListCall()
    },
    watch:{
        slug(){
            this.movieSlug = this.slug
            this.totalMovies = []
            this.movieList = []
            this.loadMore = 1
            this.endPoint = ''
            this.movieListCall()
        }
    }
}
</script>

<style scoped>

.movieMargin{
    margin: 20px 5px 0;
}

.legend_Bar {
    color: #0066b5;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
}


.row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    margin: 0 auto;
}

.load-more-btn {
    text-align: center;
}

@media (min-width: 481px) {
    .row {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
}

</style>