<template>
	<masonry-wall :items="list" :ssr-columns="1" :column-width="300" :gap="16">
		<template #default="{ item, index }">
			<v-card class="content">
				<router-link :to="{ name: 'movie-review-detail', params: { userSlug: item.user_slug, movieSlug: item.slug }}">
					<img class="home_box__journal_img rounded" :src="url + item.poster"/>
				</router-link>
				<div class="home_box__review_user_box">
					<div class="home_box__user_block">
						<router-link :to="{ name: 'user-feed', params: {slug: item.user_slug }}">
							<img class="home_box__review_user_img" :src="url + '/' + item.user_image"/>
						</router-link>
						<div class="home_box__review_user_name">{{ item.user_name }}</div>
					</div>
				</div>
				<div class="home_box__movie_title">
					{{ item.movie_title }}
				</div>
				<div class="home_box__review_title">
					{{ item.title }}
				</div>
			</v-card>
		</template>
	</masonry-wall>
</template>

<script>
export default {
    props: {
        idName: String,
        list: Array,
        type: String,
        format: String
    },
    data(){
        return{
            url: process.env.VUE_APP_BASEURL,
            allItems: []
        }
    }
}
</script>
<style scoped>

.home_box__generic_listcard {
    margin-bottom: 10px;
}

.home_box__journal_img {
    object-position: 0 0;
    max-height: 450px;
    width: 100%;
    object-fit: cover;
}

.home_box__review_user_box {
    padding: 0 10px;
    position: relative;
    height: 25px;
}

.home_box__user_block {
    top: -30px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
}

.home_box__review_user_img {
    border: 3px solid white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.home_box__review_user_name {
    margin-top: auto;
    margin-left: 0.3em;
    margin-bottom: 0.5em;
    font-weight: 600;
    font-size: .8em;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}

.home_box__movie_title {
	padding: 10px 15px 0;
	white-space: initial;
	font-size: 1.4em;
	line-height: 24px;
	font-weight: 600;
}

.home_box__review_title {
	padding: 10px 15px;
	font-size: 1.2em;
	line-height: 1.2em;
	font-weight: 300;
	color: #696969;
}

</style>