<template>
    <div class="festival-list__box">
        <div class="festival-list__title_block">
            <div class="festival-list__title">{{ title }}</div>
            <div v-html="desc" class="festival__about_txt">
            </div>
        </div>
        <div class="festival-list__row">
            <div class="d-flex flex-row flex-wrap">
                <router-link class="festival_poster__anchor" v-for="(date, index) in dates" :key="index" :to="{ name: 'annual-award-detail', params: { slug: date.slug, year: date.year }}">
                    <v-img :src="baseUrl + date.poster" class="rounded festival_poster"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import ListCard from '@/components/movie/ListCard.vue'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    components: {
        ListCard
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            dates: [],
            description: '',
            descriptionEn: ''
        }
    },
    computed:{
        date(){
            return new Date().getFullYear() - 2013
        },
        title(){
            return this.language === "MN" ? "Алтан өргөө кино наадам" : "Golden Ger Film Festival"
        },
        desc(){
            return this.language === "MN" ? this.description : this.descriptionEn
        },
        ...mapState(['language'])
    },
    methods:{
        getAwardDates(){
            axios
                .get('/award/festival-list/', { params: { name: "Golden Ger international film festival" }})
                .then(response => { this.dates = response.data.dates, this.description = response.data.description, this.descriptionEn = response.data.description_en })
        }
    },
    created(){
        this.getAwardDates()
    }
}
</script>

<style scoped>

.festival-list__box {
    min-width: 320px;
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.festival-list__title_block {
    flex: 100%;
    text-transform: uppercase;
    order: 1;
}

.festival-list__title {
    font-size: 64px;
    color: #696969;
    margin-left: auto;
    padding: 15px 20px;
}

.festival__about_txt {
    padding: 15px 20px;
    font-size: 16px;
}

.festival-list__row {
    flex: 100%;
    order: 2;
}

.festival_poster {
    width: 100%;
    height: 100%;
    max-width: 280px;
    max-height: 440px;
    object-fit: cover;
    margin: 10px 0 0 10px;
}

.festival_poster__anchor {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

@media (min-width: 481px) {

    .festival-list__box {
        max-width: 1280px;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .festival-list__title_block {
        width: 50%;
        flex: 50%;
        text-align: end;
    }

    .festival-list__title {
        border-right: 1px solid #696969;
    }

    .festival__about_txt {
        margin-left: auto;
        border-top: 1px solid #696969;
        border-right: 1px solid #696969;
    }

    .festival_poster {
        max-width: 200px;
        max-height: 240px;
    }

    .festival_poster__anchor {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }

    .festival-list__row {
        width: 50%;
        flex: 50%;
    }

}

</style>