<template>
    <v-card class="ma-1 justify-center" width="145" height="100" outlined style='background-color: rgba(0, 0, 0, 0); border-color:rgba(0, 0, 0, 0); text-decoration: none;'>
        <v-img style="text-align: center;" class="mx-auto pt-3" aspect-ratio="1.4" contain :src="award.award === 'Оскарын кино наадам' ? baseUrl + '/static/img/film-oscar.png' : baseUrl + '/static/img/film-festival.png'">
            <router-link :to="{ name: 'award-detail', params: {slug: award.award_slug} }" style="font-size: 12px; text-decoration: none; color: #696969;">
                {{ award.award }} - {{ award.date }}
            </router-link>
            <br>
            <div style="font-size: 12px">
                <span v-if="award.win"><strong>{{ award.nominee }}</strong></span>
                <span v-else>{{ award.nominee }}</span>
            </div>
        </v-img>
    </v-card>
</template>

<script>
export default {
    props: {
        award: Object
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL
        }
    }
}
</script>
