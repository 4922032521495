<template>
    <div v-if="published && seasonEpisodes != '' || ( seasonEpisodes != '' && user.contributer )">
        <legend class="season-episode-list__legendbar"><strong>Сезоны <span v-show="!published">нийтлэгдээгүй</span> ангиуд:</strong></legend>
        <div class="row">
            <v-card v-for="(episode, index) in seasonEpisodes" :key="index">
                <router-link class="season-episode__link" :to="{ name: 'episode-detail', params: { serialSlug: serialSlug, seasonSlug: seasonSlug, episodeSlug: episode.episode_slug }}">
                    <v-img :src="baseUrl + episode.episode_img" class="rounded ma-0 season-episode__background"/>
                </router-link>
                <div class="season-episode__name">{{ episode.episode_num }}.  {{ episode.episode_name }}</div>
                <p class="season-episode__plot">{{ episode.english_plot }}</p>
                <v-divider v-show="episode.grade_average !== null || episode.g_average_audience !== null" class="season-episode__divider"></v-divider>
                <div v-show="episode.grade_average !== null || episode.g_average_audience !== null" class="season-review__box">
                    <div v-show="episode.grade_average" class="season-review__block">
                        <v-rating :value="parseInt(episode.grade_average)*0.05" color="#B29600" background-color="#B29600" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="20"></v-rating>
                        <div class="season-episode__reviewer">({{ episode.critics }} шүүмжлэгч)</div>
                    </div>
                    <div v-show="episode.g_average_audience" class="season-review__block">
                        <v-rating :value="parseInt(episode.g_average_audience)*0.05" color="#0066b5" background-color="#0066b5" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="20"></v-rating>
                        <div class="season-episode__reviewer">({{ episode.audiences }} кино сонирхогч)</div>
                    </div>
                </div>
            </v-card>
        </div>
        <div class="text-center">
            <v-pagination v-model="page" :length="episodePage" @input="getSeasonEpisodes()" :total-visible="3"></v-pagination>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    props:{
        published: Boolean,
        serialSlug: String,
        seasonSlug: String
    },
    computed: mapState(['user']),
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            seasonEpisodes: [],
            page: 1,
            episodePage: 0,
            emptyIcon: 'mdi-star-outline',
            fullIcon: 'mdi-star',
            halfIcon: 'mdi-star-half-full'
        }
    },
    methods:{
        getSeasonEpisodes(){
            axios
                .get('/serial/api-season-episodes/', {params: {slug: this.serialSlug, seasonSlug: this.seasonSlug, published: this.published, page: this.page}})
                .then(response => { this.seasonEpisodes = response.data.episode_list, this.episodePage = response.data.episode_page })
        },
    },
    created(){
        this.getSeasonEpisodes()
    },
}
</script>

<style scoped src="@/assets/styles/season_detail.css">
</style>
