<template>
    <div class="generic-list__box">
        <div class="journal-list__title_box">
            <h1><strong class="journal-list__title_txt">нийтлэлүүд</strong></h1>
        </div>
        <div class="journal-list__menu_box">
            <div class="journal-list__menu_items">
                <div class="journal-list__mobile_menu">
                    <v-select :items="tabItems" v-model="selectJournal" label="Мэдээний ангилал" item-text="tab" return-object @change="subTab = 0, submitFilter(selectJournal.tab)"></v-select>
                    <v-chip class="ma-1" outlined v-for="(item, index) in subItems" :key="index" @click="subTabCheck = item.content; submitFilter()">#{{ item.content }}</v-chip>
                </div>
                <div class="journal-list__desktop_menu">
                    <div class="d-flex" dense>
                        <v-tabs>
                            <v-tab v-for="item in tabItems" :key="item.tab" @click="subTab = 0, submitFilter(item.tab)">
                                {{ item.tab }}
                            </v-tab>
                        </v-tabs>
                    </div>
                    <div class="journal-list__desktop_submenu">
                        <v-tabs-items class="mb-5">
                            <v-tabs v-model="subTab" slider-size="0">
                                <v-tab v-for="item in subItems" :key="item.tab" @click="subTabCheck = item.content; submitFilter()">
                                    {{ item.content }}
                                </v-tab>
                            </v-tabs>
                        </v-tabs-items>
                    </div>
                </div>
            </div>
            <div class="journal-list__menu_search">
                <v-text-field label="Нийтлэл хайлт" v-model="journalSearch" append-icon="mdi-magnify" outlined dense @keyup.native.enter="submitFilter()">
                    <template v-slot:append> 
                        <div type="submit" @click="submitFilter()"><v-icon>mdi-magnify</v-icon></div>
                    </template>
                </v-text-field>
            </div>
        </div>
        
        
        
        <div class="mt-5">
            <v-divider class="journal-list__divider"></v-divider>
            <div class="journal_list__row" id="router_view">
                <JournalCard v-for="(journal, index) in journals" :key="index" :journal="journal"/>
            </div>
            <div class="text-center mt-2">
                <v-pagination v-model="page" :length="journalPage" @input="submitFilter()" :total-visible="7"></v-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import JournalCard from '@/components/journal/ListCard.vue'
import axios from 'axios'

export default {
    components: {
        JournalCard
    },
    data(){
        return{
            url: process.env.VUE_APP_BASEURL,
            routeName: this.$route.name,
            published: false,
            journalListTitle: '',
            journalSearch: '',
            journalSortItems: [ {text: 'Эхэнд нэмэгдсэнийг эхэнд', value: 1},
                                {text: 'Эхэнд нэмэгдсэнийг сүүлд', value: 2},
                                {text: 'Олон хүнд таалагдсныг эхэнд', value: 3},
                                {text: 'Олон хүнд таалагдсныг сүүлд', value: 4},
                                {text: 'Цагаан толгойн дарааллаар', value: 5},
                                {text: 'Цагаан толгойн эсрэг дарааллаар', value: 6}
                                ],
            journalSort: 0,
            journalTagValues: [],
            journalTagItems: [],
            journals: [],
            total: null,
            page: 1,
            journalPage: 0,
            subTab: 0,
            subTabCheck: '',
            selectJournal: { tab: 'Бүгд', content: null },
            tabItems: [
                { tab: 'Бүгд', content: null },
                { tab: 'Булан', content: [{ content: 'Булан' },
                                          { content: 'Зурагт хуудас' }] },
                { tab: 'Видео', content: [{ content: 'Видео эссе' },
                                          { content: 'Нэвтрүүлэг' },
                                          { content: 'Видео кино шинжилгээ' }] },
                { tab: 'Мэдээ', content: [{ content: 'Мэдээ' }] },
                { tab: 'Нийтлэл', content: [{ content: 'Дурдатгал' },
                                            { content: 'Намтар цадиг' },
                                            { content: 'Сурвалжлага' },
                                            { content: 'Хэлэлцүүлэг' },
                                            { content: 'Ярилцлага' }] },
                { tab: 'Кино судлал', content: [{ content: 'Кино наадам' },
                                                { content: 'Кино онол' },
                                                { content: 'Кино судалгаа' },
                                                { content: 'Кино шүүмж' },
                                                { content: 'Кино тунхаг' },
                                                { content: 'Кино түүх' },
                                                { content: 'Кино хөдөлгөөн' },
                                                { content: 'Монгол кино' }] },
                { tab: 'Подкаст', content: [{ content: 'Подкаст' }] },
                { tab: 'Тойм шүүмж', content: [{ content: 'Видео тойм шүүмж' }] }
            ],
            subItems: []
        }
    },
    watch:{
        $route(){
            this.journalSearch = ''
            this.journalSort = 0
            this.journalTagValues = []
            this.journals = []
            this.page = 1
            this.routeName = this.$route.name
            this.submitFilter(0)
        }
    },
    methods:{
        submitFilter(mainTab){
            // setTimeout(function() {
            //     var el = document.getElementById('router_view')
            //     if(typeof el !== "undefined" && el !== null) {
            //         el.scrollIntoView({ behavior: "smooth" })
            //     }
            // }, 500)

            if ( mainTab ) this.subItems = this.tabItems.find(({ tab }) => tab === mainTab).content
            if (this.routeName === 'journal-list'){
                this.journalListTitle, document.title = 'Нийтлэлүүд'
                this.published = true
            } else if(this.routeName === 'unpublished-journal-list'){
                this.journalListTitle, document.title = 'Нийтлэгдээгүй нийтлэлүүд'
                this.published = false
            }
            if(mainTab === 'Бүгд') this.subTabCheck = ''
            axios
                .get('/journal/api-journal-list/', { params: { sub_type: this.subTabCheck, published: this.published, search: this.journalSearch, sort: this.journalSort, tags: this.journalTagValues, page: this.page } })
                .then(response => {this.journals = response.data.journal_list, this.total = response.data.journal_total, this.journalPage = response.data.journal_page})
        },
        getTags(){
            axios
                .get('/journal/journal-tag-list/').then(response => {this.journalTagItems = response.data.tag_list})
        },
    },
    mounted(){
        this.submitFilter(0)
    },
    created(){
        this.getTags()
    }
}
</script>

<style scoped>
.generic-list__box {
    min-width: 320px;
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
}

.journal-list__title_box {
    text-align: center;
    margin: 20px auto;
}

.journal-list__title_txt {
    color: #A9A9A9;
    text-transform: uppercase;
}

.journal-list__menu_box {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.journal-list__menu_items {
    flex: 100%;
}

.journal-list__menu_search {
    flex: 100%;
}

.journal-list__item_content {
    padding: 10px;
}

.journal-list__mobile_menu {
    display: block;
    margin: 10px;
}

.journal-list__desktop_menu {
    display: none;
}

.journal-list__divider {
    margin: 30px 10px;
}

.journal_list__row {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    margin: 0 auto;
}

@media all and (min-width: 481px) {
    .generic-list__box {
        /* min-width: 320px; */
        max-width: 1280px;
        margin-right: auto;
        margin-left: auto;
    }
    .journal-list__mobile_menu {
        display: none;
    }

    .journal-list__desktop_menu {
        display: block;
        position: relative;
    }

    .journal-list__desktop_submenu {
        position: absolute;
        top: 50px;
        width: 1280px;
    }

    .journal-list__menu_items {
        flex: 80%;
        width: 80%;
    }

    .journal-list__menu_search {
        flex: 20%;
        width: 20%;
    }

    .journal_list__row {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

</style>