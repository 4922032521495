<template>
    <div class="artistMargin" v-if="totalArtists != ''">
        <legend class="movie_artist__legend_bar"><strong>{{ title }}</strong></legend>
        <div class="row">
            <GenericArtistCard v-for="(artist, index) in totalArtists" :key="index" :slug="format === 'artist' ? artist.person_slug : artist.actor.person_slug" :photo="format === 'artist' ? artist.photo : artist.actor.photo" :name="format === 'artist' ? artist.pro_name : artist.actor.pro_name" :job="format === 'artist' ? artist.job : artist.role"/>
        </div>
        <div class="load-more-btn">
            <v-btn block outlined color="#484848" v-if="endPoint" @click="movieArtists(loadMore += 1)">{{ btnName }}</v-btn>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import GenericArtistCard from '@/components/generics/movie_serial/GenericArtistCard.vue'

export default {
    props: {
        slug: String,
        title: String,
        btnName: String,
        type: String,
        format: String
    },
    components: {
        GenericArtistCard
    },
    data(){
        return{
            movieSlug: this.slug,
            totalArtists: [],
            artistList: [],
            loadMore: 1,
            endPoint: ''
        }
    },
    methods:{
        movieArtists(){
            var artistURL, roleURL
            if (this.type === 'movie') artistURL = '/movie/api-movie-artist-list/', roleURL = '/movie/api-movie-role-list/'
            else if (this.type === 'serial') artistURL = '/serial/api-serial-artist-list/', roleURL = '/serial/api-serial-role-list/'
            if (this.format === 'artist') {
                
                axios
                    .get(artistURL, {params: {slug: this.movieSlug, loadMore: this.loadMore}})
                    .then(response => {this.artistList = response.data.artists, this.endPoint = response.data.end_point, this.totalArtists = this.totalArtists.concat(this.artistList)})
            } else if (this.format === 'role') {
                axios
                    .get(roleURL, {params: {slug: this.movieSlug, loadMore: this.loadMore}})
                    .then(response => {this.artistList = response.data.roles, this.endPoint = response.data.end_point, this.totalArtists = this.totalArtists.concat(this.artistList)})
            }
        }
    },
    created(){
        this.movieArtists()
    },
    watch:{
        slug(){
            this.totalArtists = []
            this.artistList = []
            this.movieSlug = this.slug
            this.loadMore = 1
            this.endPoint = ''
            this.movieArtists()
        }
    }
}
</script>

<style scoped>

.artistMargin {
    margin: 0 5px;
    padding: 20px 0;
}

.movie_artist__legend_bar {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
}

.row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    margin: 0 auto;
}

.load-more-btn {
    text-align: center;
}

@media (min-width: 481px) {
    .row {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
}

</style>