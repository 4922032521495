<template>
    <div>
        <div class="create_movie__legend_bar">Холбоотой уран бүтээлч нэмэх/хасах:</div>
        <v-card class="pa-2" outlined>
            <div class="create_movie__artist_block">
                <div class="create_movie__artist_search">
                    <v-autocomplete no-filter v-model="selectedArtist" height="100" :items="searchItems" hide-no-data return-object :search-input.sync="searchArtist" clearable item-text="name" label="Уран бүтээлч" attach solo>
                        <template v-slot:selection="data">
                            <v-avatar left height="75" tile class="mx-3"><img :src="mediaUrl + '/' + data.item['photo']" class="rounded"></v-avatar>
                            <div mx="2">{{ data.item['pro_name'] }}</div>
                        </template>
                        <template v-slot:item="data">
                            <template v-if="data.item !== 'object'">
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['photo']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['pro_name']"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['photo']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['pro_name']"></v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </div>
            </div>
            <v-btn color="blue" block @click="addArtist">Нэмэх</v-btn>
            <br>
            <draggable v-model="artistList" class="generic-list__row" tag="ul" v-bind="dragOptions" @start="drag = true" @end="drag = false">
                <UpdateArtistCard v-for="(artist, index) in artistList" :key="index" :artist="artist" :type="'artist'" v-on:delete-artist="removeArtist(index)"/>
            </draggable>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import UpdateArtistCard from '@/components/generics/movie_serial/GenericCUJournalCard.vue'

export default {
    props: {
        slug: String,
        typeSlug: String,
        subTypeSlug: String
    },
    components: {
        draggable,
        UpdateArtistCard
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            artistList: [],
            searchItems: [],
            loadMore: 0,
            searchArtist: null,
            selectedArtist: '',
            drag: false
        }
    },
    computed:{
        dragOptions(){
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    watch:{
        searchArtist(val){
            if(val){
                axios
                    .get('/artist/api-search-artist-list/', { params: { artist: val, festival: false }}).then(response => { this.searchItems = response.data.artist_list })
            }
        }
    },
    methods:{
        addArtist(){
            if(this.selectedArtist){
                this.artistList.push({ id: this.selectedArtist['id'], photo: this.selectedArtist['photo'], pro_name: this.selectedArtist['pro_name'] })
            }
            this.selectedArtist = null
            this.searchItems = []
            this.$emit('submit-button', false)
        },
        removeArtist(index){
            this.artistList.splice(index, 1)
        },
        getJournalArtists(){
            axios
                .get('/journal/api-journal-artist-list/', { params: { slug: this.slug, typeSlug: this.typeSlug, subTypeSlug: this.subTypeSlug }})
                .then(response => { this.artistList = response.data.artists })
        },
    },
    created(){
        this.getJournalArtists()
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>