<template>
    <div class="generic-list__box">
        <div class="generic-list__title">{{ artistListTitle }} <span class="generic-list__title__total">{{ total }}</span> уран бүтээлч</div>
        <v-card class="mb-2" elevation="0" outlined>
            <v-tabs color="light-blue darken-3 accent-4" fixed-tabs>
                <v-tab><v-icon>mdi-movie-search</v-icon></v-tab>
                <v-tab><v-icon>mdi-sort</v-icon></v-tab>
                <v-tab><v-icon>mdi-filter</v-icon></v-tab>
                <v-tab-item :key="1">
                    <div class="generic-list__tab_block">
                        <v-text-field label="Уран бүтээлчдээс хайх" v-model="artistSearch" @keyup.native.enter="submitFilter(true, pageLoad = false)">
                            <template v-slot:append> 
                                <v-btn class="generic-list__search_btn ma-1" rounded color="light-blue darken-3" dark type="submit" @click="submitFilter(true, pageLoad = false)"><v-icon>mdi-magnify</v-icon></v-btn>
                            </template>
                        </v-text-field>
                    </div>
                </v-tab-item>
                <v-tab-item :key="2">
                    <div class="generic-list__tab_block">
                        <v-select :items="artistSortItems" label="Үзүүлэх дараалал" v-model="artistSort" @change="submitFilter(true, pageLoad = false)"></v-select>
                    </div>
                </v-tab-item>
                <v-tab-item :key="3">
                    <div class="generic-list__tab_block">
                        <form v-on:submit.prevent="submitFilter(true, pageLoad = false)">
                            <div class="generic-list__filter_box">
                                <div class="generic-list__filter_block">
                                    <v-card class="ma-1 pa-2" elevation="0" outlined>
                                        <v-select v-if="routeName === 'artist-list'" :items="artistCountryItems" label="Төрсөн улс /тухайн төрсөн үеийн болон орчин үеийн нэршлээр хайх/" v-model="artistCountry" item-text="country_mongol_name" :return-object="false" item-value="id"></v-select>
                                        <v-combobox v-model="artistProfession" :items="artistProfessionItems" label="Уран бүтээлчийн мэргэшил" multiple item-text="profession_mongol_title" :return-object="false"></v-combobox>
                                    </v-card>
                                </div>
                                <div class="generic-list__filter_block">
                                    <v-card class="ma-1 pa-2" elevation="0" outlined>
                                        <v-subheader>Төрсөн он</v-subheader>
                                        <v-range-slider v-model="artistYearValue" :max="artistMaxYearValue" :min="artistMinYearValue" hide-details class="align-center" @mouseup="artistYear()">
                                            <template v-slot:prepend>
                                                <v-text-field :value="artistYearValue[0]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px" @change="$set(artistYearValue, 0, $event)"></v-text-field>
                                            </template>
                                            <template v-slot:append>
                                                <v-text-field :value="artistYearValue[1]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px" @change="$set(artistYearValue, 1, $event)"></v-text-field>
                                            </template>
                                        </v-range-slider>
                                    </v-card>
                                    <br>
                                    <div class="text-center">
                                        <v-btn large color="light-blue darken-3" dark type="submit" class="ma-1">Филтэрдэх</v-btn>
                                        <v-btn large color="#ffcc00" dark class="ma-1" @click="resetFilter(null)">Ресетлэх</v-btn>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </v-tab-item>
            </v-tabs>
        </v-card>
        <div class="generic-list__row" id="router_view">
            <ListCard v-for="(artist, index) in artists" :key="index" :artist="artist"/>
        </div>
        <div class="text-center mt-2">
            <v-pagination v-model="page" :length="artistPage" @input="submitFilter(false, pageLoad = false)" :total-visible="screenWidth"></v-pagination>
        </div>
    </div>
</template>

<script>
import ListCard from '@/components/artist/ListCard.vue'
import axios from 'axios'

export default {
    components: {
        ListCard
    },
    data(){
        return{
            routeName: this.$route.name,
            published: false,
            artistListTitle: '',
            artistSearch: '',
            artistSortItems: [{text: 'Цагаан толгойн дарааллаар', value: 1}, {text: 'Цагаан толгойн эсрэг дарааллаар', value: 2}, {text: 'Өндөр настайг эхэнд', value: 3}, {text: 'Өндөр настайг сүүлд', value: 4}],
            artistSort: 0,
            artistCountryItems: [],
            artistCountry: 0,
            artistProfessionItems: [],
            artistProfession: [],
            artistMinYearValue: 1200,
            artistMaxYearValue: 2023,
            artistYearValue: [1200, 2023],
            artistYearFlag: 0,
            artists: [],
            total: null,
            page: 1,
            artistPage: 0,
            parameters: {},
            pageLoad: true
        }
    },
    watch:{
        '$route.name'(){
            this.resetFilter(this.$route.name)
        },
        artistProfession(val){
            if (val.length > 5) {
                this.$nextTick(() => this.artistProfession.pop())
            }
        }
    },
    computed: {
        screenWidth(){
            var w = window.innerWidth
            return w < 481 && w > 319 ? 5 : 7
        }
    },
    methods:{
        resetFilter(route){
            this.artistSearch = ''
            this.artistSort = 0
            this.artistCountry = 0
            this.artistProfession = 0
            this.artistYearValue = [1200, 2023]
            this.artistYearFlag = 0
            this.artists = []
            this.page = 1
            if (route !== null) this.routeName = this.$route.name
            this.pageLoad = false
            this.submitFilter()
        },
        artistYear(){
            this.artistYearFlag = 1
        },
        getProfessions(){
            axios
                .get('/artist/api-profession-list/').then(response => {this.artistProfessionItems = response.data.profession_list})
        },
        getCountries(){
            axios
                .get('/country/api-country-list/').then(response => {this.artistCountryItems = response.data.country_list})
        },
        submitFilter(pageAssign){
            setTimeout(function() {
                var el = document.getElementById('router_view')
                if(typeof el !== "undefined" && el !== null) {
                    el.scrollIntoView({ behavior: "smooth" })
                }
            }, 500)

            if (pageAssign === true) this.page = 1

            let currentPage = this.$route.query.page
            let currentSort = this.$route.query.sort
            let currentCountry = this.$route.query.country
            let currentYear = this.$route.query.year ? JSON.parse(this.$route.query.year) : [1200, 2023]

            if (this.pageLoad) {
                if (this.page !== parseInt(currentPage)) {
                    if (currentPage == undefined || isNaN(currentPage)) this.page = 1
                    else this.page = parseInt(currentPage)
                }

                if (!(this.artistSearch === this.$route.query.search)) {
                    this.artistSearch = this.$route.query.search
                }

                if (this.artistSort !== parseInt(currentSort)) {
                    if (currentSort == undefined || isNaN(currentSort)) this.artistSort = 0
                    else this.artistSort = parseInt(currentSort)
                }

                if (this.artistCountry !== parseInt(currentCountry)) {
                    if (currentCountry == undefined || isNaN(currentCountry)) this.artistCountry = 0
                    else this.artistCountry = parseInt(currentCountry)
                }

                if (currentYear !== undefined && JSON.stringify(this.artistYearValue) !== JSON.stringify(currentYear)) {
                    if (JSON.stringify(currentYear) === JSON.stringify([1200, 2023])) this.artistYearValue = [1200, 2023], this.artistYearFlag = 0
                    else this.artistYearValue = currentYear, this.artistYearFlag = 1
                }
            }

            this.parameters = {
                page: this.page,
                published: this.routeName.indexOf("unpublished") === -1,
                search: this.artistSearch,
                sort: this.artistSort,
                country: this.artistCountry,
                profession: this.artistProfession,
                year: this.artistYearValue,
                yearFlag: this.artistYearFlag
            }

            if (this.routeName === 'artist-list' || this.routeName === 'unpublished-artist-list'){
                if(this.routeName === 'artist-list'){
                    this.artistListTitle = 'Олон улсын'
                    document.title = 'Олон улсын уран бүтээчид'
                } else if(this.routeName === 'unpublished-artist-list'){
                    this.artistListTitle = 'Олон улсын нийтлэгдээгүй'
                    document.title = 'Нийтлэгдээгүй олон улсын уран бүтээчид'
                }
                this.parameters.countryFlag = 1
            } else if(this.routeName === 'mongol-artist-list' || this.routeName === 'unpublished-mongol-artist-list'){
                if(this.routeName === 'mongol-artist-list'){
                    this.artistListTitle = 'Монголын улсын'
                    document.title = 'Монголын уран бүтээчид'
                } else if(this.routeName === 'unpublished-mongol-artist-list'){
                    this.artistListTitle = 'Монголын нийтлэгдээгүй'
                    document.title = 'Нийтлэгдээгүй Монголын уран бүтээчид'
                }
                this.parameters.countryFlag = 2
            }

            let querySet = {
                page: this.page
            }

            if (this.artistSearch !== '') {
                Object.assign(querySet, { search: this.artistSearch })
            }

            if (this.artistSort !== 0) {
                Object.assign(querySet, { sort: this.artistSort })
            }

            if (this.artistCountry !== 0) {
                Object.assign(querySet, { country: this.artistCountry })
            }

            if (JSON.stringify(this.artistYearValue) !== JSON.stringify([1200, 2023])) {
                Object.assign(querySet, { year: JSON.stringify(this.artistYearValue) })
            }

            axios
                .get('/artist/artist-list/', { params: this.parameters })
                .then(response => { this.artists = response.data.artist_list, this.total = response.data.artist_total, this.artistPage = response.data.artist_page })
                .finally(() => {
                    this.$router.push({ name: this.routeName, query: querySet }).catch(() => {})
                })
        }
    },
    created(){
        this.getProfessions()
        this.getCountries()
        this.submitFilter()
    }
}
</script>

<style scoped src="@/assets/styles/generic-list.css"></style>