<template>
    <v-card class="journal-card">
        <div class="mb-2" >
            <router-link :to="{ name: 'journal-detail', params: { typeSlug: journal.journal_type_slug, subTypeSlug: journal.journal_sub_type_slug, slug: journal.journal_slug }}">
                <img class="journalImage" :src="baseUrl + journal.journal_cover">
                <div class="text-on-image">
                    <strong>{{ journal.journal_type }}</strong>
                </div>
            </router-link>
            <div class="text-style">
                <div class="position-style">
                    <div class="left-position-set">
                        <router-link :to="{name: 'user-feed', params: { slug: journal.author_slug } }">
                            <img class="journal-card__user_image" :src="baseUrl + journal.author_image"/>
                        </router-link>
                        <div class="user-name">
                            {{ journal.journal_author }}
                            · <v-icon class="list-card__user_icon" icon @click="likeJournal()" :disabled="!isAuth" size="16">{{ journalHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                            {{ journalCount }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="total-movie-style">{{ journal.journal_title }}</div>
        </div>
    </v-card>
</template>

<script>
// import axios from 'axios'

export default {
    props: {
        journal: Object
    },
    data(){
        return{
            isAuth: this.$store.state.user.isAuthenticated,
            baseUrl: process.env.VUE_APP_BASEURL,
            journalHeart: this.journal.user_like,
            journalCount: this.journal.total_likes
        }
    },
    methods:{
        likeJournal(){
            if(this.journalHeart == true){
                this.journalHeart = false
                this.journalCount--
            } else {
                this.journalHeart = true
                this.journalCount++
            }
            // axios
            //     .post('/user/api/v-user-movielistlike/', { list_id: this.list.id, list_user: this.list.user_slug })
        }
    }
}
</script>

<style scoped>

.journal-card {
    min-width: 280px;
    max-width: 480px;
}

.journalImage {
    display: block;
    object-fit: cover;
    width: 100%;
    border-radius: 4px;
}

.text-on-image {
    position: absolute;
    color:white;
    background: rgba(0, 0, 0, 0.6);
    top: 15px;
    left: 15px;
    font-size: 12px;
}

.text-style {
    bottom: 0;
    width: 100%;
    padding: 0 10px;
    position: relative;
}

.position-style {
    display: flex;
    top: -40px;
    max-width: 100%;
    flex-wrap: nowrap;
    position: absolute;
}

.journal-card__user_image {
    border: 3px solid white;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.left-position-set {
    display: flex;
    flex-wrap: nowrap;
}

.list-card__user_icon {
    color: #0066b5 !important;
}

.user-name {
    font-size: 12px;
    margin-top: auto;
    font-weight: bold;
}

.total-movie-style {
    padding: 35px 10px 10px;
    font-size: 20px;
}

</style>