<template>
    <div>
        <div class="row">
            <GenericReviewerCard v-for="(review, index) in totalReviews" :key="index" :review="review" :type="type" :movieSlug="movieSlug" :serialSlug="serialSlug" :seasonSlug="seasonSlug" :episodeSlug="episodeSlug"/>
        </div>
        <v-btn block class="loadMoreButton" v-if="endPoint" @click="movieReview(loadMore += 1)">Сэтгэгдэл цааш үзэх</v-btn>
    </div>
</template>

<script>
import axios from 'axios'
import GenericReviewerCard from '@/components/generics/movie_serial/GenericReviewerCard.vue'

export default {
    props: {
        type: String,
        reviewer: String,
        movieSlug: String,
        serialSlug: String,
        seasonSlug: String,
        episodeSlug: String
    },
    components: {
        GenericReviewerCard
    },
    data(){
        return{
            movieReviews: [],
            totalReviews: [],
            loadMore: 1,
            endPoint: ''
        }
    },  
    methods:{
        movieReview(){
            var reviewsURL
            if (this.type === 'movie') {
                this.reviewer === 'critic' ? reviewsURL = '/movie/api-movie-critic-reviews/' : reviewsURL = '/movie/api-movie-audience-reviews/'
                axios
                    .get(reviewsURL, {params: {slug: this.movieSlug, loadMore: this.loadMore}})
                    .then(response => {this.movieReviews = response.data.api_movie_reviews, this.endPoint = response.data.end_point, this.totalReviews = this.totalReviews.concat(this.movieReviews)})
            } else if (this.type === 'season') {
                this.reviewer === 'critic' ? reviewsURL = '/serial/api-season-critic-reviews/' : reviewsURL = '/serial/api-season-audience-reviews/'
                axios
                    .get(reviewsURL, {params: {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug, loadMore: this.loadMore}})
                    .then(response => {this.movieReviews = response.data.api_season_reviews, this.endPoint = response.data.end_point, this.totalReviews = this.totalReviews.concat(this.movieReviews)})
            
            } else if (this.type === 'episode') {
                this.reviewer === 'critic' ? reviewsURL = '/serial/api-episode-critic-reviews/' : reviewsURL = '/serial/api-episode-audience-reviews/'
                axios
                    .get(reviewsURL, {params: {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug, episodeSlug: this.episodeSlug, loadMore: this.loadMore}})
                    .then(response => {this.movieReviews = response.data.api_episode_reviews, this.endPoint = response.data.end_point, this.totalReviews = this.totalReviews.concat(this.movieReviews)})
            }
        }
    },
    created(){
        this.movieReview()
    },
    computed: {
        multpleProps() {
            return `${this.movieSlug}|${this.serialSlug}|${this.seasonSlug}|${this.episodeSlug}`
        }
    },
    watch:{
        multpleProps(){
            this.totalReviews = []
            this.movieReviews = []
            this.loadMore = 1
            this.endPoint = ''
            this.movieReview()
        }
    }
}
</script>

<style scoped>

.loadMoreButton {
    color:#0066b5;
}

.row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100% !important;
    padding: 0.5rem;
    margin: 0 auto;
}

</style>