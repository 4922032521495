<template>
    <div class="comment-card__box">
        <div class="comment-card__block">
            <div class="comment-card__user_info">
                <router-link class="comment-card__user_img" :to="{ name: 'user-feed', params: { slug: comment.user_slug }}">
                    <v-avatar size="35" class="ma-2 rounded-circle">
                        <v-img :src="baseUrl + comment.user_photo"/>
                    </v-avatar>
                </router-link>
                <div class="comment-card__content">
                    <div class="comment-card__legendbar">
                        <strong>{{ comment.username }}</strong>
                        · {{comment.created_at}}
                    </div>
                    <p class="comment-card__body">{{ comment.body }}</p>
                </div>
            </div>
            <button class="comment-card__like_btn" @click="likeComment()">
                <v-icon class="comment-card__like_icon" size="16">{{ reviewHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon> <small>{{ reviewCount }}</small>
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        comment: Object,
        id: Number,
        message: Number
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            reviewHeart: '',
            reviewCount: 0
        }
    },
    watch:{
        id: {
            immediate: true,
            handler() {
                this.reviewHeart = this.comment.user_like
                this.reviewCount = this.comment.comment_likes
            },
        },
    },
    methods:{
        likeComment(){
            if(this.reviewHeart == true){
                this.reviewHeart = false
                this.reviewCount--
            } else {
                this.reviewHeart = true
                this.reviewCount++
            }
            axios
                .post('/user/api/v-user-likecomment/', { "message": this.message, "comment": this.id })
        }
    },
}
</script>
<style>

.comment-card__box {
    margin-bottom: 10px;
}

.comment-card__block {
    display: flex;
    flex-direction: column;
}

.comment-card__user_info {
    display: flex;
}

.comment-card__user_img {
    text-align: center;
    flex: 10%;
}

.comment-card__content {
    flex: 90%;
}

.comment-card__legendbar {
    border-bottom: 1px solid #a9a9a9;
    color: #696969;
    margin: 0;
    margin-bottom: auto;
}

.comment-card__body {
    padding: 5px 0;
}

.comment-card__like_btn {
    text-align: right;
}

.comment-card__like_icon {
    font-size: 14px;
    color: #0066b5 !important;
}

</style>