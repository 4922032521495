<template>
    <div class="generic-list__box">
        <div class="generic-list__title">тойм шүүмжүүд (<span class="generic-list__title__total">{{ total }}</span> {{ listTitle }})</div>
        <v-card class="mb-2">
            <div class="generic-list__tab_block">
                <v-text-field :label="'Шүүмж бичсэн ' + searchTxt + ' хайх'" v-model="movieSearch" @keyup.native.enter="submitFilter(true)">
                    <template v-slot:append> 
                        <v-btn class="generic-list__search_btn ma-1" rounded color="light-blue darken-3" dark type="submit" @click="submitFilter(true)"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                </v-text-field>
            </div>
        </v-card>
        <div class="generic-list__row_review">
            <ListCard v-for="(review, index) in reviews" :key="index" :review="review" :type="routeName === 'movie-review-list' ? 'movie' : 'serial'"/>
        </div>
        <div class="text-center mt-2">
            <v-pagination v-model="page" :length="reviewPage" @input="submitFilter()" :total-visible="7"></v-pagination>
        </div>
    </div>
</template>

<script>
import ListCard from '@/components/review/GenericListCard.vue'
import axios from 'axios'

export default {
    components: {
        ListCard
    },
    data(){
        return{
            routeName: this.$route.name,
            searchTxt: '',
            listTitle: '',
            movieSearch: '',
            reviews: [],
            total: null,
            page: 1,
            reviewPage: 0
        }
    },
    watch:{
        $route(){
            this.movieSearch = ''
            this.reviews = []
            this.page = 1
            this.routeName = this.$route.name
            this.submitFilter()
        }
    },
    mounted(){
        this.submitFilter()
    },
    methods:{
        submitFilter(){
            if (this.routeName === 'movie-review-list') {
                this.listTitle = document.title = "кино шүүмж"
                this.searchTxt = "кинонуудаас"
                axios
                    .get('/movie/api-movie-review-list/', { params: { search: this.movieSearch, page: this.page }})
                    .then(response => { this.reviews = response.data.review_list, this.total = response.data.review_total, this.reviewPage = response.data.review_page })
            } else if (this.routeName === 'episode-review-list') {
                this.listTitle = document.title = "эпизод шүүмж"
                this.searchTxt = "сериалуудаас"
                axios
                    .get('/serial/episode-detailed-review-list/', { params: { search: this.movieSearch, page: this.page }})
                    .then((response) => { this.reviews = response.data.review_list, this.total = response.data.review_total, this.reviewPage = response.data.review_page })
            }
            
        }
    }
}
</script>

<style scoped src="@/assets/styles/generic-list.css"></style>