<template>
    <div class="user-feed__card_card">
        <div class="user-feed__card_episode_cover_block">
            <router-link :to="{ name: 'journal-detail', params: { typeSlug: journal.journal_main_type_slug, subTypeSlug: journal.journal_type_slug, slug: journal.journal_slug }}">
                <img class="user-feed__card_journal_cover" :src="baseUrl + journal.journal_cover"/>
            </router-link>
        </div>
        <div class="user-feed__card_journal_content">
            <div class="user-feed__card_movie">
                <span class="user-feed__episode_name_txt">{{ journal.journal_title }}</span>
            </div>
            <div class="user-feed__review">{{ journal.journal_type }} </div>
            <v-divider></v-divider>
            <div class="user-feed__review_like_btn">
                <button @click="likeJournal()">
                    <v-icon class="user-feed__review_like_icon">{{ reviewHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon> <small>{{ reviewCount }}</small>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        journal: Object,
        index: Number
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            userSlug: this.$store.state.user.slug,
            reviewHeart: '',
            reviewCount: 0
        }
    },
    watch:{
        index: {
            immediate: true,
            handler() {
                this.reviewHeart = this.journal.user_like
                this.reviewCount = this.journal.journal_likes
            },
        },
    },
    methods:{
        likeJournal(){
            if(this.reviewHeart == true){
                this.reviewHeart = false
                this.reviewCount--
            } else {
                this.reviewHeart = true
                this.reviewCount++
            }
            axios
                .post('/user/api/v-user-journallike/', { "journal_id": this.journal_id })
        }
    },
}
</script>
<style>

.user-feed__card_card {
    display: flex;
    flex-direction: column;
}

.user-feed__card_journal_cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.user-feed__card_journal_content {
    padding: 5px 10px;
    flex: 100%;
    width: 100%;
}

.user-feed__card_movie {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
}

.user-feed__review {
    color: #696969;
}

.user-feed__episode_name_txt {
    font-weight: 600
}

.user-feed__review_like_btn {
    text-align: right;
}

.user-feed__review_like_icon {
    font-size: 20px;
    color: #0066b5 !important;
}

@media (min-width: 481px) {

    .user-feed__card_card {
        display: flex;
        flex-direction: row;
    }

    .user-feed__card_journal_cover_block {
        flex: 25%;
    }

    .user-feed__card_episode_cover_block {
        flex: 35%
    }

    .user-feed__card_journal_content {
        flex: 75%;
    }
}

</style>