<template>
    <v-container style="width: 1280px;">
        <div v-if="error">
            <v-container class="d-flex justify-center">
                <center>
                    <h1>{{ error }}</h1>
                    <p><router-link style="text-decoration: none;" :to="{ name: 'home' }">Нүүр хуудсанд очих?</router-link></p>
                </center>
            </v-container>
        </div>
        <div v-else-if="error === ''" style="word-wrap: break-word; margin: auto; width: 1280px; padding: 10px;">
            <span style="text-align: center;">
                <div style="margin-bottom: 50px; font-size: 48px; font-weight: 500;"><strong style='color: #A9A9A9;'>GGIFF</strong>
                <br>
                Алтан Өргөө 2023 кино наадмын бүртгэлийн удирдамж
                </div>
            </span>
            <p v-html="guidance.guidance"></p>
            <!-- <p style="font-weight: 600; font-size: 18px;">Та доорх 2 бүртгэлийн хэлбэрээс аль нэгийг сонгон бүртгүүлж болно.</p>
            <p style="color: #0066b5;">Сонголт 2-р бүртгүүлэх бол та эхлээд mfi.mn сайтад хэрэглэгчээр бүртгүүлэх ёстой. Үүний дараа найруулагчийг заавал үүсгэх шаардлагатай ба дараа нь киногоо кино санд илгээнэ. Үүний эцэст бүртгэлийн маягтыг бөглөнө.</p>
            <a :href="jotFormURL" target="blank" style="text-decoration: none;">
                <v-btn style=" background-color: green; color: white;" block>
                    Jotform БҮРТГЭЛ /сонголт - 1/
                </v-btn>
            </a>
            <v-divider class="mt-2"></v-divider> -->
            <v-btn @click="registrationForm = true" style=" background-color: green;" block>
                <router-link style="text-decoration: none; color: white;" :to="{ name: 'annual-award-register' }">MFI.MN БҮРТГЭЛ</router-link>
            </v-btn>
        </div>
        <!-- <ShareNetwork :network="shareNetwork" :url="shareUrl" :title="shareTitle" :description="shareDescription" hashtags="mfi.mn, GGIFF">
            <v-btn dark fab bottom color="blue" small><v-icon>mdi-facebook</v-icon></v-btn>
        </ShareNetwork> -->
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            shareNetwork: "facebook",
            jotFormURL: "https://form.jotform.com/212344411275447",
            shareUrl: "https://www.mfi.mn/festival/annual/guidelines",
            shareTitle: "Алтан Өргөө кино наадмын уралдаант хэсгийн удирдамж",
            shareDescription: "Бүртгэл илгээх боломж 2023 оны 3 сарын 31-ний 23:59 цагт хаагдана.",
            error: '',
            registrationForm: false,
            guidance: ''
        }
    },
    methods:{
        getFestivalGuidance(){
            axios
                .get('/award/registration-guidance/').then(response => { this.guidance = response.data })
                .catch(error => {
                        if(error.response){
                            this.error = error.response.data.message
                            this.$store.commit('SET_SNACK', {message: error.response.data.message, color: 'red'})
                        }
                    })
        }
    },
    created(){
        document.title = 'Кино наадамд бүртгүүлэх'
        this.getFestivalGuidance()
    }
}
</script>

<style>

</style>