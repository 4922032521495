<template>
    <div class="movieMargin" v-if="artistList != ''">
        <div class="artist__legend_bar"><strong>Холбоотой уран бүтээлчид / түүхэн хүмүүс</strong></div>
        <div class="row">
            <CollabCard v-for="(artist, index) in artistList" :key="index" :artist="artist"/>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CollabCard from '@/components/artist/CollabCard.vue'

export default {
    props: {
        slug: String
    },
    components: {
        CollabCard
    },
    data(){
        return{
            artistSlug: this.slug,
            artistList: []
        }
    },  
    methods:{
        artistCollabs(){
            axios
                .get('/artist/api-artist-collabs/', {params: {slug: this.artistSlug}})
                .then(response => {this.artistList = response.data.artists})
        }
    },
    created(){
        this.artistCollabs()
    },
    watch:{
        slug(){
            this.artistSlug = this.slug
            this.artistList = []
            this.artistCollabs()
        }
    }
}
</script>

<style scoped>

.movieMargin{
    margin: 20px 5px 0;
}

.artist__legend_bar {
    color: #0066b5;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
}


.row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    margin: 0 auto;
}

@media (min-width: 481px) {
    .row{
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
}

</style>