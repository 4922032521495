import { render, staticRenderFns } from "./GenericCreateArtist.vue?vue&type=template&id=0732f367&scoped=true"
import script from "./GenericCreateArtist.vue?vue&type=script&lang=js"
export * from "./GenericCreateArtist.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/CU_artist.css?vue&type=style&index=0&id=0732f367&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0732f367",
  null
  
)

export default component.exports