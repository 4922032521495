<template>
    <div v-if="user.isAuthenticated" class="create_movie">
        <div class="create_movie__title">Та цуврал нэмэх гэж байна!</div>
        <v-divider class="mx-4"></v-divider>
        <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitCreate">
            <div class="create_movie__block">
                <div class="create_movie__image_block">
                    <v-card width="240" height="360"><v-img :src="serialPosterURL"/></v-card>
                    <v-text-field v-model="serialPosterCheck" disabled label="Постер" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="posterDialog" width="600px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Постер нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="posterDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Цувралын постер зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 240, height: 360}" :canvas="{width: 240, height: 360}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropPoster">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                    <v-divider class="mt-5 mb-5"></v-divider>
                    <v-card width="240" height="135"><v-img :src="serialBackgroundURL"/></v-card>
                    <v-text-field v-model="serialBackgroundCheck" disabled label="Цувралын сцен зураг" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="backgroundDialog" width="800px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Сцен зураг нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="backgroundDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Цувралын сцен зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 1280, height: 720}" :canvas="{width: 1280, height: 720}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropBackground">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="create_movie__title_block">
                    <v-combobox v-model="serialLanguageValues" :items="serialLanguageItems" label="Хэл*" multiple item-text="language_mongol_name" :return-object="false" item-value="language_mongol_name" :rules="[rules.choice]"></v-combobox>
                    <v-text-field v-model="serialMongolTitle" label="Цувралын Монгол нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="serialInternationalTitle" label="Цувралын олон улсын нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="serialOriginalTitle" label="Цувралын өөрийн хэл дээрх нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-select v-model="serialFormat" :items="serialFormatItems" label="Формат*" :rules="[rules.choice]"></v-select>
                    <v-combobox v-model="serialGenreValues" :items="serialGenreItems" label="Жанр" multiple item-text="genre_mongol_name" :return-object="false" :rules="[rules.choice]"></v-combobox>
                    <v-combobox v-model="serialSubGenreValues" :items="serialSubGenreItems" label="Дэд жанр" multiple item-text="sub_genre_mongol_name" :return-object="false"></v-combobox>
                    <v-text-field v-model="serialReleasedYear" type="number" min="0" label="Цувралын цацагдаж эхэлсэн он*" required :rules="[rules.required, rules.yearCheck]"></v-text-field>
                    <v-text-field v-model="serialFinishedYear" type="number" min="0" label="Цувралын цацагдаж дууссан он" required :rules="[rules.required]"></v-text-field>
                    <v-combobox v-model="serialCountryValues" :items="serialCountryItems" label="Улс*" multiple item-text="country_mongol_name" :return-object="false" :rules="[rules.choice]"></v-combobox>
                    <v-select v-model="serialRating" :items="serialRatingItems" label="Насны ангилал*" :rules="[rules.choice]"></v-select>
                    <v-text-field v-model="serialRuntime" type="number" min="0" label="Үргэлжлэх дундаж хугацаа*" required :rules="[rules.required, rules.number]"></v-text-field>
                </div>
            </div>
            <div class="create_movie__block_content">
                <v-combobox v-model="serialTagValues" :items="serialTagItems" label="Киноны таг* (Хайх тагаа бичнэ үү!)" :search-input.sync="searchTag"  item-text="tag_name" chips multiple :return-object="false" :rules="[rules.choice]"></v-combobox>
                <v-textarea v-model="serialPlot" label="Үйл явдлын товч" hint="Та 3000 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.plotLength]"></v-textarea>
                <v-textarea v-model="serialPlotEng" label="Үйл явдлын товч (in English)" hint="Та 3000 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.plotLength]"></v-textarea>
                <CreateArtist ref="artists" :artistJobItems="artistJobItems"/>
                <br>
                <CreateRole ref="roles"/>
                <br>
                <v-card-actions class="justify-center">
                    <v-btn :disabled="checkValid" @click="validate" type="submit" class="create_movie__submit_btn" block>Хадгалах</v-btn>
                </v-card-actions>
            </div>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import { mapState } from 'vuex'
import 'vue-advanced-cropper/dist/style.css'

import CreateArtist from '@/views/generics/movie_serial/GenericCreateArtist.vue'
import CreateRole from '@/views/generics/movie_serial/GenericCreateRole.vue'

export default {
    components: {
        Cropper,
        CreateArtist,
        CreateRole
    },
    data(){
        return{
            currentYear: new Date().getFullYear(),
            artistJobItems: ['Санаачлагч', 'Найруулагч', 'Туслах найруулагч', 'Гүйцэтгэх продюсер', 'Продюсер', 'Туслах продюсер', 'Зохиолч', 'Зураглаач', 'Хөгжим', 'Дуу чимээ', 'Зургийн дарга', 'Эвлүүлэгч', 'Зураач', 'Хувцас', 'Гэрэлтүүлэг', 'Визуал эффэкт', 'Кино баг'],
            image: "",
            posterDialog: false,
            backgroundDialog: false,
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                choice: value => value.length > 0 || 'Сонгох шаардлагатай!',
                number: value => /^[0-9]+$/.test(value) || 'Та зөвхөн тоо оруулна уу!',
                imageSize: value => !value || value.size < 1000000 || 'Зургийн хэмжээ 1 MB -с хэтрэхгүй байх шаардлагатай!',
                imageRequire: value => !!value || 'Зураг оруулах шаардлагатай!',
                plotLength: value => value.length <= 3000 || '3000 тэмдэгтээс хэтэрлээ!',
                yearCheck: value => value >= 1900 && value <= this.currentYear || 'Та оноо зөв оруулна уу!'
            },
            serial: {},
            serialLanguageItems: [],
            serialLanguageValues: [],
            serialMongolTitle: '',
            serialInternationalTitle: '',
            serialOriginalTitle: '',
            serialPoster: null,
            serialPosterURL: null,
            serialPosterCheck: null,
            serialBackground: null,
            serialBackgroundURL: null,
            serialBackgroundCheck: null,
            serialFormatItems: [{text: 'Уран сайхны кино', value: 'Уран сайхны кино'}, {text: 'Баримтат кино', value: 'Баримтат кино'}, {text: 'Хүүхэлдэйн кино', value: 'Хүүхэлдэйн кино'}, {text: 'Эксперименталь', value: 'Эксперименталь'}],
            serialFormat: 'Уран сайхны кино',
            serialGenreItems: [],
            serialGenreValues: [],
            serialSubGenreItems: [],
            serialSubGenreValues: [],
            serialPlot: '',
            serialPlotEng: '',
            serialReleasedYear: 0,
            serialFinishedYear: 0,
            serialCountryItems: [],
            serialCountryValues: [],
            serialRatingItems: [{text: 'NR - Not Rated', value: 'NR - Not Rated'}, {text: 'G - General Audiences', value: 'G - General Audiences'}, {text: 'PG - Parental Guidance Suggested', value: 'PG - Parental Guidance Suggested'}, {text: 'R - Restricted', value: 'R - Restricted'}],
            serialRating: 'NR - Not Rated',
            serialRuntime: 0,
            searchTag: null,
            serialTagItems: [],
            serialTagValues: [],
            serialTheater: false
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user'])
    },
    watch:{
        searchTag(val){
            if(val){
                axios
                    .get('/tag/api-tag-list/', { params: { tag: val }}).then(response => {this.serialTagItems = response.data.tag_list})
            }
        }
    },
    methods:{
        getLanguage(){
            axios
                .get('/language/api-language-list/').then(response => {this.serialLanguageItems = response.data.language_list})
        },
        getGenre(){
            axios
                .get('/genre/api-genre-list/').then(response => {this.serialGenreItems = response.data.genre_list})
        },
        getSubGenre(){
            axios
                .get('/genre/api-subgenre-list/').then(response => {this.serialSubGenreItems = response.data.subgenre_list})
        },
        getCountry(){
            axios
                .get('/country/api-production-country-list/').then(response => {this.serialCountryItems = response.data.country_list})
        },
        validate(){
            this.$refs.form.validate()
        },
        submitCreate(){
            var artists = this.$refs.artists.artistList
            var roles = this.$refs.roles.roleList

            const createItems = {
                'languages': JSON.stringify(this.serialLanguageValues),
                'm_title': this.serialMongolTitle,
                'i_title': this.serialInternationalTitle,
                'o_title': this.serialOriginalTitle,
                'format': this.serialFormat,
                'genres': JSON.stringify(this.serialGenreValues),
                'subgenres': JSON.stringify(this.serialSubGenreValues),
                'plot': this.serialPlot,
                'en_plot': this.serialPlotEng,
                're_year': this.serialReleasedYear,
                'fi_year': this.serialFinishedYear,
                'countries': JSON.stringify(this.serialCountryValues),
                'rating': this.serialRating,
                'runtime': this.serialRuntime,
                'tags': JSON.stringify(this.serialTagValues),
                'artists': JSON.stringify(artists),
                'roles': JSON.stringify(roles)
            }

            const createData = new FormData()

            if(this.serialPoster){
                createData.append('serial-poster', this.serialPoster, 'serial_poster')
            }

            if(this.serialBackground){
                createData.append('serial-background', this.serialBackground, 'serial_background')
            }

            for( var key in createItems ){
                createData.append(key, createItems[key])
            }

            axios
                .post('/serial/api-create-serial/', createData)
                .then((response) => {
                    if (response.data.success === true){
                        this.$store.commit('SET_SNACK', {message: 'Цуврал амжилттай нэмэгдлээ, сайтын админ шалгаад нийтлэх болно!', color: 'green'})
                        this.$router.push({ name: 'serial-list' }).catch(()=>{})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        },
        cropPoster() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.serialPoster = blob }, "image/jpeg")
            this.serialPosterURL = canvas.toDataURL("jpeg")
            this.serialPosterCheck = 'serial-poster'
            this.posterDialog = false
            this.image = ''
        },
        cropBackground() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.serialBackground = blob }, "image/jpeg")
            this.serialBackgroundURL = canvas.toDataURL("jpeg")
            this.serialBackgroundCheck = 'serial-background'
            this.backgroundDialog = false
            this.image = ''
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])
                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        }
    },
    created(){
        this.getLanguage()
        this.getGenre()
        this.getSubGenre()
        this.getCountry()
        document.title = 'шинээр цуврал нэмэх'
    }
}
</script>

<style scoped src="@/assets/styles/CU_artist.css">
</style>