<template>
    <v-card class="ma-1 justify-center column" outlined>
        <v-img class="mx-auto pt-3" aspect-ratio="1.4" contain :src="award.award === 'Оскарын кино наадам' ? url + '/static/img/film-oscar.png' : url + '/static/img/film-festival.png'">
			<div class="award-description">
				<router-link :to="{ name: 'annual-award-detail', params: {slug: (award.award_slug + '-' + award.date), year: award.date} }" class="award-router">
					{{ award.award }} - {{ award.date }}
				</router-link>
				<div class="award-title">
					<strong v-if="award.win" class="award-win">{{ award.nominee }}</strong>
					<strong v-else class="award-nominee">{{ award.nominee }}</strong>
				</div>
			</div>
        </v-img>
    </v-card>
</template>

<script>
export default {
	props: {
		award: Object
	},
	data(){
		return{
			url: process.env.VUE_APP_BASEURL
		}
	}
}
</script>

<style scoped>

.column {
    -ms-flex: 100%; /* IE10 */
    flex: 100%;
    max-width: 100%;
	background-color: rgba(0, 0, 0, 0);
	border-color:rgba(0, 0, 0, 0);
	text-decoration: none;
}

.award-router {
	font-size: 12px;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	color: #696969;
}

.award-description {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	height: 100%;
}

.award-title{
	margin-top: 10px;
	line-height: 90%;
}

.award-win,
.award-nominee {
	font-size: 14px;
	color: #0066b5;
}

.award-win {
	color: #B29600;
}

</style>
