<template>
    <router-link :to="{ name: 'serial-detail', params: { slug: serial.serial_slug }}">
        <img :src="mediaUrl + '/' + serial.serial_poster" class="column rounded"/>
    </router-link>
</template>

<script>
export default {
    props: {
        serial: Object
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL
        }
    }
}
</script>

<style scoped>
.column {
    width: 100%;
}

</style>