import Vue from 'vue'
import App from './App.vue'
// import './assets/style.css'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
// import VueCookies from 'vue-cookies'
// import VueSocialSharing from 'vue-social-sharing'
import MasonryWall from '@yeger/vue2-masonry-wall'


Vue.use(MasonryWall)
// Vue.use(VueCookies)

// Vue.config.productionTip = false withCredentials: true, credentials: "include"

// axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_APIURL
axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APISECRETKEY
axios.defaults.headers.common['Content-Type'] = 'application/json'

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
