<template>
    <div class="home_box__generic_listcard">
        <div class="generic-list__row" :id="idName" @touchstart="$event => touchDown($event)" @touchmove="$event => touchMove($event)" @touchend="$event => touchUp($event)">
            <v-card v-for="(review, index) in list" :key="index" class="generic-list__card">
                <router-link :to="type === 'movie' ? { name: 'movie-detail', params: { slug: review.slug }} : type === 'serial' ? { name: 'serial-detail', params: { slug: review.slug }} : { name: 'episode-detail', params: { serialSlug: review.serial_slug, seasonSlug: review.season_slug, episodeSlug: review.episode_slug }}">
                    <v-img id="myImage" class="home_box__journal_img rounded" :src="url + review.poster" />
                </router-link>
                <div v-if="review.user_slug" class="home_box__review_user_box">
                    <div class="home_box__user_block">
                        <router-link :to="{ name: 'user-feed', params: {slug: review.user_slug }}">
                            <img class="home_box__review_user_img" :src="url + '/' + review.user_image"/>
                        </router-link>
                        <div :class="format === 'critic' ? 'home_box__review_user_grade' : 'home_box__review_user_grade home_box__review_user_color'">{{ review.user_grade }}<span class="home_box__movie_grade"> / {{ review.grade }}</span></div>
                    </div>
                </div>
            </v-card>
        </div>
        <div class="home_box__journal_chevrons">
            <v-btn text @click="reviewPrev">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn text @click="reviewNext">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        idName: String,
        list: Array,
        type: String,
        format: String
    },
    data(){
        return{
            url: process.env.VUE_APP_BASEURL,
            railCon: '',
            dragging: false,
            touchLocation: '',
            galleryLocation: ''
        }
    },
    methods:{
        setRailcon(){
            this.railCon = document.getElementById(this.idName)
        },
        reviewNext(){
            this.railCon.scrollLeft = this.railCon.scrollLeft + this.railCon.offsetWidth
        },
        reviewPrev(){
            this.railCon.scrollLeft = this.railCon.scrollLeft - this.railCon.offsetWidth
        },
        touchDown(event){
            this.dragging = true
            this.touchLocation = event.touches[0].clientX
            this.galleryLocation = this.railCon.scrollLeft
        },
        touchMove(event){
            if (!this.dragging)
                return
            let offset = event.touches[0].clientX - this.touchLocation
            this.railCon.scrollLeft = this.galleryLocation - offset
        },
        touchUp(event){
            this.dragging = false
            this.touchLocation = event.changedTouches[0].clientX
            this.galleryLocation = this.railCon.scrollLeft
        }
    },
    mounted(){
        this.setRailcon()
    }
}
</script>
<style scoped>

.home_box__generic_listcard {
    margin-bottom: 10px;
}

.home_box__journal_img {
    object-position: 0 0;
    max-height: 450px;
    width: 100%;
    object-fit: cover;
}

.home_box__review_user_box {
    padding: 0 10px;
    position: relative;
    height: 30px;
}

.home_box__user_block {
    top: -22px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
}

.home_box__review_user_img {
    border: 3px solid white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.home_box__review_user_name {
    margin-top: auto;
    margin-left: 0.3em;
    margin-bottom: 0.5em;
    font-weight: 600;
    font-size: .8em;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}


.home_box__review_user_grade {
    margin-top: auto;
    margin-left: 0.3em;
    margin-bottom: 0.2em;
    color: #B29600;
    font-weight: 600;
    font-size: 20px;
}

.home_box__review_user_color {
    color: #0066b5;
}

.home_box__movie_grade {
    font-size: .8em;
    color: #696969;
}

.home_box__journal_chevrons {
    float: right;
}

.generic-list__row {
    display: grid;
	grid-template-columns: repeat(16, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	overflow: hidden;
    scroll-behavior: smooth;
    gap: 0.5rem;
    padding: 0.5rem;
}

.generic-list__card {
    min-width: 190px;
}

</style>