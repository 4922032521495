<template>
    <v-card>
        <router-link :to="{ name: 'season-detail', params: {serialSlug: serialSlug, seasonSlug: season.season_slug} }">
            <v-img :src="baseUrl + season.season_poster" class="rounded"/>
        </router-link>
        <div v-show="season.grade_average !== null || season.g_average_audience !== null" class="seasonreview">
            <div v-show="season.grade_average">
                <v-rating :value="parseInt(season.grade_average)*0.05" color="#B29600" background-color="#B29600" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="18"></v-rating>
                <div class="seasonreviewer">({{ season.critics }} шүүмжлэгч)</div>
            </div>
            <div v-show="season.g_average_audience">
                <v-rating :value="parseInt(season.g_average_audience)*0.05" color="#0066b5" background-color="#0066b5" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="14"></v-rating>
                <div class="seasonreviewer">({{ season.audiences }} кино сонирхогч)</div>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        season: Object,
        serialSlug: String
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            emptyIcon: 'mdi-star-outline',
            fullIcon: 'mdi-star',
            halfIcon: 'mdi-star-half-full',
        }
    }
}
</script>

<style scoped>

.seasonreview {
    color: #484848;
    font-size: 12px;
    text-align: right;
    padding: 5px 15px;
}

.seasonreviewer {
    font-size: 12px;
    font-style: italic;
}

</style>
