<template>
    <div v-if="user.isAuthenticated && user.contributer" class="create_movie">
        <div class="create_movie__title">Та {{ artistProfessionalName }} уран бүтээлчийн мэдээлэлд засвар хийх гэж байна!</div>
        <v-divider class="mx-4"></v-divider>
        <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitUpdate">
            <div class="create_movie__block">
                <div class="create_movie__image_block">
                    <v-card><v-img :src="artistPhotoURL"/></v-card>
                    <v-text-field v-model="artistPhotoURL" disabled label="Зураг" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="imageDialog" width="600px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Зураг нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="imageDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Уран бүтээлчийн зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 240, height: 360}" :canvas="{width: 240, height: 360}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropImage">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="create_movie__title_block">
                    <v-text-field v-model="artistProfessionalName" label="Олонд танигдсан нэр (Монголоор)*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="artistProfessionalNameEn" label="Олонд танигдсан нэр (Англиар)*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="artistLastName" label="Овог (Англиар)*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="artistFirstName" label="Нэр (Англиар)*" required :rules="[rules.required]"></v-text-field>
                    <v-combobox v-model="artistCountries" :items="artistCountryItems" label="Улсын нэр*" multiple item-text="country_mongol_name" :return-object="false" :rules="[rules.choice]"></v-combobox>
                    <v-combobox v-model="artistOldCountries" :items="artistCountryItems" label="Эртний хэрэглэхээ больсон улсын нэршил" multiple item-text="country_mongol_name" :return-object="false"></v-combobox>
                    <v-text-field v-model="artistBorn" label="Төрсөн он*" required min="0" :rules="[rules.yearCheck]"></v-text-field>
                    <v-text-field v-model="artistDied" label="Нас барсан он" min="0" :rules="[rules.yearCheck]"></v-text-field>
                </div>
            </div>
            <div class="create_movie__block_content">
                <v-textarea v-model="artistBio" label="Товч намтар" hint="Та 4500 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.bioLength]"></v-textarea>
                <v-textarea v-model="artistBioEn" label="Товч намтар (Англиар)" hint="Та 4500 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.bioLength]"></v-textarea>
                <v-combobox v-model="artistProfessions" :items="artistProfessionItems" label="Мэргэжил" multiple item-text="profession_mongol_title" :return-object="false" :rules="[rules.choice]"></v-combobox>
                <UpdateQuote ref="quotes" :slug="slug"/>
                <br>
                <v-card-actions class="justify-center">
                    <v-btn :disabled="checkValid" @click="validate" type="submit" class="create_movie__submit_btn" block>Хадгалах</v-btn>
                </v-card-actions>
            </div>
        </v-form>
        <v-divider class="mx-4 ma-5"></v-divider>
        <router-link class="update_movie__no_decoration" :to="{ name: 'artist-detail', params: { slug: slug }}">
            <v-btn class="update_movie__back_btn">Буцах</v-btn>
        </router-link>
    </div>
</template>

<script>
import axios from 'axios'
import UpdateQuote from '@/views/artist/update/UpdateQuote.vue'
import { mapState } from 'vuex'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
    components: {
        UpdateQuote,
        Cropper
    },
    props: {
        slug: String
    },
    data(){
        return{
            currentYear: new Date().getFullYear(),
            baseUrl: process.env.VUE_APP_BASEURL,
            image: "",
            imageDialog: false,
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                choice: value => value.length > 0 || 'Сонгох шаардлагатай!',
                bioLength: value => value.length <= 4500 || '4500 тэмдэгтээс хэтэрлээ!',
                yearCheck: value => value <= this.currentYear || 'Та оноо зөв оруулна уу!'
            },
            artist: {},
            artistProfessionalName: '',
            artistProfessionalNameEn: '',
            artistLastName: '',
            artistFirstName: '',
            artistCountryItems: [],
            artistCountries: [],
            artistOldCountries: [],
            artistPhoto: null,
            artistPhotoURL: null,
            artistPhotoCheck: null,
            artistBorn: 0,
            artistDied: 0,
            artistBio: '',
            artistBioEn: '',
            artistProfessionItems: [],
            artistProfessions: []
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user'])
    },
    methods:{
        getCountries(){
            axios
                .get('/country/api-country-list/').then(response => { this.artistCountryItems = response.data.country_list })
        },
        getProfessions(){
            axios
                .get('/artist/api-profession-list/').then(response => {this.artistProfessionItems = response.data.profession_list})
        },
        onPhotoSelected(event){
            if(event && event['type']==='image/jpeg'){
                this.artistPhoto = event
                this.artistPhotoURL = window.URL.createObjectURL(event)
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        validate(){
            this.$refs.form.validate()
        },
        getArtistDetail(){
            axios
                .get('/artist/api-artist-detail/', { params: { slug: this.slug, flag: 1 } })
                .then(response => { this.artist = response.data.artist_detail, 
                                    document.title = this.artist['pro_name_en'],
                                    this.artistProfessionalName = this.artist['pro_name'],
                                    this.artistProfessionalNameEn = this.artist['pro_name_en'],
                                    this.artistLastName = this.artist['last_name'],
                                    this.artistFirstName = this.artist['first_name'],
                                    this.artistCountries = this.artist['countries'],
                                    this.artistOldCountries = this.artist['old_countries'],
                                    this.artistPhotoURL = this.baseUrl + this.artist['photo'],
                                    this.artistPhotoCheck = 'artist-photo',
                                    this.artistBorn = this.artist['born'],
                                    this.artistDied = this.artist['died']
                                    this.artistBio = this.artist['biography'],
                                    this.artistBioEn = this.artist['biography_en'],
                                    this.artistProfessions = this.artist['professions']})
        },
        submitUpdate(){
            var quotes = this.$refs.quotes.artistQuotes

            const updateItems = {
                'slug': this.slug,
                'pro_name': this.artistProfessionalName,
                'pro_name_en': this.artistProfessionalNameEn,
                'last_name': this.artistLastName,
                'first_name': this.artistFirstName,
                'countries': JSON.stringify(this.artistCountries),
                'old_countries': JSON.stringify(this.artistOldCountries),
                'born': this.artistBorn,
                'died': this.artistDied,
                'bio': this.artistBio,
                'bio_en': this.artistBioEn,
                'professions': JSON.stringify(this.artistProfessions),
                'quotes': JSON.stringify(quotes),
            }

            const updateData = new FormData()

            if(this.artistPhoto){
                updateData.append('artist-photo', this.artistPhoto, 'artist_photo')
            }

            for( var key in updateItems ){
                updateData.append(key, updateItems[key])
            }

            axios
                .post('/artist/api-update-artist/', updateData)
                .then((response) => {
                    if (response.data.success === true){
                        this.$store.commit('SET_SNACK', {message: 'Уран бүтээлчийн мэдээлэл амжилттай засагдлаа!', color: 'green'})
                        this.$router.push({ name: 'artist-detail', params: { slug: this.slug } }).catch(()=>{})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        },
        cropImage() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.artistPhoto = blob }, "image/jpeg")
            this.artistPhotoURL = canvas.toDataURL("jpeg")
            this.imageDialog = false
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])

                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        destroyed() {
            if (this.image) {
                URL.revokeObjectURL(this.image)
            }
        }
    },
    created(){
        this.getCountries()
        this.getProfessions()
        this.getArtistDetail()
        document.title = 'уран бүтээлч засах'
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>
