<template>
    <div class="movieListMargin">
        <legend class="generic-list__legendbar"><strong>{{ listTitle }}:</strong></legend>
        <div class="generic-list__row">
            <ListedCard v-for="(list, index) in contentList" :key="index" :type="type" :list="list" :user_like="list.user_like" :total_likes="list.list_likes" :widthGap="widthGap"/>
        </div>
        <v-pagination v-model="page" :length="listPage" @input="getGenericList()" :total-visible="7"></v-pagination>
    </div>
</template>

<script>
import axios from 'axios'
import ListedCard from '@/components/generics/movie_serial/GenericListedCard.vue'

export default {
    props: {
        slug: String
    },
    components: {
        ListedCard
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            routeName: this.$route.name,
            type: '',
            listTitle: '',
            widthGap: 9.3,
            
            requestUserSlug: this.slug,
            contentList: [],
            page: 1,
            listPage: 0
        }
    },
    created(){
        this.getGenericList()
    },
    watch: {
        $route(to, from){
            this.routeName = to.name
            this.page = 1
            this.listPage = 0
            this.contentList = []
            this.getGenericList()
        }
    },
    methods:{
        getGenericList(){
            if (this.routeName === 'user-movie-list') {
                this.type = 'movie'
                this.listTitle = document.title = 'Кино жагсаалт'

                axios
                    .get('/user/api/v-user-movielist/', { params: { slug: this.requestUserSlug, page: this.page }})
                    .then((response) => { this.contentList = response.data.api_user_movie_list, this.listPage = response.data.page })
            } else if (this.routeName === 'user-serial-list') {
                this.type = 'seril'
                this.listTitle = document.title = 'Цувралын жагсаалт'

                axios
                    .get('/user/api/v-user-seriallist/', { params: { slug: this.requestUserSlug, page: this.page }})
                    .then((response) => { this.contentList = response.data.api_user_serial_list, this.listPage = response.data.page })
            }
            
        }
    }
}
</script>

<style scoped>

.movieListMargin {
    margin: 20px 0;
}

.generic-list__legendbar {
    border-bottom: #0066b5 1px solid;
    color: #0066b5;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
    margin-bottom: 5px;
}

.generic-list__row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100% !important;
    padding: 0.5rem;
    margin: 0 auto;
    gap: 0.5rem;
}

@media (min-width: 481px) {
    .generic-list__row {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

</style>