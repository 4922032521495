<template>
    <div>
        <legend class="generic-list__legendbar" id="generic-list__title"><strong>{{ listTitle }}</strong></legend>
        <v-select :items="linkType === 'artist-detail' ? artistSortItems : genericSortItems" label="Үзүүлэх дараалал" v-model="genericSort" @change="getGenericList()" dense solo></v-select>
        <div class="generic-list__row">
            <v-card v-for="(item, index) in contentList" :key="index">
                <div class="generic-list__movie_react_block">
                    <v-img :src="baseUrl + imageURL(item)" class="rounded generic-list__column" @click="imageLink(item)"/>
                    <div v-if="item.movie_reacts">
                        <div v-show="setReaction(item.movie_reacts[0]).length">
                            <v-chip class="generic-list__react-position">
                                <span v-for="(react, index) in setReaction(item.movie_reacts[0])" :key="index">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">{{ react.emoji }}<span v-show="index + 1 < setReaction(item.movie_reacts[0]).length">&nbsp;</span></div>
                                        </template>
                                        <span>{{ react.name }}</span>
                                    </v-tooltip>
                                </span>
                            </v-chip>
                        </div>
                    </div>
                </div>
                <span v-if="linkType === 'episode-review-detail'">
                    <v-card-title >
                        {{ item.episode_name }}
                    </v-card-title>
                    <v-card-text>
                        {{ item.season_name }}
                    </v-card-text>
                </span>
            </v-card>
        </div>
        <v-pagination v-model="page" :length="itemPage" @input="getGenericList()" :total-visible="4"></v-pagination>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        slug: String
    },
    data() {
        return{
            linkType: '',
            routeName: this.$route.name,
            listTitle: '',
            baseUrl: process.env.VUE_APP_BASEURL,
            requestUserSlug: this.slug,
            contentList: [],
            genericSortItems: [
                { text: 'Шинэ киног эхэнд', value: 1 },
                { text: 'Шинэ киног сүүлд', value: 2 },
                { text: 'Өндөр үнэлгээтэйг эхэнд', value: 3 },
                { text: 'Өндөр үнэлгээтэйг сүүлд', value: 4 },
                { text: 'Их үзэгчтэйг эхэнд', value: 5 },
                { text: 'Их үзэгчтэйг сүүлд', value: 6 },
                { text: 'Цагаан толгойн дарааллаар', value: 7 },
                { text: 'Цагаан толгойн эсрэг дарааллаар', value: 8 }
            ],
            artistSortItems: [
                { text: 'Өндөр настайг эхэнд', value: 1 },
                { text: 'Өндөр настайг сүүлд', value: 2 },
                { text: 'Цагаан толгойн дарааллаар', value: 3 },
                { text: 'Цагаан толгойн эсрэг дарааллаар', value: 4 }
            ],
            genericSort: 0,
            page: 1,
            itemPage: 0,
            reactions: [
	            { name: 'Миний "guilty pleasure"', "emoji": "💙", text: "guilty_pleasure" },
                { name: "Киночдын гарын авлага болхуйц кино", "emoji": "📝", text: "movie_guide" },
                { name: "Киноны хөгжим нь маш их таалагдлаа", "emoji": "🎵", text: "awesome_music" },
                { name: "Найруулагчийн төгс ажил", "emoji": "📣", text: "top_notch_directing" },
                { name: "Жүжигчид нь маш сайн тогложээ", "emoji": "🎭", text: "top_notch_act" },
                { name: "Зураг авалт нь төгс болжээ", "emoji": "🎥", text: "top_notch_cinematography" },
                { name: "Сайн кино болжээ, БРАВО!!!", "emoji": "👏", text: "bravo" },
                { name: "Киноны мөч бүхэн таалагдлаа", "emoji": "😍", text: "loved_every_minute" },
                { name: "Дундуур нь унтсан", "emoji": "💤", text: "i_slept" },
                { name: "Шоконд орсон", "emoji": "😳", text: "i_shocked" },
                { name: "Traumatized", "emoji": "😵", text: "i_traumatized" },
                { name: "Хүлээлтэд хүрсэнгүй", "emoji": "😞", text: "i_am_dissapointed" },
                { name: "Яахавдээ л санадлаа", "emoji": "😒", text: "i_am_not_amused" },
                { name: "Үнэхээр хөгжилтэй санагдлаа", "emoji": "😆", text: "it_was_funny" },
                { name: "Маш гунигтай санагдлаа", "emoji": "😢", text: "it_was_sad" },
                { name: "Маш их айлаа", "emoji": "😱", text: "i_was_scared" },
	            { name: "Mind blown", "emoji": "🤯", text: "it_was_mind_blown" }
	        ],
        }
    },
    mounted() {
        this.getGenericList()
    },
    watch: {
        $route(to, from){
            this.genericSort = 0
            this.routeName = to.name
            this.page = 1
            this.itemPage = 0
            this.contentList = []
            this.getGenericList()
        }
    },
    methods: {
        imageLink(item){
            if (this.linkType === "movie-detail") this.$router.push({ name: "movie-detail", params: { slug: item.movie_slug } })
            else if (this.linkType === "movie-review-detail") this.$router.push({ name: "movie-review-detail", params: { userSlug: item.user_slug, movieSlug: item.movie_slug } })
            else if (this.linkType === "serial-detail") this.$router.push({ name: "serial-detail", params: { slug: item.serial_slug } })
            else if (this.linkType === "episode-review-detail") this.$router.push({ name: "episode-review-detail", params: { userSlug: item.user_slug, serialSlug: item.serial_slug, seasonSlug: item.season_slug, episodeSlug: item.episode_slug } })
            else if (this.linkType === "artist-detail") this.$router.push({ name: "artist-detail", params: { slug: item.artist_slug } })   
        },
        imageURL(a){
            if (this.linkType === "movie-detail" || this.linkType === "movie-review-detail") return a.movie_poster
            else if (this.linkType === "serial-detail" || this.linkType === "episode-review-detail") return a.serial_poster
            else if (this.linkType === "artist-detail") return a.artist_photo
        },
        setReaction(reacts){
            let reactList = []
            if (reacts) {
                for (const [, value] of Object.entries(reacts)) {
                    let react = this.reactions.find(x => x.text === `${value}`)
                    if (react) reactList.push(react)
                }
            }
            return reactList
        },
        getGenericList(){
            setTimeout(function() {
                var el = document.getElementById('generic-list__title')
                if(typeof el !== "undefined" && el !== null) {
                    el.scrollIntoView({ behavior: "smooth" })
                }
            }, 500)

            if (this.routeName === 'favorite-movies') {
                this.listTitle = document.title = 'Дуртай кинонууд'
                this.linkType = "movie-detail"
                axios
                    .get('/user/api/v-user-favoritemovies/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.genericSort }})
                    .then((response) => { this.contentList = response.data.api_favorite_movie_list, this.itemPage = response.data.page })
            } else if (this.routeName === 'favorite-short-movies') {
                this.listTitle = document.title = 'Дуртай богино кинонууд'
                this.linkType = "movie-detail"
                axios
                    .get('/user/api/v-user-favoriteshortmovies/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.genericSort }})
                    .then((response) => { this.contentList = response.data.api_favorite_short_movie_list, this.itemPage = response.data.page })
            } else if (this.routeName === 'reacted-movies') {
                this.listTitle = document.title = 'Кино хариулал'
                this.linkType = "movie-detail"
                axios
                    .get('/user/api/v-user-reactedmovies/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.genericSort }})
                    .then((response) => { this.contentList = response.data.api_favorite_movie_list, this.itemPage = response.data.page })
            } else if (this.routeName === 'watch-movies') {
                this.listTitle = document.title = 'Үзэх кинонууд'
                this.linkType = "movie-detail"
                axios
                    .get('/user/api/v-user-watchmovies/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.genericSort }})
                    .then((response) => { this.contentList = response.data.api_watch_movie_list, this.itemPage = response.data.page })
            } else if (this.routeName === 'movie-detailed-reviews') {
                this.listTitle = document.title = 'Тойм шүүмж'
                this.linkType = "movie-review-detail"
                axios
                    .get('/user/api/v-user-moviedetailedreviewlist/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.genericSort }})
                    .then((response) => { this.contentList = response.data.api_movie_detailed_review_list, this.itemPage = response.data.page })
            } else if (this.routeName === 'favorite-serials') {
                this.listTitle = document.title = 'Дуртай цувралууд'
                this.linkType = "serial-detail"
                axios
                    .get('/user/api/v-user-favoriteserials/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.genericSort }})
                    .then((response) => { this.contentList = response.data.api_favorite_serial_list, this.itemPage = response.data.page })
            } else if (this.routeName === 'reacted-serials') {
                this.listTitle = document.title = 'Цувралын хариулал'
                this.linkType = "serial-detail"
                axios
                    .get('/user/api/v-user-reactedserials/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.genericSort }})
                    .then((response) => { this.contentList = response.data.api_favorite_serial_list, this.itemPage = response.data.page })
            } else if (this.routeName === 'watch-serials') {
                this.listTitle = document.title = 'Үзэх цувралууд'
                this.linkType = "serial-detail"
                axios
                    .get('/user/api/v-user-watchserials/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.genericSort }})
                    .then((response) => { this.contentList = response.data.api_watch_serial_list, this.itemPage = response.data.page })
            } else if (this.routeName === 'idols') {
                this.listTitle = document.title = 'Шүтдэг уран бүтээлчид / түүхэн хүмүүс'
                this.linkType = "artist-detail"
                axios
                    .get('/user/api/v-user-idollist/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.genericSort }})
                    .then((response) => { this.contentList = response.data.api_user_idol_list, this.itemPage = response.data.page })
            } else if (this.routeName === 'episode-detailed-reviews') {
                this.listTitle = document.title = 'Тойм шүүмж'
                this.linkType = "episode-review-detail"
                axios
                    .get('/user/api/v-user-episodedetailedreviewlist/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.genericSort }})
                    .then((response) => { this.contentList = response.data.api_episode_detailed_review_list, this.itemPage = response.data.page })
            }
        }
    }
}
</script>

<style scoped>

.generic-list__legendbar {
    border-bottom: #0066b5 1px solid;
    color: #0066b5;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
    margin-bottom: 5px;
}


.generic-list__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    margin: 0 auto;
}

.generic-list__column {
    cursor: pointer;
}

.generic-list__movie_react_block {
    position: relative;
}

.generic-list__react-position {
    z-index: 1;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px;
    background-color: #fff;
}

@media (min-width: 481px) {
    .generic-list__row {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

</style>