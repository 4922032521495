<template>
    <div v-if="user.isAuthenticated && user.contributer" class="create_movie">
        <div class="create_movie__legend_bar">Та {{ season.serial_title }} цувралын {{ season.season_name }} архивын зургийг засах гэж байна!</div>
        <v-card class="pa-2" outlined>
            <div class="create_movie__artist_block">
                <div class="create_movie__artist_search">
                    <v-img :src="seasonImageURL" class="ma-2 mx-auto" contain :max-width="imageWidth"/>
                    <v-file-input type="file" accept="image/jpeg" label="архивын зураг нэмэх*" @change="onImageSelected" @click:clear="seasonImageURL = ''"></v-file-input>
                </div>
                <div class="create_movie__artist_role">
                    <v-textarea v-model="imageDescription" label="Зургийн тайлбар"></v-textarea>
                </div>
                <v-btn color="primary" block @click="addImage()">Зураг нэмэх</v-btn>
            </div>
            <v-card-text>
                <div class="create_movie__toggle_btn">
                    <v-btn-toggle v-if="seasonImages != ''" mandatory dense color="#0066b5">
                        <v-btn @click="layout = 'list'"><v-icon class="fas fa-th-list">mdi-view-list</v-icon></v-btn>
                        <v-btn @click="layout = 'grid'"><v-icon class="fas fa-th">mdi-view-grid</v-icon></v-btn>
                    </v-btn-toggle>
                </div>
                <draggable :class="layout === 'grid' ? 'generic-list__row_img_wide' : 'generic-list__row_img'" v-model="seasonImages" tag="ul" v-bind="dragOptions" @start="drag = true" @end="drag = false">
                    <v-card v-for="(image, index) in seasonImages" :key="index" class="ma-1">
                        <div class="create_movie__archive_image_box">
                            <div class="create_movie__archive_image_block">
                                <img :src="image.id ? baseUrl + image.image : image.image" class="rounded create_movie__archive_image"/>
                            </div>
                            <div v-if="layout !== 'grid'" class="create_movie__archive_image_txt">
                                <v-textarea v-model="image.description" label="Зургийн тайлбар"></v-textarea>
                            </div>
                            <div class="create_movie__del_btn">
                                <v-btn class="mx-2" fab dark x-small color="red" v-on:click="removeImage(index, image.id)"><v-icon dark>mdi-minus</v-icon></v-btn>
                            </div>
                        </div>
                    </v-card>
                </draggable>
            </v-card-text>
            <v-form v-on:submit.prevent="submitUpdate">
                <v-card-actions class="justify-center">
                    <v-btn :disabled="submitBtn" type="submit" class="update_movie__image_update" block>Хадгалах</v-btn>
                </v-card-actions>
            </v-form>
            <v-divider class="mx-4 ma-5"></v-divider>
            <router-link :to="{ name: 'season-detail', params: { serialSlug: serialSlug, seasonSlug: seasonSlug }}">
                <v-btn class="update_movie__back_btn">Буцах</v-btn>
            </router-link>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import { mapState } from 'vuex'

export default {
    components: {
        draggable
    },
    props: {
        serialSlug: String,
        seasonSlug: String
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            season: {},
            seasonImages: [],
            seasonImage: null,
            seasonImageURL: null,
            imageWidth: 0,
            layout: 'list',
            imageDescription: ''
        }
    },
    computed:{
        dragOptions(){
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        },
        submitBtn(){
            if(this.seasonImages.length > 0){
                return false
            } else if(this.seasonImages.length == 0){
                return true
            }
        },
        ...mapState(['user'])
    },
    methods:{
        getMovieImages(){
            axios
                .get('/serial/api-season-detail/', { params: {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug }})
                .then(response => { this.season = response.data.season_detail, document.title = this.season['serial_title'] + ' - ' + this.season['season_name'], this.seasonImages = this.season.images })
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404' }) }})
        },
        onImageSelected(event){
            if(event && event['type']==='image/jpeg'){
                this.imageWidth = 250
                this.seasonImage = event
                this.seasonImageURL = window.URL.createObjectURL(event)
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        addImage(){
            if(this.seasonImage){
                this.seasonImages.push({update: true, image: this.seasonImageURL, file: this.seasonImage, description: this.imageDescription})
            }
            this.seasonImage = ''
            this.seasonImageURL = ''
            this.imageDescription = ''
            this.imageWidth = 0
        },
        removeImage(index, id){
            this.seasonImages.splice(index, 1)
            axios
                .post('/serial/api-delete-season-image/', { season: this.season.id, id: id })
        },
        submitUpdate(){
            const updateImage = new FormData()

            for(var i = 0; i < this.seasonImages.length; i++){
                updateImage.append('id', i)
                updateImage.append('image-id', this.seasonImages[i].id)
                updateImage.append('description', this.seasonImages[i].description)
                updateImage.append('season-images', this.seasonImages[i].file)
            }

            updateImage.append("serial-slug", this.serialSlug)
            updateImage.append("season-slug", this.seasonSlug)

            axios
                .post('/serial/api-update-season-image/', updateImage)
                .then(response => {
                    if(response){
                        this.$router.push({ name: 'season-detail', params: { serialSlug: this.serialSlug, seasonSlug: this.seasonSlug } }).catch(()=>{})
                        this.$store.commit('SET_SNACK', {message: 'Цувралын сезоны архивын зураг амжилттай нэмэгдлээ!', color: 'green'})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        }
    },
    created(){
        this.getMovieImages()
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>