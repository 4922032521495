<template>
    <div class="home_box">
        <div class="home_box__article_box">
            <div class="home_box__legend_bar"><strong>Нийтлэл</strong></div>
            <v-card flat tile>
                <v-window v-model="journalOnboarding" reverse>
                    <v-window-item v-for="(journal, index) in journals" :key="index">
                        <router-link :to="{ name: 'journal-detail', params: { typeSlug: journal.main_type_slug, subTypeSlug: journal.sub_type_slug, slug: journal.slug }}">
                            <div class="home_box__journal_card">
                                <img class="home_box__journal_img" :src="url + journal.image"/>
                            </div>
                        </router-link>
                        <div class="list-card__user_box">
                            <div class="journal-card__txt">
                                <div class="journal-card__username">
                                    <strong>
                                        <router-link class="home_box__no_decoration" :to="{ name: 'user-feed', params: { slug: journal.user_slug }}">
                                            {{ journal.user_name }}
                                        </router-link>
                                    </strong> · <v-chip outlined>{{ journal.main_type }}</v-chip>
                                </div>
                            </div>
                        </div>
                        <div class="journal-card__title">{{ journal.title }}</div>
                    </v-window-item>
                </v-window>
                <div class="home_box__journal_chevrons">
                    <v-btn text @click="journalPrev">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn text @click="journalNext">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
            </v-card>
        </div>
        <div class="home_box__review_box">
            <div class="home_box__legend_bar"><strong>Сүүлийн үеийн кино шүүмжлэгчдийн үнэлгээ</strong></div>
            <GenerisListCard :list="criticMovieReviews" :type="'movie'" :idName="'critic_movie_review'" :format="'critic'"/>
        </div>
        <div class="home_box__review_box">
            <div class="home_box__legend_bar"><strong>Сүүлийн үеийн кино сонирхогчдын үнэлгээ</strong></div>
            <GenerisListCard :list="audienceMovieReviews" :type="'movie'" :idName="'audience_movie_review'" :format="'audience'"/>
        </div>
        <div class="home_box__review_box">
            <div class="home_box__legend_bar"><strong>Сүүлийн үеийн цуврал кино шүүмжлэгчдийн үнэлгээ</strong></div>
            <GenerisListCard :list="criticEpisodeReviews" :type="'episode'" :idName="'critic_serial_review'" :format="'critic'"/>
        </div>
        <div class="home_box__review_box">
            <div class="home_box__legend_bar"><strong>Сүүлийн үеийн цуврал кино сонирхогчдын үнэлгээ</strong></div>
            <GenerisListCard :list="audienceEpisodeReviews" :type="'episode'" :idName="'audience_serial_review'" :format="'audience'"/>
        </div>
        <div class="home_box__review_box">
            <div class="home_box__legend_bar"><strong>Сүүлд нэмэгдсэн кинонууд</strong></div>
            <GenerisListCard :list="latestMovies" :type="'movie'" :idName="'movie_list'"/>
        </div>
        <div class="home_box__review_box">
            <div class="home_box__legend_bar"><strong>Сүүлд нэмэгдсэн цувралууд</strong></div>
            <GenerisListCard :list="latestSerials" :type="'serial'" :idName="'serial_list'"/>
        </div>
        <div class="home_box__review_box">
            <div class="home_box__legend_bar"><strong>Сүүлийн үеийн тойм шүүмж</strong></div>
            <GenericMasonry :list="criticMovieDetailedReviews" :type="'movie'" :idName="'detailed_movie_review'" :format="'critic'"/>
        </div>
        <div class="home_box__review_box">
            <div class="home_box__legend_bar"><strong>Сүүлд нэмэгдсэн кино жагсаалтууд</strong></div>
            <div class="row">
                <ListedCard v-for="(list, index) in listedMovies" :key="index" :type="'movie'" :list="list" :user_like="list.user_like" :total_likes="list.list_likes" :widthGap="widthGap"/>
            </div>
        </div>
        <div class="home_box__review_box">
            <div class="home_box__legend_bar"><strong>Сүүлд нэмэгдсэн цуврал жагсаалтууд</strong></div>
            <div class="row">
                <ListedCard v-for="(list, index) in listedSerials" :key="index" :type="'serial'" :list="list" :user_like="list.user_like" :total_likes="list.list_likes" :widthGap="widthGap"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import GenerisListCard from '@/components/home/GenericListCard.vue'
import GenericMasonry from '@/components/home/GenericMasonry.vue'
import ListedCard from '@/components/generics/movie_serial/GenericListedCard.vue'

export default {
    name: 'Home',
    components: {
        GenerisListCard,
        GenericMasonry,
        ListedCard
    },
    data(){
        return {
            url: process.env.VUE_APP_BASEURL,
            journalLength: 5,
            journalOnboarding: 0,
            journals: [],
            criticMovieReviews: [],
            audienceMovieReviews: [],
            criticEpisodeReviews: [],
            audienceEpisodeReviews: [],
            latestMovies: [],
            latestSerials: [],
            criticMovieDetailedReviews: [],
            listedMovies: [],
            listedSerials: [],
            widthGap: 8
        }
    },
    methods:{
        journalNext(){
            this.journalOnboarding = this.journalOnboarding + 1 === this.journalLength ? 0 : this.journalOnboarding + 1
        },
        journalPrev(){
            this.journalOnboarding = this.journalOnboarding - 1 < 0 ? this.journalLength - 1 : this.journalOnboarding - 1
        },
        getJournals(){
            axios
                .get('/journal/api-home-journal-list/')
                .then(response => { this.journals = response.data.journal_list })
        },
        getLatestMovieCriticsReviews(){
            axios
                .get('/movie/api-home-latest-movie-reviews/', { params: { critic: true, detailed: false }})
                .then(response => { this.criticMovieReviews = response.data.latest_review_list })
        },
        getLatestMovieAudiencesReviews(){
            axios
                .get('/movie/api-home-latest-movie-reviews/', { params: { critic: false, detailed: false }})
                .then(response => { this.audienceMovieReviews = response.data.latest_review_list })
        },
        getLatestEpisodeCriticsReviews(){
            axios
                .get('/serial/api-home-latest-episode-reviews/', { params: { critic: true, detailed: false }})
                .then(response => { this.criticEpisodeReviews = response.data.latest_review_list })
        },
        getLatestEpisodeAudiencesReviews(){
            axios
                .get('/serial/api-home-latest-episode-reviews/', { params: { critic: false, detailed: false }})
                .then(response => { this.audienceEpisodeReviews = response.data.latest_review_list })
        },
        getLatestMovieList(){
            axios
                .get('/movie/api-home-latest-movie-list/')
                .then(response => { this.latestMovies = response.data.latest_movie_list })
        },
        getLatestSerialList(){
            axios
                .get('/serial/api-home-latest-serial-list/')
                .then(response => { this.latestSerials = response.data.latest_serial_list })
        },
        getLatestMovieCriticsDetailedReviews(){
            axios
                .get('/movie/api-home-latest-movie-reviews/', { params: { critic: true, detailed: true }})
                .then(response => { this.criticMovieDetailedReviews = response.data.latest_review_list })
        },
        getLatestListedMovies(){
            axios
                .get('/movie/api-home-latest-listed_movies/')
                .then(response => { this.listedMovies = response.data.listed_movies })
        },
        getLatestListedSerials(){
            axios
                .get('/serial/home-latest-listedserials/')
                .then(response => { this.listedSerials = response.data.listed_serials })
        }
    },
    created(){
        document.title = 'MFI'
        this.getJournals()
        this.getLatestMovieCriticsReviews()
        this.getLatestMovieAudiencesReviews()
        this.getLatestEpisodeCriticsReviews()
        this.getLatestEpisodeAudiencesReviews()
        this.getLatestMovieList()
        this.getLatestSerialList()
        this.getLatestMovieCriticsDetailedReviews()
        this.getLatestListedMovies()
        this.getLatestListedSerials()
    }
}
</script>
<style scoped>

.home_box {
    min-width: 320px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.home_box__legend_bar {
    color: #0066b5;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    margin: 10px auto;
    width: 100%;
}

.home_box__journal_card {
    max-height: 450px;
    max-width: 100%;
    margin-bottom: 20px;
}

.home_box__article_box {
    padding: 10px;
}

.home_box__review_box {
    padding: 20px 10px;
}

.home_box__journal_img {
    object-position: 0 0;
    max-height: 450px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.list-card__user_box {
    padding: 0 10px;
}

.home_box__review_slider {
    width: 1540px;
}

.journal-card__title {
    padding: 15px;
    font-size: 28px;
    font-weight: 400;
    color: #696969;
    border-bottom: 1px solid #a3a3a3;
}

.journal-card__txt {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.journal-card__username {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: .5em;
}

.home_box__no_decoration {
    text-decoration: none;
    color: #000;
}

.home_box__journal_chevrons {
    float: right;
}

.row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100% !important;
    padding: 0.5rem;
    margin: 0 auto;
    gap: 0.5rem;
}

@media all and (min-width: 481px) {

    .home_box {
        max-width: 1280px;
    }

    .home_box__journal_card {
        height: 450px;
    }

    .row {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

}

</style>
