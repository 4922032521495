<template>
    <div>
        <div class="create_movie__legend_bar">Холбоотой цуврал нэмэх/хасах:</div>
        <v-card class="pa-2" outlined>
            <div class="create_movie__artist_block">
                <div class="create_movie__artist_search">
                    <v-autocomplete no-filter v-model="selectedSerial" height="100" :items="searchItems" hide-no-data return-object :search-input.sync="searchSerial" clearable item-text="name" label="Цуврал" attach solo>
                        <template v-slot:selection="data">
                            <v-avatar left height="75" tile class="mx-3"><img :src="mediaUrl + '/' + data.item['serial_poster']" class="rounded"></v-avatar>
                            <div mx="2">{{ data.item['international_title'] }}</div>
                        </template>
                        <template v-slot:item="data">
                            <template v-if="data.item !== 'object'">
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['serial_poster']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['international_title']"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['serial_poster']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['international_title']"></v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </div>
            </div>
            <v-btn color="blue" block @click="addSerial">Нэмэх</v-btn>
            <br>
            <draggable v-model="serialList" class="generic-list__row" tag="ul" v-bind="dragOptions" @start="drag = true" @end="drag = false">
                <UpdateSerialCard v-for="(serial, index) in serialList" :key="index" :serial="serial" :type="'serial'" v-on:delete-serial="removeSerial(index)"/>
            </draggable>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import UpdateSerialCard from '@/components/generics/movie_serial/GenericCUJournalCard.vue'

export default {
    props: {
        slug: String,
        typeSlug: String,
        subTypeSlug: String
    },
    components: {
        draggable,
        UpdateSerialCard
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            serialList: [],
            searchItems: [],
            loadMore: 0,
            searchSerial: null,
            selectedSerial: '',
            drag: false
        }
    },
    computed:{
        dragOptions(){
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    watch:{
        searchSerial(val){
            if(val){
                axios
                    .get('/serial/api-search-serial-list/', { params: { serial: val }}).then(response => { this.searchItems = response.data.serial_list })
            }
        }
    },
    methods:{
        addSerial(){
            if(this.selectedSerial){
                this.serialList.push({ id: this.selectedSerial['id'], serial_poster: this.selectedSerial['serial_poster'], international_title: this.selectedSerial['international_title'] })
            }
            this.selectedSerial = null
            this.searchItems = []
            this.$emit('submit-button', false)
        },
        removeSerial(index){
            this.serialList.splice(index, 1)
        },
        getJournalSerials(){
            axios
                .get('/journal/api-journal-serial-list/', { params: { slug: this.slug, typeSlug: this.typeSlug, subTypeSlug: this.subTypeSlug }})
                .then(response => { this.serialList = response.data.serials })
        },
    },
    created(){
        this.getJournalSerials()
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>