<template>
    <div v-if="user.isAuthenticated" class="create_movie">
        <div class="create_movie__title">Та цувралд бүлэг нэмэх гэж байна!</div>
        <v-divider class="mx-4"></v-divider>
        <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitCreate">
            <div class="create_movie__block">
                <div class="create_movie__image_block">
                    <v-card width="240" height="360"><v-img :src="seasonPosterURL"/></v-card>
                    <v-text-field v-model="seasonPosterCheck" disabled label="Постер" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="posterDialog" width="600px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Постер нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="posterDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Сезоны постер зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 240, height: 360}" :canvas="{width: 240, height: 360}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropPoster">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="create_movie__title_block">
                    <v-autocomplete no-filter v-model="selectedSerial" height="100" :items="searchItems" hide-no-data return-object :search-input.sync="searchSerial" clearable item-text="name" label="Цувралаас хайх" attach solo :rules="[rules.autoChoice]">
                        <template v-slot:selection="data">
                            <v-avatar left height="75" tile class="mx-3"><img :src="mediaUrl + '/' + data.item['serial_poster']" class="rounded"></v-avatar>
                            <div mx="2">{{ data.item['international_title'] }}</div>
                        </template>
                        <template v-slot:item="data">
                            <template v-if="data.item !== 'object'">
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['serial_poster']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['international_title']"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['serial_poster']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['international_title']"></v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                    <v-text-field v-model="seasonNumber" type="number" label="Бүлгийн дугаар*" required :rules="[rules.number, rules.required]"></v-text-field>
                    <v-text-field v-model="seasonTitle" label="Бүлгийн нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="seasonTrailer" label="Трейлэр" required></v-text-field>
                </div>
            </div>
            <div class="create_movie__block_content">
                <CreateImage ref="images"/>
                <br>
                <v-card-actions class="justify-center">
                    <v-btn :disabled="checkValid" @click="validate" type="submit" class="create_movie__submit_btn" block>Хадгалах</v-btn>
                </v-card-actions>
            </div>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import { mapState } from 'vuex'
import 'vue-advanced-cropper/dist/style.css'

import CreateImage from '@/views/generics/movie_serial/GenericCreateImage.vue'

export default {
    components: {
        Cropper,
        CreateImage
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            image: "",
            posterDialog: false,
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                autoChoice: value => !true || !!value || 'Цуврал сонгох шаардлагатай',
                number: value => /^[0-9]+$/.test(value) || 'Та зөвхөн тоо оруулна уу!',
                imageSize: value => !value || value.size < 1000000 || 'Зургийн хэмжээ 1 MB -с хэтрэхгүй байх шаардлагатай!',
                imageRequire: value => !!value || 'Зураг оруулах шаардлагатай!'
            },
            searchItems: [],
            searchSerial: null,
            selectedSerial: '',
            seasonPoster: null,
            seasonPosterURL: null,
            seasonPosterCheck: null,
            seasonNumber: '',
            seasonTitle: '',
            seasonTrailer: ''
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user'])
    },
    watch:{
        searchSerial(val){
            if(val){
                axios
                    .get('/serial/api-search-serial-list/', { params: { serial: val }}).then(response => {this.searchItems = response.data.serial_list })
            }
        }
    },
    methods:{
        validate(){
            this.$refs.form.validate()
        },
        submitCreate(){
            var images = this.$refs.images.movieImages

            const createItems = {
                'serial': JSON.stringify(this.selectedSerial),
                'season_number': this.seasonNumber,
                'season_title': this.seasonTitle,
                'trailer': this.seasonTrailer
            }

            const createData = new FormData()

            if(this.seasonPoster){
                createData.append('season-poster', this.seasonPoster, 'season_poster')
            }

            for(var i = 0; i < images.length; i++){
                createData.append('id', i)
                createData.append('season-images', images[i].file)
                createData.append('description', images[i].description)
            }

            for( var key in createItems ){
                createData.append(key, createItems[key])
            }

            axios
                .post('/serial/api-create-season/', createData)
                .then((response) => {
                    if (response.data.success === true){
                        this.$store.commit('SET_SNACK', {message: 'Цувралд бүлэг амжилттай нэмэгдлээ, сайтын админ шалгаад нийтлэх болно!', color: 'green'})
                        this.$router.push({ name: 'serial-list' }).catch(()=>{})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: error.response.data.message, color: 'red'})
                    } else {
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        },
        cropPoster() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.seasonPoster = blob }, "image/jpeg")
            this.seasonPosterURL = canvas.toDataURL("jpeg")
            this.seasonPosterCheck = 'season-poster'
            this.posterDialog = false
            this.image = ''
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])
                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        }
    },
    created(){
        document.title = 'цувралд бүлэг нэмэх'
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>