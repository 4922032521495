<template>
    <div v-if="user.isAuthenticated" class="create_movie">
        <div class="create_movie__title">Та цувралд анги нэмэх гэж байна!</div>
        <v-divider class="mx-4"></v-divider>
        <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitCreate">
            <div class="create_movie__block">
                <div class="create_movie__image_block">
                    <v-card width="240" height="135"><v-img :src="episodeBackgroundURL"/></v-card>
                    <v-text-field v-model="episodeBackgroundCheck" disabled label="Цувралын сцен зураг" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="backgroundDialog" width="800px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Сцен зураг нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="backgroundDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Ангийн сцен зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 1280, height: 720}" :canvas="{width: 1280, height: 720}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropBackground">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="create_movie__title_block">
                    <v-autocomplete no-filter v-model="selectedSeason" height="100" :items="searchItems" hide-no-data return-object :search-input.sync="searchSeason" clearable item-text="name" label="Сезоноос хайх" attach solo :rules="[rules.autoChoice]">
                        <template v-slot:selection="data">
                            <v-avatar left height="75" tile class="mx-3"><img :src="mediaUrl + '/' + data.item['season_poster']" class="rounded"></v-avatar>
                            <div mx="2">{{ data.item['international_title'] }} - {{ data.item['season_num'] }}</div>
                        </template>
                        <template v-slot:item="data">
                            <template v-if="data.item !== 'object'">
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['season_poster']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content >{{ data.item['international_title'] }} - {{ data.item['season_num'] }}</v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['season_poster']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content >{{ data.item['international_title'] }} - {{ data.item['season_num'] }}</v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                    <v-text-field v-model="episodeNumber" label="Ангийн дугаар*" type="number" required :rules="[rules.number, rules.required]"></v-text-field>
                    <v-text-field v-model="episodeName" label="Ангийн нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="episodeRuntime" type="number" label="Үргэлжлэх хугацаа*" required :rules="[rules.number, rules.required]"></v-text-field>
                </div>
            </div>
            <div class="create_movie__block_content">
                <v-textarea v-model="episodePlot" label="Үйл явдлын товч*" hint="Та 1000 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.plotLength]"></v-textarea>
                <v-textarea v-model="episodePlotEng" label="Үйл явдлын товч (in English)*" hint="Та 1000 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.plotLength]"></v-textarea>
                <br>
                <v-card-actions class="justify-center">
                    <v-btn :disabled="checkValid" @click="validate" type="submit" class="create_movie__submit_btn" block>Хадгалах</v-btn>
                </v-card-actions>
            </div>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import { mapState } from 'vuex'
import 'vue-advanced-cropper/dist/style.css'


export default {
    components: {
        Cropper
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            image: "",
            backgroundDialog: false,
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                autoChoice: value => !true || !!value || 'Цувралын бүлэг сонгох шаардлагатай',
                number: value => /^[0-9]+$/.test(value) || 'Та зөвхөн тоо оруулна уу!',
                imageSize: value => !value || value.size < 1000000 || 'Зургийн хэмжээ 1 MB -с хэтрэхгүй байх шаардлагатай!',
                imageRequire: value => !!value || 'Зураг оруулах шаардлагатай!',
                plotLength: value => value.length <= 1000 || '1000 тэмдэгтээс хэтэрлээ!',
            },
            searchSeason: null,
            selectedSeason: '',
            searchItems: [],
            episodeNumber: '',
            episodeName: '',
            episodeBackground: null,
            episodeBackgroundURL: null,
            episodeBackgroundCheck: null,
            episodeRuntime: 0,
            episodePlot: '',
            episodePlotEng: ''
        }
    },
    watch:{
        searchSeason(val){
            if(val){
                axios
                    .get('/serial/api-search-season-list/', { params: { season: val }}).then(response => {this.searchItems = response.data.season_list })
            }
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user'])
    },
    methods:{
        validate(){
            this.$refs.form.validate()
        },
        submitCreate(){
            const createItems = {
                'season': JSON.stringify(this.selectedSeason),
                'epi_number': this.episodeNumber,
                'epi_name': this.episodeName,
                'epi_runtime': this.episodeRuntime,
                'plot': this.episodePlot,
                'en_plot': this.episodePlotEng
            }

            const createData = new FormData();

            if(this.episodeBackground){
                createData.append('episode-background', this.episodeBackground, this.episodeBackground.name)
            }

            for( var key in createItems ){
                createData.append(key, createItems[key])
            }

            axios
                .post('/serial/api-create-episode/', createData)
                .then(this.$router.push({ name: 'serial-list'}).catch(()=>{}))
                .then(this.$store.commit('SET_SNACK', {message: 'Анги амжилттай нэмэгдлээ, сайтын админ шалгаад нийтлэх болно!', color: 'green'}))
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        },
        cropBackground() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.episodeBackground = blob }, "image/jpeg")
            this.episodeBackgroundURL = canvas.toDataURL("jpeg")
            this.episodeBackgroundCheck = 'episode-poster'
            this.backgroundDialog = false
            this.image = ''
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])
                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        }
    },
    created(){
        document.title = 'Шинэ анги нэмэх'
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>