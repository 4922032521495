<template>
    <div class="movieMargin" v-show="selfPortraitList != ''">
        <div class="artist__legend_bar"><strong>Бусад бүтээлд</strong></div>
        <div class="row">
            <GenericMovieCard v-for="(self, index) in selfPortraitList" :key="index" :type="self.type" :slug="self.slug" :poster="self.poster" :year="self.year"/>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import GenericMovieCard from '@/components/artist/GenericMovieCard.vue'

export default {
    props: {
        slug: String
    },
    components: {
        GenericMovieCard
    },
    data(){
        return{
            artistSlug: this.slug,
            selfPortraitList: []
        }
    },  
    methods:{
        selfPortraitArtists(){
            axios
                .get('/artist/api-artist-self-portraits/', { params: { slug: this.artistSlug }})
                .then(response => { this.selfPortraitList = response.data.list_selves })
        }
    },
    created(){
        this.selfPortraitArtists()
    },
    watch:{
        slug(){
            this.selfPortraitList = []
            this.artistSlug = this.slug
            this.selfPortraitArtists()
        }
    }
}
</script>

<style scoped>

.movieMargin{
    margin: 20px 5px 0;
}

.artist__legend_bar {
    color: #0066b5;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
}


.row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    margin: 0 auto;
}

@media (min-width: 481px) {
    .row{
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
}

</style>