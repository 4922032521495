<template>
    <v-card>
        <div class="movie-year-position">
            <router-link :to="{ name: `${ type + '-detail' }`, params: { slug: slug } }">
                <v-img :src="mediaUrl + '/' + poster" class="column rounded"/>
            </router-link>
            <div v-show="year" class="movie-year">
                {{ year }}
            </div>
        </div>
        <v-card-text v-show="type ==! 'self' || (role || job)" >
            <strong v-show="role">{{ role }}<br></strong>
            {{ job }}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        type: String,
        slug: String,
        poster: String,
        year: Number,
        role: String,
        job: String
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL
        }
    }
}
</script>

<style scoped>

.column {
    vertical-align: middle;
    width: 100%;
}

.movie-year-position {
    position: relative;
}

.movie-year {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    font-weight: bold;
    background-color: #0066b5;
    padding: 5px 10px;
}

</style>
