<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <v-img :src="require('../assets/mfi_logo.png')" class="my-3" contain height="200"/>
            </v-col>

            <v-col class="mb-4">
                <h1 class="display-2 font-weight-bold mb-3">
                    MFI movie platform
                </h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'Home',
        data: () => ({}),
        created(){
            document.title = 'MFI'
        }
    }
</script>
