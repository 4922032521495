<template>
    <div>
        <legend class="generic-list__legendbar" id="generic-list__title"><strong>{{ listTitle }}:</strong></legend>
        <v-select :items="reviewSortItems" label="Үзүүлэх дараалал" v-model="reviewSort" @change="getGenericReview()" dense solo></v-select>
        <v-card class="mb-3" v-for="review in contentList" :key="review.id">
            <GenericReviewCard :review='review' :index='review.id'/>
        </v-card>
        <v-pagination v-model="page" :length="reviewPage" @input="getGenericReview()" :total-visible="7"></v-pagination>
    </div>
</template>

<script>
import axios from 'axios'
import GenericReviewCard from '@/components/user/GenericReviewCard.vue'

export default {
    props: {
        slug: String
    },
    components: {
        GenericReviewCard
    },
    data(){
        return{
            routeName: this.$route.name,
            listTitle: '',
            requestUserSlug: this.slug,
            contentList: [],
            reviewSortItems: [  { text: 'Өндрөөр үнэлснийг эхэнд', value: 1 },
                                { text: 'Өндрөөр үнэлснийг сүүлд', value: 2 },
                                { text: 'Шинэ киног эхэнд', value: 3 },
                                { text: 'Шинэ киног сүүлд', value: 4 },
                                { text: 'Цагаан толгойн дарааллаар', value: 5 },
                                { text: 'Цагаан толгойн эсрэг дарааллаар', value: 6 }],
            reviewSort: 0,
            page: 1,
            reviewPage: 0
        }
    },
    created(){
        this.getGenericReview()
    },
    watch: {
        $route(to, from){
            this.routeName = to.name
            this.page = 1
            this.reviewPage = 0
            this.contentList = []
            this.getGenericReview()
        }
    },
    methods:{
        getGenericReview(){
            setTimeout(function() {
                var el = document.getElementById('generic-list__title')
                if(typeof el !== "undefined" && el !== null) {
                    el.scrollIntoView({ behavior: "smooth" })
                }
            }, 500)

            if (this.routeName === 'movie-reviews') {
                this.listTitle = "Кино үнэлгээ"
                axios
                    .get('/user/api/v-user-moviereviewlist/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.reviewSort }})
                    .then((response) => { this.contentList = response.data.api_movie_review_list, this.reviewPage = response.data.page })
            } else if (this.routeName === 'episode-reviews') {
                this.listTitle = "Эпизод үнэлгээ"
                axios
                    .get('/user/api/v-user-episodereviewlist/', { params: { slug: this.requestUserSlug, page: this.page, sort: this.reviewSort }})
                    .then((response) => { this.contentList = response.data.api_episode_review_list, this.reviewPage = response.data.page })
            }
            
        }
    },
}
</script>

<style>

.generic-list__legendbar {
    border-bottom: #0066b5 1px solid;
    color: #0066b5;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
    margin-bottom: 5px;
}

</style>