<template>
    <v-app>
        <NavBar style="z-index: 99;"/>
        <div class="text-center">
            <v-progress-circular v-if="$store.state.isLoading" indeterminate color="primary"></v-progress-circular>
        </div>
        <v-main>
            <SnackBar/>
            <transition name="slide-fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </v-main>
        <Footer />
    </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import SnackBar from '@/components/SnackBar.vue'
import Footer from '@/components/Footer.vue'


export default {
    name: 'App',
    components: {
        NavBar,
        SnackBar,
        Footer
    },
    beforeCreate(){
        this.$store.dispatch('initStore').then(() => {
            if(this.$store.state.token){
                this.$store.dispatch('getUserProfile')
            } else {
                this.$store.commit('REMOVE_TOKEN')
            }
        })
    }
};
</script>

<style scoped>

.v-progress-circular {
    margin: 1rem;
}

.slide-fade-enter {
    transform: translateY(10px);
    opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
}

</style>