<template>
    <v-card class="ma-1" max-width="200">
        <v-img :src="baseUrl + image.image" class="rounded"/>
        <v-card-text class="pa-1" style='color:#484848; font-size: 11px; text-align:center;'>
            <strong>{{ image.name }}</strong>
            <br>
            {{ image.created }}
        </v-card-text>
        <div style="position: absolute; top: 5px; right: 1px;"><v-btn class="mx-2" fab dark x-small color="red" v-on:click="$emit('delete-image')"><v-icon dark>mdi-minus</v-icon></v-btn></div>
    </v-card>
</template>

<script>
export default {
    props: {
        image: Object
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
        }
    }
}
</script>
