<template>
    <router-link :to="{ name: 'artist-detail', params: {slug: artist.person_slug} }">
        <v-img :src="mediaUrl + '/' + artist.photo" class="column rounded"/>
    </router-link>
</template>

<script>
export default {
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL
        }
    },
    props: {
        artist: Object
    }
}
</script>

<style scoped>

.column {
    vertical-align: middle;
    width: 100%;
}

</style>
