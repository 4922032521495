<template>
    <div class="user-feed">
        <div id="router_view">
            <li class="list_no_style" v-for="list in contentList" :key="list.id">
                <v-card class="mb-3 pa-2">
                    <div v-if="list.name == 'user-watch-status'" class="user-feed__card_box">
                        <div class="user-feed__img_block">
                            <router-link :to="{ name: 'user-feed', params: { slug: list.values.action_user_slug }}">
                                <img class="user-feed__img" :src="baseUrl + list.values.action_user_photo"/>
                            </router-link>
                        </div>
                        <div class="user-feed__card_comment">
                            <div class="user-feed__card_block">
                                <div class="user-feed__card_legendbar">
                                    <div class="user-feed__card_msg">
                                        <span>кино үзэж байна</span>
                                        · {{ list.values.created_at }}
                                    </div>
                                </div>
                                <span>{{ list.values.action_object }} - {{ list.values.action_verb }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="user-feed__card_box">
                        <div class="user-feed__img_block">
                            <router-link :to="{ name: 'user-feed', params: { slug: list.values.action_user_slug }}">
                                <img class="user-feed__img" :src="baseUrl + list.values.action_user_photo"/>
                            </router-link>
                        </div>
                        <div class="user-feed__card_comment">
                            <div class="user-feed__card_block">
                                <div class="user-feed__card_legendbar">
                                    <div class="user-feed__card_msg">
                                        <span>{{ list.values.action_verb }}</span>
                                        · {{ list.values.created_at }}
                                    </div>
                                </div>
                                <div class="user-feed__card_content">
                                    <v-card outlined>
                                        <div v-if="list.name == 'journal'" class="user-feed__card_card">
                                            <div class="user-feed__card_journal_cover_block">
                                                <router-link :to="{ name: 'journal-detail', params: { slug: list.values.journal_slug, typeSlug: list.values.journal_type_slug }}">
                                                    <img class="user-feed__card_journal_cover" :src="baseUrl + list.values.journal_cover"/>
                                                </router-link>
                                            </div>
                                            <div class="user-feed__card_journal_content">
                                                <h4>{{ list.values.journal_title }}</h4> 
                                                <v-chip outlined>{{ list.values.journal_type }}</v-chip>
                                            </div>
                                        </div>

                                        <div v-else-if="list.name == 'movie-review'" class="user-feed__card_card">
                                            <div class="user-feed__card_journal_cover_block">
                                                <router-link :to="{name: 'movie-detail', params: { slug: list.values.movie_slug }}">
                                                    <img class="user-feed__card_journal_cover" :src="baseUrl + list.values.movie_poster"/>
                                                </router-link>
                                            </div>
                                            <div class="user-feed__card_journal_content">
                                                <h2>{{ list.values.movie_international_title }}</h2>
                                                <v-rating :value="parseInt(list.values.movie_grade) * 0.05" :color="list.values.action_user_critic ? '#B29600' : '#0066b5'" :background-color="list.values.action_user_critic ? '#B29600' : '#0066b5'" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="24"></v-rating>
                                                <div class="user-feed__card_grade">{{ list.values.movie_grade }}%</div>
                                                <v-divider v-show="list.values.movie_review" class="my-2"></v-divider>
                                                <div>{{ list.values.movie_review }}</div>
                                                <!-- <movie-review-like :review_id="list.values.movie_review_id" :movie_id="list.values.movie_id" :user_like="list.values.user_like" :total_likes="list.values.movie_review_likes"></movie-review-like> -->
                                            </div>
                                        </div>

                                        <div v-else-if="list.name == 'movie-detailed-review'" class="user-feed__card_card">
                                            <div class="user-feed__card_journal_cover_block">
                                                <router-link :to="{ name: 'movie-review-detail', params: { userSlug: list.values.action_user_slug, movieSlug: list.values.movie_slug }}">
                                                    <img class="user-feed__card_journal_cover" :src="baseUrl + list.values.movie_poster"/>
                                                </router-link>
                                            </div>
                                            <div class="user-feed__card_journal_content">
                                                <h4>{{ list.values.movie_international_title }}</h4>
                                                <div class="user-feed__detailed_review_content" v-html="list.values.movie_detailed_review"></div>
                                                <!-- <movie-detailed-review-like :review_id="list.values.movie_detailed_review_id" :movie_id="list.values.movie_id" :user_like="list.values.user_like" :total_likes="list.values.movie_detailed_review_likes"></movie-detailed-review-like> -->
                                            </div>
                                        </div>

                                        <div v-else-if="list.name == 'episode-review'" class="user-feed__card_card">
                                            <div class="user-feed__card_journal_cover_block">
                                                <router-link :to="{name: 'episode-detail', params: { serialSlug: list.values.serial_slug, seasonSlug: list.values.season_slug, episodeSlug: list.values.episode_slug }}">
                                                    <img class="user-feed__card_journal_cover" :src="baseUrl + list.values.episode_poster"/>
                                                </router-link>
                                            </div>
                                            <div class="user-feed__card_journal_content">
                                                <h4>{{ list.values.episode_serial_title }} - {{ list.values.episode_season_title }} - {{ list.values.episode_title }}</h4>
                                                <v-rating :value="parseInt(list.values.episode_grade) * 0.05" :color="list.values.action_user_critic ? '#B29600' : '#0066b5'" :background-color="list.values.action_user_critic ? '#B29600' : '#0066b5'" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="24"></v-rating>
                                                <div class="user-feed__card_grade">{{ list.values.episode_grade }}%</div>
                                                <v-divider v-show="list.values.episode_review" class="my-2"></v-divider>
                                                <div >{{ list.values.episode_review }}</div>
                                                <!-- <episode-review-like :review_id="list.values.episode_review_id" :episode_id="list.values.episode_id" :user_like="list.values.user_like" :total_likes="list.values.episode_review_likes"></episode-review-like> -->
                                            </div>
                                        </div>

                                        <div v-else-if="list.name == 'episode-detailed-review'" class="user-feed__card_card">
                                            <div class="user-feed__card_journal_cover_block">
                                                <router-link :to="{name: 'episode-detail', params: { serialSlug: list.values.serial_slug, seasonSlug: list.values.season_slug, episodeSlug: list.values.episode_slug }}">
                                                    <img class="user-feed__card_journal_cover" :src="baseUrl + list.values.episode_poster"/>
                                                </router-link>
                                            </div>
                                            <div class="user-feed__card_journal_content">
                                                <h4>{{ list.values.episode_serial_title }} - {{ list.values.episode_season_title }} - {{ list.values.episode_title }}</h4>
                                                <div class="user-feed__card_grade">{{ list.values.episode_grade }}%</div>
                                                <v-divider v-show="list.values.episode_review" class="my-2"></v-divider>
                                                <div >{{ list.values.episode_review }}</div>
                                                <!-- <episode-detailed-review-like :review_id="list.values.episode_detailed_review_id" :episode_id="list.values.episode_id" :user_like="list.values.user_like" :total_likes="list.values.episode_detailed_review_likes"></episode-detailed-review-like> -->
                                            </div>
                                        </div>


                                    </v-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </li>
            <div class="text-center">
                <v-pagination v-model="page" :length="userFeedPage" @input="getUserFeed(slug, page)" :total-visible="5"></v-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

import ShortMessageComment from '@/components/user/ShortMessageComment.vue'

export default {
    props: {
        slug: String
    },
    components: {
        ShortMessageComment
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            requestUserSlug: this.slug,
            emptyIcon: 'mdi-star-outline',
            fullIcon: 'mdi-star',
            halfIcon: 'mdi-star-half-full',
            contentList: [],
            body: '',
            userMessages: [],
            createdAt: 'одоо',
            page: 1,
            userFeedPage: 0,
        }
    },
    computed: mapState(['user']),
    methods:{
        getUserFeed(slug, page){
            setTimeout(function() {
                var el = document.getElementById('router_view')
                if(typeof el !== "undefined" && el !== null) {
                    el.scrollIntoView({ behavior: "smooth" })
                }
            }, 500)

            axios
                .get('/user/api/v-user-feed/', { params: { slug: slug, feedPage: page }})
                .then((response) => {this.contentList = response.data.api_user_action_list, this.requestUser = response.data.request_user, this.userFeedPage = response.data.feed_page})
        },
        submitUserMessage(){
            if(this.body.length > 0){

                var message = {
                    'body': this.body
                }

                this.userMessages.unshift(message)

                axios
                    .post('/user/api/v-user-addshortmessage/', message)

                this.body = ''
            }
        }
    },
    created(){
        this.requestUserSlug = this.slug
        this.getUserFeed(this.requestUserSlug, this.page)
    },
    watch:{
        slug(){
            this.page = 1
            this.requestUserSlug = this.slug
            this.getUserFeed(this.slug, this.page)
        }
    }
}
</script>

<style scoped>

.user-feed__twt_btn {
    color: white !important;
    background-color: #0066b5 !important;
}

.user-feed__card_box {
    display: flex;
}

.user-feed__img_block {
    flex: 10%;
    text-align: center;
}

.user-feed__img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.user-feed__card_block {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
}

.user-feed__card_msg {
    color: #696969;
    flex: 85%;
}

.user-feed__card_date {
    color: #696969;
    flex: 15%;
}

.user-feed__card_legendbar {
    display: flex;
    border-bottom: 1px solid #a9a9a9;
}

.user-feed__card_legendbar,
.user-feed__card_content {
    flex: 100%;
    margin-bottom: 5px;
}

.user-feed__card_card {
    display: flex;
    flex-direction: column;
}

.user-feed__card_comment {
    flex-direction: column;
    flex: 90%;
}

.user-feed__card_journal_cover_block {
    flex: 100%;
}

.user-feed__card_journal_cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.user-feed__card_journal_content {
    padding: 5px 10px;
    flex: 100%;
    width: 100%;
}

.user-feed__card_grade {
    color: #696969;
    font-weight: 600;
    font-size: 18px;
}

.user-feed__detailed_review_content {
    margin-top: 10px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.user-feed__detailed_review_content >>> p {
    width: 100%;
    max-width: 420px;
}

.list_no_style {
    list-style: none;
}

@media (min-width: 481px) {

    .user-feed__block {
        max-width: 850px;
        margin-right: auto;
        margin-left: auto;
    }

    .user-feed__card_card {
        display: flex;
        flex-direction: row;
    }

    .user-feed__card_journal_cover_block {
        flex: 25%;
    }

    .user-feed__card_journal_content {
        flex: 75%;
    }

    .user-feed__detailed_review_content {
        -webkit-line-clamp: 7;
    }
}



</style>