<template>
    <div v-show="season || user.contributer">
        <div id="season-detail__wrapper">
            <div class="season-block season-poster__box">
                <img :src="baseUrl + season.season_poster" class="rounded mt-5"/>
                <div class="movie-info__block">
                    <v-dialog v-if="season.images != ''" v-model="archiveImageDialog" width="unset">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="#0066b5" small outlined v-bind="attrs" v-on="on">архивын зургууд</v-btn>
                        </template>
                        <v-card>
                            <v-card-title>Бүлгийн архивын зургууд:</v-card-title>
                            <v-card-text>
                                <v-carousel hide-delimiters show-arrows-on-hover height="auto">
                                    <v-carousel-item v-for="(image, index) in season.images" :key="'image' + index" :src="baseUrl + image.image">
                                        <v-container fill-height justify-center><div class="movie-info__archive_image">{{ image.description }}</div></v-container>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-card-text>
                            <v-card-actions class="justify-end"><v-btn text @click="archiveImageDialog = false" color="#0066b5">Хаах</v-btn></v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <v-dialog v-model="commentDialog" max-width="640" min-width="320">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn block class="movie-review__dlg_btn" v-bind="attrs" v-on="on">
                            <span class="movie-review__dlg_btn_txt">Үнэлгээ</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="commentDialog">
                        <v-card>
                            <div v-if="user.isAuthenticated">
                                <v-tabs v-model="commentTab" fixed-tabs>
                                    <v-tabs-slider></v-tabs-slider>
                                    <v-tab href="#tabs-5-1" class="primary--text"><v-icon>mdi-comment</v-icon></v-tab>
                                    <v-tab href="#tabs-5-2" class="primary--text"><v-icon>mdi-heart</v-icon></v-tab>
                                    <v-tab href="#tabs-5-3" class="text font-weight-bold" v-if="user.critic && reviewCheck">Шүүмж</v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="commentTab">
                                    <v-tab-item :key="1" value="tabs-5-1">
                                        <div class="ma-3 pa-3" max-width="640" min-width="320">
                                            <div v-if="errors.length" class="movie-review__error"><p v-for="(error, index) in errors" :key="index">{{ error }}</p></div>
                                            <v-form ref="form" v-on:submit.prevent="submitReview(loadReview = true)">
                                                <v-textarea outlined v-model="review" name="review" label="Үгэн үнэлгээ" hint="Та 1000 тэмдэгтэнд багтааж бичнэ үү!"></v-textarea>
                                                <v-checkbox v-model="spoilerCheckBox" label="Миний сэтгэгдэл үйл явдлын ИЛЧЛЭЛТ агуулсан"></v-checkbox>
                                                
                                                
                                                <v-card-actions v-if="review" class="justify-center">
                                                    <v-dialog v-model="reviewDeleteDialog" persistent max-width="290">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn depressed color="red" width="33%" dark v-bind="attrs" v-on="on" class="ma-1">устгах</v-btn>
                                                        </template>
                                                        <v-card>
                                                            <v-spacer></v-spacer>
                                                            <v-card-text class="pa-2 movie-review__delete_txt">Та өөрийн үлдээсэн сэтгэгдэлийг устгах гэж байна.</v-card-text>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn color="red darken-1" text @click="reviewDeleteDialog = false; deleteReview();">УСТГАХ</v-btn>
                                                                <v-btn color="blue darken-1" text @click="reviewDeleteDialog = false">БУЦАХ</v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                    <v-btn dark color="green" depressed width="33%" type="submit">Хадгалах</v-btn>
                                                </v-card-actions>


                                            </v-form>
                                        </div>
                                    </v-tab-item>
                                    <v-tab-item :key="2" value="tabs-5-2">
                                        <div class="ma-3 pa-3" max-width="840" min-width="320">
                                            <v-btn-toggle outlined color="#FFF" class="movie_reaction__box">
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ attrs, on }">
                                                        <v-btn color="#FFF" v-bind="attrs" v-on="on" class="movie_reaction__btn">{{ seasonReact1 }}</v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item-content class="text-center">
                                                            <v-list-item-title>Season reactions <v-btn v-show="seasonReact1" color="red darken-1" icon plain text @click="seasonReact(1, null)"><v-icon>mdi-trash-can</v-icon></v-btn></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-divider></v-divider>
                                                        <v-list-item v-for="(item, index) in reactions" :key="index" link>
                                                            <v-list-item-title @click="item.check ? null : seasonReact(1, item)" :class="[item.check ? 'movie_reaction__check' : null]">{{ item.emoji }} - {{ item.name }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ attrs, on }">
                                                        <v-btn color="#FFF" v-bind="attrs" v-on="on" class="movie_reaction__btn">{{ seasonReact2 }}</v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item-content class="text-center">
                                                            <v-list-item-title>Season reactions <v-btn v-show="seasonReact2" color="red darken-1" icon plain text @click="seasonReact(2, null)"><v-icon>mdi-trash-can</v-icon></v-btn></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-divider></v-divider>
                                                        <v-list-item v-for="(item, index) in reactions" :key="index" link>
                                                            <v-list-item-title @click="item.check ? null : seasonReact(2, item)" :class="[item.check ? 'movie_reaction__check' : null]">{{ item.emoji }} - {{ item.name }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ attrs, on }">
                                                        <v-btn color="#FFF" v-bind="attrs" v-on="on" class="movie_reaction__btn">{{ seasonReact3 }}</v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item-content class="text-center">
                                                            <v-list-item-title>Season reactions <v-btn v-show="seasonReact3" color="red darken-1" icon plain text @click="seasonReact(3, null)"><v-icon>mdi-trash-can</v-icon></v-btn></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-divider></v-divider>
                                                        <v-list-item v-for="(item, index) in reactions" :key="index" link>
                                                            <v-list-item-title @click="item.check ? null : seasonReact(3, item)" :class="[item.check ? 'movie_reaction__check' : null]">{{ item.emoji }} - {{ item.name }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-btn-toggle>
                                        </div>
                                    </v-tab-item>
                                    <v-tab-item :key="3" value="tabs-5-3">
                                        <div class="ma-2 pa-2 text-center font-weight-bold" max-width="840" min-width="320">
                                            <div>Тойм шүүмж бичих</div>
                                            <v-spacer></v-spacer>
                                            <div class="d-flex flex-column pa-2">
                                                <div v-if="detailedReviewErrors.length" class="movie_detailed_review__error">
                                                    <p v-for="(error, index) in detailedReviewErrors" :key="index">{{ error }}</p>
                                                </div>
                                                <v-text-field v-model="reviewTitle" label="Гарчиг*" required></v-text-field>
                                                <tinymceEditor api-key="dh9tjnxmzhiub2bx0brpetqavxuw7z518330gl5p1kn1ivy6" v-model="detailedReview" :init="customToolbar"/>
                                            </div>
                                            <v-dialog v-model="detailedReviewDeleteDialog" persistent>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-if="detailedReview" dark depressed color="red" v-bind="attrs" v-on="on" class="movie_detailed_review__delete_btn">Устгах</v-btn>
                                                </template>
                                                <v-card>
                                                    <v-spacer></v-spacer>
                                                    <v-card-text class="pa-2 movie_detailed_review__delete_txt">Та өөрийн үлдээсэн тойм шүүмжийг устгах гэж байна.</v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn class="ma-1" color="red darken-1" text @click="detailedReviewDeleteDialog = false; deleteDetailedReview();">УСТГАХ</v-btn>
                                                        <v-btn color="blue darken-1" text @click="detailedReviewDeleteDialog = false">БУЦАХ</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            <v-btn dark depressed @click="submitDetailedReview(loadDetailedReview = true);" color="green" width="33%">Хадгалах</v-btn>
                                        </div>
                                    </v-tab-item>
                                </v-tabs-items>
                            </div>
                            <div v-else>
                                <v-container class="text-center">
                                    <div class="movie_review__login_txt">Та үнэлгээ өгөхийн тулд <span class="movie_review__login_link" @click="loginRequired()">НЭВТЭРНЭ</span> үү!</div>
                                </v-container>
                            </div>
                            <v-card-actions class="justify-end"><v-btn text @click="commentDialog.value = false" color="#0066b5">Хаах</v-btn></v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
            <div class="season-block season-episode-list__box">
                <GenericEpisodeList :published="true" :serialSlug="serialSlug" :seasonSlug="seasonSlug"/>
                <GenericEpisodeList :published="false" :serialSlug="serialSlug" :seasonSlug="seasonSlug"/>
            </div>
        </div>
        <div class="season-review__block">
            <legend class="movie-review__legendbar"><strong >Үнэлгээ:</strong></legend>
            <v-tabs v-model="activeTab" color="light-blue darken-3 accent-4" fixed-tabs width="100%">
                <v-tab @click="criticsCall" id="critics">шүүмжлэгчид</v-tab>
                <v-tab @click="audiencesCall" id="audiences">сонирхогчид</v-tab>
                <v-tab-item :key="1">
                    <v-container fluid>
                        <GenericReviewers :reviewer="'critic'" :type="'season'" :serialSlug="serialSlug" :seasonSlug="seasonSlug" ref="criticRef"/>
                    </v-container>
                </v-tab-item>
                <v-tab-item :key="2">
                    <v-container fluid>
                        <GenericReviewers :reviewer="'audience'" :type="'season'" :serialSlug="serialSlug" :seasonSlug="seasonSlug" ref="audienceRef"/>
                    </v-container>
                </v-tab-item>
            </v-tabs>
        </div>
        <div v-if="user.isAuthenticated && user.contributer && user.adminBarHide" class="subadmin__box">
            <v-dialog v-model="publishDialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                    <button :class="publish ? 'subadmin__publish' : 'subadmin__unpublish'" v-bind="attrs" v-on="on" ><v-icon class="subadmin__icon">mdi-clipboard-check-outline</v-icon></button>
                </template>
                <v-card>
                    <v-card-title class="headline grey lighten-2 mx-auto">Бүлэг нийтлэх</v-card-title>
                    <v-card-text class="mt-3">Та "{{ season.serial_title }}" цувралын "{{ season.season_name }}" <span v-if="publish">бүлгийн нийтлэгдснийг болиулах</span><span v-else>бүлгийг нийтлэх</span> гэж байна!</v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :color="publish ? 'red' : 'primary'" text @click="publishDialog = false; publishSeason();">{{publish ? "болиулах" : "нийтлэх"}}</v-btn>
                        <v-btn color="warning" text @click="publishDialog = false">буцах</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <router-link class="subadmin__update" :to="{name: 'season-update', params: { serialSlug: serialSlug, seasonSlug: seasonSlug }}"><v-icon class="subadmin__icon">mdi-lead-pencil</v-icon></router-link>
            <router-link class="subadmin__image_update" :to="{ name: 'season-image-update', params: { serialSlug: serialSlug, seasonSlug: seasonSlug } }"><v-icon class="subadmin__icon">mdi-image-edit-outline</v-icon></router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Editor from '@tinymce/tinymce-vue'

import GenericReviewers from '@/views/generics/movie_serial/GenericReviewerList.vue'
import GenericEpisodeList from '@/views/season/detail/GenericEpisodeList.vue'

export default {
    props: {
        serialSlug: String,
        seasonSlug: String
    },
    components: {
        GenericReviewers,
        GenericEpisodeList,
        'tinymceEditor': Editor
    },
    data(){
        return{
            commentDialog: false,
            commentTab: null,
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            baseUrl: process.env.VUE_APP_BASEURL,
            season: {},
            archiveImageDialog: false,
            publish: '',
            activeTab: 0,
            publishDialog: false,
            errors: [],
            detailedReviewErrors: [],
            review: '',
            reviewTitle: '',
            reviewCheck: false,
            reviewDeleteDialog: false,
            loadReview: '',
            detailedReview: '',
            detailedReviewDeleteDialog: false,
            userWatched: false,
            userWatching: false,
            userReactions: null,
            spoilerCheckBox: false,
            reviewFormExpand: false,
            seasonReact1: '?',
            seasonReactText1: '',
            seasonReact2: '?',
            seasonReactText2: '',
            seasonReact3: '?',
            seasonReactText3: '',
            customToolbar: {
                height: 500,
                contextmenu: 'link image table',
                content_style: 'img {max-width: 800px;}',
                menu : {
                    file: {title: 'File', items: 'newdocument restoredraft | preview | print | deleteallconversations'},
                    edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace'},
                    view: {title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | fullscreen | showcomments'},
                    insert: {title: 'Insert', items: 'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'},
                    format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat'},
                    tools: {title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount'},
                    table: {title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable'}
                },
                images_file_types: 'jpg, jpeg',
                images_upload_handler: this.uploadContentImage,
                plugins: ['advlist autolink lists link image charmap print preview anchor searchreplace visualblocks fullscreen insertdatetime media table paste wordcount imagetools'],
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
            },
            reactions: [
	            { name: 'Миний "guilty pleasure"', "emoji": "💙", text: "guilty_pleasure", check: false },
                { name: "Киночдын гарын авлага болхуйц кино", "emoji": "📝", text: "movie_guide", check: false },
                { name: "Киноны хөгжим нь маш их таалагдлаа", "emoji": "🎵", text: "awesome_music", check: false },
                { name: "Найруулагчийн төгс ажил", "emoji": "📣", text: "top_notch_directing", check: false },
                { name: "Жүжигчид нь маш сайн тогложээ", "emoji": "🎭", text: "top_notch_act", check: false },
                { name: "Зураг авалт нь төгс болжээ", "emoji": "🎥", text: "top_notch_cinematography", check: false },
                { name: "Сайн кино болжээ, БРАВО!!!", "emoji": "👏", text: "bravo", check: false },
                { name: "Киноны мөч бүхэн таалагдлаа", "emoji": "😍", text: "loved_every_minute", check: false },
                { name: "Дундуур нь унтсан", "emoji": "💤", text: "i_slept", check: false },
                { name: "Шоконд орсон", "emoji": "😳", text: "i_shocked", check: false },
                { name: "Traumatized", "emoji": "😵", text: "i_traumatized", check: false },
                { name: "Хүлээлтэд хүрсэнгүй", "emoji": "😞", text: "i_am_dissapointed", check: false },
                { name: "Яахавдээ л санадлаа", "emoji": "😒", text: "i_am_not_amused", check: false },
                { name: "Үнэхээр хөгжилтэй санагдлаа", "emoji": "😆", text: "it_was_funny", check: false },
                { name: "Маш гунигтай санагдлаа", "emoji": "😢", text: "it_was_sad", check: false },
                { name: "Маш их айлаа", "emoji": "😱", text: "i_was_scared", check: false },
	            { name: "Mind blown", "emoji": "🤯", text: "it_was_mind_blown", check: false }
	        ],
            reactStyle: {
                'margin': '0 -0.4em',
                'padding': '0.1em 0.4em',
                'border-radius': '0.8em 0.3em',
                'background': 'transparent',
                'background-image': 'linear-gradient(to right, rgba(255, 225, 0, 0.1), rgba(255, 225, 0, 0.7) 4%, rgba(255, 225, 0, 0))',
                '-webkit-box-decoration-break': 'clone',
                'box-decoration-break': 'clone'
            }
        }
    },
    computed: mapState(['user']),
    methods:{
        uploadContentImage(blobInfo, success){
            var image = blobInfo.blob()
            if(image['type']==='image/jpeg'){
                var formData = new FormData()
                formData.append("image", image, "content-image")

                axios
                    .post('/user/api/v-user-useraddimage/', formData)
                    .then(result => {
                        const url = result.data.url
                        success(url)
                    })
                    .catch(err => {
                    })
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        
        changeCheck(text, boolean){
            for (var i in this.reactions) {
                if (this.reactions[i].text == text) {
                    this.reactions[i].check = boolean
                    break
                }
            }
        },
        setReaction(seasonReact, reaction){
            if(reaction){
                let result = this.reactions.find(x => x.text === reaction)
                if (result) {
                    if(seasonReact === 1) this.seasonReact1 = result.emoji, this.seasonReactText1 = result.text
                    else if(seasonReact === 2) this.seasonReact2 = result.emoji, this.seasonReactText2 = result.text
                    else if(seasonReact === 3) this.seasonReact3 = result.emoji, this.seasonReactText3 = result.text
                    this.changeCheck(result.text, true)
                }
            }
        },
        seasonReact(id, item){
            let emoji = '?', text = ''
            if(item !== null) emoji = item.emoji, text = item.text, this.changeCheck(item.text, true)

            if(id === 1) this.changeCheck(this.seasonReactText1, false), this.seasonReact1 = emoji, this.seasonReactText1 = text
            else if(id === 2) this.changeCheck(this.seasonReactText2, false), this.seasonReact2 = emoji, this.seasonReactText2 = text
            else if(id === 3) this.changeCheck(this.seasonReactText3, false), this.seasonReact3 = emoji, this.seasonReactText3 = text

            axios
                .post('/serial/api-season-react/', { season: this.seasonSlug, serial: this.serialSlug, react: id, reaction: text })
        },
        getSeasonDetail(){
            axios
                .get('/serial/api-season-detail/', {params: {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug}})
                .then(response => {this.season = response.data.season_detail, document.title = this.season['serial_title'] + ' - ' + this.season['season_name'], this.publish = this.season.publish, this.userReactions = response.data.user_reactions})
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404'}) }})
        },
        publishSeason(){
            if(this.user.isAuthenticated){
                if(this.publish == false){
                    this.publish = true
                } else{
                    this.publish = false
                }
                axios
                    .post('/serial/api-publish-season/', {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug})
            }
        },
        submitReview(load){
            this.errors = []

            if(this.review == '' && this.loadReview){
                this.errors.push("Та сэтгэгдлээ бичнэ үү!")
            }

            if(!this.errors.length){
                this.commentDialog = false
                axios
                    .post('/serial/api-submit-season-review/', {review: this.review, spoiler: this.spoilerCheckBox, serialSlug: this.serialSlug, seasonSlug: this.seasonSlug, load: load})
                    .then(response => {this.review = response.data.review, this.spoilerCheckBox = response.data.spoiler, this.reviewCheck = response.data.review_check, this.reviewFormExpand = false})
            }
        },
        deleteReview(){
            axios
                .post('/serial/api-delete-season-review/', {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug})
            this.review = ''
        },
        submitDetailedReview(load){
            this.detailedReviewErrors = []

            if(this.reviewTitle == '' && this.loadDetailedReview){
                this.detailedReviewErrors.push("Та гарчигийн нэр заавал өгөх шаардлагатай!")
            }
            if(this.detailedReview == '' && this.loadDetailedReview){
                this.detailedReviewErrors.push("Та шүүмжээ бичнэ үү!")
            }

            const createItems = {
                'serialSlug': this.serialSlug,
                'seasonSlug': this.seasonSlug,
                'title': this.reviewTitle,
                'review': this.detailedReview,
                'load': load
            }

            const createData = new FormData()

            for(var key in createItems){
                createData.append(key, createItems[key]);
            }

            if(!this.detailedReviewErrors.length){
                this.commentDialog = false
                axios
                    .post('/serial/api-submit-season-detailed-review/', createData)
                    .then(response => {this.reviewTitle = response.data.title, this.detailedReview = response.data.review})
            }

        },
        deleteDetailedReview(){
            axios
                .post('/serial/api-delete-season-detailed-review/', {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug})
            this.reviewTitle = ''
            this.detailedReview = ''
        },
        criticsCall(){
            this.$nextTick(() => {
                if(this.$refs.criticRef){
                    this.$refs.criticRef.totalReviews = []
                    this.$refs.criticRef.movieReviews = []
                    this.$refs.criticRef.movieReview()
                }
            })
        },
        audiencesCall(){
            this.$nextTick(() => {
                if(this.$refs.audienceRef){
                    this.$refs.audienceRef.totalReviews = []
                    this.$refs.audienceRef.movieReviews = []
                    this.$refs.audienceRef.movieReview()
                }
            })
        },
        loginRequired(){
            if(!this.user.isAuthenticated) {
                this.$router.push({ name: 'login', query: { redirect:  { name: 'season-detail', params: { serialSlug: this.serialSlug, seasonSlug: this.seasonSlug }}}})
            }
        }
    },
    created(){
        this.getSeasonDetail()
        this.submitReview(this.loadReview = false)
        this.submitDetailedReview(this.loadDetailedReview = false)
    },
    watch:{
        userReactions(){
            if(this.userReactions !== null){
                this.setReaction(1, this.userReactions['react1'])
                this.setReaction(2, this.userReactions['react2'])
                this.setReaction(3, this.userReactions['react3'])
            }
        }
    }
}
</script>

<style scoped src="@/assets/styles/movie_serial.css">
</style>
