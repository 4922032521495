<template>
    <div v-if="user.isAuthenticated">
        <img class="background-image" :src="userBackgroundURL ? userBackgroundURL : ''"/>
        <div class="user-image-position">
            <img class="user-image" :src="userPhotoURL">
        </div>
        <div class="user-title-and-watching">
            <strong>{{ userObject.username }} </strong>
            <small v-show="userStatus && userStatus.content === (1 || 2)">is watching
                <router-link class="no-decoration" :to="userStatus && userStatus.content === 1 ? { name: 'movie-detail', params: {slug: userStatus.slug}} : { name: 'episode-detail', params: {serialSlug: userStatus.serial_slug, seasonSlug: userStatus.season_slug, episodeSlug: userStatus.episode_slug}}">{{ userStatus.title }}</router-link>
            </small>
            <br>
            <strong class="user-title">({{ userObject.title }})</strong>
        </div>
        <div class="user-info__follow_bar">
            <div class="user-info__follow_block">
                <div v-if="user.slug !== requestUserSlug" class="user-button-position">
                    <button v-if="(userObject.blocked && userObject.beingblocked) === false" class="user-button follow-button">
                        <span v-show="userObject.following === true">Дагасан</span>
                        <span v-show="userObject.following === false">Дагах</span>
                    </button>
                    <!-- <button @click="userBlock()" class="user-button block-button">
                        <span v-show="userObject.blocked === false">Блоклох</span>
                        <span v-show="userObject.blocked === true">Блоклогдсон</span>
                    </button> -->
                </div>
                <div v-else class="user-button-position">
                    <v-dialog v-model="userUpdateDialog" persistent min-width="310px">
                        <template v-slot:activator="{ on, attrs }">
                            <button v-bind="attrs" v-on="on" class="user-button profile-button"><v-icon color="white">mdi-pencil</v-icon>Профайл засах</button>
                        </template>
                        <v-card class="pa-3">
                            <v-form v-on:submit.prevent="submitUserInfo()">
                                <strong mb-auto class="user-profile-edit-title">Хэрэглэгчийн мэдээллээ засах:</strong>
                                <v-divider class="mb-5"></v-divider>
                                <div class="create_movie__block">
                                    <div class="create_movie__image_block">
                                        <v-card class="user-profile-image"><v-img class="user-profile-image" :src="userPhotoURL"/></v-card>
                                        <v-dialog v-model="photoDialog" min-width="300px" transition="dialog-bottom-transition">
                                            <template v-slot:activator="{ on, attrs }">
                                                <button v-bind="attrs" v-on="on" class="user-button generic-button">Профайл зураг нэмэх</button>
                                            </template>
                                            <v-card>
                                                <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 240, height: 240}" :canvas="{width: 240, height: 240}" image-restriction="stencil"/>
                                                <div class="button-wrapper">
                                                    <button class="user-button image-button generic-button" @click="$refs.file.click()"><input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Профайл зураг оруулах</button>
                                                    <button class="user-button image-button generic-button" @click="cropPhoto">Профайл зураг хуулах</button>
                                                </div>
                                                <v-card-actions class="justify-end"><v-btn text @click="photoDialog = false" color="#0066b5">Хаах</v-btn></v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-divider class="mt-5 mb-5"></v-divider>
                                        <v-card class="user-background-image"><v-img class="user-background-image" :src="userBackgroundURL"/></v-card>
                                        <v-dialog v-model="backgroundDialog" min-width="300px" transition="dialog-bottom-transition">
                                            <template v-slot:activator="{ on, attrs }">
                                                <button v-bind="attrs" v-on="on" class="user-button generic-button">Арын зураг нэмэх</button>
                                            </template>
                                            <v-card>
                                                <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 1280, height: 720}" :canvas="{width: 1280, height: 720}" image-restriction="stencil"/>
                                                <div class="button-wrapper">
                                                    <button class="user-button image-button generic-button" @click="$refs.file.click()"><input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Арын зураг оруулах</button>
                                                    <button class="user-button image-button generic-button" @click="cropBackground">Арын зураг хуулах</button>
                                                </div>
                                                <v-card-actions class="justify-end"><v-btn text @click="backgroundDialog = false" color="#0066b5">Хаах</v-btn></v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </div>
                                    <div class="create_movie__title_block">
                                        <v-text-field v-model="userLastName" name="userLastName" label="Овог*" required></v-text-field>
                                        <v-text-field v-model="userFirstName" name="userFirstName" label="Нэр*" required></v-text-field>
                                        <v-text-field v-model="userEmail" name="userEmail" label="Имэйл*" required></v-text-field>
                                        <v-checkbox v-model="userBirthdayHide" label="Төрсөн өдрөө нуух" ></v-checkbox>
                                        <v-menu ref="birthdayMenu" v-model="birthdayMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="userBirthday" label="Төрсөн өдөр" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker width="250px" v-model="userBirthday" :active-picker.sync="birthdayPicker" :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" min="1900-01-01" @change="birthdaySave"></v-date-picker>
                                        </v-menu>
                                        <v-combobox v-model="userCountry" :items="userCountryItems" label="Төрсөн улс" item-text="country_mongol_name" :return-object="false"></v-combobox>
                                    </div>
                                </div>
                                <v-textarea v-model="userBio" label="Өөрийнхөө тухай" hint="Та 1500 тэмдэгтэнд багтааж бичнэ үү!" :rules="bioRules"></v-textarea>
                                <v-text-field v-model="userFacebook" name="userFacebook" label="Фэйсбүүк (facebook.com/***** -с зөвхөн ***** нэрээ оруулна уу!)"></v-text-field>
                                <v-text-field v-model="userInstagram" name="userInstagram" label="Инстаграм"></v-text-field>
                                <v-text-field v-model="userTwitter" name="userTwitter" label="Твиттэр"></v-text-field>
                                <v-text-field v-model="userYoutube" name="userYoutube" label="Ютуб суваг"></v-text-field>
                                <v-text-field v-model="userYoutubeName" name="userYoutube" label="Ютуб сувгийн нэр"></v-text-field>
                                <v-text-field v-model="userYoutubeBanner" name="userYoutubeBanner" label="Ютуб сувгийн баннэр"></v-text-field>
                                <small>*та заавал бөглөөрэй</small>
                                <v-card-actions class="justify-center">
                                    <button type="submit" class="user-button generic-button" @click="userUpdateDialog = false">Хадгалах</button>
                                </v-card-actions>
                                <v-card-actions class="justify-end"><v-btn text @click="userUpdateDialog = false" color="#0066b5">Хаах</v-btn></v-card-actions>
                            </v-form>
                        </v-card>
                    </v-dialog>
                </div>
            </div>
            <div class="user-info__follower_number">
                <router-link class="followers no-decoration ma-1" to="">
                    <span class="user-profile__board_txt">{{ userObject.followers }} </span>дагасан
                </router-link>
            </div>
        </div>
        <div class="user-info__box">
            <div class="user-info__board">
                <v-card class="mx-auto" max-width="480">
                    <v-card-text v-show="userObject.firstname || userObject.lastname || userObject.country || userObject.birthday || userObject.registered || userObject.userartist" class="mb-3">
                        <span v-show="userObject.firstname || userObject.lastname || userObject.bio">
                            <h2>{{ userObject.firstname }} {{ userObject.lastname }}</h2>
                            <div v-show="userObject.bio" class="align-center mx-auto justify-center font-italic mt-5">{{ userObject.bio }}</div>
                            <v-divider class="mt-4 mb-4"></v-divider>
                        </span>
                        <span v-show="userObject.country"><v-icon class="user-info__txt">mdi-earth</v-icon> {{ userObject.country }}</span>
                        <span v-show="!userBirthdayHide && userObject.birthday"><span v-show="userObject.country"> | </span><v-icon class="user-info__txt">mdi-cake-variant</v-icon> {{ userObject.birthday }}</span>
                        <span v-show="userObject.registered"><span v-show="userObject.country || !userBirthdayHide && userObject.birthday"> | </span><v-icon class="user-info__txt">mdi-clock</v-icon> {{ userObject.registered }}</span>
                        <span v-if="userObject.userartist"><span v-show="userObject.country || !userBirthdayHide && userObject.birthday || userObject.registered"> | </span><v-icon class="user-info__txt">mdi-drama-masks</v-icon>
                            <router-link class='followers no-decoration' :to="{ name: 'artist-detail', params: {slug: userObject.userartist.slug}}"> {{ userObject.userartist.name }}</router-link>
                        </span>
                        <div v-show="userObject.youtube || userObject.facebook || userObject.twitter || userObject.instagram" class="user-info__social_box">
                            <span v-show="userObject.youtube" class="user-info__social_block">
                                <a :href="'https://www.youtube.com/channel/' + userObject.youtube" target="_blank"><v-btn fab dark x-small depressed color="#0066b5"><v-icon dark>mdi-youtube</v-icon></v-btn></a>
                            </span>
                            <span v-show="userObject.facebook" class="user-info__social_block">
                                <a :href="'https://www.facebook.com/' + userObject.facebook" target="_blank"><v-btn fab dark x-small depressed color="#0066b5"><v-icon dark>mdi-facebook</v-icon></v-btn></a>
                            </span>
                            <span v-show="userObject.twitter" class="user-info__social_block">
                                <a :href="'https://www.twitter.com/' + userObject.twitter" target="_blank"><v-btn fab dark x-small depressed color="#0066b5"><v-icon dark>mdi-twitter</v-icon></v-btn></a>
                            </span>
                            <span v-show="userObject.instagram">
                                <a :href="'https://www.instagram.com/' + userObject.instagram" target="_blank"><v-btn fab dark x-small depressed color="#0066b5"><v-icon dark>mdi-instagram</v-icon></v-btn></a>
                            </span>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card class="mx-auto" max-width="480">
                    <v-expansion-panels accordion>
                        <v-expansion-panel readonly>
                            <v-expansion-panel-header hide-actions>
                                <div @click="gotoId" class="user-info__board_block">
                                    <router-link class="no-decoration" :to="'/user/' + requestUserSlug">
                                        <v-icon color="#0066b5">mdi-clipboard-text</v-icon>
                                        <span class="user-profile__board_txt"> Самбар <v-badge bordered :content="userNotification" :value="userNotification" inline></v-badge></span>
                                    </router-link>
                                </div>
                            </v-expansion-panel-header>
                        </v-expansion-panel>
                        <v-expansion-panel v-for="(item, index) in boardItems" :key="index">
                            <v-expansion-panel-header class="justify-self-start" disable-icon-rotate>
                                <div class="user-info__board_block">
                                    <v-icon :color="item.color">{{ item.icon }}</v-icon><span :style="{ color: item.color }"> {{ item.name }}</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-chip @click="gotoId" v-for="(listItem, index) in item.list" :key="index" v-show="(listItem.critic || listItem.publisher) === undefined || listItem.critic || listItem.publisher" outlined class="ma-1">
                                    <router-link class="no-decoration" :to="{ name: listItem.url, params: { slug: listItem.slug }}">
                                        {{ listItem.title }}
                                        <v-badge v-show="listItem.content" bordered :color="item.color" :content="listItem.content" :value="listItem.content" inline class="user-info__board_block"></v-badge>
                                    </router-link>
                                </v-chip>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </div>
            <div class="mx-auto user-info__content" id="router_view">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
    components: {
        Cropper
    },
    props: {
        slug: String
    },
    data(){
        return{
            requestUserSlug: this.slug,
            image: "",
            photoDialog: false,
            backgroundDialog: false,
            baseUrl: process.env.VUE_APP_BASEURL,
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            userObject: [],
            userStatus: '',
            userNotification: 0,
            userNotified: false,
            userLastName: '',
            userFirstName: '',
            userEmail: '',
            birthdayPicker: null,
            userBirthday: null,
            userBirthdayHide: '',
            birthdayMenu: false,
            userPhoto: null,
            userPhotoURL: null,
            userBackground: null,
            userBackgroundURL: null,
            userCountryItems: [],
            userCountry: '',
            userBio: '',
            bioRules: [v => v.length <= 1500 || 'Та 1500 тэмдэгтэнд багтаан бичнэ үү!'],
            userFacebook: '',
            userInstagram: '',
            userTwitter: '',
            userYoutube: '',
            userYoutubeName: '',
            userYoutubeBanner: '',
            userUpdateObject: '',
            userUpdateDialog: false,
            boardItems: []
        }
    },
    computed: mapState(['user']),
    methods:{
        gotoId(){
            setTimeout(function() {
                var el = document.getElementById('router_view')
                if(typeof el !== "undefined" && el !== null) {
                    el.scrollIntoView({ behavior: "smooth" })
                }
            }, 1500)
        },
        getUserDetail(slug){
            axios
                .get('/user/api/' + slug + '/v-user-detail/')
                .then((response) => {   this.userObject = response.data.user_object,
                                        this.userPhotoURL = this.baseUrl + this.userObject.image,
                                        this.userBackgroundURL = this.baseUrl + this.userObject.backgroundimage,
                                        this.userBirthdayHide = this.userObject.birthdaycheck
                                        this.userStatus = response.data.user_status,
                                        this.boardItems = [
                                            {   name: "Кино",
                                                color: "#c0220e",
                                                icon: "mdi-movie-roll",
                                                list: [ {   title: "Шилдэг кино жагсаалт",
                                                            url: "best-movie-list",
                                                            slug: this.requestUserSlug
                                                        },
                                                        {   title: "Сэтгэгдэл",
                                                            url: "movie-reviews",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.moviereview
                                                        },
                                                        {   title: "Тойм шүүмж",
                                                            url: "movie-detailed-reviews",
                                                            critic: this.userObject.critic,
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.moviedetailedreview
                                                        },
                                                        {   title: "Дуртай кино",
                                                            url: "favorite-movies",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.favoritemovies
                                                        },
                                                        {   title: "Дуртай богино кино",
                                                            url: "favorite-short-movies",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.favoriteshortmovies
                                                        },
                                                        {   title: "Кино хариулал",
                                                            url: "reacted-movies",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.reactedmovies
                                                        },
                                                        {   title: "Үзэх кино",
                                                            url: "watch-movies",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.watchmovies
                                                        },
                                                        {   title: "Кино жагсаалт",
                                                            url: "user-movie-list",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.usermovielist
                                                        }
                                                ]
                                            },
                                            {
                                                name: "Сериал",
                                                color: "#FF7000",
                                                icon: "mdi-television-box",
                                                list: [ {   title: "Шилдэг цувралын жагсаалт",
                                                            url: "best-serial-list",
                                                            slug: this.requestUserSlug
                                                        },
                                                        {   title: "Сэтгэгдэл",
                                                            url: "episode-reviews",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.episodereview
                                                        },
                                                        {   title: "Тойм шүүмж",
                                                            url: "episode-detailed-reviews",
                                                            slug: this.requestUserSlug,
                                                            critic: this.userObject.critic,
                                                            content: this.userObject.episodedetailedreview
                                                        },
                                                        {   title: "Дуртай цуврал",
                                                            url: "favorite-serials",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.favoriteserials
                                                        },
                                                        {   title: "Цуврал хариулал",
                                                            url: "reacted-serials",
                                                            slug: this.requestUserSlug,
                                                            content: null
                                                        },
                                                        {   title: "Үзэх цуврал",
                                                            url: "watch-serials",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.watchserials
                                                        },
                                                        {   title: "ТВ цуврал жагсаалт",
                                                            url: "user-serial-list",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.userseriallist
                                                        }
                                                ]
                                            },
                                            {
                                                name: "Уран бүтээлч",
                                                color: "#101315",
                                                icon: "mdi-drama-masks",
                                                list: [ {   title: "Шүтдэг уран бүтээлч",
                                                            url: "idols",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.idols
                                                        }
                                                ]
                                            },
                                            {
                                                name: "Нийтлэл",
                                                color: "#008b8b",
                                                icon: "mdi-paperclip",
                                                list: [{   title: "Нийтлэл",
                                                            url: "published-journals",
                                                            slug: this.requestUserSlug,
                                                            content: this.userObject.publishedarticles
                                                        },
                                                        {   title: "Нийтлэгдээгүй",
                                                            url: "unpublished-journals",
                                                            publisher: this.user.publisher && (this.user.slug === this.requestUserSlug),
                                                            slug: this.requestUserSlug, content: this.userObject.unpublishedarticles
                                                        }
                                                ]
                                            }
                                        ],
                                        document.title = this.userObject.username })
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404'}) } })
        },
        notification(){
            axios
                .get('/user/api/v-user-notification/', { params: { notified: this.userNotified }})
                .then((response) => {this.userNotification = response.data.notification})
        },
        userBlock(){
            axios
                .post('/user/api/v-user-blockuser/', { "slug": this.slug })
        },
        getCountries(){
            axios
                .get('/country/api-production-country-list/').then(response => { this.userCountryItems = response.data.country_list })
        },
        birthdaySave(date){
            this.$refs.birthdayMenu.save(date)
        },
        getProfileDetail(){
            axios
                .get('/user/api/' + this.requestUserSlug + '/v-user-detail/')
                .then((response) => {   this.userUpdateObject = response.data.user_object,
                                        this.userLastName = this.userUpdateObject.lastname,
                                        this.userFirstName = this.userUpdateObject.firstname,
                                        this.userEmail = this.userUpdateObject.email,
                                        this.userBirthday = this.userUpdateObject.birthday,
                                        this.userBirthdayHide = this.userUpdateObject.birthdaycheck,
                                        this.userCountry = this.userUpdateObject.country,
                                        this.userBio = this.userUpdateObject.bio,
                                        this.userFacebook = this.userUpdateObject.facebook,
                                        this.userInstagram = this.userUpdateObject.instagram,
                                        this.userTwitter = this.userUpdateObject.twitter,
                                        this.userYoutube = this.userUpdateObject.youtube,
                                        this.userYoutubeName = this.userUpdateObject.youtubename,
                                        this.userYoutubeBanner = this.userUpdateObject.youtubebanner,
                                        this.userPhotoURL = this.baseUrl + this.userUpdateObject.image, 
                                        this.userBackgroundURL = this.baseUrl + this.userUpdateObject.backgroundimage })
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404'}) } })
        },
        submitUserInfo(){
            const updateItems = {
                'lastname': this.userLastName,
                'firstname': this.userFirstName,
                'email': this.userEmail,
                'birthday': this.userBirthday,
                'birthdayhide': this.userBirthdayHide,
                'country': this.userCountry,
                'bio': this.userBio,
                'facebook': this.userFacebook,
                'instagram': this.userInstagram,
                'twitter': this.userTwitter,
                'youtube': this.userYoutube,
                'youtubename': this.userYoutubeName,
                'youtubebanner': this.userYoutubeBanner
            }

            const updateData = new FormData()

            if(this.userPhoto){
                updateData.append('user-photo', this.userPhoto)
            }

            if(this.userBackground){
                updateData.append('user-background', this.userBackground)
            }

            for( var key in updateItems ){
                updateData.append(key, updateItems[key])
            }

            this.$store.commit('SET_ISLOADING', true)

            axios
                .post('/user/api/v-user-profile-update/', updateData)
                .then(response => {
                    if(response && response.data){
                        this.$store.commit('SET_USER_IMAGE', response.data.user.image)
                        this.$store.commit('SET_USER_BACKGROUND', response.data.user.background)
                        this.$store.commit('SET_SNACK', {message: 'Таны мэдээлэл амжилттай засагдлаа!', color: 'green'})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })

            this.$store.commit('SET_ISLOADING', false)
        },
        cropPhoto() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.userPhoto = blob }, "image/jpeg")
            this.userPhotoURL = canvas.toDataURL("jpeg")
            this.photoDialog = false
            this.image = ''
        },
        cropBackground() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.userBackground = blob }, "image/jpeg")
            this.userBackgroundURL = canvas.toDataURL("jpeg")
            this.backgroundDialog = false
            this.image = ''
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])
                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        }
    },
    watch: {
        birthdayMenu(val){
            val && setTimeout(() => (this.birthdayPicker = 'YEAR'))
        },
        $route(to){
            if(to.path == '/user/' + this.requestUserSlug){
                this.userNotified = true
                this.notification()
            }
        },
        slug(){
            window.scrollTo(100,100)
            this.requestUserSlug = this.slug
            this.getUserDetail(this.requestUserSlug)
            this.getProfileDetail()
        }
    },
    created(){
        this.getUserDetail(this.requestUserSlug)
        this.getCountries()
        document.title = 'Хэрэглэгчийн мэдээлэл засах'
        this.getProfileDetail()
    }
}
</script>


<style scoped lang="scss">

.user-info__box,
.user-info__follow_bar {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    margin-left: auto;
    min-width: 320px;
    max-width: 480px;
}

.create_movie__block {
    display: flex;
    flex-wrap: wrap;
}

.create_movie__image_block {
    flex: 100%;
    max-width: 200px;
    margin: 20px auto;
    padding: 10px;
}

.create_movie__title_block {
    flex: 100%;
}

.user-info__board,
.user-info__content {
    flex: 100%;
    padding: 5px 5px;
}

.user-info__board,
.user-info__content {
    min-width: 320px;
    max-width: 480px;
}

.user-info__txt {
    font-size: 12px;
}

.user-info__social_box {
    margin-top: 15px;
}

.user-info__social_block {
    margin-right: 5px;
}

.user-info__board_block {
    font-weight: 700;
}

.user-info__my_twt_txt {
    color: #6a4c93;
}

.cropper {
    max-height: 300px;
    width: 100%;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 17px;
}

// suuld nemsen

.user-button-position {
    display: flex;
}

.user-info__follow_block {
    justify-content: flex-start;
}

.user-info__follower_number {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
}


.background-image {
    object-fit: cover;
    border-radius: 0 0 15px 15px;
    width: 100%;
    min-height: 250px;
    max-height: 450px;
}

.user-image {
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid white;
    width: 150px; 
    height: 150px;
}

.user-image-position {
    margin-top: -80px;
    display: flex;
    justify-content: center;
}

.user-title-and-watching {
    margin: 15px auto;
    font-size: 24px;
    line-height: 0.9;
    word-break: break-all;
    text-align: center;
}

.no-decoration {
    text-decoration: none;
    color: grey;
}

.followers {
    color: grey;
    font-weight: bold;
}

.user-title {
    font-size: 10px;
}

.user-button {
    color: white;
    background-color: #0066b5;
    font-weight: bold;
    border: none;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
}

.image-button {
    width: 100%;
    transition: background 0.5s;
    &:not(:last-of-type) {
        margin-right: 10px;
    }
    &:hover {
        background: #2F2F2F;
    }
    input {
        display: none;
    }
}

.profile-button {
    border-radius: 8px;
    width: 150px;
}

.generic-button {
    border-radius: 8px;
    width: 100%;
}

.follow-button {
    border-radius: 8px;
    width: 80px;
}

.block-button {
    border-radius: 8px;
    width: 100px;
}

.user-profile-edit-title {
    font-size: 1.4em;
    font-weight: 300;
    color: #0066b5;
}

.user-profile__board_txt {
    color: #0066b5;
}

.user-profile-image {
    width: 200px;
    height: 200px;
}

.user-background-image {
    width: 200px;
    height: 135px;
}

.user-profile {
    font-size: 24px;
    color: #0066b5;
}

.circle-btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #0066b5;
}

@media (min-width: 481px) {

    .create_movie__image_block {
        flex: 20%;
    }

    .create_movie__title_block {
        flex: 80%;
        padding: 20px;
    }

    .user-info__board {
        flex: 30%;
        max-width: 30%;
    }

    .user-info__content {
        flex: 70%;
        max-width: 70%;
    }

    .user-info__follow_bar,
    .user-info__box {
        flex-wrap: nowrap;
        min-width: 1280px;
    }
}

</style>