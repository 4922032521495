<template>
    <v-container v-if="user.isAuthenticated && user.admin">
        <v-card-title class="justify-center">Та танилцуулага засварлах гэж байна!</v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
            <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitAboutUs">
                <v-text-field v-model="aboutTitle" label="Гарчиг*" required :rules="[rules.required]"></v-text-field>
                <br>
                <tinymceEditor api-key="dh9tjnxmzhiub2bx0brpetqavxuw7z518330gl5p1kn1ivy6" v-model="aboutContent" :init="customToolbar"/>
                <br>
                <EditMember ref="members"/>
                <v-card-actions class="justify-center"><v-btn :disabled="checkValid" @click="validate" type="submit" style="color: white; background-color: green;" block>Хадгалах</v-btn></v-card-actions>
            </v-form>
            <v-divider class="mx-4"></v-divider>
        </v-card-text>
    </v-container>
</template>

<script>
import axios from 'axios'

import { mapState } from 'vuex'
import Editor from '@tinymce/tinymce-vue'

import EditMember from '@/views/institute/aboutus/edit/EditMember.vue'

export default {
    components: {
        EditMember,
        'tinymceEditor': Editor
    },
    data(){
        return{
            image: "",
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
            },
            coverDialog: false,
            aboutTitle: '',
            aboutContent: '',
            memberList: [],
            customToolbar: {
                height: 500,
                contextmenu: 'link image table',
                content_style: 'img {max-width: 1280px;}',
                menu : {
                    file: {title: 'File', items: 'newdocument restoredraft | preview | print | deleteallconversations'},
                    edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace'},
                    view: {title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | fullscreen | showcomments'},
                    insert: {title: 'Insert', items: 'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'},
                    format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat'},
                    tools: {title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount'},
                    table: {title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable'}
                },
                images_file_types: 'jpg, jpeg',
                images_upload_handler: this.uploadContentImage,
                plugins: ['advlist autolink lists link image charmap print preview anchor searchreplace visualblocks fullscreen insertdatetime media table paste wordcount imagetools'],
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
            }
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user'])
    },
    methods:{
        validate(){
            this.$refs.form.validate()
        },
        uploadContentImage(blobInfo, success){
            var image = blobInfo.blob()
            if(image['type']==='image/jpeg'){
                var formData = new FormData()
                formData.append("image", image, "content-image")

                axios
                    .post('/user/api/v-user-useraddimage/', formData)
                    .then(result => {
                        const url = result.data.url
                        success(url)
                    })
                    .catch(err => {
                    })
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        submitAboutUs(){
            var members = this.$refs.members.memberList

            const createItems = {
                'main_title': this.aboutTitle,
                'content': this.aboutContent
            }

            const createData = new FormData()

            if(members.length > 0){
                for(var i = 0; i < members.length; i++){
                    createData.append('id', i)
                    createData.append('memberTitle', members[i].memberTitle)
                    createData.append('memberName', members[i].memberName)
                    createData.append('memberCategory', members[i].memberCategory)
                    createData.append('memberImage', members[i].memberImageURL)
                    createData.append('memberUserSlug', members[i].memberUserSlug !== null ? members[i].memberUserSlug : null)
                    createData.append('memberArtistSlug', members[i].memberArtistSlug !== null ? members[i].memberArtistSlug : null)
                    createData.append('memberDescription', members[i].memberDescription !== null ? members[i].memberDescription : null)
                }
            }

            for( var key in createItems ){
                createData.append(key, createItems[key])
            }

            axios
                .post('/institute/edit-aboutus/', createData)
                .then((response) => {
                        if(response){
                            this.$store.commit('SET_SNACK', {message: 'Танилцуулага амжилттай засагдлаа!', color: 'green'})
                            this.$router.push({ name: 'home' })
                        }
                    })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү! ' + error.response.data['message'], color: 'red'})
                    }
                })
        },
        getDetailAboutUs(){
            axios
                .get('/institute/detail-aboutus/')
                .then(response => {this.aboutTitle = response.data.aboutTitle, this.aboutContent = response.data.aboutContent})
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404'}) }})
        }
    },
    created(){
        document.title = 'Танилцуулага засварлах'
        this.getDetailAboutUs()
    }
}
</script>

<style scoped lang="scss">

.cropper {
    max-height: 300px;
    width: 100%;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 17px;
}

.button {
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    width: 100%;
    background: #0066b5;
    cursor: pointer;
    transition: background 0.5s;
    border: none;
    &:not(:last-of-type) {
        margin-right: 10px;
    }
    &:hover {
        background: #2F2F2F;
    }
    input {
        display: none;
    }
}

</style>