<template>
    <div>
        <div class="create_movie__legend_bar">Дүр нэмэх:</div>
        <v-card class="pa-2" outlined>
            <div class="create_movie__artist_block">
                <div class="create_movie__artist_search">
                    <v-autocomplete no-filter v-model="selectedRole" height="100" :items="searchItems" hide-no-data return-object :search-input.sync="searchRole" clearable item-text="name" label="Уран бүтээлч" attach solo>
                        <template v-slot:selection="data">
                            <v-avatar left height="75" tile class="mx-3"><img :src="mediaUrl + '/' + data.item['photo']" class="rounded"></v-avatar>
                            <div mx="2">{{ data.item['pro_name'] }}</div>
                        </template>
                        <template v-slot:item="data">
                            <template v-if="data.item !== 'object'">
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['photo']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['pro_name']"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['photo']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['pro_name']"></v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </div>
                <div class="create_movie__artist_role">
                    <v-textarea v-model="artistRole" label="Дүрийн нэр"></v-textarea>
                </div>
            </div>
            <v-btn :color="toggleColor" block @click="addArtist">{{ toggleButton }}</v-btn>
        </v-card>
        <br>
        <div v-show="roleList.length === 0" class="generic-list__row">
            <v-card v-for="index in 3" :key="index" class="create_movie__artist_mock_card">
                <v-card class="create_movie__artist_mock_card_img"></v-card>
            </v-card>
        </div>
        <draggable v-model="roleList" class="generic-list__row" tag="ul" v-bind="dragOptions" @start="drag = true" @end="drag = false">
            <CreateRoleCard v-for="(role, index) in roleList" :key="index" :type="'role'" :edited="beingEdited" :role="role" v-on:delete-role="removeRole(index)" v-on:edit-role="editRole(index, role)"/>
        </draggable>
    </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import CreateRoleCard from '@/components/generics/movie_serial/GenericCUArtistCard.vue'

export default {
    props: {
        festival: Boolean
    },
    components: {
        draggable,
        CreateRoleCard,
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            roleList: [],
            searchItems: [],
            loadMore: 0,
            searchRole: null,
            selectedRole: '',
            artistRole: '',
            toggleButton: "Дүр нэмэх",
            toggleColor: "primary",
            beingEdited: false,
            drag: false
        }
    },
    computed:{
        dragOptions(){
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
    watch:{
        searchRole(val){
            if(val){
                axios
                    .get('/artist/api-search-artist-list/', {params: { artist: val, festival: this.festival }}).then(response => {this.searchItems = response.data.artist_list})
            }
        }
    },
    methods:{
        addArtist(){
            this.toggleButton = "Дүр нэмэх"
            this.toggleColor = "primary"
            this.beingEdited = false
            if(this.selectedRole && this.roleList.findIndex(i => i.actor.id === this.selectedRole['id']) == -1){
                this.roleList.push({role: this.artistRole, actor: {id: this.selectedRole['id'], pro_name: this.selectedRole['pro_name'], photo: this.selectedRole['photo']}})
            }
            this.selectedRole = null
            this.artistRole = null
            this.searchItems = []
            this.$emit('submit-button', false)
        },
        editRole(index, role){
            this.artistRole = role['role']
            this.searchItems.push(role.actor)
            this.selectedRole = role.actor
            this.toggleButton = "Дүр засах"
            this.toggleColor = "amber"
            this.beingEdited = true
            this.roleList.splice(index, 1)
            this.$emit('submit-button', true)
        },
        removeRole(index){
            this.roleList.splice(index, 1)
        }
    }
}
</script>

<style scoped src="@/assets/styles/CU_artist.css">
</style>