<template>
    <div v-if="user.isAuthenticated" class="create_movie">
        <div class="create_movie__title">Та шинэ уран бүтээлч нэмэх гэж байна!</div>
        <v-divider class="mx-4"></v-divider>
        <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitCreate">
            <div class="create_movie__block">
                <div class="create_movie__image_block">
                    <v-card width="240" height="360"><v-img :src="artistPhotoURL"/></v-card>
                    <v-text-field v-model="artistPhotoCheck" disabled label="Зураг" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="imageDialog" width="600px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Зураг нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="imageDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Уран бүтээлчийн зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 240, height: 360}" :canvas="{width: 240, height: 360}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropImage">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="create_movie__title_block">
                    <v-alert prominent dense type="info">
                        <v-checkbox v-model="artistFestival" class="create_movie__festival_checkbox" label="Энэхүү уран бүтээлчийг GGIFF2023-т илгээх кинонд бүртгүүлнэ*"></v-checkbox>
                    </v-alert>
                    <v-text-field v-model="artistProfessionalName" label="Олонд танигдсан нэр (Монголоор)*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="artistProfessionalNameEn" label="Олонд танигдсан нэр (Англиар)*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="artistLastName" label="Овог (Англиар)*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="artistFirstName" label="Нэр (Англиар)*" required :rules="[rules.required]"></v-text-field>
                    <v-combobox v-model="artistCountries" :items="artistCountryItems" label="Улсын нэр*" multiple item-text="country_mongol_name" :return-object="false" :rules="[rules.choice]"></v-combobox>
                    <v-combobox v-model="artistOldCountries" :items="artistCountryItems" label="Эртний хэрэглэхээ больсон улсын нэршил" multiple item-text="country_mongol_name" :return-object="false"></v-combobox>
                    <v-text-field v-model="artistBorn" label="Төрсөн он*" required min="0" :rules="[rules.yearCheck]"></v-text-field>
                    <v-text-field v-model="artistDied" label="Нас барсан он" min="0" :rules="[rules.yearCheck]"></v-text-field>
                </div>
            </div>
            <div class="create_movie__block_content">
                <v-textarea v-model="artistBio" label="Товч намтар" hint="Та 4500 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.bioLength]"></v-textarea>
                <v-textarea v-model="artistBioEn" label="Товч намтар (Англиар)" hint="Та 4500 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.bioLength]"></v-textarea>
                <v-combobox v-model="artistProfessions" :items="artistProfessionItems" label="Мэргэжил" multiple item-text="profession_mongol_title" :return-object="false" :rules="[rules.choice]"></v-combobox>
                <CreateQuote ref="quotes"/>
                <br>
            </div>
            <v-card-actions class="justify-center">
                <v-btn :disabled="checkValid" @click="validate" type="submit" class="create_movie__submit_btn" block>Хадгалах</v-btn>
            </v-card-actions>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import { mapState } from 'vuex'
import 'vue-advanced-cropper/dist/style.css'

import CreateQuote from '@/views/artist/create/CreateQuote.vue'


export default {
    components: {
        CreateQuote,
        Cropper
    },
    props: {
        slug: String
    },
    data(){
        return{
            currentYear: new Date().getFullYear(),
            image: "",
            imageDialog: false,
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                choice: value => value.length > 0 || 'Сонгох шаардлагатай!',
                bioLength: value => value.length <= 4500 || '4500 тэмдэгтээс хэтэрлээ!',
                yearCheck: value => value <= this.currentYear || 'Та оноо зөв оруулна уу!'
            },
            artistProfessionalName: '',
            artistProfessionalNameEn: '',
            artistLastName: '',
            artistFirstName: '',
            artistCountryItems: [],
            artistCountries: [],
            artistOldCountries: [],
            artistPhoto: null,
            artistPhotoURL: null,
            artistPhotoCheck: null,
            artistBorn: 0,
            artistDied: 0,
            artistBio: '',
            artistBioEn: '',
            artistProfessionItems: [],
            artistProfessions: [],
            artistFestival: false
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user'])
    },
    methods:{
        getCountries(){
            axios
                .get('/country/api-country-list/').then(response => { this.artistCountryItems = response.data.country_list })
        },
        getProfessions(){
            axios
                .get('/artist/api-profession-list/').then(response => {this.artistProfessionItems = response.data.profession_list})
        },
        validate(){
            this.$refs.form.validate()
        },
        submitCreate(){
            var quotes = this.$refs.quotes.artistQuotes

            const createItems = {
                'pro_name': this.artistProfessionalName,
                'pro_name_en': this.artistProfessionalNameEn,
                'last_name': this.artistLastName,
                'first_name': this.artistFirstName,
                'countries': JSON.stringify(this.artistCountries),
                'old_countries': JSON.stringify(this.artistOldCountries),
                'born': this.artistBorn,
                'died': this.artistDied,
                'bio': this.artistBio,
                'bio_en': this.artistBioEn,
                'professions': JSON.stringify(this.artistProfessions),
                'quotes': JSON.stringify(quotes),
                'festival': this.artistFestival,
            }

            const createData = new FormData()

            if(this.artistPhoto){
                createData.append('artist-photo', this.artistPhoto, "artist_photo")
            }

            for( var key in createItems ){
                createData.append(key, createItems[key])
            }

            axios
                .post('/artist/api-create-artist/', createData)
                .then((response) => {
                    if (response.data.success === true){
                        this.$store.commit('SET_SNACK', {message: 'Уран бүтээлч амжилттай нэмэгдлээ, сайтын админ шалгаад нийтлэх болно!', color: 'green'})
                        this.$router.push({ name: 'artist-list' }).catch(()=>{})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, та оруулсан утгуудаа шалгаад дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        },
        cropImage() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.artistPhoto = blob }, "image/jpeg")
            this.artistPhotoURL = canvas.toDataURL("jpeg")
            this.artistPhotoCheck = 'artist-photo'
            this.imageDialog = false
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])

                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        destroyed() {
            if (this.image) {
                URL.revokeObjectURL(this.image)
            }
        }
    },
    created(){
        this.getCountries()
        this.getProfessions()
        document.title = 'шинээр уран бүтээлч нэмэх'
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>