<template>
    <v-card class="list-card">
        <router-link class="list-card__movies" :to="{ name: url, params: { slug: list.user_slug, listSlug: list.title_slug }}">
            <img v-for="(movie, index) in checkList()" :key="index" :src="baseUrl + poster(movie)" class="rounded " :style="cardStyle(index)"/>
        </router-link>
        <div class="list-card__user_box">
            <div class="list-card__user_block">
                <router-link :to="{name: 'user-feed', params: { slug: list.user_slug } }">
                    <v-img class="list-card__user_avatar" :src="baseUrl + list.user_image"/>
                </router-link>
                <div class="list-card__user_name">
                    {{ list.user_name }}
                    · <v-icon class="list-card__user_icon" icon @click="likeList()" :disabled="!isAuth" size="16">{{ reviewHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                    {{ reviewCount }}
                </div>
            </div>
            <div class="list-card__title_box">
                <div class="list-card__title">{{ list.title }}</div>
                <div class="list-card__total">{{ total }}</div> 
            </div>
        </div>
    </v-card>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        type: String,
        list: Object,
        user_like: Boolean,
        total_likes: Number,
        widthGap: Number
    },
    computed:{
        url(){
            return this.type === "movie" ? 'user-movie-list-detail' : 'user-serial-list-detail'
        },
        total(){
            return this.type === "movie" ? this.list.total_movies + " кино" : this.list.total_serials + " цуврал"
        }
    },
    data(){
        return{
            isAuth: this.$store.state.user.isAuthenticated,
            baseUrl: process.env.VUE_APP_BASEURL,
            reviewHeart: this.user_like,
            reviewCount: this.total_likes,
            width: 0
        }
    },
    mounted(){
        this.resizeWidth()
        window.addEventListener("resize", this.resizeWidth)
    },
    unmounted() {
        window.removeEventListener("resize", this.resizeWidth)
    },
    methods:{
        checkList(){
            return this.type === "movie" ? this.list.movies : this.list.serials
        },
        poster(movie){
            return this.type === "movie" ? movie.movie_poster : movie.serial_poster
        },
        resizeWidth(){
            this.width = window.innerWidth
            if ( this.width < 320 ) this.width = 320
            if ( this.width > 481 ) this.width = 760
        },
        cardStyle(g){
            let width = this.width
            let wGap = 8

            if (480 < width) width = 333, wGap = this.widthGap
            else wGap = 8

            let gap = width / wGap
            return {
                'margin-left': g * gap + 'px',
                'width': ( width / 2 - 25 ) + 'px',
                'grid-row-start': '1',
                'grid-column-start': '1'
            }
        },
        likeList(){
            if (this.reviewHeart == true) {
                this.reviewHeart = false
                this.reviewCount--
            } else {
                this.reviewHeart = true
                this.reviewCount++
            }

            if (this.type === "movie") {
                axios
                    .post('/user/api/v-user-movielistlike/', { list_id: this.list.id, list_user: this.list.user_slug })
            } else {
                axios
                    .post('/user/api/v-user-seriallistlike/', { list_id: this.list.id, list_user: this.list.user_slug })
            }
            
        }
    }
}
</script>

<style scoped>

/* Listed Movie Class */

.list-card {
    min-width: 280px;
    max-width: 480px;
    margin-bottom: 5px;
    overflow: hidden;
    
}
.list-card__movies {
    display: grid;
    grid-template-columns: 1fr;
}

.list-card__user_box {
    padding: 0 10px;
    position: relative;
}

.list-card__user_block {
    top: -40px;
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
}

.list-card__user_avatar {
    border: 3px solid white;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.list-card__user_name {
    font-size: 12px;
    margin-top: auto;
    padding: 0 10px;
    flex: 100%;
    font-weight: bold;
}

.list-card__title_box {
    padding: 35px 10px 10px;
    flex: 100%;
}

.list-card__user_icon {
    color: #0066b5 !important;
}


.list-card__title {
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 1.3rem;
    overflow-wrap: break-word;
}

.list-card__total {
    color: #696969;
    font-size: 14px;
}

</style>