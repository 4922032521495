<template>
  <v-snackbar v-model="show" timeout="3000" :color="color" top="top">{{message}}</v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: '',
      message: '',
      color: ''
    }
  },
  created(){
    this.$store.watch(state => state.snack.message, () => {
      const msg = this.$store.state.snack.message
      if (msg !== '') {
        this.show = true;
        this.message = this.$store.state.snack.message;
        this.color = this.$store.state.snack.color;
        this.$store.commit('SET_SNACK', {message: '', color: ''});
      }
    })
  }
}
</script>