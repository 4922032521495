<template>
    <div class="journal-detail-content__box">
        <div class="movie-review__box">
            <div class="block movie-review__title">
                <h2><strong class="movie-review__category">MFI</strong> {{ journal.type }}</h2>
                <br>
                <h1 class="movie-review__main_title"><strong>{{ journal.title }}</strong></h1>
                <div class="movie-review__legendbar">
                    <div class="movie-review__legendbar_username">
                        <router-link class="movie-review__legendbar_userlink" :to="{ name: 'user-feed', params: { slug: journal.user_slug }}">
                            {{ journal.user }}
                        </router-link> | {{ journal.publish_date }}
                    </div>
                    <div class="movie-review__legendbar_like">
                        <button v-if="isAuth" @click="journalLike(journal.id)">
                            <v-icon class="movie-review__like_btn">{{ journalHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon> <small>{{ journalLikeCount }}</small>
                        </button>
                    </div>
                </div>
            </div>
            <div class="movie-review__block">
                <div class="block movie-review__review">
                    <img class="rounded journal-content__cover" :src="url + journal.cover"/>
                    <div class="movie-review__review_content" v-html="journal.content"></div>
                </div>
                <div class="block movie-review__content">
                    <div v-if="journal.artists && journal.artists.length">
                        <legend class="movie-review__content_legendbar"><strong>Холбоотой уран бүтээлчид:</strong></legend>
                        <div class="movie-review__container_movie">
                            <router-link v-for="(role, index) in journal.artists" :key="index" :to="{ name: 'artist-detail', params: { slug: role.slug } }">
                                <img :src="url + role.photo" class="rounded movie-review__content_movie"/>
                            </router-link>
                        </div>
                    </div>
                    <div v-if="journal.movies && journal.movies.length">
                        <legend class="movie-review__content_legendbar"><strong>Холбоотой кинонууд:</strong></legend>
                        <div class="movie-review__container_movie">
                            <router-link v-for="(movie, index) in journal.movies" :key="index" :to="{ name: 'movie-detail', params: { slug: movie.slug } }">
                                <img :src="url + movie.poster" class="rounded movie-review__content_movie"/>
                            </router-link>
                        </div>
                    </div>
                    <div v-if="journal.serials && journal.serials.length">
                        <legend class="movie-review__content_legendbar"><strong>Холбоотой цувралууд:</strong></legend>
                        <div class="movie-review__container_movie">
                            <router-link v-for="(serial, index) in journal.serials" :key="index" :to="{ name: 'serial-detail', params: { slug: serial.slug } }">
                                <img :src="url + serial.poster" class="rounded movie-review__content_movie"/>
                            </router-link>
                        </div>
                    </div>
                    <div v-if="journal.p_journals && journal.p_journals.length">
                        <legend class="movie-review__content_legendbar"><strong>Сэтгүүлийн өмнөх дугааруудад:</strong></legend>
                        <div class="d-flex flex-wrap justify-center">
                            <v-card class="mb-2 movie-review__recent_card" v-for="(journal, index) in journal.p_journals" :key="index">
                                <router-link :to="{ name: 'journal-detail', params: { slug: journal.slug } }">
                                    <v-img :src="url + journal.cover" class="rounded movie-review__recent_image"/>
                                </router-link>
                                <div class="movie-review__recent_content">
                                    <div class="movie-review__recent_title"><strong>{{ journal.title }}</strong></div>
                                    <legend class="movie-review__recent_date"><small class='text-muted'><strong>{{ journal.user }}</strong> | {{ journal.publish }}</small></legend>
                                </div>
                            </v-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isAuth && $store.state.user.publisher && $store.state.user.adminBarHide && ($store.state.user.name === journal.user)" class="subadmin__box">
            <v-dialog v-model="publishDialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                    <button :class="publish ? 'subadmin__publish' : 'subadmin__unpublish'" v-bind="attrs" v-on="on" ><v-icon class="subadmin__icon">mdi-clipboard-check-outline</v-icon></button>
                </template>
                <v-card>
                    <v-card-title class="headline grey lighten-2 mx-auto">Сэтгүүл нийтлэх</v-card-title>
                    <v-card-text class="mt-3">Та "{{ journal.title }}" <span v-if="publish">сэтгүүлийн нийтлэгдснийг болиулах</span><span v-else>киног нийтлэх</span> гэж байна!</v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :color="publish ? 'red' : 'primary'" text @click="publishDialog = false; publishJournal();">{{publish ? "болиулах" : "нийтлэх"}}</v-btn>
                        <v-btn color="warning" text @click="publishDialog = false">буцах</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <router-link class="subadmin__update" :to="{ name: 'journal-update', params: { slug: slugJournal } }"><v-icon class="subadmin__icon">mdi-lead-pencil</v-icon></router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        slug: String,
        typeSlug: String,
        subTypeSlug: String
    },
    data(){
        return{
            isAuth: this.$store.state.user.isAuthenticated,
            slugJournal: this.slug,
            url: process.env.VUE_APP_BASEURL,
            journal: {},
            publish: '',
            journalHeart: '',
            journalLikeCount: 0,
            publishDialog: false,
            content_css: [ '//www.tiny.cloud/css/codepen.min.css' ],

        }
    },  
    methods:{
        getJournalDetail(){
            axios
                .get('/journal/api-journal-detail/', { params: { slug: this.slugJournal, typeSlug: this.typeSlug, subTypeSlug: this.subTypeSlug } })
                .then(response => { this.journal = response.data.journal_detail, this.journalLikeCount = this.journal.total_likes, this.journalHeart = this.journal.user_like, this.publish = this.journal.publish, document.title = this.journal['title'] })
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404'}) }})
        },
        journalLike(journal_id){
            if(this.isAuth){
                if(this.journalHeart == true){
                    this.journalHeart = false
                    this.journalLikeCount--
                } else{
                    this.journalHeart = true
                    this.journalLikeCount++
                }
            axios
                .post('/journal/api-journal-like/', { journal_id: journal_id })
            }
        },
        publishJournal(){
            if(this.isAuth){
                if(this.publish == false){
                    this.publish = true
                } else{
                    this.publish = false
                }
                axios
                    .post('journal/api-journal-publish/', { slug: this.slugJournal, type_slug: this.typeSlug, sub_type_slug: this.subTypeSlug })
            }
        }
    },
    created(){
        this.getJournalDetail()
    },
    computed: {
        multpleProps() {
            return `${this.slug}|${this.typeSlug}|${this.subTypeSlug}`
        }
    },
    watch:{
        multpleProps(){
            window.scrollTo(100,100)
            this.slugJournal = this.slug
            this.getJournalDetail()
        }
    }
    
}
</script>

<style scoped src="@/assets/styles/journal_detail.css">
</style>