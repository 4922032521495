<template>
    <v-card class="pa-5">
        <legend class="legendBar mb-2">Гишүүн нэмэх:</legend>
        <v-card class="pa-2">
            <div class="d-flex flex-row mt-2 mb-2">
                <div class="ma-2">
                    <v-card width="240" height="240"><v-img :src="memberImageURL"/></v-card>
                    <v-dialog v-model="imageDialog" width="600px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Зураг нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="imageDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Гишүүний зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 240, height: 240}" :canvas="{width: 240, height: 240}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropImage">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <v-row class="mx-5">
                    <v-col>
                        <v-text-field v-model="memberArtistSlug" label="Хэрвээ бүртгэгдсэн уран бүтээлч бол холбоосыг оруулна уу! (ж: fred-astaire)"></v-text-field>
                        <v-text-field v-model="memberUserSlug" label="Хэрвээ бүртгэгдсэн хэрэглэгч бол холбоосыг оруулна уу! (ж: ike)"></v-text-field>
                        <v-text-field v-model="memberCategory" label="Категори*"></v-text-field>
                        <v-text-field v-model="memberName" label="Нэр*"></v-text-field>
                        <v-text-field v-model="memberTitle" label="Үүрэг*"></v-text-field>
                        <v-textarea solo v-model="memberDescription" label="Нэмэлт тайлбар"></v-textarea>
                    </v-col>
                </v-row>
            </div>
            <v-btn :color="toggleColor" block @click="addMember">{{ toggleButton }}</v-btn>
        </v-card>
        <br>
        <draggable v-model="memberList" class="drag_list_group" tag="ul" v-bind="dragOptions" @start="drag = true" @end="drag = false" style="display: flex; flex-wrap: wrap;">
            <EditMemberCard v-for="(member, index) in memberList" :key="index" :edited="beingEdited" :member="member" v-on:delete-member="removeArtist(index)" v-on:edit-member="editMember(index, member)"/>
        </draggable>
    </v-card>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import draggable from 'vuedraggable'
import EditMemberCard from '@/views/institute/aboutus/edit/EditMemberCard.vue'
import 'vue-advanced-cropper/dist/style.css'


export default {
    components: {
        Cropper,
        draggable,
        EditMemberCard
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            baseUrl: process.env.VUE_APP_BASEURL,
            image: "",
            imageDialog: false,
            memberImage: null,
            memberImageURL: null,
            memberArtistSlug: '',
            memberUserSlug: '',
            memberCategory: '',
            memberName: '',
            memberTitle: '',
            memberDescription: '',
            memberList: [],
            loadMore: 0,
            memberJob: '',
            toggleButton: "Гишүүн нэмэх",
            toggleColor: "primary",
            beingEdited: false,
            drag: false,
        }
    },
    computed:{
        dragOptions(){
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
    methods:{
        cropImage() {
            const { canvas } = this.$refs.cropper.getResult()
            new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg')).then((blob) => this.uploadImageTest(blob))
        },
        uploadImageTest(blob){
            this.memberImage = blob

            var formData = new FormData()
            formData.append("image", this.memberImage, 'member-image')

            axios
                .post('/user/api/v-user-useraddimage/', formData)
                .then(result => {
                    this.memberImageURL = result.data.url
                    this.imageDialog = false
                    this.image = ''
                })
                .catch(err => {
                })
            
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])
                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        addMember(){
            this.toggleButton = "Гишүүн нэмэх"
            this.toggleColor = "primary"
            this.beingEdited = false
            if(this.memberCategory && this.memberName && this.memberImageURL && this.memberTitle){
                this.memberList.push({  memberTitle: this.memberTitle,
                                        memberName: this.memberName,
                                        memberCategory: this.memberCategory,
                                        memberUserSlug: this.memberUserSlug,
                                        memberArtistSlug: this.memberArtistSlug,
                                        memberImageURL: this.memberImageURL,
                                        memberDescription: this.memberDescription })
            }
            this.memberTitle = ''
            this.memberArtistSlug = ''
            this.memberUserSlug = ''
            this.memberCategory = ''
            this.memberName = ''
            this.memberTitle = ''
            this.memberDescription = ''
            this.memberImage = null
            this.memberImageURL = null
            this.$emit('submit-button', false)
        },
        editMember(index, member){
            this.memberTitle = member['memberTitle']
            this.memberArtistSlug = member['memberArtistSlug']
            this.memberUserSlug = member['memberUserSlug']
            this.memberCategory = member['memberCategory']
            this.memberName = member['memberName']
            this.memberImageURL = member['memberImageURL']
            this.memberDescription = member['memberDescription']
            this.toggleButton = "Гишүүн засах"
            this.toggleColor = "amber"
            this.beingEdited = true
            this.memberList.splice(index, 1)
            this.$emit('submit-button', true)
        },
        removeArtist(index){
            this.memberList.splice(index, 1)
        },
        getDetailAboutUs(){
            axios
                .get('/institute/detail-aboutus/')
                .then(response => {this.memberList = response.data.members})
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404'}) }})
        }
    },
    created(){
        this.getDetailAboutUs()
    }
}
</script>

<style scoped lang="scss">


.legendBar {
    border-bottom: #696969 1px solid;
    opacity: 0.8;
    font-size: 16px;
    margin: auto;
    width: 100%;
}

/* Member list drag */

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.drag_list_group {
    min-height: 20px;
}

/* Image */

.cropper {
    max-height: 300px;
    width: 100%;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 17px;
}

.button {
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    width: 100%;
    background: #0066b5;
    cursor: pointer;
    transition: background 0.5s;
    border: none;
    &:not(:last-of-type) {
        margin-right: 10px;
    }
    &:hover {
        background: #2F2F2F;
    }
    input {
        display: none;
    }
}

</style>