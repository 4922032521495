<template>
    <div v-if="user.isAuthenticated && user.publisher" class="create_movie">
        <div class="create_movie__title">Та нийтлэл илгээх гэж байна!</div>
        <v-divider class="mx-4"></v-divider>
        <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitCreate">
            <div class="create_movie__block">
                <div class="create_movie__image_block">
                    <v-card width="240" height="135"><v-img :src="journalCoverURL"/></v-card>
                    <v-text-field v-model="journalCoverCheck" disabled label="Постер" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="coverDialog" width="600px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Постер нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="coverDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Киноны постер зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 1280, height: 720}" :canvas="{width: 1280, height: 720}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropCover">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="create_movie__title_block">
                    <v-text-field v-model="journalTitle" label="Нийтлэлийн нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-combobox v-model="journalTagValues" :items="journalTagItems" label="Сэтгүүлийн таг (Хайх тагаа бичнэ үү!)*" :search-input.sync="searchTag" chips multiple item-text="journal_tag_name" :return-object="false" :rules="[rules.choice]"></v-combobox>
                    <v-select v-model="journalType" :items="journalTypeItems" label="Төрөл*" item-text="type_mongol_name" :return-object="false" :rules="[rules.choice]"></v-select>
                    <v-select v-model="journalSubType" :items="journalSubTypeItems" label="Дэд төрөл*" item-text="type_mongol_name" :return-object="false" :rules="[rules.choice]"></v-select>
                </div>
            </div>
            <div class="create_movie__block_content">
                <tinymceEditor api-key="dh9tjnxmzhiub2bx0brpetqavxuw7z518330gl5p1kn1ivy6" v-model="journalContent" :init="customToolbar"/>
                <br>
                <CreateArtist ref="artists"/>
                <br>
                <CreateMovie ref="movies"/>
                <br>
                <CreateSerial ref="serials"/>
                <br>
                <v-card-actions class="justify-center">
                    <v-btn :disabled="checkValid" @click="validate" type="submit" class="create_movie__submit_btn" block>Хадгалах</v-btn>
                </v-card-actions>
            </div>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import { mapState } from 'vuex'
import Editor from '@tinymce/tinymce-vue'

import CreateArtist from '@/views/journal/create/CreateArtist.vue'
import CreateMovie from '@/views/journal/create/CreateMovie.vue'
import CreateSerial from '@/views/journal/create/CreateSerial.vue'

export default {
    components: {
        Cropper,
        CreateArtist,
        CreateMovie,
        CreateSerial,
        'tinymceEditor': Editor
    },
    data(){
        return{
            image: "",
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                choice: value => value.length > 0 || 'Сонгох шаардлагатай!',
                imageSize: value => !value || value.size < 1000000 || 'Зургийн хэмжээ 1 MB -с хэтрэхгүй байх шаардлагатай!',
                imageRequire: value => !!value || 'Зураг оруулах шаардлагатай!'
            },
            coverDialog: false,
            journalTitle: '',
            journalCover: null,
            journalCoverURL: null,
            journalCoverCheck: null,
            journalTypeItems: [],
            journalType: '',
            journalSubTypeItems: [],
            journalSubType: '',
            journalTagItems: [],
            journalTagValues: [],
            journalContent: '',
            searchTag: null,
            customToolbar: {
                height: 500,
                contextmenu: 'link image table',
                menu : {
                    file: {title: 'File', items: 'newdocument restoredraft | preview | print | deleteallconversations'},
                    edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace'},
                    view: {title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | fullscreen | showcomments'},
                    insert: {title: 'Insert', items: 'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'},
                    format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat'},
                    tools: {title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount'},
                    table: {title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable'}
                },
                images_file_types: 'jpg, jpeg',
                images_upload_handler: this.uploadContentImage,

                // text -nd зураг хуулахад бүтэн замыг зааж өгсөн
                relative_urls : false,
                remove_script_host : false,
                
                plugins: ['advlist autolink lists link image charmap print preview anchor searchreplace visualblocks fullscreen insertdatetime media table paste wordcount imagetools'],
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
            }
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true && this.journalContent){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user'])
    },
    watch:{
        searchTag(val){
            if(val){
                axios
                    .get('/journal/journal-tag-list/', { params: { tag: val }}).then(response => {this.journalTagItems = response.data.tag_list})
            }
        },
        journalType(){
            this.getSubTypes(this.journalType)
        }
    },
    methods:{
        validate(){
            this.$refs.form.validate()
        },
        getTypes(){
            axios
                .get('/journal/journal-type-api-list/').then(response => {this.journalTypeItems = response.data.journal_type_list})
        },
        getSubTypes(typeName){
            axios
                .get('/journal/journal-subtype-api-list/', {params: {journalType: typeName}}).then(response => {this.journalSubTypeItems = response.data.journal_type_list})
        },
        cropCover(){
            const { canvas } = this.$refs.cropper.getResult()
            if(canvas){
                canvas.toBlob((blob) => { this.journalCover = blob }, "image/jpeg")
                this.journalCoverURL = canvas.toDataURL("jpeg")
                this.journalCoverCheck = 'journal-cover'
                this.coverDialog = false
                this.image = ''
            }
        },
        uploadContentImage(blobInfo, success){
            var image = blobInfo.blob()
            if(image['type']==='image/jpeg'){
                var formData = new FormData()
                formData.append("image", image, "content-image")

                axios
                    .post('/user/api/v-user-useraddimage/', formData)
                    .then(result => {
                        const url = result.data.url
                        success(url)
                    })
                    .catch(err => {
                    })
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])

                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        submitCreate(){
            var artists = this.$refs.artists.artistList
            var movies = this.$refs.movies.movieList
            var serials = this.$refs.serials.serialList

            const createItems = {
                'title': this.journalTitle,
                'type': this.journalType,
                'sub_type': this.journalSubType,
                'tags': JSON.stringify(this.journalTagValues),
                'artists': JSON.stringify(artists),
                'movies': JSON.stringify(movies),
                'serials': JSON.stringify(serials),
                'content': this.journalContent
            }

            const createData = new FormData()

            if(this.journalCover){
                createData.append('journal-cover', this.journalCover, 'journal-cover')
            }

            for( var key in createItems ){
                createData.append(key, createItems[key])
            }

            axios
                .post('/journal/api-journal-create/', createData)
                .then((response) => {
                        if(response){
                            this.$router.push({ name: 'journal-list' })
                            this.$store.commit('SET_SNACK', {message: 'Нийтлэл амжилттай нэмэгдлээ, сайтын админ шалгаад нийтлэх болно!', color: 'green'})
                        }
                    })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү! ' + error.response.data['message'], color: 'red'})
                    }
                })
        }
    },
    created(){
        this.getTypes()
        document.title = 'шинээр нийтлэл нэмэх'
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>