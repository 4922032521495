<template>
    <div class="not_found__box">
        <h1>Хуудас олдсонгүй!</h1>
        <p><router-link class="not_found__no_decoration" :to="{ name: 'home' }">Нүүр хуудсанд очих?</router-link></p>
    </div>
</template>

<script>
export default {
    name: 'NotFound404',
    created(){
        document.title = 'Хуудас олдсонгүй!'
    }
}
</script>

<style scoped>

.not_found__box {
    margin: 15vh;
    text-align: center;
}

.not_found__no_decoration {
    text-decoration: none;
    font-weight: 600;
    font-size: 1.2em;
}

</style>