<template>
    <div>
        <div class="festival_detail__box">
            <!-- ene error bugdiig ni haachaad bna -->
            <!-- <span v-if="!error"> -->
                <div class="ma-2 text-center">
                    <h2><strong class="festival_detail__title">GGIFF</strong> {{ year }}</h2>
                    <div class="festival_detail__desc_box">
                        <img class="rounded festival_detail__img" :src="baseUrl + image"/>
                        <div class="festival_detail__desc" v-html="description"></div>
                    </div>
                </div>
                <v-divider v-show="winnerMovies.length" class="mx-2"></v-divider>
                <div v-for="(winner, index) in winnerMovies" :key="index">
                    <div class="festival_detail__award">{{ winner.award }}</div>
                    <router-link class="festival-detail__no_decoration" :to="{ name: 'movie-detail', params: { slug: winner.slug }}">
                        <v-card class="festival_detail__winner_card ma-2 pa-0">
                            <v-img :src="baseUrl + winner.image" class="ma-0 festival_detail__movie_still"/>
                            <v-img :src="baseUrl + winner.poster" class="ma-0 festival_detail__movie_poster"/>
                            <div class="festival-detail__winner_block">
                                <div class="festival-detail__winner_name">
                                    {{ winner.name }}
                                </div>
                                <div class="festival-detail__winner_director" v-if="winner.director.length">
                                    Найруулагч:
                                    <span v-for="(dir, index) in winner.director" :key="index">
                                        {{ dir.name }}<span v-show="index + 1 < winner.director.length">, </span>
                                    </span>
                                </div>
                            </div>
                        </v-card>
                    </router-link>
                </div>
                <div class="festival_detail__top_margin" v-show="nominatedMovies.length">
                    <v-divider class="mx-2"></v-divider>
                    <h2 class="ml-2 festival_detail__title">{{ name }}</h2>
                    <div class="festival-detail__row">
                        <v-card v-for="(movie, index) in nominatedMovies" :key="index">
                            <router-link class="festival-detail__no_decoration" :to="{ name: 'movie-detail', params: { slug: movie.slug } }">
                                <v-img :src="baseUrl + movie.image" class="rounded ma-0"/>
                                <v-card-title class="festival-detail__nominee_name">
                                    {{ movie.name }}
                                </v-card-title>
                            </router-link>
                        </v-card>
                    </div>
                </div>
                <span v-for="(nom, index) in allNominees" :key="index">
                    <v-divider class="mx-2"></v-divider>
                    <h2 class="ml-2 festival_detail__title">{{ nom.nominee_name }}</h2>
                    <div v-show="nom" class="festival-detail__row">
                        <v-card v-for="(movie, index) in nom.movies" :key="index">
                            <router-link class="festival-detail__no_decoration" :to="{ name: 'movie-detail', params: { slug: movie.slug } }">
                                <v-img :src="baseUrl + movie.image" class="rounded ma-0"/>
                                <v-card-title class="festival-detail__nominee_name">
                                    {{ movie.name }}
                                </v-card-title>
                            </router-link>
                        </v-card>
                    </div>
                </span>
            <!-- </span> -->
        </div>
        <div v-if="user.isAuthenticated && user.admin" class="subadmin-bar">
            <router-link class="subadmin-edit" :to="{ name: 'annual-award-update', params: { slug: slug, year: year } }"><v-icon style="color: #FFFFFF;">mdi-lead-pencil</v-icon></router-link>
            <router-link class="subadmin-edit-image" :to="{ name: 'annual-award-update', params: { slug: slug, year: year } }"><v-icon style="color: #FFFFFF;">mdi-image-edit-outline</v-icon></router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    props: ['slug', 'year'],
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            description: '',
            image: '',
            nominatedMovies: [],
            notNominatedMovies: [],
            winnerMovies: [],
            name: '',
            notNomName: '',
            error: '',

            allNominees: []
        }
    },
    computed:{
        ...mapState(['user'])
    },
    methods:{
        styles(i){
            return {
                'position': 'absolute',
                'top': 0,
                'right': 0,
                'width': '100%',
                'height': '100%',
                'background-color': i ? '#FFBF00' : '#0066b5',
                'clip-path': 'polygon(69% 0, 100% 0, 100% 100%, 63% 100%)'
            }
	    },
        getFestivalDetail(){
            axios
                .get('/award/annual-detail/', { params: { slug: this.slug, year: this.year }})
                .then(response => { this.image = response.data.image, this.description = response.data.description })
        },
        getNominatedMovies(){
            axios
                .get('/award/annual-nominated-movie-list/', { params: { slug: this.slug, year: this.year }})
                .then(response => { this.name = response.data.nominee_name, this.nominatedMovies = response.data.nominated_movies })
                .catch(error => {
                        this.error = error.response.data.message
                    })
        },
        getNotNominatedMovies(){
            axios
                .get('/award/annual-notnominated-movie-list/', { params: { slug: this.slug, year: this.year }})
                .then(response => { this.allNominees = response.data.all_nominees })
                .catch(error => {
                        this.error = error.response.data.message
                    })
        },
        getWinnerMovies(){
            axios
                .get('/award/annual-winner-movie-list/', { params: { slug: this.slug, year: this.year }})
                .then(response => { this.winnerMovies = response.data.winner_movies })
        }
    },
    created(){
        document.title = 'Кино наадам'
        this.getNominatedMovies()
        this.getNotNominatedMovies()
        this.getWinnerMovies()
        document.title = 'Кино наадам'
        this.getFestivalDetail()
    },
    watch:{
        year(){
            window.scrollTo(100,100)
            this.getFestivalDetail()
        }
    }
}
</script>

<style>

.festival_detail__top_margin {
    margin-top: 4em;
}

.festival_detail__box {
    min-width: 320px;
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
}

.festival_detail__title {
    color: #A9A9A9;
}

.festival_detail__name {
    color: #0066b5;
    font-size: 48px;
    font-weight: 400;
}

.festival_detail__desc_box {
    text-align: center;
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.festival_detail__img {
    margin: auto;
    flex: 50%;
    width: 100%;
    max-width: 320px;
}

.festival_detail__desc {
    margin: 0 20px;
    text-align: left;
    flex: 50%;
    font-size: 28px;
    color: #696969;
}

.festival_detail__award {
    margin: 2em 1em 0;
    color: darkgoldenrod;
    font-size: 28px;
    text-transform: uppercase;
    word-wrap: break-word;
}

.festival_detail__winner_card {
    border: 2px solid darkgoldenrod !important;
}

.festival-detail__winner_name {
    margin: 5px 15px 0;
    font-size: 2em;
}

.festival-detail__winner_director {
    color: #696969;
    margin: 5px 15px 20px;
    font-size: 1em;
}

.festival-detail__nominee_name {
    color: #000;
    font-size: 1.2em;
}

.festival-detail__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    margin: 0.5em auto 4em;
}

.festival-detail__no_decoration {
    text-decoration: none;
}

.festival_detail__movie_still {
    display: none;
}

/********************** SUBADMIN STICKY NAV *************************/

.subadmin-bar {
    z-index: 2;
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.subadmin-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
}

.subadmin-bar a:hover {
    background-color: #000;
}

.subadmin-unpublished {
    width: 70px;
    height: 70px;
    background: #3B5998;
    color: white;
}

.subadmin-published {
    width: 70px;
    height: 70px;
    background: #008000;
    color: white;
}

.subadmin-edit {
    width: 70px;
    height: 70px;
    background: #e5e500;
    color: white;
}

.subadmin-edit-image {
    width: 70px;
    height: 70px;
    background: #FF4500;
    color: white;
}

@media (min-width: 481px) {

    .festival_detail__box {
        max-width: 1280px;
    }

    .festival_detail__desc_box {
        flex-direction: row;
    }

    .festival-detail__row {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .festival-detail__winner_block {
        display: block;
        background-color: rgba(0,0,0, 0.5);
        position: absolute;
        bottom: 50px;
        right: 0;
        padding: 10px 40px;
        text-transform: uppercase;
        text-align: right;
        border-right: 10px solid #5C9DD0;
        line-height: 1.2;
    }

    .festival_detail__winner_card {
        border-radius: 0 !important;
        position: relative;
    }

    .festival-detail__winner_name {
        color: #FFF;
        font-size: 2.5em;
    }

    .festival-detail__winner_director {
        color: #d4d4d4;
    }

    .festival_detail__movie_still {
        display: block;
    }

    .festival_detail__movie_poster {
        display: none;
    }

}
</style>