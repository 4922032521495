<template>
    <v-container style="max-width: 800px;">
        <h1 style="text-align: center; color: #A9A9A9; text-transform: uppercase; margin: 20px;">{{ aboutTitle }}</h1>
        <p style="width: 800px;  text-align: justify; text-justify: inter-word;" v-html="aboutContent"></p>
        <span v-for="idx in 3" :key="idx">
            <div v-if="idx === 1" style="text-align: center; color: #A9A9A9; text-transform: uppercase; margin: 40px; font-size: 28px;">Монголын кино институтийн удирдлага</div>
            <div v-else-if="idx === 2" style="text-align: center; color: #A9A9A9; text-transform: uppercase; margin: 40px; font-size: 28px;">Монголын кино институтийн элчин сайдууд</div>
            <div v-else-if="idx === 3" style="text-align: center; color: #A9A9A9; text-transform: uppercase; margin: 40px; font-size: 28px;">Монголын кино институтийн зөвлөхүүд</div>
            <v-row>
                <v-col class="d-flex flex-wrap">
                    <v-card v-for="(member, index) in category(idx)" :key="index" class="ma-1" max-width="250">
                        <v-img height="250" :src="member.memberImageURL"></v-img>
                        <v-card-title>{{ member.memberName }}</v-card-title>
                        <v-card-text>
                            <div class="my-4">{{ member.memberTitle }}</div>
                            <v-divider v-if="member.memberDescription" class="mx-4"></v-divider>
                            <div class="my-4 text-subtitle-1">{{ member.memberDescription }}</div>
                        </v-card-text>
                        <v-card-text class="align-end">
                            <router-link v-if="member.memberArtistSlug" style="text-decoration: none;" :to="{ name: 'artist-detail', params: { slug: member.memberArtistSlug } }"><v-icon color="#0066b5">mdi-drama-masks</v-icon></router-link>
                            <router-link v-if="member.memberUserSlug" style="text-decoration: none;" :to="{ name: 'user-feed', params: { slug: member.memberUserSlug } }"><v-icon color="#0066b5">mdi-account</v-icon></router-link>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </span>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            aboutTitle: '',
            aboutContent: '',
            members: []
        }
    },
    methods:{
        category(index){
            return this.members.filter(function(u) {
                return u.memberCategory === index
            })
        },
        getDetailAboutUs(){
            axios
                .get('/institute/detail-aboutus/')
                .then(response => {this.aboutTitle = response.data.aboutTitle, this.aboutContent = response.data.aboutContent, this.members = response.data.members})
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404'}) }})
        }
    },
    created(){
        this.getDetailAboutUs()
    }
    
}
</script>

<style scoped>
[v-cloak] {display: none}

.legendBar {
    border-bottom: #0066b5 1px solid; 
    font-size: 16px; 
    margin: auto; 
    width: 100%;
}

.legendBarJournal {
    border-bottom: #FFFFFF 1px solid; 
    font-size: 16px; 
    margin: auto; 
    width: 100%;
}

</style>