<template>
    <v-card class="create_movie__artist_card">
        <v-img :src="mediaUrl + '/' + image" class="rounded"/>
        <v-card-text class="pa-2">
            <strong>{{ name }}</strong>
        </v-card-text>
        <div class="create_movie__artist_del_btn">
            <v-btn class="mx-2" fab dark x-small color="red" v-on:click="$emit(emmiter)">
                <v-icon dark>mdi-minus</v-icon>
            </v-btn>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        type: String,
        artist: Object,
        movie: Object,
        serial: Object
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL
        }
    },
    computed:{
        image(){
            return this.type === 'artist' ? this.artist.photo : this.type === 'movie' ? this.movie.movie_poster : this.serial.serial_poster
        },
        name(){
            return this.type === 'artist' ? this.artist.pro_name : this.type === 'movie' ? this.movie.international_title : this.serial.international_title
        },
        emmiter(){
            return this.type === 'artist' ? 'delete-artist' : this.type === 'movie' ? 'delete-movie' : 'delete-serial'
        }
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>