<template>
    <div>
        <div class="create_movie__legend_bar">Архивын зураг нэмэх:</div>
        <v-card class="pa-2" outlined>
            <div class="create_movie__artist_block">
                <div class="create_movie__artist_search">
                    <v-img :src="movieImageURL" class="ma-2 mx-auto" contain :max-width="imageWidth"/>
                    <v-file-input type="file" accept="image/jpeg" label="Архивын зураг нэмэх" @change="onImageSelected" @click:clear="movieImageURL = null"></v-file-input>
                </div>
                <div class="create_movie__artist_role">
                    <v-textarea v-model="imageDescription" label="Зургийн тайлбар"></v-textarea>
                </div>
                <v-btn color="primary" block @click="addImage()">Зураг нэмэх</v-btn>
            </div>
            <v-card-text>
                <div class="create_movie__toggle_btn">
                    <v-btn-toggle v-if="movieImages != ''" mandatory dense color="#0066b5">
                        <v-btn @click="layout = 'list'"><v-icon class="fas fa-th-list">mdi-view-list</v-icon></v-btn>
                        <v-btn @click="layout = 'grid'"><v-icon class="fas fa-th">mdi-view-grid</v-icon></v-btn>
                    </v-btn-toggle>
                </div>
                <div v-show="movieImages.length === 0" class="generic-list__row_img_wide">
                    <v-card v-for="index in 3" :key="index" class="create_movie__archive_image_mock">

                    </v-card>
                </div>
                <draggable v-show="movieImages" :class="layout === 'grid' ? 'generic-list__row_img_wide' : 'generic-list__row_img'" v-model="movieImages" tag="ul" v-bind="dragOptions" @start="drag = true" @end="drag = false">
                    <v-card v-for="(image, index) in movieImages" :key="index" class="ma-1">
                        <div class="create_movie__archive_image_box">
                            <div class="create_movie__archive_image_block">
                                <img :src="image.id ? baseUrl + image.image : image.image" class="rounded create_movie__archive_image"/>
                            </div>
                            <div v-if="layout !== 'grid'" class="create_movie__archive_image_txt">
                                <v-textarea v-model="image.description" label="Зургийн тайлбар"></v-textarea>
                            </div>
                            <div class="create_movie__del_btn">
                                <v-btn class="mx-2" fab dark x-small color="red" v-on:click="removeImage(index)"><v-icon dark>mdi-minus</v-icon></v-btn>
                            </div>
                        </div>
                    </v-card>
                </draggable>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState } from 'vuex'

export default {
    components: {
        draggable
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            movieImages: [],
            movieImage: null,
            movieImageURL: null,
            imageWidth: 0,
            layout: 'list',
            imageDescription: ''
        }
    },
    computed:{
        dragOptions(){
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        },
        ...mapState(['user'])
    },
    methods:{
        onImageSelected(event){
            if(event && event['type']==='image/jpeg'){
                this.imageWidth = 250
                this.movieImage = event
                this.movieImageURL = window.URL.createObjectURL(event)
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        addImage(){
            if(this.movieImage){
                this.movieImages.push({image: this.movieImageURL, file: this.movieImage, description: this.imageDescription})
            }
            this.movieImage = null
            this.movieImageURL = null
            this.imageDescription = ''
            this.imageWidth = 0
        },
        removeImage(index){
            this.movieImages.splice(index, 1)
        }
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>