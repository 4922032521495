<template>
    <div class="user-feed__card_card">
        <div :class="movieOrSerial ? 'user-feed__card_journal_cover_block' : 'user-feed__card_episode_cover_block'">
            <router-link :to="movieOrSerial ? { name: 'movie-detail', params: { slug: review.movie_slug }} : { name: 'episode-detail', params: { serialSlug: review.serial_slug ,seasonSlug: review.season_slug, episodeSlug: review.episode_slug }}">
                <img class="user-feed__card_journal_cover" :src="baseUrl + (movieOrSerial ? review.movie_poster : review.episode_image)"/>
            </router-link>
        </div>
        <div class="user-feed__card_journal_content">
            <div class="user-feed__card_movie">
                <span class="user-feed__episode_name_txt" v-show="movieOrSerial">{{ review.movie }}</span>
                <span v-show="!movieOrSerial">
                    <span class="user-feed__episode_name_txt">{{ review.serial }}</span> - {{ review.season }} - <span class="user-feed__episode_name_txt">{{ review.episode }}</span>
                </span>
            </div>
            <v-rating :value="parseInt(review.grade_review) * 0.05" :color="review.critic ? '#B29600' : '#0066b5'" :background-color="review.critic ? '#B29600' : '#0066b5'" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="24"></v-rating>
            <div class="user-feed__review">Үнэлгээ: {{ review.grade_review }} %</div>
            <v-divider class="my-2"></v-divider>
            <div class="user-feed__review">{{ review.word_review }} </div>
            <div class="user-feed__review_like_btn">
                <button @click="likeReview()">
                    <v-icon class="user-feed__review_like_icon">{{ reviewHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon> <small>{{ reviewCount }}</small>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        review: Object,
        index: Number
    },
    data(){
        return{
            routeName: this.$route.name,
            baseUrl: process.env.VUE_APP_BASEURL,
            emptyIcon: 'mdi-star-outline',
            fullIcon: 'mdi-star',
            halfIcon: 'mdi-star-half-full',
            userSlug: this.$store.state.user.slug,
            reviewHeart: '',
            reviewCount: 0
        }
    },
    watch:{
        index: {
            immediate: true,
            handler() {
                this.reviewHeart = this.review.user_like
                this.reviewCount = this.review.movie_review_likes
            }
        }
    },
    computed:{
        movieOrSerial(){
            return this.routeName === 'movie-reviews'
        }
    },
    methods:{
        likeReview(){
            if(this.reviewHeart == true){
                this.reviewHeart = false
                this.reviewCount--
            } else {
                this.reviewHeart = true
                this.reviewCount++
            }
            if (this.routeName === 'movie-reviews') {
                axios
                    .post('/user/api/v-user-moviereviewlike/', { user: this.userSlug, review_id: this.review.id, movie_id: this.review.movie_id })
            } else if (this.routeName === 'episode-reviews') {
                axios
                    .post('/user/api/v-user-episodereviewlike/', { user: this.userSlug, episode_id: this.review.episode_id, review_id: this.review.id})
            }

            
        }
    },
}
</script>

<style>

.user-feed__card_card {
    display: flex;
    flex-direction: column;
}

.user-feed__card_journal_cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.user-feed__card_journal_content {
    padding: 5px 10px;
    flex: 100%;
    width: 100%;
}

.user-feed__card_movie {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
}

.user-feed__review {
    color: #696969;
}

.user-feed__episode_name_txt {
    font-weight: 600
}

.user-feed__review_like_btn {
    text-align: right;
}

.user-feed__review_like_icon {
    font-size: 20px;
    color: #0066b5 !important;
}

@media (min-width: 481px) {

    .user-feed__card_card {
        display: flex;
        flex-direction: row;
    }

    .user-feed__card_journal_cover_block {
        flex: 25%;
    }

    .user-feed__card_episode_cover_block {
        flex: 35%
    }

    .user-feed__card_journal_content {
        flex: 75%;
    }
}

</style>