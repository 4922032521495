import { render, staticRenderFns } from "./UserImageCard.vue?vue&type=template&id=7c0678a6"
import script from "./UserImageCard.vue?vue&type=script&lang=js"
export * from "./UserImageCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports