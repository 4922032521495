<template>
    <v-card class="journal-card">
        <div class="mb-2" >
            <router-link :to="{ name: 'artist-detail', params: { slug: journal.title_slug } }">
                <img class="journalImage" :src="baseUrl + journal.journal_cover">
                <div class="text-on-image">
                    <strong>{{ journal.journal_type }}</strong>
                </div>
            </router-link>
            <div class="text-style">
                <div class="total-movie-style">
                    <div class="title-style">{{ journal.journal_title }}</div>
                </div>
                <div class="position-style">
                    <div class="left-position">
                        <div class="left-position-set">
                            <router-link :to="{name: 'user-feed', params: { slug: journal.author_slug }}">
                                <v-img class="rounded-xl" width="35" height="35" :src="baseUrl + journal.author_image"/>
                            </router-link>
                            <small class="user-name">{{ journal.journal_author }}</small>
                        </div>
                    </div>
                    <div class="right-position">
                        <v-btn style="color: #0066b5; font-size: 12px;" icon @click="likeJournal()" :disabled="!isAuth"><v-icon>{{ journalHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon> <span>{{ journalCount }}</span></v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        journal: Object
    },
    data(){
        return{
            isAuth: this.$store.state.user.isAuthenticated,
            baseUrl: process.env.VUE_APP_BASEURL,
            journalHeart: this.journal.user_like,
            journalCount: this.journal.total_likes
        }
    },
    methods:{
        likeJournal(){
            if(this.journalHeart == true){
                this.journalHeart = false
                this.journalCount--
            } else {
                this.journalHeart = true
                this.journalCount++
            }
            // axios
            //     .post('/user/api/v-user-movielistlike/', { list_id: this.list.id, list_user: this.list.user_slug })
        }
    }
}
</script>

<style scoped>

.journal-card {
    min-width: 280px;
    max-width: 480px;
}

.journalImage {
    display: block;
    object-fit: cover;
    width: 100%;
    border-radius: 4px;
}

.journalTitle {
    font-weight: bold;
    margin: 20px; 
    text-align: center; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    overflow: hidden; 
    font-size: 24px;
}

.text-on-image {
    position: absolute;
    color:white;
    background: rgba(0, 0, 0, 0.6);
    top: 15px;
    left: 15px;
    font-size: 12px;
}

.text-style {
    bottom: 0;
    padding: 5px;
    width: 100%;
}

.title-style {
    margin: auto;
    min-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.3rem;
}

.total-movie-style {
    text-align: center;
    font-weight: 600;
    color: #0066b5;
}

.position-style {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
}

.left-position {
    flex: 50%;
}

.left-position-set {
    display: flex;
    align-items: flex-end;;
}

.right-position {
    flex: 50%;
    text-align: right;
}

.user-name {
    margin-left: 5px;
    font-weight: bold;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.listCard {
    height: auto; 
    width: 300px; 
    background-color: rgba(0, 0, 0, 0); 
    border-color:rgba(0, 0, 0, 0);
}

</style>