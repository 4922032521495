<template>
    <v-container class="d-flex justify-center">
        <v-card class="ma-5" width="400" :disabled="$store.state.isLoading">
            <v-card-title class="justify-center">Мартсан нууц үг солих!</v-card-title>
            <div v-if="errors.length" style="text-align: center; color: red;">
                <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
            </div>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-row class="justify-center my-4">
                    <v-form ref="form" style="width: 300px;" v-model="formValid" v-on:submit.prevent="activate">
                        <v-text-field v-model="email" name="email" label="Имэйл хаяг" required :rules="[rules.required, rules.email]"></v-text-field>
                        <v-card-actions class="justify-center"><v-btn :disabled="checkValid" type="submit" style="color: white; background-color: #0066b5;">илгээх</v-btn></v-card-actions>
                    </v-form>
                    <v-snackbar v-model="snackbar" :color="color">{{ text }}</v-snackbar>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ForgotPassword',
    data(){
        return{
            
            formValid: false,
            email: '',  
            errors: [],
            snackbar: false,
            text: '',
            status: '',
            color: '',
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Та э-мэйл хаягаа зөв оруулна уу!'
                }
            }
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        }
    },
    methods:{
        async activate(){
            this.errors = []

            if(this.email === ''){
                this.errors.push("Та имэйл хаягаа оруулна оруулна уу!")
            }

            if(!this.errors.length){
                this.$store.commit('SET_ISLOADING', true)

                const emailData = {
                    email: this.email
                }

                await axios
                    .post('/v1/users/reset_password/', emailData)
                    .then( response => {
                        if(response.status === 204){
                            this.$store.commit('SET_SNACK', {message: 'Таны имэйл хаягруу нууц үг солих линк явууллаа. Та имэйлрүүгээ нэвтэрч линкээр нууц үгээ сэргээнэ үү!', color: 'success'})
                            this.$router.push('/login')
                        }
                    })
                    .catch(error => {
                        if(error.response.status === 400){
                            this.errors.push('Таны имэйл хаягаа зөв оруулна уу!')
                        } else if(error.message){
                            this.errors.push('Алдаа гарлаа, дахин оролдож үзнэ үү!')
                        }
                    })

                this.$store.commit('SET_ISLOADING', false)
            }
        }
    }
}
</script>
