<template>
    <div v-if="user.isAuthenticated && user.contributer" class="create_movie">
        <div class="create_movie__title">Та цувралын ангийг засах гэж байна!</div>
        <v-divider class="mx-4"></v-divider>
        <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitCreate">
            <div class="create_movie__block">
                <div class="create_movie__image_block">
                    <v-card width="240" height="360"><v-img :src="baseUrl + episode.serial_poster" class="rounded poster-size"/></v-card>
                    <v-text-field mx="2" v-model="episode.ser_title" disabled></v-text-field>
                    <v-card width="240" height="360"><v-img :src="baseUrl + episode.season_poster" class="rounded poster-size"/></v-card>
                    <v-text-field mx="2" v-model="episode.sea_title" disabled></v-text-field>
                </div>
                <div class="create_movie__title_block">
                    <v-card width="640" height="360" ><v-img :src="episodeBackgroundURL"/></v-card>
                    <v-text-field v-model="episodeBackgroundCheck" disabled label="Ангийн сцен зураг" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="backgroundDialog" width="800px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Сцен зураг нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="backgroundDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Киноны сцен зураг зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 1280, height: 720}" :canvas="{width: 1280, height: 720}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropBackground">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                    <v-text-field v-model="episodeNumber" label="Ангийн дугаар*" type="number" required :rules="[rules.number, rules.required]"></v-text-field>
                    <v-text-field v-model="episodeName" label="Ангийн нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="episodeRuntime" type="number" label="Үргэлжлэх хугацаа*" required :rules="[rules.number, rules.required]"></v-text-field>
                    <v-textarea v-model="episodePlot" label="Үйл явдлын товч" hint="Та 1000 тэмдэгтэнд багтааж бичнэ үү!" :rules="[rules.required, rules.plotLength]"></v-textarea>
                    <v-textarea v-model="episodePlotEng" label="Үйл явдлын товч (in English)" hint="Та 1000 тэмдэгтэнд багтааж бичнэ үү!"></v-textarea>
                </div>
            </div>
            <div class="create_movie__block_content">
                <v-card-actions class="justify-center"><v-btn :disabled="checkValid" @click="validate" type="submit" style="color: white; background-color: green;" block>Хадгалах</v-btn></v-card-actions>
            </div>
        </v-form>
        <v-divider class="mx-4 ma-5"></v-divider>
        <router-link class="update_movie__no_decoration" :to="{ name: 'episode-detail', params: { serialSlug: serialSlug, seasonSlug: seasonSlug, episodeSlug: episodeSlug }}">
            <v-btn class="update_movie__back_btn">Буцах</v-btn>
        </router-link>
    </div>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import { mapState } from 'vuex'
import 'vue-advanced-cropper/dist/style.css'

export default {
    components: {
        Cropper
    },
    props: {
        serialSlug: String,
        seasonSlug: String,
        episodeSlug: String
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                number: value => /^[0-9]+$/.test(value) || 'Та зөвхөн тоо оруулна уу!',
                imageSize: value => !value || value.size < 1000000 || 'Зургийн хэмжээ 1 MB -с хэтрэхгүй байх шаардлагатай!',
                plotLength: value => value.length <= 1000 || '1000 тэмдэгтээс хэтэрлээ!'
            },
            episode: {},
            image: '',
            episodeBackground: null,
            episodeBackgroundURL: null,
            episodeBackgroundCheck: null,
            backgroundDialog: false,
            episodeNumber: '',
            episodeName: '',
            episodeRuntime: 0,
            episodePlot: '',
            episodePlotEng: ''
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user']),
    },
    methods:{
        cropBackground() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.episodeBackground = blob }, "image/jpeg")
            this.episodeBackgroundURL = canvas.toDataURL("jpeg")
            this.episodeBackgroundCheck = 'episode-background'
            this.backgroundDialog = false
            this.image = ''
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])

                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        validate(){
            this.$refs.form.validate()
        },
        submitCreate(){
            const updateItems = {
                'serialSlug': this.serialSlug,
                'seasonSlug': this.seasonSlug,
                'episodeSlug': this.episodeSlug,
                'episode_number': this.episodeNumber,
                'episode_name': this.episodeName,
                'episode_runtime': this.episodeRuntime,
                'plot': this.episodePlot,
                'en_plot': this.episodePlotEng
            }

            const updateData = new FormData()

            if(this.episodeBackground){
                updateData.append('episode-background', this.episodeBackground, 'episode-background')
            }

            for( var key in updateItems ){
                updateData.append(key, updateItems[key])
            }

            axios
                .post('/serial/api-update-episode/', updateData)
                .then((response) => {
                    if(response){
                        this.$store.commit('SET_SNACK', {message: 'Анги амжилттай засагдлаа!', color: 'green'})
                        this.$router.push({name: 'season-detail', params: {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug}})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        },
        getEpisodeDetail(){
            axios
                .get('/serial/api-episode-detail/', {params: {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug, episodeSlug: this.episodeSlug}})
                .then(response =>   {   this.episode = response.data.episode_detail,
                                        this.episodeNumber = this.episode['e_num'],
                                        this.episodeName = this.episode['e_title'],
                                        this.episodeRuntime = this.episode['runtime'],
                                        this.episodePlot = this.episode['plot'],
                                        this.episodePlotEng = this.episode['eng_plot'],
                                        this.episodeBackgroundURL = this.baseUrl + this.episode['background'],
                                        this.episodeBackgroundCheck = 'episode-background'})
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404'}) }})
        },
    },
    created(){
        document.title = 'Анги засах'
        this.getEpisodeDetail()
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>
