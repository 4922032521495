<template>
    <v-container class="d-flex justify-center">
        <v-card v-if="activateUser" class="ma-5" width="400" :disabled="isLoading">
            <v-card-title class="justify-center">Хэрэглэгчийн бүртгэл идэвхжүүлэх</v-card-title>
            <div v-if="errors.length" style="text-align: center; color: red;">
                <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
            </div>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-row class="justify-center my-4">
                    <v-form ref="form" style="width: 300px;" v-model="formValid" v-on:submit.prevent="reActivateCode">
                        <small style="max-width: 200px; font-weight: bold; text-align: center;">
                            <p style="margin: 10px; font-style: italic;">* Та өөрийн бүртгүүлсэн хэрэглэгчийн нэр, нууц үг, утасны дугаараа оруулна уу!</p>
                        </small>
                        <v-text-field  v-model="username" name="username" label="Хэрэглэгчийн нэр" required :rules="[rules.required, rules.nameCheck]"></v-text-field>
                        <v-text-field v-model="password" name="password" label="Нууц үг" required type="password" :rules="[rules.required]"></v-text-field>
                        <v-text-field v-model="phone" name="phone" label="Утасны дугаар" required :rules="[rules.required, rules.phoneRule]"></v-text-field>
                        <v-card-actions class="justify-center"><v-btn :disabled="checkValid" type="submit" style="color: white; background-color: #0066b5;">идэвхжүүлэх</v-btn></v-card-actions>
                    </v-form>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card v-else class="ma-5" width="400" :disabled="isLoading">
            <v-card-title class="justify-center">Бүртгэл идэвхжүүлэх</v-card-title>
            <div v-if="errors.length" style="text-align: center; color: red;">
                <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
            </div>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-row ref="form" class="justify-center my-4">
                    <v-form style="width: 300px;" v-model="activateFormValid" v-on:submit.prevent="activate">
                        <v-text-field v-model="phone" name="phone" label="Утасны дугаар" required disabled></v-text-field>
                        <div style="text-align: center; margin: 10px;">
                            <span v-show="!codeResend" style="font-style: italic;">Та {{ countDown }} секундын дараа дахин код авах боломжтой!</span>
                            <span v-show="codeResend"><v-btn x-small @click="resendCode()">Дахин код авах</v-btn></span>
                        </div>
                        <span>Нэг удаагийн код оруулах</span>
                        <v-otp-input v-model="code" length="6" @keypress="filter($event)" :rules="[rules.required, rules.codeLength]"></v-otp-input>
                        <v-card-actions class="justify-center"><v-btn :disabled="checkActivateValid" type="submit" color="success">код илгээх</v-btn></v-card-actions>
                    </v-form>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Activate',
    data(){
        return{
            formValid: false,
            activateFormValid: false,
            countDown : 60,
            count: 0,
            codeResend: false,
            username: '',
            phone: '',
            code: '',
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                nameCheck: value => value.length >= 3 && value.length <= 20 && /^[0-9a-zA-Z]+$/.test(value) || 'Та нэрээ зөв оруулна уу!',
                phoneRule: value => !isNaN(parseFloat(value)) && value >= 10000000 && value <= 99999999 || 'Утасны дугаараа зөв оруулна уу!',
                codeLength: value => value.length == 6
            },
            password: '',
            errors: [],
            status: ''
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        checkActivateValid(){
            if(this.activateFormValid == true){
                return false
            } else {
                return true
            }
        },
        ...mapState(['activateUser', 'isLoading', 'error'])
    },
    watch:{
        error(){
            this.errors.push(this.error)
        }
    },
    methods:{
        filter: function(evt) {
            evt = (evt) ? evt : window.event
            let expect = evt.target.value.toString() + evt.key.toString()
            if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        countDownTimer(){
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            } else {
                this.codeResend = !this.codeResend
                this.count = this.count + 60
                this.countDown = this.count
            }
        },
        resendCode(){
            this.errors = []

            if(this.phone === ''){
                this.errors.push("Та утасны дугаараа оруулна уу!")
            }

            if(!this.errors.length){
                this.$store.commit('SET_ISLOADING', true)
                this.$store.dispatch('resendCode', { phone: this.phone }).then(() => { this.$store.commit('SET_ISLOADING', false) })
                this.codeResend = !this.codeResend
                this.countDownTimer()
                this.code = ''
            }
        },
        activate(){
            this.errors = []

            if(this.phone === ''){
                this.errors.push("Та утасны дугаараа оруулна уу!")
            }

            if(this.code === ''){
                this.errors.push("Та код оруулна уу!")
            }

            if(!this.errors.length){
                this.$store.commit('SET_ISLOADING', true)
                this.$store.dispatch('userActivate', { code: this.code, phone: this.phone }).then(() => { this.$store.commit('SET_ISLOADING', false) })
            }
        },
        reActivateCode(){
            this.errors = []
        
            if(this.username === ''){
                this.errors.push("Та хэрэглэгчийн нэр оруулна уу!")
            } else if(this.username.length <= 3 && this.username.length >= 20){
                this.errors.push("Та хэрэглэгчийн нэрээ зөв оруулна уу!")
            }

            if(this.phone === ''){
                this.errors.push("Та утасны дугаараа оруулна уу!")
            } else if(this.phone.length != 8 || this.phone <= 10000000 && this.phone >= 99999999){
                this.errors.push("Та утасны дугаараа зөв оруулна уу!")
            }

            if(this.password === ''){
                this.errors.push("Та хэрэглэгчийн нууц үг оруулна уу!")
            }

            if(!this.errors.length){
                this.$store.commit('SET_ISLOADING', true)
                this.$store.dispatch('reActivateCode', { username: this.username, password: this.password, phone: this.phone }).then(() => { this.$store.commit('SET_ISLOADING', false) })
                this.countDownTimer()
            }
        }
    },
    created(){
        this.$store.commit('SET_ACTIVATE', false)
        this.$store.commit('SET_PHONE_ACTIVATE', false)
    }
}
</script>
<style scoped>

.pass_required{
    text-decoration: line-through;
    color:#689868;
}

</style>