<template>
    <div class="generic-list__box">
        <div class="generic-list__title">{{ movieListTitle }} <span class="generic-list__title__total">{{ total }}</span> кино</div>
        <v-card class="mb-2" elevation="0" outlined>
            <v-tabs color="light-blue darken-3 accent-4" fixed-tabs>
                <v-tab><v-icon>mdi-movie-search</v-icon></v-tab>
                <v-tab><v-icon>mdi-sort</v-icon></v-tab>
                <v-tab><v-icon>mdi-filter</v-icon></v-tab>
                <v-tab-item :key="1">
                    <div class="generic-list__tab_block">
                        <v-text-field label="Кино хайлт" v-model="movieSearch" @keyup.native.enter="submitFilter(true, pageLoad = false)">
                            <template v-slot:append> 
                                <v-btn class="generic-list__search_btn ma-1" rounded color="light-blue darken-3" dark type="submit" @click="submitFilter(true, pageLoad = false)"><v-icon>mdi-magnify</v-icon></v-btn>
                            </template>
                        </v-text-field>
                    </div>
                </v-tab-item>
                <v-tab-item :key="2">
                    <div class="generic-list__tab_block">
                        <v-select :items="movieSortItems" label="Үзүүлэх дараалал" v-model="movieSort" @change="submitFilter(true, pageLoad = false)"></v-select>
                    </div>
                </v-tab-item>
                <v-tab-item :key="3">
                    <div class="generic-list__tab_block">
                        <form v-on:submit.prevent="submitFilter(true, pageLoad = false)">
                            <div class="generic-list__filter_box">
                                <div class="generic-list__filter_block">
                                    <v-card class="ma-1 pa-2" elevation="0" outlined>
                                        <v-select v-show="routeName === 'movie-list' || routeName === 'short-movie-list'" :items="movieCountryItems" label="Улс" v-model="movieCountry" item-text="country_mongol_name" :return-object="false" item-value="id"></v-select>
                                        <v-select :items="movieFormatItems" label="Киноны формат" v-model="movieFormat"></v-select>
                                        <v-select :items="movieRatingItems" label="Киноны рэйтинг" v-model="movieRating"></v-select>
                                        <v-combobox v-show="routeName === 'movie-list' || routeName === 'short-movie-list'" v-model="movieLanguageValue" :items="movieLanguageItems" label="Киноны хэл" multiple item-text="language_mongol_name" :return-object="false"></v-combobox>
                                        <v-combobox v-model="movieGenreValue" :items="movieGenreItems" label="Киноны жанр" multiple item-text="genre_mongol_name" :return-object="false"></v-combobox>
                                        <v-combobox v-model="movieSubGenreValue" :items="movieSubGenreItems" label="Киноны дэд жанр" multiple item-text="sub_genre_mongol_name" :return-object="false"></v-combobox>
                                        <v-combobox v-model="movieTagValue" :items="movieTagItems" label="Киноны таг (Хайх тагаа бичнэ үү!)" :search-input.sync="searchTag" item-text="tag_name" chips multiple :return-object="false"></v-combobox>
                                    </v-card>
                                </div>
                                <div class="generic-list__filter_block">
                                    <v-card class="ma-1 pa-2" elevation="0" outlined>
                                        <v-subheader>Киноны үнэлгээ</v-subheader>
                                        <v-range-slider v-model="movieGradeValue" :min="movieMinGradeValue" :max="movieMaxGradeValue" hide-details class="align-center" @mouseup="movieGrade()">
                                            <template v-slot:prepend>
                                                <v-text-field :value="movieGradeValue[0]" class="generic-list__filter__style" hide-details single-line type="number" @change="$set(movieGradeValue, 0, $event)"></v-text-field>
                                            </template>
                                            <template v-slot:append>
                                                <v-text-field :value="movieGradeValue[1]" class="generic-list__filter__style" hide-details single-line type="number" @change="$set(movieGradeValue, 1, $event)"></v-text-field>
                                            </template>
                                            </v-range-slider>
                                            <v-subheader>Киноны он</v-subheader>
                                            <v-range-slider v-model="movieYearValue" :max="movieMaxYearValue" :min="movieMinYearValue" hide-details class="align-center" @mouseup="movieYear()">
                                            <template v-slot:prepend>
                                                <v-text-field :value="movieYearValue[0]" class="generic-list__filter__style" hide-details single-line type="number" @change="$set(movieYearValue, 0, $event)"></v-text-field>
                                            </template>
                                            <template v-slot:append>
                                                <v-text-field :value="movieYearValue[1]" class="generic-list__filter__style" hide-details single-line type="number" @change="$set(movieYearValue, 1, $event)"></v-text-field>
                                            </template>
                                            </v-range-slider>
                                            <v-subheader>Киноны үргэлжлэх хугацаа</v-subheader>
                                            <v-range-slider v-model="movieRuntimeValue" :min="movieMinRuntimeValue" :max="movieMaxRuntimeValue" hide-details class="align-center" @mouseup="movieRuntime()">
                                            <template v-slot:prepend>
                                                <v-text-field :value="movieRuntimeValue[0]" class="generic-list__filter__style" hide-details single-line type="number" @change="$set(movieRuntimeValue, 0, $event)"></v-text-field>
                                            </template>
                                            <template v-slot:append>
                                                <v-text-field :value="movieRuntimeValue[1]" class="generic-list__filter__style" hide-details single-line type="number" @change="$set(movieRuntimeValue, 1, $event)"></v-text-field>
                                            </template>
                                        </v-range-slider>
                                    </v-card>
                                    <br>
                                    <div class="d-flex flex-wrap justify-center">
                                        <v-btn large color="#0066b5" dark type="submit" class="ma-1">Филтэрдэх</v-btn>
                                        <v-btn large color="#ffcc00" dark class="ma-1" @click="resetFilter(null)">Ресетлэх</v-btn>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </v-tab-item>
            </v-tabs>
        </v-card>
        <div class="generic-list__row" id="router_view">
            <ListCard v-for="(movie, index) in movies" :key="index" :movie="movie"/>
        </div>
        <div class="text-center mt-2">
            <v-pagination v-model="page" :length="moviePage" @input="submitFilter(false, pageLoad = false)" :total-visible="screenWidth"></v-pagination>
        </div>
    </div>
</template>

<script>
import ListCard from '@/components/movie/ListCard.vue'
import axios from 'axios'

export default {
    components: {
        ListCard
    },
    data(){
        return{
            routeName: this.$route.name,
            published: false,
            movieListTitle: '',
            movieSearch: '',
            movieSortItems: [{text: 'Эхэнд нэмэгдсэнийг эхэнд', value: 1}, {text: 'Эхэнд нэмэгдсэнийг сүүлд', value: 2}, {text: 'Өндөр үнэлгээтэйг эхэнд', value: 3}, {text: 'Өндөр үнэлгээтэйг сүүлд', value: 4}, {text: 'Их үзэгчтэйг эхэнд', value: 5}, {text: 'Их үзэгчтэйг сүүлд', value: 6}, {text: 'Цагаан толгойн дарааллаар', value: 7}, {text: 'Цагаан толгойн эсрэг дарааллаар', value: 8}],
            movieSort: 0,
            movieCountryItems: [],
            movieCountry: 0,
            movieFormatItems: [{text: 'Уран сайхны кино', value: 1}, {text: 'Баримтат кино', value: 2}, {text: 'Хүүхэлдэйн кино', value: 3}, {text: 'Эксперименталь', value: 4}],
            movieFormat: 0,
            movieRatingItems: [{text: 'Not Rated', value: 1}, {text: 'General Audiences', value: 2}, {text: 'Parental Guidance Suggested', value: 3}, {text: 'Restricted', value: 4}],
            movieRating: 0,
            movieLanguageValue: [],
            movieLanguageItems: [],
            movieGenreValue: [],
            movieGenreItems: [],
            movieSubGenreValue: [],
            movieSubGenreItems: [],
            movieTagValue: [],
            movieTagItems: [],
            searchTag: null,
            movieMinGradeValue: 0,
            movieMaxGradeValue: 100,
            movieGradeValue: [0, 100],
            movieGradeFlag: 0,
            movieMinYearValue: 1900,
            movieMaxYearValue: 2023,
            movieYearValue: [1900, 2023],
            movieYearFlag: 0,
            movieMinRuntimeValue: 0,
            movieMaxRuntimeValue: 200,
            movieRuntimeValue: [0, 200],
            movieRuntimeFlag: 0,
            movies: [],
            total: null,
            page: 1,
            moviePage: 0,
            parameters: {},
            pageLoad: true
        }
    },
    computed: {
        screenWidth(){
            var w = window.innerWidth
            return w < 481 && w > 319 ? 5 : 7
        },
        multpleProps() {
            return `${this.$route.query.page}|${this.$route.query.search}|${this.$route.query.sort}|${this.$route.query.country}|${this.$route.query.format}|${this.$route.query.rating}|${this.$route.query.lang}|${this.$route.query.genre}|${this.$route.query.subgenre}|${this.$route.query.tag}|${this.$route.query.grade}|${this.$route.query.year}|${this.$route.query.runtime}`
        }
    },
    watch:{
        multpleProps(){
            this.submitFilter()
        },
        searchTag(val){
            if(val){
                axios
                    .get('/tag/api-tag-list/', { params: { tag: val }}).then(response => {this.movieTagItems = response.data.tag_list})
            }
        },
        '$route.name'(){
            this.resetFilter(this.$route.name)
        },
        movieGenreValue(val){
            if (val.length > 5) {
                this.$nextTick(() => this.movieGenreValue.pop())
            }
        },
        movieLanguageValue(val){
            if (val.length > 5) {
                this.$nextTick(() => this.movieLanguageValue.pop())
            }
        },
        movieSubGenreValue(val){
            if (val.length > 5) {
                this.$nextTick(() => this.movieSubGenreValue.pop())
            }
        },
        movieTagValue(val){
            if (val.length > 5) {
                this.$nextTick(() => this.movieTagValue.pop())
            }
        }
    },
    methods:{
        resetFilter(route){
            this.movieSearch = ''
            this.movieSort = 0
            this.movieCountry = 0
            this.movieFormat = 0
            this.movieRating = 0
            this.movieLanguageValue = []
            this.movieGenreValue = []
            this.movieSubGenreValue = []
            this.movieTagValue = []
            this.movieGradeValue = [0, 100]
            this.movieGradeFlag = 0
            this.movieYearValue = [1900, 2023]
            this.movieYearFlag = 0
            this.movieRuntimeValue = [0, 200]
            this.movieRuntimeFlag = 0
            this.movies = []
            this.page = 1
            if (route !== null) this.routeName = route
            this.pageLoad = false
            this.submitFilter()
        },
        movieGrade(){
            this.movieGradeFlag = 1
        },
        movieYear(){
            this.movieYearFlag = 1
        },
        movieRuntime(){
            this.movieRuntimeFlag = 1
        },
        getLanguage(){
            axios
                .get('/language/api-language-list/').then(response => {this.movieLanguageItems = response.data.language_list})
        },
        getGenres(){
            axios
                .get('/genre/api-genre-list/').then(response => {this.movieGenreItems = response.data.genre_list})
        },
        getSubGenres(){
            axios
                .get('/genre/api-subgenre-list/').then(response => {this.movieSubGenreItems = response.data.subgenre_list})
        },
        getProductionCountries(){
            axios
                .get('/country/api-production-country-list/').then(response => {this.movieCountryItems = response.data.country_list})
        },
        submitFilter(pageAssign){
            setTimeout(function() {
                var el = document.getElementById('router_view')
                if(typeof el !== "undefined" && el !== null) {
                    el.scrollIntoView({ behavior: "smooth" })
                }
            }, 500)

            if (pageAssign === true) this.page = 1

            let currentPage = this.$route.query.page
            let currentSort = this.$route.query.sort
            let currentCountry = this.$route.query.country
            let currentFormat = this.$route.query.format
            let currentRating = this.$route.query.rating
            let currentLang = this.$route.query.lang ? JSON.parse(this.$route.query.lang) : []
            let currentGenre = this.$route.query.genre ? JSON.parse(this.$route.query.genre) : []
            let currentSubGenre = this.$route.query.subgenre ? JSON.parse(this.$route.query.subgenre) : []
            let currentTag = this.$route.query.tag ? JSON.parse(this.$route.query.tag) : []
            let currentGrade = this.$route.query.grade ? JSON.parse(this.$route.query.grade) : [0, 100]
            let currentYear = this.$route.query.year ? JSON.parse(this.$route.query.year) : [1900, 2023]
            let currentRuntime = this.$route.query.runtime ? JSON.parse(this.$route.query.runtime) : [0, 200]

            if (this.pageLoad) {
                if (this.page !== parseInt(currentPage)) {
                    if (currentPage == undefined || isNaN(currentPage)) this.page = 1
                    else this.page = parseInt(currentPage)
                }

                if (!(this.movieSearch === this.$route.query.search)) {
                    this.movieSearch = this.$route.query.search
                }

                if (this.movieSort !== parseInt(currentSort)) {
                    if (currentSort == undefined || isNaN(currentSort)) this.movieSort = 0
                    else this.movieSort = parseInt(currentSort)
                }

                if (this.movieCountry !== parseInt(currentCountry)) {
                    if (currentCountry == undefined || isNaN(currentCountry)) this.movieCountry = 0
                    else this.movieCountry = parseInt(currentCountry)
                }

                if (this.movieFormat !== parseInt(currentFormat)) {
                    if (currentFormat == undefined || isNaN(currentFormat)) this.movieFormat = 0
                    else this.movieFormat = parseInt(currentFormat)
                }

                if (this.movieRating !== parseInt(currentRating)) {
                    if (currentRating == undefined || isNaN(currentRating)) this.movieRating = 0
                    else this.movieRating = parseInt(currentRating)
                }

                if (currentLang !== undefined && this.movieLanguageValue !== currentLang) {
                    if (currentLang == []) this.movieLanguageValue = []
                    else this.movieLanguageValue = currentLang
                }

                if (currentGenre !== undefined && this.movieGenreValue !== currentGenre) {
                    if (currentGenre == []) this.movieGenreValue = []
                    else this.movieGenreValue = currentGenre
                }

                if (currentSubGenre !== undefined && this.movieSubGenreValue !== currentSubGenre) {
                    if (currentSubGenre == []) this.movieSubGenreValue = []
                    else this.movieSubGenreValue = currentSubGenre
                }

                if (currentTag !== undefined && this.movieTagValue !== currentTag) {
                    if (currentTag == []) this.movieTagValue = []
                    else this.movieTagValue = currentTag
                }

                if (currentGrade !== undefined && JSON.stringify(this.movieGradeValue) !== JSON.stringify(currentGrade)) {
                    if (JSON.stringify(currentGrade) == JSON.stringify([0, 100])) this.movieGradeValue = [0, 100], this.movieGradeFlag = 0
                    else this.movieGradeValue = currentGrade, this.movieGradeFlag = 1
                }

                if (currentYear !== undefined && JSON.stringify(this.movieYearValue) !== JSON.stringify(currentYear)) {
                    if (JSON.stringify(currentYear) === JSON.stringify([1900, 2023])) this.movieYearValue = [1900, 2023], this.movieYearFlag = 0
                    else this.movieYearValue = currentYear, this.movieYearFlag = 1
                }

                if (currentRuntime !== undefined && JSON.stringify(this.movieRuntimeValue) !== JSON.stringify(currentRuntime)) {
                    if (JSON.stringify(currentRuntime) === JSON.stringify([0, 200])) this.movieRuntimeValue = [0, 200], this.movieRuntimeFlag = 0
                    else this.movieRuntimeValue = currentRuntime, this.movieRuntimeFlag = 1
                }
            }

            this.parameters = {
                page: this.page,
                published: this.routeName.indexOf("unpublished") === -1,
                search: this.movieSearch,
                sort: this.movieSort,
                country: this.movieCountry,
                formation: this.movieFormat,
                rating: this.movieRating,
                language: this.movieLanguageValue,
                genre: this.movieGenreValue,
                subgenre: this.movieSubGenreValue,
                tag: this.movieTagValue,
                grade: this.movieGradeValue,
                gradeFlag: this.movieGradeFlag,
                year: this.movieYearValue,
                yearFlag: this.movieYearFlag,
                runtime: this.movieRuntimeValue,
                runtimeFlag: this.movieRuntimeFlag
            }

            if (this.routeName === 'movie-list' || this.routeName === 'unpublished-movie-list'){
                if(this.routeName === 'movie-list'){
                    this.movieListTitle = 'Бүрэн хэмжээний'
                    document.title = 'Кино жагсаалт'
                } else if(this.routeName === 'unpublished-movie-list'){
                    this.movieListTitle = 'Бүрэн хэмжээний нийтлэгдээгүй'
                    document.title = 'Нийтлэгдээгүй кино жагсаалт'
                }
                this.parameters.countryFlag = 1
            } else if(this.routeName === 'short-movie-list' || this.routeName === 'unpublished-short-movie-list'){
                if(this.routeName === 'short-movie-list'){
                    this.movieListTitle = 'Богино хэмжээний'
                    document.title = 'Богино кино жагсаалт'
                } else if(this.routeName === 'unpublished-short-movie-list'){
                    this.movieListTitle = 'Богино хэмжээний нийтлэгдээгүй'
                    document.title = 'Нийтлэгдээгүй богино кино жагсаалт'
                }
                this.parameters.countryFlag = 2
            } else if(this.routeName === 'mongol-movie-list' || this.routeName === 'unpublished-mongol-movie-list'){
                if(this.routeName === 'mongol-movie-list'){
                    this.movieListTitle = 'Монголын бүрэн хэмжээний'
                    document.title = 'Монгол кино жагсаалт'
                } else if(this.routeName === 'unpublished-mongol-movie-list'){
                    this.movieListTitle = 'Монголын бүрэн хэмжээний нийтлэгдээгүй'
                    document.title = 'Нийтлэгдээгүй Монгол кино жагсаалт'
                }
                this.parameters.countryFlag = 3
            } else if(this.routeName === 'mongol-short-movie-list' || this.routeName === 'unpublished-mongol-short-movie-list'){
                if(this.routeName === 'mongol-short-movie-list'){
                    this.movieListTitle = 'Монголын богино хэмжээний'
                    document.title = 'Монгол богино кино жагсаалт'
                } else if(this.routeName === 'unpublished-mongol-short-movie-list'){
                    this.movieListTitle = 'Монголын богино хэмжээний нийтлэгдээгүй'
                    document.title = 'Нийтлэгдээгүй Монгол богино кино жагсаалт'
                }
                this.parameters.countryFlag = 4
            }

            let querySet = {
                page: this.page
            }

            if (this.movieSearch !== '') {
                Object.assign(querySet, { search: this.movieSearch })
            }

            if (this.movieSort !== 0) {
                Object.assign(querySet, { sort: this.movieSort })
            }

            if (this.movieCountry !== 0) {
                Object.assign(querySet, { country: this.movieCountry })
            }

            if (this.movieFormat !== 0) {
                Object.assign(querySet, { format: this.movieFormat })
            }

            if (this.movieRating !== 0) {
                Object.assign(querySet, { rating: this.movieRating })
            }

            if (!!this.movieLanguageValue.length) {
                Object.assign(querySet, { lang: JSON.stringify(this.movieLanguageValue) })
            }

            if (!!this.movieGenreValue.length) {
                Object.assign(querySet, { genre: JSON.stringify(this.movieGenreValue) })
            }

            if (!!this.movieSubGenreValue.length) {
                Object.assign(querySet, { subgenre: JSON.stringify(this.movieSubGenreValue) })
            }

            if (!!this.movieTagValue.length) {
                Object.assign(querySet, { tag: JSON.stringify(this.movieTagValue) })
            }

            if (JSON.stringify(this.movieGradeValue) !== JSON.stringify([0, 100])) {
                Object.assign(querySet, { grade: JSON.stringify(this.movieGradeValue) })
            }

            if (JSON.stringify(this.movieYearValue) !== JSON.stringify([1900, 2023])) {
                Object.assign(querySet, { year: JSON.stringify(this.movieYearValue) })
            }

            if (JSON.stringify(this.movieRuntimeValue) !== JSON.stringify([0, 200])) {
                Object.assign(querySet, { runtime: JSON.stringify(this.movieRuntimeValue) })
            }

            axios
                .get('/movie/movie-list/', { params: this.parameters })
                .then(response => { this.movies = response.data.results, this.total = response.data.count, this.moviePage = Math.ceil(this.total/64) })
                .finally(() => {
                    this.$router.push({ name: this.routeName, query: querySet }).catch(() => {})
                })
        }
    },
    created(){
        this.getLanguage()
        this.getGenres()
        this.getSubGenres()
        this.getProductionCountries()
        this.submitFilter()
        
    }
}
</script>

<style scoped src="@/assets/styles/generic-list.css"></style>