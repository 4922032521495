<template>
    <v-card class="ma-1">
        <div class="create_movie__archive_image_box">
            <div class="create_movie__list_image_block">
                <img :src="url + '/media/' + switchName" class="rounded create_movie__list_image"/>
            </div>
            <div v-if="layout !== 'grid'" class="create_movie__list_txt">
                <v-textarea v-model="movie.description" label="Нэмэлт тайлбар"></v-textarea>
            </div>
            <div class="create_movie__del_btn">
                <v-btn class="mx-2" fab dark x-small color="red" v-on:click="$emit('delete-movie')"><v-icon dark>mdi-minus</v-icon></v-btn>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        type: Boolean,
        movie: Object,
        layout: String
    },
    data(){
        return{
            url: process.env.VUE_APP_BASEURL
        }
    },
    computed:{
        switchName(){
            return this.type ? this.movie.movie_poster : this.movie.serial_poster
        }
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>