<template>
    <v-container v-if="user.isAuthenticated && user.admin">
        <v-card-title class="justify-center">Та кино наадамд шинээр арга хэмжээ нэмэх гэж байна!</v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
            <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitCreate">
                <div class="d-flex flex-row mt-2 mb-2">
                    <div class="ma-2">
                        <v-card width="240" height="320"><v-img :src="festivalPosterURL"/></v-card>
                        <v-text-field v-model="festivalPosterCheck" disabled label="Наадмын постер" :rules="[rules.required]"></v-text-field>
                        <v-dialog v-model="posterDialog" width="600px" transition="dialog-bottom-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Постер нэмэх</v-btn>
                            </template>
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn icon dark @click="posterDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                    <v-toolbar-title>Наадмын постер зураг</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 420, height: 560}" :canvas="{width: 420, height: 560}" image-restriction="stencil"/>
                                <div class="button-wrapper">
                                    <button class="button" @click="$refs.file.click()">
                                        <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                    </button>
                                    <button class="button" @click="cropPoster">Зураг хуулах</button>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                    <v-row class="mx-5">
                        <v-col>
                            <v-autocomplete no-filter v-model="selectedFestival" height="100" :items="searchItems" hide-no-data return-object :search-input.sync="searchFestival" clearable item-text="name" label="Кино наадам" attach solo>
                                <template v-slot:selection="data">
                                    <v-avatar left width="95" tile class="mx-3"><img :src="mediaUrl + '/' + data.item['award_image']" class="rounded"></v-avatar>
                                    <div mx="2">{{ data.item['award_name'] }}</div>
                                </template>
                                <template v-slot:item="data">
                                    <template v-if="data.item !== 'object'">
                                        <v-list-item-avatar width="75" tile><img :src="mediaUrl + '/' + data.item['award_image']" class="rounded"></v-list-item-avatar>
                                        <v-list-item-content v-text="data.item['award_name']"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-avatar width="75" tile><img :src="mediaUrl + '/' + data.item['award_image']" class="rounded"></v-list-item-avatar>
                                        <v-list-item-content v-text="data.item['award_name']"></v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                            <v-text-field v-model="festivalYear" label="Кино наадмын жил*" required :rules="[rules.required, rules.yearCheck]"></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <legend class="legendBar mt-5 mb-2"><strong style="color: #0066b5;">Кино наадмын хөтөлбөр:</strong></legend>
                <tinymceEditor api-key="dh9tjnxmzhiub2bx0brpetqavxuw7z518330gl5p1kn1ivy6" v-model="festivalAgenda" :init="customToolbar"/>
                <legend class="legendBar mt-5 mb-2"><strong style="color: #0066b5;">Кино наадмын хөтөлбөр /Англи/:</strong></legend>
                <tinymceEditor api-key="dh9tjnxmzhiub2bx0brpetqavxuw7z518330gl5p1kn1ivy6" v-model="festivalAgendaEn" :init="customToolbar"/>
                <legend class="legendBar mt-5 mb-2"><strong style="color: #0066b5;">Кино наадмын тухай:</strong></legend>
                <tinymceEditor api-key="dh9tjnxmzhiub2bx0brpetqavxuw7z518330gl5p1kn1ivy6" v-model="festivalDescription" :init="customToolbar"/>
                <legend class="legendBar mt-5 mb-2"><strong style="color: #0066b5;">Кино наадмын тухай /Англи/:</strong></legend>
                <tinymceEditor api-key="dh9tjnxmzhiub2bx0brpetqavxuw7z518330gl5p1kn1ivy6" v-model="festivalDescriptionEn" :init="customToolbar"/>
                <legend class="legendBar mt-5 mb-2"><strong style="color: #0066b5;">Кино наадамд оролцох заавар:</strong></legend>
                <tinymceEditor api-key="dh9tjnxmzhiub2bx0brpetqavxuw7z518330gl5p1kn1ivy6" v-model="festivalGuidance" :init="customToolbar"/>
                <legend class="legendBar mt-5 mb-2"><strong style="color: #0066b5;">Кино наадамд оролцох заавар /Англи/:</strong></legend>
                <tinymceEditor api-key="dh9tjnxmzhiub2bx0brpetqavxuw7z518330gl5p1kn1ivy6" v-model="festivalGuidanceEn" :init="customToolbar"/>
                <v-card-actions class="justify-center"><v-btn :disabled="checkValid" @click="validate" type="submit" style="color: white; background-color: green;" block>Хадгалах</v-btn></v-card-actions>
            </v-form>
            <v-divider class="mx-4"></v-divider>
        </v-card-text>
    </v-container>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import { mapState } from 'vuex'
import 'vue-advanced-cropper/dist/style.css'
import Editor from '@tinymce/tinymce-vue'

export default {
    components: {
        Cropper,
        'tinymceEditor': Editor
    },
    data(){
        return{
            currentYear: new Date().getFullYear(),
            image: "",
            posterDialog: false,
            backgroundDialog: false,
            baseUrl: process.env.VUE_APP_BASEURL,
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                yearCheck: value => value >= 1900 && value <= this.currentYear + 1 || 'Та оноо зөв оруулна уу!'
            },
            festivalYear: '',
            festivalAgenda: '',
            festivalAgendaEn: '',
            festivalDescription: '',
            festivalDescriptionEn: '',
            festivalGuidance: '',
            festivalGuidanceEn: '',
            festivalPoster: null,
            festivalPosterURL: null,
            festivalPosterCheck: null,
            searchItems: [],
            searchFestival: null,
            selectedFestival: '',
            customToolbar: {
                height: 500,
                contextmenu: 'link image table',
                content_style: 'img {max-width: 1280px;}',
                menu : {
                    file: {title: 'File', items: 'newdocument restoredraft | preview | print | deleteallconversations'},
                    edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace'},
                    view: {title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | fullscreen | showcomments'},
                    insert: {title: 'Insert', items: 'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'},
                    format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat'},
                    tools: {title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount'},
                    table: {title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable'}
                },
                images_file_types: 'jpg, jpeg',
                images_upload_handler: this.uploadContentImage,
                plugins: ['advlist autolink lists link image charmap print preview anchor searchreplace visualblocks fullscreen insertdatetime media table paste wordcount imagetools'],
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
            }
        }
    },
    watch:{
        searchFestival(val){
            if(val){
                axios
                    .get('/award/search-festival-list/', { params: { festival: val }})
                    .then(response => {this.searchItems = response.data.festival_list})
            }
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true && this.selectedFestival !== ''){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user'])
    },
    methods:{
        validate(){
            this.$refs.form.validate()
        },
        cropPoster() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.festivalPoster = blob }, "image/jpeg")
            this.festivalPosterURL = canvas.toDataURL("jpeg")
            this.festivalPosterCheck = 'festival-poster'
            this.posterDialog = false
            this.image = ''
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])
                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        uploadContentImage(blobInfo, success){
            var image = blobInfo.blob()
            if(image['type']==='image/jpeg'){
                var formData = new FormData()
                formData.append("image", image, "content-image")

                axios
                    .post('/user/api/v-user-useraddimage/', formData)
                    .then(result => {
                        const url = result.data.url
                        success(url)
                    })
                    .catch(err => {
                    })
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        submitCreate(){
            const createItems = {
                'festival': this.selectedFestival['id'],
                'year': this.festivalYear,
                'agenda': this.festivalAgenda,
                'agenda_en': this.festivalAgendaEn,
                'description': this.festivalDescription,
                'description_en': this.festivalDescriptionEn,
                'guidance': this.festivalGuidance,
                'guidance_en': this.festivalGuidanceEn
            }

            const createData = new FormData()

            if(this.festivalPoster){
                createData.append('festival-poster', this.festivalPoster, 'festival_poster')
            }

            for(var key in createItems){
                createData.append(key, createItems[key])
            }

            axios
                .post('/award/create-annual-festival/', createData)
                .then((response) => {
                    if (response.data.success === true){
                        this.$store.commit('SET_SNACK', {message: 'Кино наадмын эвэнт амжилттай нэмэгдлээ!', color: 'green'})
                        this.$router.push({ name: 'home' }).catch(()=>{})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        }
    },
    created(){
        document.title = 'Шинээр наадам нэмэх'
    }
}
</script>

<style scoped lang="scss">

.cropper {
    max-height: 300px;
    width: 100%;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 17px;
}

.button {
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    width: 100%;
    background: #0066b5;
    cursor: pointer;
    transition: background 0.5s;
    border: none;
    &:not(:last-of-type) {
        margin-right: 10px;
    }
    &:hover {
        background: #2F2F2F;
    }
    input {
        display: none;
    }
}

</style>