<template>
    <v-card class="ma-1" max-width="110">
        <v-img :src="member.memberImageURL" max-width="110" class="rounded"/>
        <v-card-text style='color:#484848; font-size: 11px; text-align: center;'>
            <strong>{{ member.memberTitle }}</strong>
            <br>
            {{ member.memberDescription }}
        </v-card-text>
        <div style="position: absolute; top: 5px; left: 1px;"><v-btn class="mx-2" v-if="!edited" fab dark x-small color="success" v-on:click="$emit('edit-member')"><v-icon dark>mdi-pencil</v-icon></v-btn></div>
        <div style="position: absolute; top: 5px; right: 1px;"><v-btn class="mx-2" fab dark x-small color="red" v-on:click="$emit('delete-member')"><v-icon dark>mdi-minus</v-icon></v-btn></div>
    </v-card>
</template>

<script>
export default {
    props: {
        member: Object,
        edited: Boolean
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL
        }
    }
}
</script>
