<template>
    <div class="v-cloak--hidden" v-show="artist || user.contributer">
        <div class="ai-1">
            <div class="ai-2">
                <div class="ai-3">
                    <v-img :src="baseUrl + artist.photo" class="rounded" min-width="300"/>
                    <v-btn class="ai-4" v-show="user.isAuthenticated" @click="likeArtist" small><v-icon>{{ userFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon></v-btn>
                </div>
                <div class="ai-5">
                    <div class="ai-6">
                        <span class="ai-7">{{ artist.pro_name }}</span><span class="ai-7-1" v-show="artist.pro_name && artist.pro_name_en"> / </span><span class="ai-7">{{ artist.pro_name_en }}</span>
                        <div class="ai-8" v-show="fullName != ' ' && artist.pro_name_en !== fullName">{{ fullName }}</div>
                        <div class="ai-9" v-show="artist.professions != ''"><span v-for="(profession, index) in artist.professions" :key="'profession' + index"><span v-if="actorCheck(profession)">ЖҮЖИГЧИН</span><span v-else>{{ profession['profession_mongol_title'] }}</span><span v-show="index + 1 < artist.professions.length">, </span></span></div>
                        <div class="ai-10">
                            <span v-show="artist.old_countries != ''">хуучнаар (<span v-for="(o_country, index) in artist.old_countries" :key="'o_country' + index">{{ o_country['country_mongol_name'] }}<span v-show="index+1 < artist.old_countries.length">, </span></span>)
                            <span v-show="artist.countries != ''">, </span><span v-show="artist.countries == '' && ( artist.born || artist.died )">  |  </span></span>
                            <span v-show="artist.countries != ''"><span v-for="(country, index) in artist.countries" :key="'country' + index">{{ country['country_mongol_name'] }} <span v-show="index+1 < artist.countries.length">, </span></span><span v-show="artist.born || artist.died" >  |  </span></span>
                            <span v-show="artist.born" ><span>{{ artist.born }}</span></span>
                            <span v-show="artist.died"> - {{ artist.died }}</span>
                        </div>
                        <div v-show="artist.quotes != ''" class="ai-11">
                            <span v-for="(quote, index) in artist.quotes" :key="'quote' + index">
                                <li><strong>&rdquo;</strong> {{ quote['quote'] }} <strong>&rdquo;</strong></li>
                            </span>
                        </div>
                        <div class="ai-12">{{ artist.biography }}</div>
                    </div>
                    <AwardList :slug="slugArtist"/>
                </div>
            </div>
        </div>
        <div class="am-1">
            <MovieList :slug="slugArtist" :died="artist.died"/>
            <SerialList :slug="slugArtist"/>
            <SelfList :slug="slugArtist"/>
            <JournalList :slug="slugArtist"/>
            <CollabList :slug="slugArtist"/>
        </div>
        <div v-if="user.isAuthenticated && user.contributer && user.adminBarHide" class="sab-1">
            <v-dialog v-model="publishDialog" width="500px">
                <template v-slot:activator="{ on, attrs }">
                    <button :class="publish ? 'sab-2' : 'sab-3'" v-bind="attrs" v-on="on" ><v-icon class="sa-1">mdi-clipboard-check-outline</v-icon></button>
                </template>
                <v-card>
                    <v-card-title class="headline grey lighten-2 mx-auto">Уран бүтээлч нийтлэх</v-card-title>
                    <v-card-text class="mt-3">Та "{{ artist.pro_name }}" <span v-if="publish">уран бүтээлчийн нийтлэгдснийг болиулах</span><span v-else>уран бүтээлчийг нийтлэх</span> гэж байна!</v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :color="publish ? 'red' : 'primary'" text @click="publishDialog = false; publishArtist();">{{publish ? "болиулах" : "нийтлэх"}}</v-btn>
                        <v-btn color="warning" text @click="publishDialog = false">буцах</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <router-link class="sab-4" :to="{ name: 'artist-update', params: { slug: slugArtist } }"><v-icon class="sa-1">mdi-lead-pencil</v-icon></router-link>
        </div>
        
    </div>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'

import AwardList from '@/views/artist/detail/AwardList.vue'
import MovieList from '@/views/artist/detail/MovieList.vue'
import SerialList from '@/views/artist/detail/SerialList.vue'
import SelfList from '@/views/artist/detail/SelfList.vue'
import JournalList from '@/views/artist/detail/Journals.vue'
import CollabList from '@/views/artist/detail/CollabList.vue'

export default {
    props: {
        slug: String
    },
    components: {
        AwardList,
        MovieList,
        SerialList,
        SelfList,
        JournalList,
        CollabList
    },
    data(){
        return{
            slugArtist: this.slug,
            baseUrl: process.env.VUE_APP_BASEURL,
            
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            artist: {},
            fullName: '',
            userFavorite: false,
            publish: '',
            publishDialog: false
        }
    },
    computed: mapState(['user']),
    methods:{
        actorCheck(actor){
            return actor['profession_mongol_title'].includes("жүжигчин")
        },
        getArtistDetail(){
            axios
                .get('/artist/api-artist-detail/', { params: { slug: this.slugArtist }})
                .then(response => { this.artist = response.data.artist_detail, this.userFavorite = this.artist.user_like, this.publish = this.artist.publish, document.title = this.artist['pro_name'], this.fullName = this.artist.first_name + ' ' + this.artist.last_name })
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404'}) }})                
        },
        publishArtist(){
            if(this.user.isAuthenticated){
                if(this.publish == false){
                    this.publish = true
                } else{
                    this.publish = false
                }
                axios
                    .post('/artist/api-publish-artist/', { slug: this.slugArtist })
            }
        },
        likeArtist(){
            if(this.user.isAuthenticated){
                if(this.userFavorite == false){
                    this.userFavorite = true
                } else{
                    this.userFavorite = false
                }
                axios
                    .post('/artist/api-like-artist/', { slug: this.slugArtist })
            }
        },
    },
    created(){
        this.getArtistDetail()
    },
    watch:{
        slug(){
            this.slugArtist = this.slug
            this.getArtistDetail()
        }
    }
}
</script>

<style scoped>
[v-cloak] {display: none}

/* Artist Info and Movie */


.ai-1,
.am-1 {
    min-width: 320px;
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
}

.ai-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.ai-3 {
    max-width: 300px;
    text-align: center;
    height: 520px;
    margin-top: 10px;
}

.ai-4 {
    font-size: 12px;
    color: #FFF;
    background-color: #0066b5 !important;
    margin-top: 15px;
    height: 42px !important;
}


.ai-5 {
    width: 100%;
    background-color: rgba(220, 220, 220, 0.5);
    padding: 10px 0 20px;
}

.ai-6 {
    margin: 10px;
}

.ai-7,
.ai-7-1,
.ai-8 {
    margin: 15px 0 30px;
    color: black;
    font-size: 28px;
    font-weight: 600;
}

.ai-7-1 {
    font-weight: normal;
}

.ai-8 {
    font-size: 18px;
    font-weight: normal;
}

.ai-9 {
    font-size: 20px;
    text-transform: uppercase;
    color: grey;
}

.ai-10 {
    margin-bottom: 30px;
    font-size: 14px;
    text-transform: uppercase;
    color: grey;
}

.ai-11 {
    font-style: italic;
    list-style: none;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
}

.ai-12 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px;
}

/* SubAdmin Bar */

.sa-1 {
    color: #FFF;
}

.sab-1 {
    z-index: 2;
    position: fixed;
    top: 20vh;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
}

.sab-1 a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
}

.sab-1 a:hover {
    background-color: #000;
}

.sab-3,
.sab-2,
.sab-4 {
    width: 70px;
    height: 70px;
    background: #3B5998;
    color: white;
}

.sab-2 {
    background: #008000;
}

.sab-4 {
    background: #e5e500;
}


.backGroundBar {
    background: rgba(220,220,220,1);
    top: 1000px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (min-width: 481px) {

    /* Artist Info and Movie */

    .ai-1 {
        min-width: 1280px;
        max-width: 1920px;
        background-color: rgba(220, 220, 220, 0.5);
    }

    .ai-2 {
        width: 1280px;
        display: flex;
        flex-wrap: wrap;
        margin-right: auto;
        margin-left: auto;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ai-3 {
        flex: 50%;
        margin: 20px;
    }

    .ai-5 {
        flex: 50%;
        margin: 20px;
        background-color: transparent;
    }

    .am-1 {
        min-width: 1280px;
        margin-right: auto;
        margin-left: auto;
    }
}

</style>