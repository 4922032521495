<template>
    <div class="v-cloak--hidden" v-show="movie || user.contributer">
        <div id="movie-info__wrapper">
            <div class="block movie-poster__box">
                <img :src="baseUrl + movie.poster" class="rounded" width="300"/>
                <v-dialog v-model="commentDialog" max-width="640" min-width="320">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn block class="movie-review__dlg_btn" v-bind="attrs" v-on="on">
                            <span v-show="grade"><v-rating :value="parseInt(grade) * 0.05" color="#FFF" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" half-increments readonly size="20"></v-rating></span>
                            <span v-show="!grade" class="movie-review__dlg_btn_txt">Үнэлгээ</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="commentDialog">
                        <v-card>
                            <div v-if="user.isAuthenticated">
                                <v-tabs v-model="commentTab" fixed-tabs>
                                    <v-tabs-slider></v-tabs-slider>
                                    <v-tab href="#tabs-5-1" class="primary--text"><v-icon>mdi-comment</v-icon></v-tab>
                                    <v-tab href="#tabs-5-2" class="primary--text"><v-icon>mdi-heart</v-icon></v-tab>
                                    <v-tab href="#tabs-5-3" class="text font-weight-bold" v-if="user.critic && reviewCheck">Шүүмж</v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="commentTab">
                                    <v-tab-item :key="1" value="tabs-5-1">
                                        <div class="ma-3 pa-3" max-width="640" min-width="320">
                                            <div v-if="errors.length" class="movie-review__error"><p v-for="(error, index) in errors" :key="index">{{ error }}</p></div>
                                            <v-form ref="form" v-on:submit.prevent="submitReview(loadReview = true)">
                                                <div class="movie-review__grade_box">
                                                    <div class="movie-review__grade_txt_block">
                                                        <v-text-field class="movie-review__grade_txt_field" outlined v-model="grade" name="grade" type="number" min="0" label="Тоон үнэлгээ" hint="Та 0-100 хооронд тоо оруулна уу!" required></v-text-field>
                                                    </div>
                                                    <div class="movie-review__grade_slider_block">
                                                        <div class="movie-review__grade_slider">
                                                            <v-slider class="movie-review__grade_slider_style" v-model="grade" min="0" max="100"></v-slider>
                                                            <v-rating :value="parseInt(grade) * 0.05" color="#0066b5" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" half-increments readonly size="28"></v-rating>
                                                        </div>
                                                    </div>
                                                </div>
                                                <v-textarea outlined v-model="review" name="review" label="Үгэн үнэлгээ" hint="Та 500 тэмдэгтэнд багтааж бичнэ үү!"></v-textarea>
                                                <v-checkbox v-model="spoilerCheckBox" label="Миний сэтгэгдэл үйл явдлын ИЛЧЛЭЛТ агуулсан"></v-checkbox>
                                                <v-card-actions class="justify-center">
                                                    <v-dialog v-model="reviewDeleteDialog" persistent max-width="290">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn v-if="grade" depressed color="red" width="33%" dark v-bind="attrs" v-on="on" class="ma-1">устгах</v-btn>
                                                        </template>
                                                        <v-card>
                                                            <v-spacer></v-spacer>
                                                            <v-card-text class="pa-2 movie-review__delete_txt">Та өөрийн үлдээсэн үнэлгээг устгах гэж байна.</v-card-text>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn color="red darken-1" text @click="reviewDeleteDialog = false; deleteReview();">УСТГАХ</v-btn>
                                                                <v-btn color="blue darken-1" text @click="reviewDeleteDialog = false">БУЦАХ</v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                    <v-btn dark color="green" depressed width="33%" type="submit">Хадгалах</v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </div>
                                    </v-tab-item>
                                    <v-tab-item :key="2" value="tabs-5-2">
                                        <div class="ma-3 pa-3" max-width="840" min-width="320">
                                            <v-btn-toggle outlined color="#FFF" class="movie_reaction__box">
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ attrs, on }">
                                                        <v-btn color="#FFF" v-bind="attrs" v-on="on" class="movie_reaction__btn">{{ movieReact1 }}</v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item-content class="text-center">
                                                            <v-list-item-title>Movie reactions <v-btn v-show="movieReact1" color="red darken-1" icon plain text @click="movieReact(1, null)"><v-icon>mdi-trash-can</v-icon></v-btn></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-divider></v-divider>
                                                        <v-list-item v-for="(item, index) in reactions" :key="index" link>
                                                            <v-list-item-title @click="item.check ? null : movieReact(1, item)" :class="[item.check ? 'movie_reaction__check' : null]">{{ item.emoji }} - {{ item.name }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ attrs, on }">
                                                        <v-btn color="#FFF" v-bind="attrs" v-on="on" class="movie_reaction__btn">{{ movieReact2 }}</v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item-content class="text-center">
                                                            <v-list-item-title>Movie reactions <v-btn v-show="movieReact2" color="red darken-1" icon plain text @click="movieReact(2, null)"><v-icon>mdi-trash-can</v-icon></v-btn></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-divider></v-divider>
                                                        <v-list-item v-for="(item, index) in reactions" :key="index" link>
                                                            <v-list-item-title @click="item.check ? null : movieReact(2, item)" :class="[item.check ? 'movie_reaction__check' : null]">{{ item.emoji }} - {{ item.name }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ attrs, on }">
                                                        <v-btn color="#FFF" v-bind="attrs" v-on="on" class="movie_reaction__btn">{{ movieReact3 }}</v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item-content class="text-center">
                                                            <v-list-item-title>Movie reactions <v-btn v-show="movieReact3" color="red darken-1" icon plain text @click="movieReact(3, null)"><v-icon>mdi-trash-can</v-icon></v-btn></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-divider></v-divider>
                                                        <v-list-item v-for="(item, index) in reactions" :key="index" link>
                                                            <v-list-item-title @click="item.check ? null : movieReact(3, item)" :class="[item.check ? 'movie_reaction__check' : null]">{{ item.emoji }} - {{ item.name }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-btn-toggle>
                                            <v-btn height="42px" color="#0066b5" @click="favoriteUser" x-small outlined class="mx-1 ma-1"><v-icon>{{ userFavorite ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>миний дуртай кино</v-btn>
                                            <v-btn height="42px" color="#0066b5" v-show="userWatched" x-small outlined class="mx-1 ma-1"><v-icon>mdi-check</v-icon> миний үзсэн кино</v-btn>
                                            <v-btn height="42px" color="#0066b5" v-show="!userWatch && !userWatched" @click="watchUser" x-small outlined class="mx-1"><v-icon>mdi-plus</v-icon> үзэх кинонд нэмэх</v-btn>
                                            <v-btn height="42px" color="#0066b5" v-show="userWatching && movie.runtime" @click="watchingUser" x-small outlined class="mx-1"><v-icon>mdi-eye</v-icon></v-btn>
                                        </div>
                                    </v-tab-item>
                                    <v-tab-item :key="3" value="tabs-5-3">
                                        <div class="ma-2 pa-2 text-center font-weight-bold" max-width="840" min-width="320">
                                            <div>{{ movie.i_title }} -д тойм шүүмж бичих</div>
                                            <v-spacer></v-spacer>
                                            <div class="d-flex flex-column pa-2">
                                                <div v-if="detailedReviewErrors.length" class="movie_detailed_review__error">
                                                    <p v-for="(error, index) in detailedReviewErrors" :key="index">{{ error }}</p>
                                                </div>
                                                <v-text-field v-model="reviewTitle" label="Гарчиг*" required></v-text-field>
                                                <tinymceEditor api-key="dh9tjnxmzhiub2bx0brpetqavxuw7z518330gl5p1kn1ivy6" v-model="detailedReview" :init="customToolbar"/>
                                            </div>
                                            <v-dialog v-model="detailedReviewDeleteDialog" persistent max-width="290">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-if="detailedReview" dark depressed color="red" v-bind="attrs" v-on="on" class="movie_detailed_review__delete_btn">Устгах</v-btn>
                                                </template>
                                                <v-card>
                                                    <v-spacer></v-spacer>
                                                    <v-card-text class="pa-2 movie_detailed_review__delete_txt">Та өөрийн үлдээсэн тойм шүүмжийг устгах гэж байна.</v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn class="ma-1" color="red darken-1" text @click="detailedReviewDeleteDialog = false; deleteDetailedReview();">УСТГАХ</v-btn>
                                                        <v-btn color="blue darken-1" text @click="detailedReviewDeleteDialog = false">БУЦАХ</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            <v-btn dark depressed @click="submitDetailedReview(loadDetailedReview = true);" color="green" width="33%">Хадгалах</v-btn>
                                        </div>
                                    </v-tab-item>
                                </v-tabs-items>
                            </div>
                            <div v-else>
                                <v-container class="text-center">
                                    <div class="movie_review__login_txt">Та үнэлгээ өгөхийн тулд <span class="movie_review__login_link" @click="loginRequired()">НЭВТЭРНЭ</span> үү!</div>
                                </v-container>
                            </div>
                            <v-card-actions class="justify-end"><v-btn text @click="commentDialog.value = false" color="#0066b5">Хаах</v-btn></v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
            <div class="block movie-background__box">
                <img class="movie-background__image" :src="baseUrl + movie.background"/>
                <div v-show="movie.m_title || movie.i_title" class="movie-background__block">
                    <div class="movie-background__title">{{ movie.m_title ? movie.m_title : movie.i_title }}</div>
                    <div v-show="movie.year" class="movie-background__year">{{ movie.year }}</div>
                </div>
            </div>
            <div class="block movie-info__box">
                <div v-show="movieClassicFlag || movie.grade || movie.a_grade" class="movie-grade">
                    <v-card elevation="3">
                        <div class="movie-grade__box">
                            <div @click="gotoId(1)" v-show="movie.grade" :class="movie.grade && movie.a_grade ? 'movie-grade__block movie-grade__block__divider' : 'movie-grade__block'">
                                <div class="movie-grade__critic">{{ movie.grade }}%</div>
                                <div class="movie-grade__rate">
                                    <v-rating :value="parseInt(movie.grade) * 0.05" color="#B29600" background-color="#B29600" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="32"></v-rating>
                                    {{ movie.critics }} шүүмжлэгч
                                </div>
                            </div>
                            <div @click="gotoId(2)" v-show="movie.a_grade" class="movie-grade__block">
                                <div class="movie-grade__audience">{{ movie.a_grade }}%</div>
                                <div class="movie-grade__rate">
                                    <v-rating :value="parseInt(movie.a_grade) * 0.05" color="#0066b5" background-color="#0066b5" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="32"></v-rating>
                                    {{ movie.audiences }} кино сонирхогч
                                </div>
                            </div>
                        </div>
                    </v-card>
                    <div v-show="movieClassicFlag" class="movie-classic">MFI Classics</div>
                </div>
                <div class="movie-info__title_m" v-show="movie.m_title">{{ movie.m_title }}</div>
                <div class="movie-info__title_io" v-show="movie.i_title">{{ movie.i_title }}</div>
                <div class="movie-info__title_io" v-show="movie.i_title && (movie.i_title !== movie.o_title && movie.m_title !== movie.o_title)">{{ movie.o_title }}</div>
                <div class="movie-info__block">
                    <small>
                        <div v-show="movie.rating" class="movie-info__rating"><v-btn x-small text outlined class="" ><span >{{movie.rating}}</span></v-btn></div>
                        <span v-show="movie.format" class="movie-info__fltr"><v-icon class="movie-info__icon">mdi-filmstrip</v-icon> {{ movie.format }} | </span>
                        <span v-show="movie.languages" class="movie-info__fltr"><v-icon class="movie-info__icon">mdi-volume-high</v-icon> <span v-for="(language, index) in movie.languages" :key="'language' + index">{{ language['language_mongol_name'] }}<span v-show="index + 1 < movie.languages.length">, </span></span> | </span>
                        <span v-show="movie.year">{{ movie.year }} | </span>
                        <span v-show="movie.trailer"><a class="movie-info__fltr" target="_blank" :href="movie.trailer"><v-icon>mdi-youtube</v-icon> </a> |</span>
                        <span v-show="movie.runtime" class="movie-info__fltr"><v-icon class="movie-info__icon">mdi-clock-time-three</v-icon> {{ movie.runtime }}' | </span>
                        <span v-show="movie.countries"><span v-for="(country, index) in movie.countries" :key="'country' + index">{{ country['country_mongol_name'] }}<span v-show="index + 1 < movie.countries.length">, </span></span></span>
                    </small>
                </div>
                <div class="movie-info__block">
                    <small>
                        <span class="movie-info__genre" v-for="(genre, index) in movie.genres" :key="'genre' + index">{{ genre['genre_mongol_name'] }}<span v-show="index + 1 < movie.genres.length">, </span></span>
                    </small>
                </div>
                <div class="movie-info__block">
                    <v-dialog v-if="movie.images != ''" v-model="archiveImageDialog" width="unset">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="#0066b5" x-small outlined v-bind="attrs" v-on="on">архивын зургууд</v-btn>
                        </template>
                        <v-card>
                            <v-card-title>Киноны архивын зургууд:</v-card-title>
                            <v-card-text>
                                <v-carousel hide-delimiters show-arrows-on-hover height="auto">
                                    <v-carousel-item v-for="(image, index) in movie.images" :key="'image' + index" :src="baseUrl + image.image">
                                        <v-container fill-height justify-center><div class="movie-info__archive_image">{{ image.description }}</div></v-container>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-card-text>
                            <v-card-actions class="justify-end"><v-btn text @click="archiveImageDialog = false" color="#0066b5">Хаах</v-btn></v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="movie-info__block">
                    <span class="movie-info__plot">{{ movie.plot }}</span>
                </div>
            </div>
        </div>
        <div class="movie-content">
            <div class="movie-content__block_aa">
                <div class="movie-content__box">
                    <GenericAwardList :type="'movie'" :slug="slugMovie"/>
                    <GenericArtistList :title="'Уран бүтээлчид'" :btnName="'Бусад уран бүтээлчид'" :type="'movie'" :format="'artist'" :slug="slugMovie"/>
                    <GenericArtistList :title="'Дүрүүд'" :btnName="'Бусад дүрүүд'" :type="'movie'" :format="'role'" :slug="slugMovie"/>
                </div>
            </div>
            <div class="movie-content__box">
                <GenericMovieList :title="'Бусад ангиуд'" :type="'movie'" :format="'collected'" :slug="slugMovie"/>
                <GenericMovieList :title="'Төстэй кинонууд'" :type="'movie'" :format="'similar'" :slug="slugMovie"/>
                <GenericMovieList :title="'Танд таалагдаж магадгүй'" :type="'movie'" :format="'suggested'" :slug="slugMovie"/>
                <GenericJournals :type="'movie'" :slug="slugMovie"/>
                <GenericListedMovies :type="'movie'" :slug="slugMovie"/>
                <div class="movie-review__block">
                    <legend class="movie-review__legendbar"><strong >Үнэлгээ</strong></legend>
                    <v-tabs v-model="activeTab" color="light-blue darken-3 accent-4" fixed-tabs width="100%">
                        <v-tab @click="criticsCall" id="critics">шүүмжлэгчид</v-tab>
                        <v-tab @click="audiencesCall" id="audiences">сонирхогчид</v-tab>
                        <v-tab-item :key="1">
                            <v-container fluid>
                                <GenericReviewers :type="'movie'" :reviewer="'critic'" :movieSlug="slugMovie" ref="criticRef"/>
                            </v-container>
                        </v-tab-item>
                        <v-tab-item :key="2">
                            <v-container fluid>
                                <GenericReviewers :type="'movie'" :reviewer="'audience'" :movieSlug="slugMovie" ref="audienceRef"/>
                            </v-container>
                        </v-tab-item>
                    </v-tabs>
                </div>
            </div>
        </div>
        <div v-if="user.isAuthenticated && user.contributer && user.adminBarHide" class="subadmin__box">
            <v-dialog v-model="publishDialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                    <button :class="publish ? 'subadmin__publish' : 'subadmin__unpublish'" v-bind="attrs" v-on="on" ><v-icon class="subadmin__icon">mdi-clipboard-check-outline</v-icon></button>
                </template>
                <v-card>
                    <v-card-title class="headline grey lighten-2 mx-auto">Кино нийтлэх</v-card-title>
                    <v-card-text class="mt-3">Та "{{ movie.i_title }}" <span v-if="publish">киноны нийтлэгдснийг болиулах</span><span v-else>киног нийтлэх</span> гэж байна!</v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :color="publish ? 'red' : 'primary'" text @click="publishDialog = false; publishMovie();">{{publish ? "болиулах" : "нийтлэх"}}</v-btn>
                        <v-btn color="warning" text @click="publishDialog = false">буцах</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <router-link class="subadmin__update" :to="{ name: 'movie-update', params: { slug: slugMovie } }"><v-icon class="subadmin__icon">mdi-lead-pencil</v-icon></router-link>
            <router-link class="subadmin__image_update" :to="{ name: 'movie-image-update', params: { slug: slugMovie } }"><v-icon class="subadmin__icon">mdi-image-edit-outline</v-icon></router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Editor from '@tinymce/tinymce-vue'

import NotFound404 from '@/views/NotFound404.vue'
import GenericAwardList from '@/views/generics/movie_serial/GenericAwardList.vue'
import GenericArtistList from '@/views/generics/movie_serial/GenericArtistList.vue'
import GenericMovieList from '@/views/generics/movie_serial/GenericMovieList.vue'
import GenericListedMovies from '@/views/generics/movie_serial/GenericListedMovies.vue'
import GenericJournals from '@/views/generics/movie_serial/GenericJournals.vue'
import GenericReviewers from '@/views/generics/movie_serial/GenericReviewerList.vue'

export default {
    props: {
        slug: String
    },
    components: {
        NotFound404,
        GenericAwardList,
        GenericArtistList,
        GenericListedMovies,
        GenericJournals,
        GenericMovieList,
        GenericReviewers,
        'tinymceEditor': Editor
    },
    data(){
        return{
            commentDialog: false,
            slugMovie: this.slug,
            tinyMceKey: process.env.VUE_TINYMCE_SECRETKEY,
            baseUrl: process.env.VUE_APP_BASEURL,
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            fbID: process.env.FACEBOOK_APP_ID,
            movie: {},
            publish: '',
            movieClassicFlag: null,
            emptyIcon: 'mdi-star-outline',
            fullIcon: 'mdi-star',
            halfIcon: 'mdi-star-half-full',
            loadReview: '',
            loadDetailedReview: '',
            grade: '',
            review: '',
            reviewTitle: '',
            detailedReview: '',
            errors: [],
            detailedReviewErrors: [],
            userWatch: false,
            userWatched: false,
            userFavorite: false,
            userWatching: false,
            activeTab: 0,
            commentTab: null,
            archiveImageDialog: false,
            reviewDeleteDialog: false,
            detailedReviewDeleteDialog: false,
            publishDialog: false,
            reviewCheck: false,
            spoilerCheckBox: false,
            userReactions: null,
            movieReact1: '?',
            movieReactText1: '',
            movieReact2: '?',
            movieReactText2: '',
            movieReact3: '?',
            movieReactText3: '',
            customToolbar: {
                menubar: true,
                height: 500,
                contextmenu: 'link image table',
                content_style: 'img {max-width: 800px;}',
                menu : {
                    file: {title: 'File', items: 'newdocument restoredraft | preview | print | deleteallconversations'},
                    edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace'},
                    view: {title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | fullscreen | showcomments'},
                    insert: {title: 'Insert', items: 'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'},
                    format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat'},
                    tools: {title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount'},
                    table: {title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable'}
                },
                images_file_types: 'jpg, jpeg',
                images_upload_handler: this.uploadContentImage,
                plugins: ['advlist autolink lists link image charmap print preview anchor searchreplace visualblocks fullscreen insertdatetime media table paste wordcount imagetools'],
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
            },
            reactions: [
	            { name: 'Миний "guilty pleasure"', "emoji": "💙", text: "guilty_pleasure", check: false },
                { name: "Киночдын гарын авлага болхуйц кино", "emoji": "📝", text: "movie_guide", check: false },
                { name: "Киноны хөгжим нь маш их таалагдлаа", "emoji": "🎵", text: "awesome_music", check: false },
                { name: "Найруулагчийн төгс ажил", "emoji": "📣", text: "top_notch_directing", check: false },
                { name: "Жүжигчид нь маш сайн тогложээ", "emoji": "🎭", text: "top_notch_act", check: false },
                { name: "Зураг авалт нь төгс болжээ", "emoji": "🎥", text: "top_notch_cinematography", check: false },
                { name: "Сайн кино болжээ, БРАВО!!!", "emoji": "👏", text: "bravo", check: false },
                { name: "Киноны мөч бүхэн таалагдлаа", "emoji": "😍", text: "loved_every_minute", check: false },
                { name: "Дундуур нь унтсан", "emoji": "💤", text: "i_slept", check: false },
                { name: "Шоконд орсон", "emoji": "😳", text: "i_shocked", check: false },
                { name: "Traumatized", "emoji": "😵", text: "i_traumatized", check: false },
                { name: "Хүлээлтэд хүрсэнгүй", "emoji": "😞", text: "i_am_dissapointed", check: false },
                { name: "Яахавдээ л санадлаа", "emoji": "😒", text: "i_am_not_amused", check: false },
                { name: "Үнэхээр хөгжилтэй санагдлаа", "emoji": "😆", text: "it_was_funny", check: false },
                { name: "Маш гунигтай санагдлаа", "emoji": "😢", text: "it_was_sad", check: false },
                { name: "Маш их айлаа", "emoji": "😱", text: "i_was_scared", check: false },
	            { name: "Mind blown", "emoji": "🤯", text: "it_was_mind_blown", check: false },
                { name: "Popcorn movie", "emoji": "🍿", text: "popcorn_movie", check: false }
	        ]
        }
    },
    computed: mapState(['user']),
    methods:{
        gotoId(a){
            var b
            a === 1 ? (b = "critics", this.activeTab = 0) : (b = "audiences", this.activeTab = 1)

            var el = document.getElementById(b)
            if(typeof el !== "undefined" && el !== null) {
                el.scrollIntoView({ behavior: "smooth" })
            }
        },
        uploadContentImage(blobInfo, success){
            var image = blobInfo.blob()
            if(image['type']==='image/jpeg'){
                var formData = new FormData()
                formData.append("image", image, "content-image")

                axios
                    .post('/user/api/v-user-useraddimage/', formData)
                    .then(result => {
                        const url = result.data.url
                        success(url)
                    })
                    .catch(err => {
                    })
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        changeCheck(text, boolean){
            for (var i in this.reactions) {
                if (this.reactions[i].text == text) {
                    this.reactions[i].check = boolean
                    break
                }
            }
        },
        setReaction(movieReact, reaction){
            if(reaction){
                let result = this.reactions.find(x => x.text === reaction)
                if (result) {
                    if(movieReact === 1) this.movieReact1 = result.emoji, this.movieReactText1 = result.text
                    else if(movieReact === 2) this.movieReact2 = result.emoji, this.movieReactText2 = result.text
                    else if(movieReact === 3) this.movieReact3 = result.emoji, this.movieReactText3 = result.text
                    this.changeCheck(result.text, true)
                }
            }
        },
        movieReact(id, item){
            let emoji = '?', text = ''
            if(item !== null) emoji = item.emoji, text = item.text, this.changeCheck(item.text, true)

            if(id === 1) this.changeCheck(this.movieReactText1, false), this.movieReact1 = emoji, this.movieReactText1 = text
            else if(id === 2) this.changeCheck(this.movieReactText2, false), this.movieReact2 = emoji, this.movieReactText2 = text
            else if(id === 3) this.changeCheck(this.movieReactText3, false), this.movieReact3 = emoji, this.movieReactText3 = text

            axios
                .post('/movie/api-movie-react/', { movie: this.slug, react: id, reaction: text })
        },
        getMovieDetail(){
            axios
                .get('/movie/api-movie-detail/', { params: { slug: this.slugMovie } })
                .then(response => { this.movie = response.data.movie_detail,
                                    this.publish = this.movie.publish,
                                    this.movieClassicFlag = this.movie.classic,
                                    document.title = this.movie['i_title'],
                                    this.userWatch = response.data.user_watch,
                                    this.userWatched = response.data.user_watched,
                                    this.userWatching = response.data.user_watching,
                                    this.userFavorite = response.data.user_favorite,
                                    this.userReactions = response.data.user_reactions
                                    })
                .catch(error => { if(error.response.status === 404){ this.$router.push({ name: 'not-found-404'}) } })
        },
        submitReview(load){
            if (this.user.isAuthenticated) {
                this.errors = []

                if(this.grade == '' && this.loadReview){
                    this.errors.push("Та тоон үнэлгээ заавал оруулах шаардлагатай!")
                } else if((this.grade < 0 || this.grade > 100) && this.loadReview){
                    this.errors.push("Та тоон үнэлгээгээ зөв оруулна уу!")
                }

                if(!this.errors.length){
                    this.commentDialog = false
                    axios
                        .post('/movie/api-movie-review/', { grade: this.grade, review: this.review, movie: this.slugMovie, spoiler: this.spoilerCheckBox, load: load })
                        .then(response => {this.grade = response.data.grade, this.spoilerCheckBox = response.data.spoiler, this.review = response.data.review, this.reviewCheck = response.data.review_check})
                }
            }
        },
        deleteReview(){
            axios
                .post('/movie/api-movie-delete-review/', { movie: this.slugMovie })
                this.grade = ''
                this.review = ''
                this.userWatched = false
                this.reviewCheck = false
        },
        submitDetailedReview(load){
            if (this.user.isAuthenticated && this.user.critic) {
                this.detailedReviewErrors = []

                if(this.reviewTitle == '' && this.loadDetailedReview){
                    this.detailedReviewErrors.push("Та гарчигийн нэр заавал өгөх шаардлагатай!")
                }
                if(this.detailedReview == '' && this.loadDetailedReview){
                    this.detailedReviewErrors.push("Та шүүмжээ бичнэ үү!")
                }
                
                const createItems = {
                    'movie': this.slugMovie,
                    'title': this.reviewTitle,
                    'review': this.detailedReview,
                    'load': load
                }

                const createData = new FormData()

                for(var key in createItems){
                    createData.append(key, createItems[key]);
                }

                if(!this.detailedReviewErrors.length){
                    this.commentDialog = false
                    axios
                        .post('/movie/api-movie-detailed-review/', createData)
                        .then(response => { this.reviewTitle = response.data.title, this.detailedReview = response.data.review})
                }
            }
        },
        deleteDetailedReview(){
            axios
                .post('/movie/api-movie-delete-detailed-review/', { movie: this.slugMovie })
            this.reviewTitle = ''
            this.detailedReview = ''
        },
        criticsCall(){
            this.$nextTick(() => {
                if(this.$refs.criticRef){
                    this.$refs.criticRef.totalReviews = []
                    this.$refs.criticRef.movieReviews = []
                    this.$refs.criticRef.movieReview()
                }
            })
        },
        audiencesCall(){
            this.$nextTick(() => {
                if(this.$refs.audienceRef){
                    this.$refs.audienceRef.totalReviews = []
                    this.$refs.audienceRef.movieReviews = []
                    this.$refs.audienceRef.movieReview()
                }
            })
        },
        favoriteUser(){
            if(this.user.isAuthenticated){
                if(this.userFavorite == false){
                    this.userFavorite = true
                } else{
                    this.userFavorite = false
                }
                axios
                    .post('/movie/api-favorite-movie/', { movie: this.slugMovie })
            }
        },
        watchUser(){
            if(this.user.isAuthenticated){
                if(this.userWatch == false){
                    this.userWatch = true
                } else{
                    this.userWatch = false
                }
                axios
                    .post('/movie/api-watch-movie/', { movie: this.slugMovie })
            }
        },
        watchingUser(){
            if(this.user.isAuthenticated){
                if(this.userWatching == false){
                    this.userWatching = true
                } else{
                    this.userWatching = false
                }
                axios
                    .post('/movie/api-watching-movie/', { movie: this.slugMovie })
            }
        },
        publishMovie(){
            if(this.user.isAuthenticated){
                if(this.publish == false){
                    this.publish = true
                } else{
                    this.publish = false
                }
                axios
                    .post('/movie/api-publish-movie/', { movie: this.slugMovie })
            }
        },
        loginRequired(){
            if(!this.user.isAuthenticated) {
                this.$router.push({ name: 'login', query: { redirect:  { name: 'movie-detail', params: { slug: this.slugMovie }}}})
            }
        }
    },
    created(){
        this.getMovieDetail()
        this.submitReview(this.loadReview = false)
        this.submitDetailedReview(this.loadDetailedReview = false)
    },
    watch:{
        slug(){
            window.scrollTo(100,100)
            this.activeTab = 0
            this.slugMovie = this.slug
            this.grade = ''
            this.review = ''
            this.spoilerCheckBox = false,
            this.reviewTitle = ''
            this.detailedReview = ''
            this.getMovieDetail()
            this.submitReview(this.loadReview = false)
            this.submitDetailedReview(this.loadDetailedReview = false)
            this.userReactions = null
            this.movieReact1 = '?'
            this.movieReactText1 = ''
            this.movieReact2 = '?'
            this.movieReactText2 = ''
            this.movieReact3 = '?'
            this.movieReactText3 = ''
            for (var i in this.reactions) {
                this.reactions[i].check = false
            }
        },
        userReactions(){
            if(this.userReactions !== null){
                this.setReaction(1, this.userReactions['react1'])
                this.setReaction(2, this.userReactions['react2'])
                this.setReaction(3, this.userReactions['react3'])
            }
        }
    }
}
</script>

<style scoped src="@/assets/styles/movie_serial.css">
</style>