<template>
    <div>
        <legend class="generic-list__legendbar" id="generic-list__title"><strong>{{ listTitle }}:</strong></legend>
        <v-card v-for="(journal, index) in contentList" :key="index">
            <PublishedJournalCard :journal="journal" :index="journal.id"/>
        </v-card>
        <v-pagination v-model="page" :length="journalPage" @input="getUserJournalList()" :total-visible="7"></v-pagination>
    </div>
</template>

<script>
import axios from 'axios'
import PublishedJournalCard from '@/components/user/GenericUserJournalCard.vue'

export default {
    props: {
        slug: String
    },
    components: {
        PublishedJournalCard
    },
    data(){
        return{
            routeName: this.$route.name,
            listTitle: '',
            requestUserSlug: this.slug,
            contentList: [],
            page: 1,
            journalPage: 0,
        }
    },
    created(){
        this.getUserJournalList()
    },
    watch: {
        $route(to, from){
            this.routeName = to.name
            this.page = 1
            this.journalPage = 0
            this.contentList = []
            this.getUserJournalList()
        }
    },
    methods:{
        getUserJournalList(){
            setTimeout(function() {
                var el = document.getElementById('generic-list__title')
                if(typeof el !== "undefined" && el !== null) {
                    el.scrollIntoView({ behavior: "smooth" })
                }
            }, 500)

            var url 
            if (this.routeName === 'published-journals') {
                url = '/user/api/v-user-journallist/'
                this.listTitle = "Нийтлүүлсэн нийтлэлүүд"
            } else if (this.routeName === 'unpublished-journals') {
                url = '/user/api/v-user-unpublishedjournallist/'
                this.listTitle = "Нийтлүүлээгүй нийтлэлүүд"
            }
            axios
                .get(url, { params: { slug: this.requestUserSlug, page: this.page }})
                .then((response) => { this.contentList = response.data.api_user_journal_list, this.journalPage = response.data.page })
        }
    }
}
</script>
<style>

.generic-list__legendbar {
    border-bottom: #0066b5 1px solid;
    color: #0066b5;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
    margin-bottom: 5px;
}

</style>