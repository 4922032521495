<template>
    <router-link :to="{ name: url, params: { slug: slug } }">
        <img :src="mediaUrl + '/' + poster" class="column rounded"/>
    </router-link>
</template>

<script>
export default {
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL
        }
    },
    props: {
        type: String,
        movie: Object
    },
    computed: {
        url() {
            return this.type === "movie" ? "movie-detail" : "serial-detail"
        },
        slug() {
            return this.type === "movie" ? this.movie.movie_slug : this.movie.serial_slug
        },
        poster() {
            return this.type === "movie" ? this.movie.movie_poster : this.movie.serial_poster
        }
    }
}
</script>

<style scoped>

.column {
    width: 100%;
}

</style>
