<template>
    <v-card class="journal-card__box">
        <router-link :to="{ name: 'journal-detail', params: { typeSlug: journal.journal_type__main_type__type_slug, subTypeSlug: journal.journal_type__type_slug, slug: journal.journal_slug }}">
            <v-img :src="baseUrl + '/media/' + journal.journal_cover" class="rounded journal-card__image"/>
        </router-link>
        <div class="list-card__user_box">
            <div class="journal-card__txt">
                <img class="rounded-circle journal-card__user_image" :src="baseUrl + '/media/' + journal.user__image"/>
                <div class="journal-card__username"><strong>{{ journal.user__username }}</strong></div>
            </div>
        </div>
        <div class="journal-card__type"><v-chip class="journal-card__type_color" outlined>{{ journal.journal_type__type_mongol_name }}</v-chip></div>
        <div class="journal-card__title">{{ journal.journal_title }}</div>
        <div class="journal-card__content" v-html="journal.content + '...'"></div>
    </v-card>
</template>

<script>
export default {
    props: {
        journal: Object
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL
        }
    }    
}
</script>
<style scoped>

.journal-card__box {
    display: flex;
    flex-direction: column;
}

.journal-card__image {
    object-fit: cover;
    height: 250px;
}

.journal-card__title {
    margin: 15px;
    font-size: 24px;
    font-weight: 600;
}

.journal-card__content {
    margin: 15px;
    font-size: 16px;
    font-weight: 200;
}

.list-card__user_box {
    padding: 0 10px;
    position: relative;
}


.journal-card__txt {
    top: -40px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
}

.journal-card__user_image {
    border: 3px solid white;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.journal-card__type {
    margin: 35px 15px 0;
    width: 100%;
}

.journal-card__username {
    font-size: 12px;
    margin-top: auto;
    padding: 0 10px;
    flex: 100%;
    font-weight: bold;
}

.journal-card__type_color {
    color: #696969;
    font-size: 14px;
    font-weight: 600;
}

</style>