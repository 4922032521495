<template>
    <div class="awardMargin" v-show="totalAwards != ''">
        <div class="award__legend_bar"><strong>Нэр дэвшсэн / хүртсэн шагналууд</strong></div>
        <div class="row">
            <GenericAwardCard v-for="(award, index) in totalAwards" :key="index" :award="award"/>
        </div>
        <div class="load-more-btn">
            <button type="button" class="btn btn-outline" v-if="endPoint" @click="movieAwards(loadMore += 1)">Бусад шагналууд</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import GenericAwardCard from '@/components/generics/movie_serial/GenericAwardCard.vue'

export default {
    props: {
        type: String,
        slug: String
    },
    components: {
        GenericAwardCard
    },
    data(){
        return{
            movieSlug: this.slug,
            totalAwards: [],
            awardList: [],
            loadMore: 1,
            endPoint: ''
        }
    },  
    methods:{
        movieAwards(){
            var url
            this.type === "movie" ? url = '/movie/api-movie-award-list/' : url = '/serial/api-serial-award-list/'

            axios
                .get(url, {params: {slug: this.movieSlug, loadMore: this.loadMore}})
                .then(response => {this.awardList = response.data.awards, this.endPoint = response.data.end_point, this.totalAwards = this.totalAwards.concat(this.awardList)})  
            
            console.log(this.awardList)
        }
    },
  created(){
    this.movieAwards()
  },
    watch:{
        slug(){
            this.totalAwards = []
            this.awardList = []
            this.movieSlug = this.slug
            this.loadMore = 1
            this.endPoint = ''
            this.movieAwards()
        }
    }
}
</script>

<style scoped>

.awardMargin {
    padding: 20px 5px 0;
}

.award__legend_bar {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
}

.row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    margin: 0 auto;
}

.load-more-btn {
    text-align: center;
}

.load-more-btn button {
    background-color: #696969;
    color: #FFFFFF;
}

@media (min-width: 481px) {
    .row {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}

</style>