<template>
    <div>
        <div class="create_movie__legend_bar">Уран бүтээлч нэмэх:</div>
        <v-card class="pa-2" outlined>
            <div class="create_movie__artist_block">
                <div class="create_movie__artist_search">
                    <v-autocomplete no-filter v-model="selectedArtist" height="100" :items="searchItems" hide-no-data return-object :search-input.sync="searchArtist" clearable item-text="name" label="Уран бүтээлч" attach solo>
                        <template v-slot:selection="data">
                            <v-avatar left height="75" tile class="mx-3"><img :src="mediaUrl + '/' + data.item['photo']" class="rounded"></v-avatar>
                            <div mx="2">{{ data.item['pro_name'] }}</div>
                        </template>
                        <template v-slot:item="data">
                            <template v-if="data.item !== 'object'">
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['photo']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['pro_name']"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-avatar height="60" tile><img :src="mediaUrl + '/' + data.item['photo']" class="rounded"></v-list-item-avatar>
                                <v-list-item-content v-text="data.item['pro_name']"></v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </div>
                <div class="create_movie__artist_role">
                    <v-combobox v-model="artistJob" :items="artistJobItems" label="Үүрэг*" multiple></v-combobox>
                </div>
            </div>
            <v-btn :color="toggleColor" block @click="addArtist">{{ toggleButton }}</v-btn>
        </v-card>
        <br>
        <div v-show="artistList.length === 0" class="generic-list__row">
            <v-card v-for="index in 3" :key="index" class="create_movie__artist_mock_card">
                <v-card class="create_movie__artist_mock_card_img"></v-card>
            </v-card>
        </div>
        <draggable v-show="artistList" v-model="artistList" class="generic-list__row" tag="ul" v-bind="dragOptions" @start="drag = true" @end="drag = false">
            <CreateArtistCard v-for="(artist, index) in artistList" :key="index" :type="'artist'" :edited="beingEdited" :artist="artist" v-on:delete-artist="removeArtist(index)" v-on:edit-artist="editArtist(index, artist)"/>
        </draggable>
    </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import CreateArtistCard from '@/components/generics/movie_serial/GenericCUArtistCard.vue'

export default {
    props: {
        artistJobItems: Array,
        festival: Boolean
    },
    components: {
        draggable,
        CreateArtistCard
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            artistList: [],
            searchItems: [],
            loadMore: 0,
            searchArtist: null,
            selectedArtist: '',
            artistJob: '',
            toggleButton: "Уран бүтээлч нэмэх",
            toggleColor: "primary",
            beingEdited: false,
            drag: false,
        }
    },
    computed:{
        dragOptions(){
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    watch:{
        searchArtist(val){
            if(val){
                axios
                    .get('/artist/api-search-artist-list/', { params: { artist: val, festival: this.festival }})
                    .then(response => {this.searchItems = response.data.artist_list})
            }
        }
    },
    methods:{
        addArtist(){
            this.toggleButton = "Уран бүтээлч нэмэх"
            this.toggleColor = "primary"
            this.beingEdited = false
            if(this.selectedArtist && this.artistJob && this.artistList.findIndex(i => i.id === this.selectedArtist['id']) == -1){
                this.artistList.push({id: this.selectedArtist['id'], photo: this.selectedArtist['photo'], pro_name: this.selectedArtist['pro_name'], job: this.artistJob.join(', ')})
            }
            this.selectedArtist = null
            this.artistJob = null
            this.searchItems = []
            this.$emit('submit-button', false)
        },
        editArtist(index, artist){
            var jobs = artist['job'].split(',')
            var total = []
            this.searchItems.push(artist)
            for(var i=0; i<jobs.length; i++){
                total.push(this.artistJobItems.find(x => x === jobs[i].trim()))
            }
            this.selectedArtist = artist
            this.artistJob = total
            this.toggleButton = "Уран бүтээлч засах"
            this.toggleColor = "amber"
            this.beingEdited = true
            this.artistList.splice(index, 1)
            this.$emit('submit-button', true)
        },
        removeArtist(index){
            this.artistList.splice(index, 1)
        }
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>