<template>
    <div class="festival_detail__box">
        <span v-if="!error">
            <div class="ma-2 text-center">
                <h2><strong class="festival_detail__title">GGIFF</strong> {{ year }}</h2>
                <h1 class="festival_detail__name">{{ title }}</h1>
                
            </div>
            <div v-html="agenda"></div>
        </span>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    props: ['slug', 'year'],
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            festivalAgenda: '',
            festivalAgendaEn: '',
            error: ''
        }
    },
    computed:{
        title(){
            return this.language === "MN" ? "Хөтөлбөр" : "Programm"
        },
        agenda(){
            return this.language === "MN" ? this.festivalAgenda : this.festivalAgendaEn
        },
        ...mapState(['user','language'])
    },
    methods:{
        getFestivalAgenda(){
            axios
                .get('/award/annual-detail/', { params: { slug: this.slug, year: this.year }})
                .then(response => { this.festivalAgenda = response.data.agenda, this.festivalAgendaEn = response.data.agenda_en })
        }
    },
    created(){
        document.title = 'Кино наадам'
        this.getFestivalAgenda()
    }
}
</script>

<style>

.festival_detail__box {
    min-width: 320px;
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
}

.festival_detail__title {
    color: #A9A9A9;
}

.festival_detail__name {
    color: #0066b5;
    font-size: 48px;
    font-weight: 400;
}


@media (min-width: 481px) {

    .festival_detail__box {
        max-width: 1280px;
    }

}
</style>