<template>
    <div>
        <div class="create_movie__legend_bar">Ишлэл нэмэх:</div>
        <v-card class="pa-2" outlined>
            <v-card-text>
                <v-textarea v-model="artistQuote" label="Алдартай ишлэл"></v-textarea>
                <br>
                <v-btn color="primary" class="mb-5" block @click="addQuote()">Нэмэх</v-btn>
                <v-card v-for="(quote, index) in artistQuotes" :key="index" class="pa-1 ma-1">
                    <div class="pa-3">
                        <p>" {{ quote.quote }} "</p>
                        <div class="create_movie__del_btn">
                            <v-btn class="mx-2" fab dark x-small color="red" v-on:click="removeQuote(index)"><v-icon dark>mdi-minus</v-icon></v-btn>
                        </div>
                    </div>
                </v-card>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    data(){
        return{
            artistQuote: '',
            artistQuotes: []
        }
    },
    methods:{
        addQuote(){
            if(this.artistQuote !== '' && !/^ *$/.test(this.artistQuote)){
                this.artistQuotes.push({ quote: this.artistQuote })
                this.artistQuote = ''
            }
        },
        removeQuote(index){
            this.artistQuotes.splice(index, 1)
        }
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>