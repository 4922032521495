<template>
    <div>
        <div class="d-flex justify-space-around">
            <v-btn class="short-message-comment__btn_block" icon @click="commentsSectionToggle(commentSection = false)"><v-icon>mdi-comment</v-icon>{{ total }}</v-btn>
            <v-btn class="short-message-comment__btn_block" icon @click="commentSectionToggle(commentsSection = false)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn class="short-message-comment__btn_block" icon @click="likeComment()"><v-icon>{{ reviewHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon> {{ reviewCount }}</v-btn>
        </div>
        <div class="ma-5" v-show="commentsSection">
            <span v-for="comment in comments" :key="comment.id">
                <CommentCard :comment='comment' :id='comment.id' :message="messageid"/>
            </span>
        </div>
        <div v-if="isAuth" class="ma-5" v-show="commentSection">
            <v-form v-on:submit.prevent="submitComment(commentSection = false)">
                <v-textarea solo v-model="comment" label="Сэтгэгдэл"></v-textarea>
                <span class="d-flex justify-end">
                    <v-btn type="submit" class="short-message-comment__submit">Хадгалах</v-btn>
                </span>
            </v-form>
        </div>
        <div v-else class="text-center">
            <div class="short-message-comment__login">Та сэтгэгдэл үлдээхийн тулд нэвтрэнэ үү!</div>
            <small class="text-muted">Хэрвээ та бүртгэлтэй бол<router-link class="navbar-text" :to="{ name: 'login' }"> ЭНД ДАРЖ </router-link>нэвтрэнэ үү!</small><br>
            <small class="text-muted">Харин бүртгэлгүй бол<router-link class="navbar-text" :to="{ name: 'signup' }"> БҮРТГЭХ </router-link>хэсэгт бүртгүүлнэ үү!</small>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CommentCard from '@/components/user/CommentCard.vue'

export default {
    props: {
        messageid: Number,
        count: Number,
        user_like: Boolean,
        total_likes: Number
    },
    components: {
        CommentCard
    },
    data(){
        return{
            isAuth: this.$store.state.user.isAuthenticated,
            commentSection: false,
            commentsSection: false,
            comment: '',
            comments: '',
            total: this.count,
            reviewHeart: this.user_like,
            reviewCount: this.total_likes
        }
    },
    methods:{
        submitComment(){
            axios
                .post('/user/api/v-user-submitcomment/', { message: this.messageid, comment: this.comment })
            
            this.total++
            this.comment = ''
        },
        getComments(){
            axios
                .get('/user/api/v-user-commentlist/', { params: { message: this.messageid }})
                .then(response => { this.comments = response.data.comments, this.total = response.data.counts })
        },
        commentSectionToggle(){
            this.commentSection = !this.commentSection
            
        },
        commentsSectionToggle(){
            this.commentsSection = !this.commentsSection

            if(this.commentsSection === true){
                this.getComments()
            }
        },
        likeComment(){
            if(this.reviewHeart == true){
                this.reviewHeart = false
                this.reviewCount--
            } else {
                this.reviewHeart = true
                this.reviewCount++
            }
            axios
                .post('/user/api/v-user-liketweet/', { "message": this.messageid })
        }
    },
}
</script>

<style>

.short-message-comment__btn_block {
    color: #0066b5 !important;
    font-size: 12px;
}

.short-message-comment__submit {
    color: white;
    background-color: #0066b5;
}

.short-message-comment__login {
    margin: 20px;
    font-weight: bold;
}

</style>