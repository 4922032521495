<template>
    <div v-if="user.isAuthenticated && user.contributer" class="create_movie">
        <div class="create_movie__title">Та "{{ serialTitle }}" цувралын "{{ seasonTitle }}" бүлгийг засах гэж байна!</div>
        <v-divider class="mx-4"></v-divider>
        <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitUpdate">
            <div class="create_movie__block">
                <div class="create_movie__image_block">
                    <v-card><v-img :src="url + serialPoster"/></v-card>
                    <v-text-field mx="2" v-model="serialTitle" disabled></v-text-field>
                    <v-card><v-img :src="seasonPosterURL"/></v-card>
                    <v-text-field v-model="seasonPosterCheck" disabled label="Постер" :rules="[rules.required]"></v-text-field>
                    <v-dialog v-model="posterDialog" width="600px" transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Постер нэмэх</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="posterDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                <v-toolbar-title>Сезоны постер зураг</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 240, height: 360}" :canvas="{width: 240, height: 360}" image-restriction="stencil"/>
                            <div class="button-wrapper">
                                <button class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                </button>
                                <button class="button" @click="cropPoster">Зураг хуулах</button>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="create_movie__title_block">
                    <v-text-field v-model="seasonNumber" type="number" label="Бүлгийн дугаар*" required :rules="[rules.number, rules.required]"></v-text-field>
                    <v-text-field v-model="seasonTitle" label="Бүлгийн нэр*" required :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="seasonTrailer" label="Трейлэр" required></v-text-field>
                </div>
            </div>
            <div class="create_movie__block_content">
                <v-card-actions class="justify-center">
                    <v-btn :disabled="checkValid" @click="validate" type="submit" class="create_movie__submit_btn" block>Хадгалах</v-btn>
                </v-card-actions>
            </div>
        </v-form>
        <v-divider class="mx-4 ma-5"></v-divider>
        <router-link class="update_movie__no_decoration" :to="{ name: 'serial-detail', params: { slug: serialSlug }}">
            <v-btn class="update_movie__back_btn">Буцах</v-btn>
        </router-link>
    </div>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import { mapState } from 'vuex'
import 'vue-advanced-cropper/dist/style.css'

export default {
    components: {
        Cropper
    },
    props: {
        serialSlug: String,
        seasonSlug: String
    },
    data(){
        return{
            url: process.env.VUE_APP_BASEURL,
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            image: "",
            posterDialog: false,
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                autoChoice: value => !true || !!value || 'Цуврал сонгох шаардлагатай',
                number: value => /^[0-9]+$/.test(value) || 'Та зөвхөн тоо оруулна уу!',
                imageSize: value => !value || value.size < 1000000 || 'Зургийн хэмжээ 1 MB -с хэтрэхгүй байх шаардлагатай!',
                imageRequire: value => !!value || 'Зураг оруулах шаардлагатай!'
            },
            season: {},
            serialPoster: '',
            serialTitle: '',
            seasonPoster: null,
            seasonPosterURL: null,
            seasonPosterCheck: null,
            seasonNumber: '',
            seasonTitle: '',
            seasonTrailer: ''
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user'])
    },
    methods:{
        validate(){
            this.$refs.form.validate()
        },
        getSeasonDetail(){
            axios
                .get('/serial/api-season-detail/', {params: {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug}})
                .then(response => { this.season = response.data.season_detail, 
                                    document.title = this.season['serial_title'],
                                    this.seasonNumber = this.season['season_num'],
                                    this.seasonTitle = this.season['season_name'],
                                    this.seasonTrailer = this.season['season_trailer'],
                                    this.seasonPosterURL = this.url + this.season['season_poster'],
                                    this.seasonPosterCheck = 'season-poster',
                                    this.serialPoster = this.season['serial_poster'],
                                    this.serialTitle = this.season['serial_title']
                                    })
        },
        submitUpdate(){
            const updateItems = {
                'serial_slug': this.serialSlug,
                'season_slug': this.seasonSlug,
                'season_number': this.seasonNumber,
                'season_title': this.seasonTitle,
                'season_trailer': this.seasonTrailer
            }

            const updateData = new FormData()

            if(this.seasonPoster){
                updateData.append('season-poster', this.seasonPoster, 'season_poster')
            }

            for( var key in updateItems ){
                updateData.append(key, updateItems[key])
            }

            axios
                .post('/serial/api-update-season/', updateData)
                .then((response) => {
                    if(response){
                        this.$store.commit('SET_SNACK', {message: 'Цувралын бүлгийн засвар амжилттай хийгдлээ!', color: 'green'})
                        this.$router.push({name: 'season-detail', params: {serialSlug: this.serialSlug, seasonSlug: this.seasonSlug}})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        },
        cropPoster() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.seasonPoster = blob }, "image/jpeg")
            this.seasonPosterURL = canvas.toDataURL("jpeg")
            this.seasonPosterCheck = 'season-poster'
            this.posterDialog = false
            this.image = ''
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])
                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        }
    },
    created(){
        document.title = "Бүлэг засах"
        this.getSeasonDetail()
    }
}
</script>
<style scoped src="@/assets/styles/CU_artist.css">
</style>
