<template>
    <v-container v-if="user.isAuthenticated && user.mainContributer" class="d-flex justify-center">
        <v-card style=" width: 600px;" class="pa-1" :disabled="$store.state.isLoading">
            <v-card-title class="justify-center">Уран бүтээлчийн код нэмэх</v-card-title>
            <div v-if="error" style="text-align: center; color: red;"><p>{{ error }}</p></div>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-text-field v-model="artist" label="Код"></v-text-field>
                <br>
                <v-btn color="primary" class="mb-5" block @click="addCode()">Нэмэх</v-btn>
                <v-card v-for="(artist, index) in artistList" :key="index" class="pa-1 ma-1">
                    <v-row class="pa-3">
                        <v-col>
                            <p>{{ artist }}</p>
                        </v-col>
                        <div style="position: absolute; top: 5px; right: 1px;">
                            <v-btn class="mx-2" fab dark x-small color="red" v-on:click="removeCode(index)"><v-icon dark>mdi-minus</v-icon></v-btn>
                        </div>
                    </v-row>
                </v-card>
            </v-card-text>
            <v-btn block style="color: #FFF; background-color: green;" :disabled="artistList.length === 0" v-on:click="send()">Илгээх</v-btn>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    data(){
        return{
            artist: '',
            error: '',
            artistList: []
        }
    },
    computed: mapState(['user']),
    methods:{
        addCode(){
            if(this.artist !== '' && /^[0-9]*$/.test(this.artist) && !this.artistList.find(item => item === this.artist)){
                this.artistList.push(this.artist)
                this.artist = ''
            } else {
                this.error = 'Хоосон зай болон үсэг авч болохгүй, мөн код давхардсан эсэхээ шалгана уу!'
            }
        },
        removeCode(index){
            this.artistList.splice(index, 1)
        },
        send(){
            this.$store.commit('SET_ISLOADING', true)
            axios
                .post('/add-artist/', { list: this.artistList })
                .then(response => {
                    if(response){
                        this.$store.commit('SET_ISLOADING', false)
                        if(response.data.success === false){
                            this.$store.commit('SET_SNACK', {message: response.data.message, color: 'warning'})
                        } else {
                            this.$store.commit('SET_SNACK', {message: response.data.message, color: 'success'})
                        }
                        this.$router.push({ name: 'home' })
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_ISLOADING', false)
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, webmaster -тай холбогдоно уу!', color: 'red'})
                    }
                })
        }
    },
    created(){
        document.title = "Уран бүтээлч нэмэх"
    }
}
</script>

<style>

[v-cloak] .v-cloak--hidden{
    display: none;
}

</style>