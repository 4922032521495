<template>
    <v-footer dark padless>
        <v-card tile dense dark class="lighten-1 white--text text-center" color="#3384C3 " width="100%">
            <v-card-text>
                <span class="mx-4">
                    <v-btn text rounded ><router-link style="text-decoration: none; color: white;" :to="{ name: 'home' }">Нүүр хуудас</router-link></v-btn>
                    <v-btn text rounded ><router-link style="text-decoration: none; color: white;" :to="{ name: 'about-us' }">Бидний тухай</router-link></v-btn>
                    <v-btn text rounded ><router-link style="text-decoration: none; color: white;" :to="{ name: 'journal-list' }">Сэтгүүл</router-link></v-btn>
                </span>
                <span>|</span>
                <span class="mx-4">
                    <v-btn icon><a :href="connections.email.link" target="_blank"><v-icon class="mx-4 white--text" size="24px">{{ connections.email.icon }}</v-icon></a></v-btn>
                    <v-btn icon><a :href="connections.twitter.link" target="_blank"><v-icon class="mx-4 white--text" size="24px">{{ connections.twitter.icon }}</v-icon></a></v-btn>
                    <v-btn icon><a :href="connections.facebook.link" target="_blank"><v-icon class="mx-4 white--text" size="24px">{{ connections.facebook.icon }}</v-icon></a></v-btn>
                    <v-btn icon><a :href="connections.instagram.link" target="_blank"><v-icon class="mx-4 white--text" size="24px">{{ connections.instagram.icon }}</v-icon></a></v-btn>
                </span>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="white--text">
                {{ new Date().getFullYear() }} - <strong>©MFI </strong> /made by community/
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    data(){
        return{
            connections: {
                email: {icon: 'mdi-email', link: 'mailto:info@mfi.mn' },
                twitter: {icon: 'mdi-twitter', link: 'https://twitter.com/MongolianFilmInstitute' },
                facebook: {icon: 'mdi-facebook', link: 'https://facebook.com/MongolianFilmInstitute' },
                instagram: {icon: 'mdi-instagram', link: 'https://instagram.com/mongolianfilminstitute.ngo' }
            }
        }
    }
}
</script>

<style scoped>

* {
    text-transform: none !important;
}

.navbar-text {
    text-decoration: none;
    font-size: 14px;
}


</style>