<template>
    <div class="movieMargin" v-show="totalSeasons != '' && ( published || user.contributer && !published )">
        <legend class="serial__legend_bar"><strong>Цувралын {{ published ? '' : "нийтлэгдээгүй" }} сезонууд:</strong></legend>
        <div class="row">
            <SeasonCard v-for="(season, index) in totalSeasons" :key="index" :serialSlug="slug" :season="season"/>
        </div>
        <div class="load-more-btn">
            <v-btn block outlined color="#0066b5" text v-if="endPoint" @click="serialSeasons(loadMore += 1)">Бусад сезонууд</v-btn>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import SeasonCard from '@/components/serial/detail/SeasonCard.vue'
import { mapState } from 'vuex'

export default {
    props: {
        published: Boolean,
        slug: String,
        height: Number
    },
    components: {
        SeasonCard
    },
    computed: mapState(['user']),
    data(){
        return{
            serialSlug: this.slug,
            totalSeasons: [],
            seasonList: [],
            loadMore: 1,
            endPoint: ''
        }
    },
    methods:{
        serialSeasons(){
            axios
                .get('/serial/api-serial-seasons/', {params: {slug: this.serialSlug, published: this.published, loadMore: this.loadMore}})
                .then(response => {this.seasonList = response.data.seasons, this.endPoint = response.data.end_point, this.totalSeasons = this.totalSeasons.concat(this.seasonList)})
        }
    },
    created(){
        this.serialSeasons()
    },
    watch:{
        slug(){
            this.totalSeasons = []
            this.seasonList = []
            this.serialSlug = this.slug
            this.loadMore = 1
            this.endPoint = ''
            this.serialSeasons()
        }
    }
}
</script>

<style scoped>

.movieMargin{
    margin: 20px 5px 0;
}

.serial__legend_bar {
    color: #0066b5;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
}

.row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    margin: 0 auto;
}

.load-more-btn {
    text-align: center;
}

@media (min-width: 481px) {
    .row {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
}

</style>