<template>
    <div class="movie-review__box">
        <div class="block movie-review__title">
            <h2><strong class="movie-review__category">MFI</strong> ШҮҮМЖ</h2>
            <br>
            <h1 class="movie-review__main_title"><strong>{{ review.title }}</strong></h1>
            <div class="movie-review__legendbar">
                <div class="movie-review__legendbar_username">
                    <router-link class="movie-review__legendbar_userlink" :to="{ name: 'user-feed', params: { slug: review.user_slug }}">
                        {{ review.user }}
                    </router-link> | {{ review.published }}
                </div>
                <div class="movie-review__legendbar_like">
                    <button v-if="isAuth" @click="reviewLike(review.id)">
                        <v-icon class="movie-review__like_btn">{{ reviewHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon> <small>{{ reviewLikeCount }}</small>
                    </button>
                </div>
            </div>
        </div>
        <div class="movie-review__block">
            <div class="block movie-review__review">
                <div class="movie-review__review_content" v-html="review.content"></div>
            </div>
            <div class="block movie-review__content">
                <legend class="movie-review__content_legendbar"><strong>Холбоотой {{ relatedMovie }}:</strong></legend>
                <div class="movie-review__container_movie">
                    <router-link :to="routeName === 'movie-review-detail' ? { name: 'movie-detail', params: { slug: movie.slug }} : { name: 'serial-detail', params: { slug: movie.slug }}">
                        <img :src="url + movie.poster" class="rounded movie-review__content_movie"/>
                    </router-link>
                </div>
                <div v-if="review.recent_critics">
                    <legend class="movie-review__content_legendbar"><strong>{{ review.user }} -н өмнөх шүүмжүүд:</strong></legend>
                    <div class="d-flex flex-wrap justify-center">
                        <v-card class="mb-2 movie-review__recent_card" v-for="(r_review, index) in review.recent_critics" :key="index">
                            <router-link :to="routeName === 'movie-review-detail' ? { name: 'movie-review-detail', params: { userSlug: userSlug, movieSlug: r_review.slug }} : { name: 'episode-review-detail', params: { userSlug: userSlug, serialSlug: r_review.serial_slug, seasonSlug: r_review.season_slug, episodeSlug: r_review.episode_slug }}">
                                <v-img :src="url + r_review.poster" class="rounded movie-review__recent_image"/>
                            </router-link>
                            <div class="movie-review__recent_content">
                                <div class="movie-review__recent_title"><strong>{{ r_review.title }}</strong></div>
                                <legend class="movie-review__recent_date" ><small class='text-muted'>{{ r_review.published }}</small></legend>
                            </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        userSlug: String,
        movieSlug: String,
        serialSlug: String,
        seasonSlug: String,
        episodeSlug: String
    },
    data(){
        return{
            routeName: this.$route.name,
            isAuth: this.$store.state.user.isAuthenticated,
            url: process.env.VUE_APP_BASEURL,
            review: {},
            reviewHeart: '',
            reviewLikeCount: 0,
            movie: {},
            relatedMovie: ''
        }
    },
    created(){
        this.getReviewDetail()
    },
    computed: {
        multpleProps() {
            return `${this.movieSlug}|${this.serialSlug}|${this.seasonSlug}|${this.episodeSlug}`
        }
    },
    watch: {
        multpleProps() {
            this.review = {}
            this.movie = {}
            this.routeName = this.$route.name
            window.scrollTo(100,100)
            this.getReviewDetail()
        }
    },
    methods:{
        getReviewDetail(){
            if (this.routeName === 'movie-review-detail') {
                this.relatedMovie = 'кино'
                axios
                    .get('/movie/api-review-detail/', { params: { slug: this.userSlug, movieSlug: this.movieSlug } })
                    .then(response => { this.review = response.data.review_detail, this.reviewLikeCount = this.review.total_likes, this.reviewHeart = this.review.user_like, document.title = this.review['title'], this.movie = this.review.review_movie })
            } else if (this.routeName === 'episode-review-detail') {
                this.relatedMovie = 'цуврал'
                axios
                    .get('/serial/episode-detailed-review-detail/', { params: { slug: this.userSlug, serialSlug: this.serialSlug, seasonSlug: this.seasonSlug, episodeSlug: this.episodeSlug } })
                    .then(response => { this.review = response.data.review_detail, this.reviewLikeCount = this.review.total_likes, this.reviewHeart = this.review.user_like, document.title = this.review['title'], this.movie = this.review.review_movie })
            }
            
        },
        reviewLike(review_id){
            if (this.isAuth) {
                if(this.reviewHeart == true){
                    this.reviewHeart = false
                    this.reviewLikeCount--
                } else{
                    this.reviewHeart = true
                    this.reviewLikeCount++
                }
            if (this.routeName === 'movie-review-detail') {
                axios
                    .post('/movie/api-like-detailed-review/', { id: review_id })
            } else if (this.routeName === 'episode-review-detail') {
                axios
                    .post('/serial/api-like-detailed-review/', { id: review_id })
            }
            
            }
        }
    }
}
</script>

<style scoped src="@/assets/styles/journal_detail.css">
</style>