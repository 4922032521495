<template>
    <v-container v-if="totalAwards != ''" style="max-width: 1280px;">
        <legend class="legendBar"><strong style="color: #FFFFFF;">Нэр дэвшсэн / хүртсэн шагналууд</strong></legend>
        <div class="awardList" :style="{height: computedHeight}">
            <v-col class="d-flex flex-wrap" ref="awardListRef">
                <AwardCard v-for="(award, index) in totalAwards" :key="index" :award="award"/>
            </v-col>
        </div>
        <div style="text-align: center;">
            <button type="button" class="btn btn-outline" style="background-color: #696969; color:#FFFFFF;" v-if="endPoint" @click="artistAwards(loadMore += 1)">Бусад шагналууд</button>
        </div>
    </v-container>
</template>

<script>
import AwardCard from '@/components/artist/AwardCard.vue'

import axios from 'axios'

export default {
    props: {
        slug: String
    },
    components: {
        AwardCard
    },
    data(){
        return{
            artistSlug: this.slug,
            totalAwards: [],
            awardList: [],
            loadMore: 1,
            endPoint: '',
            computedHeight: 0
        }
    },  
    methods:{
        artistAwards(){
            axios
                .get('/artist/api-artist-awards/', { params: { slug: this.artistSlug, loadMore: this.loadMore}})
                .then(response => {this.awardList = response.data.awards, this.endPoint = response.data.end_point, this.totalAwards = this.totalAwards.concat(this.awardList)})
            },
    },
    created(){
        this.artistAwards()
    },
    watch:{
        slug(){
            this.totalAwards = []
            this.awardList = []
            this.artistSlug = this.slug
            this.loadMore = 1
            this.endPoint = ''
            this.artistAwards()
        }
    },
    updated(){
        if(this.$refs.awardListRef){
            this.computedHeight = this.$refs.awardListRef.offsetHeight + "px"
        }
    }
}
</script>

<style scoped>

.awardList {
    overflow: hidden;
    transition: 2s;
}

.legendBar {
    font-size: 16px;
    margin: auto;
    width: 100%;
}

.loadMoreButton {
    color:#FFFFFF;
    background-image: linear-gradient(#FFFFFF, #696969);
}

</style>