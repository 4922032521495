import { render, staticRenderFns } from "./GenericUpdateArtist.vue?vue&type=template&id=5d5afad0&scoped=true"
import script from "./GenericUpdateArtist.vue?vue&type=script&lang=js"
export * from "./GenericUpdateArtist.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/CU_artist.css?vue&type=style&index=0&id=5d5afad0&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d5afad0",
  null
  
)

export default component.exports