<template>
    <router-link :to="{ name: 'movie-detail', params: { slug: movie.movie_slug }}">
        <v-tooltip top color="#0066b5" :disabled="!movie.mongol_title">
            <template v-slot:activator="{ on, attrs }">
                <img v-bind="attrs" v-on="on" :src="movie.movie_poster" class="column rounded"/>
            </template>
            <span class="molc-1">{{ movie.mongol_title }}</span>
        </v-tooltip>
    </router-link>
</template>

<script>
export default {
    props: {
        movie: Object
    }
}
</script>

<style scoped>

.column {
    width: 100%;
}

.molc-1 {
    font-size: 18px;
}

</style>
