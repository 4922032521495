<template>
    <div v-if="user.isAuthenticated && ustga" class="register_main">
        <v-card :disabled="isLoading">
            <div v-if="error">
                <v-container class="d-flex justify-center">
                    <h1>{{ error }}</h1>
                    <p>
                        <router-link
                            class="register_no_decoration"
                            :to="{ name: 'home' }"
                            >Нүүр хуудсанд очих?</router-link
                        >
                    </p>
                </v-container>
            </div>
            <v-stepper v-else-if="error === ''" v-model="step" vertical>
                <v-stepper-step :complete="step > 1" step="1">
                    <span class="register_step_name">Бүртгүүлэхийн өмнө</span>
                </v-stepper-step>

                <v-stepper-content step="1">
                    <v-card class="mb-12 pa-2" outlined>
                        <div>
                            <p>
                                <span
                                    >Сайн байна уу, Алтан Өргөө олон улсын кино
                                    наадам 2023-ын Монгол уралдаант хэсэгт
                                    киногоо бүртгүүлэхээр зэхэж буй танд
                                    баярлалаа</span
                                ><span>!</span>
                            </p>
                            <p class="register_first_text">
                                <span
                                    >Та киногоо бүртгүүлэхээс өмнө удирдамжтай
                                    сайтар танилцана уу.</span
                                >
                            </p>
                            <p class="register_first_text">
                                <span
                                    >Киног бүртгүүлэхийн тулд киноны уран
                                    бүтээлчид болон киногоо манай сайт дээр
                                    нэмэх хэрэгтэй болно.
                                </span>
                            </p>
                            <p>
                                <span
                                    >Энэхүү сайт дээр байршсанаар таны киноны
                                    мэдээлэл Монголын кино институтийн бүрдүүлж
                                    эхэлж буй Монголын кино урлагийн төвлөрсөн
                                    мэдээллийн системийн нэг хэсэг болох завшаан
                                    тохиож буй гэдгийг онцолмоор байна.</span
                                >
                            </p>
                            <p class="register_first_text">
                                <span
                                    >Бүртгүүлэхээс өмнө дараах зүйлс ЗААВАЛ
                                    хэрэг болохыг анхаарна уу:</span
                                >
                            </p>
                            <ul>
                                <li>
                                    <span>Найруулагчийн хөрөг зураг</span
                                    ><span> (2:3 </span><span>хэмжээтэй </span
                                    ><span>JPEG)</span>
                                </li>
                                <li>
                                    <span
                                        >Киноны зурагт хуудас &ndash; постер </span
                                    ><span>(2:3 </span><span>хэмжээтэй </span
                                    ><span>JPEG)</span>
                                </li>
                                <li>
                                    <span>Киног илэрхийлэх кадр </span
                                    ><span>(</span><span>16</span><span>:</span
                                    ><span>9</span><span> </span
                                    ><span>хэмжээтэй </span><span>JPEG)</span>
                                </li>
                                <li>
                                    <span>Кино файлыг аль нэг хуваалцах </span
                                    ><span>(sharing) </span
                                    ><span>сайт дээр байршуулсан линк.</span>
                                </li>
                            </ul>
                            <p class="register_first_text">
                                <span
                                    >Хэрвээ хүсвэл дараах зүйлсийг мөн нэмж
                                    бэлдэж болно:</span
                                >
                            </p>
                            <ul>
                                <li>
                                    <span
                                        >Бусад уран бүтээлчдийн хөрөг
                                        зураг</span
                                    ><span> (2:3 </span><span>хэмжээтэй </span
                                    ><span>JPEG)</span>
                                </li>
                                <li>
                                    <span>Киноны трейлерийн </span
                                    ><span>Youtube </span><span>холбоос.</span>
                                </li>
                            </ul>
                            <p class="register_first_text">
                                <span
                                    >Эдгээрийг бэлдсэн бол дараагийн алхам руу
                                    шилжиж киногоо бүртгүүлнэ үү.</span
                                >
                            </p>
                            <p><span>Танд амжилт хүсье</span><span>!</span></p>
                        </div>
                        <img
                            class="register_guidance_img"
                            src="https://www.mfi.mn/media/home/ggiff_2023_guidance.jpg"
                        />
                    </v-card>
                    <v-btn color="success" @click="step = 2"> Эхлэх </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="step > 2" step="2">
                    <span class="register_step_name">Наадмын удирдамж</span>
                </v-stepper-step>

                <v-stepper-content step="2">
                    <v-card class="mb-12 pa-2" outlined>
                        <div v-html="this.guidance.guidance"></div>
                    </v-card>
                    <v-btn color="success" @click="step = 3"> Зөвшөөрөх </v-btn>
                    <v-btn text @click="step = 1"> Буцах </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="step > 3" step="3">
                    <span class="register_step_name">Уран бүтээлч</span>
                </v-stepper-step>

                <v-stepper-content step="3">
                    <div class="mb-12 pa-2" outlined>
                        Та наадамд илгээх киноны уран бүтээлчид манай санд
                        бүртгэгдсэн эсэхийг
                        <router-link
                            target="_blank"
                            :to="{ name: 'mongol-artist-list' }"
                        >
                            <strong>эндээс</strong>
                        </router-link>
                        шалгана уу! Тухайн уран бүтээлчид манай санд бүртгэлгүй
                        бол
                        <router-link
                            target="_blank"
                            :to="{ name: 'artist-create' }"
                        >
                            <strong>энд</strong>
                        </router-link>
                        бүртгүүлнэ үү!
                        <div class="register_important_msg_2">
                            АНХААРАХ: Та наадамд бүртгүүлэх киноны уран бүтээлч
                            үүсгэхдээ "GGIFF 2023 -т илгээх кинонд бүртгүүлэнэ"
                            -г чагтлаарай!
                        </div>
                    </div>
                    <v-btn color="primary" @click="step = 4"> Алгасах </v-btn>
                    <v-btn text @click="step = 2"> Буцах </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="step > 4" step="4">
                    <span class="register_step_name">Кино үүсгэх</span>
                </v-stepper-step>

                <v-stepper-content step="4">
                    <div class="mb-12 pa-2" outlined>
                        Та наадамд илгээх киногоо эхлээд манай санд үүсгэх
                        шаардлагатай!
                        <router-link
                            target="_blank"
                            :to="{ name: 'movie-create' }"
                        >
                            <strong>Энэ</strong>
                        </router-link>
                        холбоосоор киногоо үүсгэнэ үү!

                        <div class="register_important_msg_2">
                            АНХААРАХ: Та наадамд бүртгүүлэх киногоо үүсгэхдээ
                            "GGIFF 2023 кино наадамд бүртгүүлэх" -г чагтлаарай!
                        </div>
                    </div>
                    <v-btn color="primary" @click="step = 5">
                        Үргэлжлүүлэх
                    </v-btn>
                    <v-btn text @click="step = 3"> Буцах </v-btn>
                </v-stepper-content>

                <v-stepper-step step="5">
                    <span class="register_step_name">Кино илгээх</span>
                </v-stepper-step>
                <v-stepper-content step="5">
                    <v-form
                        ref="form"
                        v-model="formValid"
                        v-on:submit.prevent="submitCreate"
                    >
                        <div class="mb-12 pa-2" outlined>
                            <div class="mx-5">
                                <v-autocomplete
                                    no-filter
                                    v-model="selectedMovie"
                                    height="100"
                                    :items="searchItems"
                                    hide-no-data
                                    return-object
                                    :search-input.sync="searchMovie"
                                    clearable
                                    item-text="name"
                                    label="* Кино"
                                    attach
                                    solo
                                >
                                    <template v-slot:selection="data">
                                        <v-avatar
                                            left
                                            height="75"
                                            tile
                                            class="mx-3"
                                            ><img
                                                :src="
                                                    mediaUrl +
                                                    '/' +
                                                    data.item['movie_poster']
                                                "
                                                class="rounded"
                                        /></v-avatar>
                                        <div mx="2">
                                            {{
                                                data.item["international_title"]
                                            }}
                                        </div>
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="data.item !== 'object'">
                                            <v-list-item-avatar height="60" tile
                                                ><img
                                                    :src="
                                                        mediaUrl +
                                                        '/' +
                                                        data.item[
                                                            'movie_poster'
                                                        ]
                                                    "
                                                    class="rounded"
                                            /></v-list-item-avatar>
                                            <v-list-item-content
                                                v-text="
                                                    data.item[
                                                        'international_title'
                                                    ]
                                                "
                                            ></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-avatar height="60" tile
                                                ><img
                                                    :src="
                                                        mediaUrl +
                                                        '/' +
                                                        data.item[
                                                            'movie_poster'
                                                        ]
                                                    "
                                                    class="rounded"
                                            /></v-list-item-avatar>
                                            <v-list-item-content
                                                v-text="
                                                    data.item[
                                                        'international_title'
                                                    ]
                                                "
                                            ></v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                                <v-text-field
                                    v-model="directorID"
                                    label="* Кино найруулагчийн регистрийн дугаар"
                                    required
                                    :rules="[rules.required]"
                                ></v-text-field>
                                <v-text-field
                                    v-model="registerEmail"
                                    label="* Холбоо барих и-мейл"
                                    required
                                    :rules="[rules.required, rules.email]"
                                ></v-text-field>
                                <div class="register_phone">
                                    <div class="register_phone__code">
                                        <v-text-field
                                            v-model="registerPhone1"
                                            label="* Код /холбоо барих утас/"
                                            required
                                            :rules="[
                                                rules.required,
                                                rules.number,
                                                rules.length,
                                            ]"
                                        ></v-text-field>
                                    </div>
                                    <div class="register_phone__number">
                                        <v-text-field
                                            v-model="registerPhone2"
                                            label="* Утасны дугаар /холбоо барих утас/"
                                            required
                                            :rules="[
                                                rules.required,
                                                rules.number,
                                            ]"
                                        ></v-text-field>
                                    </div>
                                </div>
                                <v-text-field
                                    v-model="registerLink"
                                    label="* Киног татаж авах холбоос /Өндөр чанартай кино файлыг илгээх ба MEGA.NZ, Google Drive, Wetransfer, Dropbox зэрэг саит дээрх холбоосыг илгээнэ үү!/"
                                    required
                                    :rules="[rules.required]"
                                ></v-text-field>
                                <v-checkbox v-model="condition1">
                                    <template v-slot:label>
                                        <div class="register_check">
                                            Та уг бүтээлийг өөрөө бүтээсэн,
                                            эзэмшдэг, бүх мэдээлэл үнэн зөв
                                            гэдгийг батлан дааж байна уу?
                                        </div>
                                    </template>
                                </v-checkbox>
                                <v-checkbox v-model="condition2">
                                    <template v-slot:label>
                                        <div class="register_check">
                                            Та бүтээлээ бүртгүүлснээр Алтан
                                            Өргөө кино наадмын удирдамжийн
                                            зөвшилцөл хэсгийн бүх заалтыг хүлээн
                                            зөвшөөрч байна уу?
                                        </div>
                                    </template>
                                </v-checkbox>
                                <v-checkbox v-model="condition3">
                                    <template v-slot:label>
                                        <div class="register_check">
                                            Хэрвээ таны бүртгүүлсэн кино
                                            уралдаант хэсэгт шалгарвал
                                            бүртгүүлэгч та бүтээлээ уралдаант
                                            хэсгээс татахгүй, зохион байгуулах
                                            хороонд үзвэрийн үеэр киног гаргах
                                            эрхийг олгож байна уу?
                                        </div>
                                    </template>
                                </v-checkbox>
                                <v-checkbox v-model="condition4">
                                    <template v-slot:label>
                                        <div class="register_check">
                                            Уралдаант хэсэгт шалгарвал кино
                                            наадмын зүгээс кино наадмын дараах 3
                                            сарын турш ашгийн бус зорилгоор кино
                                            наадмыг сурталчлах үүднээс телевиз,
                                            кино театр, streaming platform
                                            зэрэгт гаргах эрхийг олгохийг
                                            зөвшөөрч байна уу?
                                        </div>
                                    </template>
                                </v-checkbox>
                            </div>
                        </div>

                        <v-btn
                            :disabled="checkValid"
                            @click="validate"
                            type="submit"
                            class="register_submit_btn"
                            >Хадгалах</v-btn
                        >
                        <v-btn text @click="step = 4"> Буцах </v-btn>
                    </v-form>
                </v-stepper-content>
            </v-stepper>
        </v-card>
        <v-dialog v-model="successDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="text-h5"> MFI </v-card-title>
                <v-card-text>
                    <div>Баярлалаа!</div>
                    <br />
                    <div>Таны бүртгэлийг бид хүлээн авлаа.</div>
                    <br />
                    <div>
                        Бүртгэлийн хураамж төлөх зааврыг таны email руу илгээсэн
                        болно.
                    </div>
                    <br />
                    <span
                        ><strong>2023 оны 5 сарын 25-ны 23:59</strong> цагаас
                        өмнө та бүртгэлийн хураамжийг төлж бүртгэлээ
                        баталгаажуулаарай. Та бүтээлээ уралдаант хэсэгт
                        шалгарсан эсэхийг <strong>2023 оны 6 сарын 1</strong>-ны
                        өдөр mfi.mn сайт дээр нийтлэгдэх зарлалаас олж харах
                        боломжтой.</span
                    >
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="
                            successDialog = false;
                            nextPage();
                        "
                        >OK</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
    data() {
        return {
            step: 1,
            baseUrl: process.env.VUE_APP_BASEURL,
            mediaUrl: process.env.VUE_APP_MEDIAURL,
            searchItems: [],
            searchMovie: null,
            formValid: false,
            rules: {
                required: (value) => !!value || "Бичих шаардлагатай!",
                number: (value) =>
                    /^[0-9]+$/.test(value) || "Та зөвхөн тоо оруулна уу!",
                length: (value) => value.length <= 3 || "Зөв оруулна уу!",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return (
                        pattern.test(value) ||
                        "Та э-мэйл хаягаа зөв оруулна уу!"
                    );
                },
            },
            error: "",
            succesMessage: "",
            successDialog: false,
            guidanceDialog: false,
            guidance: "",
            selectedMovie: "",
            directorID: "",
            registerEmail: "",
            registerPhone1: "",
            registerPhone2: "",
            condition1: false,
            condition2: false,
            condition3: false,
            condition4: false,
            registerLink: "",
        };
    },
    computed: {
        checkValid() {
            if (
                this.formValid == true &&
                this.selectedMovie !== "" &&
                this.condition1 &&
                this.condition1 &&
                this.condition3 &&
                this.condition4
            ) {
                return false;
            } else {
                return true;
            }
        },
        ...mapState(["user", "isLoading", "language"]),
    },
    watch: {
        searchMovie(val) {
            if (val) {
                axios
                    .get("/movie/api-search-movie-list/", {
                        params: { movie: val, festival: true },
                    })
                    .then((response) => {
                        this.searchItems = response.data.movie_list;
                    });
            }
        },
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        getFestivalGuidance() {
            axios
                .get("/award/registration-guidance/", {
                    params: {
                        name: "Golden Ger international film festival",
                        year: 2023,
                    },
                })
                .then((response) => {
                    this.guidance = response.data;
                })
                .catch((error) => {
                    if (error.response) {
                        this.error = error.response.data.message;
                        this.$store.commit("SET_SNACK", {
                            message: error.response.data.message,
                            color: "red",
                        });
                    }
                });
        },
        submitCreate() {
            const createItems = {
                movie: this.selectedMovie["id"],
                director_id: this.directorID,
                email: this.registerEmail,
                phone_code: this.registerPhone1,
                phone_number: this.registerPhone2,
                movie_link: this.registerLink,
            };

            const createData = new FormData();

            for (var key in createItems) {
                createData.append(key, createItems[key]);
            }

            this.$store.commit("SET_ISLOADING", true);

            axios
                .post("/award/register-annual-festival/", createData)
                .then((response) => {
                    if (response.data.success === true) {
                        this.successDialog = true;
                        this.$store.commit("SET_ISLOADING", false);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.$store.commit("SET_SNACK", {
                            message: error.response.data.message,
                            color: "red",
                        });
                        this.$store.commit("SET_ISLOADING", false);
                    }
                });
        },
        nextPage() {
            this.$router.push({ name: "home" }).catch(() => {});
        },
    },
    created() {
        document.title = "Кино наадамд бүртгүүлэх";
        this.getFestivalGuidance();
    },
};
</script>

<style>
.register_main {
    margin: 20px auto;
    max-width: 1280px;
    min-width: 320px;
}

.register_no_decoration {
    text-decoration: none;
}

.register_first_text {
    margin: 20px 0;
}

.register_step_name {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}

.register_guidance_img {
    width: 100%;
}

.register_dialog__title {
    font-size: 16px;
}

.register_important_msg {
    margin: 20px;
    color: #0066b5;
    font-style: italic;
}

.register_important_msg_2 {
    margin-top: 10px;
    color: #cc3300;
    font-style: italic;
    font-weight: 600;
}

.register_check {
    color: #0066b5;
    font-weight: 600;
}

.register_submit_btn {
    color: white !important;
    background-color: green !important;
}

.register_phone {
    display: flex;
    flex-wrap: wrap;
}

.register_phone__code {
    order: 1;
    width: 100%;
}

.register_phone__number {
    order: 2;
    width: 100%;
}

@media (min-width: 481px) {
    .register_guidance_img {
        width: 65vh;
    }

    .register_phone__code {
        width: 15%;
        margin-right: 10px;
    }

    .register_phone__number {
        width: 82%;
    }
}
</style>
