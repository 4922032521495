<template>
    <v-container v-if="user.isAuthenticated && user.admin">
        <v-card-title class="justify-center">Та шинэ кино наадам нэмэх гэж байна!</v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
            <v-form ref="form" v-model="formValid" v-on:submit.prevent="submitCreate">
                <div class="d-flex flex-row mt-2 mb-2">
                    <div class="ma-2">
                        <v-card width="240" height="135"><v-img :src="festivalImageURL"/></v-card>
                        <v-text-field v-model="festivalImageCheck" disabled label="Наадмын үндсэн зураг" :rules="[rules.required]"></v-text-field>
                        <v-dialog v-model="backgroundDialog" width="800px" transition="dialog-bottom-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn block color="primary" class="mt-2" dark v-bind="attrs" v-on="on">Наадмын зураг нэмэх</v-btn>
                            </template>
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn icon dark @click="backgroundDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                                    <v-toolbar-title>Наадмын зураг</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <cropper ref="cropper" :src="image" :stencil-props="{handlers: {}, movable: false, scalable: false}" :stencil-size="{width: 1280, height: 720}" :canvas="{width: 1280, height: 720}" image-restriction="stencil"/>
                                <div class="button-wrapper">
                                    <button class="button" @click="$refs.file.click()">
                                        <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg"/>Зураг оруулах
                                    </button>
                                    <button class="button" @click="cropBackground">Зураг хуулах</button>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                    <v-row class="mx-5">
                        <v-col>
                            <v-text-field v-model="festivalMongolName" label="Кино наадмын Монгол нэр*" required :rules="[rules.required]"></v-text-field>
                            <v-text-field v-model="festivalInternationalName" label="Кино наадмын олон улсын нэр*" required :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <v-card-actions class="justify-center"><v-btn :disabled="checkValid" @click="validate" type="submit" style="color: white; background-color: green;" block>Хадгалах</v-btn></v-card-actions>
            </v-form>
            <v-divider class="mx-4"></v-divider>
        </v-card-text>
    </v-container>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import { mapState } from 'vuex'
import 'vue-advanced-cropper/dist/style.css'

export default {
    components: {
        Cropper
    },
    data(){
        return{
            image: "",
            backgroundDialog: false,
            baseUrl: process.env.VUE_APP_BASEURL,
            formValid: false,
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!'
            },
            festivalMongolName: '',
            festivalInternationalName: '',
            festivalImage: null,
            festivalImageURL: null,
            festivalImageCheck: null
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        },
        ...mapState(['user'])
    },
    methods:{
        validate(){
            this.$refs.form.validate()
        },
        cropBackground() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => { this.festivalImage = blob }, "image/jpeg")
            this.festivalImageURL = canvas.toDataURL("jpeg")
            this.festivalImageCheck = 'festival-background'
            this.backgroundDialog = false
            this.image = ''
        },
        uploadImage(event){
            const { files } = event.target
            if (files && files[0] && files[0]['type']==='image/jpeg') {
                if (this.image) {
                    URL.revokeObjectURL(this.image)
                }
                const blob = URL.createObjectURL(files[0])
                this.image = blob
            } else {
                this.$store.commit('SET_SNACK', {message: 'Та JPEG өргөтгөлтэй зураг оруулна уу!', color: 'red'})
            }
        },
        submitCreate(){
            const createItems = {
                'mongol_name': this.festivalMongolName,
                'international_name': this.festivalInternationalName
            }

            const createData = new FormData()

            if(this.festivalImage){
                createData.append('festival-background', this.festivalImage, 'festival_background')
            }

            for(var key in createItems){
                createData.append(key, createItems[key])
            }

            axios
                .post('/award/create-festival/', createData)
                .then((response) => {
                    if (response.data.success === true){
                        this.$store.commit('SET_SNACK', {message: 'Кино наадам амжилттай нэмэгдлээ!', color: 'green'})
                        this.$router.push({ name: 'home' }).catch(()=>{})
                    }
                })
                .catch(error => {
                    if(error.response){
                        this.$store.commit('SET_SNACK', {message: 'Алдаа гарлаа, дахин оролдож үзнэ үү!', color: 'red'})
                    }
                })
        } 
    },
    created(){
        document.title = 'Шинээр наадам нэмэх'
    }
}
</script>

<style scoped lang="scss">

.cropper {
    max-height: 300px;
    width: 100%;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 17px;
}

.button {
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    width: 100%;
    background: #0066b5;
    cursor: pointer;
    transition: background 0.5s;
    border: none;
    &:not(:last-of-type) {
        margin-right: 10px;
    }
    &:hover {
        background: #2F2F2F;
    }
    input {
        display: none;
    }
}

</style>