<template>
    <v-container class="d-flex justify-center">
        <v-card class="ma-5" width="400" :disabled="$store.state.isLoading">
            <v-card-title class="justify-center">Шинэ нууц үгээ оруулна уу!</v-card-title>
            <div v-if="errors.length" style="text-align: center; color: red;">
                <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
            </div>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-row class="justify-center my-4">
                    <v-form ref="form" style="width: 300px;" v-model="formValid" v-on:submit.prevent="activate">
                        <v-text-field v-model="password1" name="password1" label="Нууц үг" required type="password" :rules="[rules.required, rules.passwordValidation]"></v-text-field>
                        <small style="max-width: 200px; font-weight: bold;">
                            <p style="margin: 0; font-style: italic;" :class="passMinimum ? 'pass_required' : ''">- Багадаа 10 тэмдэгт</p> 
                            <p style="margin: 0; font-style: italic;" :class="passLowercase ? 'pass_required' : ''">- Жижиг Англи үсэг</p>
                            <p style="margin: 0; font-style: italic;" :class="passUppercase ? 'pass_required' : ''">- Том Англи үсэг</p>
                            <p style="margin: 0; font-style: italic;" :class="passNumber ? 'pass_required' : ''">- Тоо</p>
                            <p style="margin: 0; font-style: italic;" :class="passSpecial ? 'pass_required' : ''">- Тусгай тэмдэгт: @,#,$,!,%,*,?,&</p>
                        </small>
                        <v-text-field v-model="password2" name="password2" label="Нууц үг батлах" required type="password" :rules="[rules.required, rules.passwordMatch]"></v-text-field>
                        <v-card-actions class="justify-center"><v-btn :disabled="checkValid" type="submit" style="color: white; background-color: #0066b5;">илгээх</v-btn></v-card-actions>
                    </v-form>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ResetPassword',
    data(){
        return{
            formValid: false,
            password1: '',
            password2: '',
            errors: [],
            status: '',
            rules: {
                required: value => !!value || 'Бичих шаардлагатай!',
                passwordValidation: value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&]).{10,}$/.test(value),
                passwordMatch: value => value === this.password1 || 'Та баталгаажуулах нууц үгээ зөв оруулна уу!',
            },
        }
    },
    watch:{
        password1(){
            this.passMinimum = (this.password1.length >= 10)
            this.passNumber = /\d/.test(this.password1)
            this.passLowercase = /[a-z]/.test(this.password1)
            this.passUppercase = /[A-Z]/.test(this.password1)
            this.passSpecial = /[@#$!%*?&]/.test(this.password1)
        }
    },
    computed:{
        checkValid(){
            if(this.formValid == true){
                return false
            } else {
                return true
            }
        }
    },
    methods:{
        async activate(){
            this.errors = []

            if(this.password1 === ''){
                this.errors.push("Та хэрэглэгчийн нууц үг оруулна уу!")
            }

            if(this.password1 !== this.password2){
                this.errors.push("Нууц үгнүүд таарахгүй байна!")
            }

            if(!this.errors.length){
                this.$store.commit('SET_ISLOADING', true)

                const resetPasswordData = {
                    uid: this.$route.params.uid,
                    token: this.$route.params.token,
                    new_password: this.password1,
                    re_new_password: this.password2
                }

                await axios
                    .post('/v1/users/reset_password_confirm/', resetPasswordData)
                    .then(response => {
                        if(response.status === 204){
                            this.$router.push('/login')
                            this.$store.commit('SET_SNACK', {message: 'Таны нууц үг амжилттай солигдлоо. Та системд хэрэглэгчийн нэр нууц үгээрээ нэвтрэнэ үү!', color: 'success'})
                        } else {
                            this.$store.commit('SET_SNACK', {message: 'Таны нууц үг солигдсонгүй. Та хэсэг хугацааны дараа дахин оролдож үзнэ үү!', color: 'red'})
                        }
                    })
                    .catch(error => {
                        if(error.response){
                            Object.entries(error.response.data).forEach(([key, value]) => {
                                this.errors.push(`${key} : ${value}`)
                            })
                        } else if(error.message){
                            this.errors.push('Алдаа гарлаа, дахин оролдож үзнэ үү!')
                        }
                    })

                this.$store.commit('SET_ISLOADING', false)
            }
        }
    },
    created(){
        this.$store.commit('SET_ACTIVATE', false)
        this.$store.commit('SET_PHONE_ACTIVATE', false)
    }
}
</script>
<style scoped>

.pass_required{
    text-decoration: line-through;
    color:#689868;
}

</style>