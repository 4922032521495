<template>
    <div class="gsc-1">
        <span :class="[overlay ? 'gsc-2' : 'gsc-3']">{{ review }}</span>
        <v-overlay :absolute="absolute" :opacity="opacity" :value="overlay" color="#FFF">
            <div class="gsc-4">үйл явдлын ИЛЧЛЭЛТ агуулсан байна! <span class="gsc-5" @click="overlay = false">УНШИХ</span></div>
        </v-overlay>
    </div>
</template>

<script>
export default {
    props: {
        spoiler: Boolean,
        review: String
    },
    data(){
        return{
            absolute: true,
            opacity: 0.5,
            overlay: this.spoiler,
        }
    }
}
</script>

<style scoped>

.gsc-1 {
    position: relative;
    color: #000;
}

.gsc-2 {
    filter: blur(4px);
}

.gsc-3 {
    filter: blur(0);
}

.gsc-4 {
    color: #0066b5;
    font-weight: 500;
    text-align: center;
}

.gsc-5 {
    color: #0066b5;
    font-weight: 800;
    cursor: pointer;
}

</style>
