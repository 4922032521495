import { render, staticRenderFns } from "./UpdateSerial.vue?vue&type=template&id=604a7e13&scoped=true"
import script from "./UpdateSerial.vue?vue&type=script&lang=js"
export * from "./UpdateSerial.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/CU_artist.css?vue&type=style&index=0&id=604a7e13&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "604a7e13",
  null
  
)

export default component.exports