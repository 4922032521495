<template>
    <v-card>
        <router-link :to="{ name: 'artist-detail', params: { slug: slug } }">
            <img :src="mediaUrl + '/' + photo" class="column rounded"/>
        </router-link>
        <v-card-text>
            <strong>{{ name }}</strong>
            <br>
            {{ job }}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        slug: String,
        photo: String,
        name: String,
        job: String
    },
    data(){
        return{
            mediaUrl: process.env.VUE_APP_MEDIAURL
        }
    }
}
</script>

<style scoped>

.column {
    vertical-align: middle;
    width: 100%;
    filter: grayscale(100%);
}

</style>
