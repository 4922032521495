<template>
    <v-container class="d-flex justify-center">
        <v-card class="ma-5" width="400" :disabled="isLoading">
            <v-card-title class="justify-center">Системд нэвтрэх</v-card-title>
            <div v-show="errors" style="text-align: center; color: red;">
                <p v-for="(err, index) in errors" :key="index">{{ err }}</p>
            </div>
            <div v-if="activateUser" style="text-align: center; font-size: 14px;">
                <router-link style="text-decoration: none;" :to="{ name: 'activate-user' }"><b>Энд дарж</b></router-link> бүртгэл идэвхжүүлэнэ үү!
            </div>
            <v-divider class="mt-2 mx-4"></v-divider>
            <v-card-text>
                <v-row class="justify-center my-4">
                    <v-form style="width: 300px;" v-on:submit.prevent="login">
                        <v-text-field v-model="username" name="username" label="Хэрэглэгчийн нэр" required></v-text-field>
                        <v-text-field v-model="password" name="password" label="Нууц үг" required type="password"></v-text-field>
                        <v-card-actions class="justify-center"><v-btn type="submit" style="color: white; background-color: #0066b5;">Нэвтрэх</v-btn></v-card-actions>
                    </v-form>
                </v-row>
                <v-divider class="mx-4 my-4"></v-divider>
                <v-card-actions class="justify-center"><router-link class="navbar-text" style="text-decoration: none;" :to="{ name: 'signup' }"><v-btn>Бүртгүүлэх</v-btn></router-link></v-card-actions>
                <v-row class="justify-center mt-4 my-4">
                    <div style="display: block; margin: 10px; margin-top: 20px;">
                        Мартсан нууц үгээ <router-link :to="{ name: 'forgot-password' }" style="font-weight: bold; text-decoration: none; color: #0066b5;">солих</router-link>
                    </div>
                    <div style="display: block; margin: 10px;">
                        Утасны дугаараа <router-link :to="{ name: 'change-phone' }" style="font-weight: bold; text-decoration: none; color: #0066b5;">өөрчлөх</router-link>
                    </div>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Login',
    data(){
        return{
            url: process.env.VUE_APP_BASEURL,
            username: '',
            password: '',
            errors: []
        }
    },
    computed: mapState(['activateUser', 'isLoading', 'error']),
    watch:{
        error(){
            this.errors.push(this.error)
        }
    },
    methods:{
        login(){
            this.errors = []

            if(this.username === ''){
                this.errors.push("Та хэрэглэгчийн нэрээ оруулна уу!")
            }

            if (/[а-яА-ЯЁё]/.test(this.username)){
                this.errors.push("Таны хэрэглэгчийн нэрэнд зөвхөн Англи үсэг болон тоо орох боломжтой!")
            }

            if(this.username.length < 3 || this.username.length > 20){
                this.errors.push("Та хэрэглэгчийн нэрээ 3-25 тэмдэгт байх байсныг анхаарана уу!")
            }

            if(this.password === ''){
                this.errors.push("Та хэрэглэгчийн нууц үгээ оруулна уу!")
            }

            if(!this.errors.length){
                this.$store.commit('SET_ISLOADING', true)
                this.$store.dispatch('userLogin', { username: this.username, password: this.password })
                    .then(() => {
                        this.username = ''
                        this.password = ''
                        this.$store.commit('SET_ISLOADING', false)
                        if(this.$route.query.redirect){
                            this.$router.push(this.$route.query.redirect)
                        }else{
                            this.$router.push('/')
                        }
                    })
            }
            
        }
    },
    created(){
        this.$store.commit('SET_ACTIVATE', false)
        this.$store.commit('SET_PHONE_ACTIVATE', false)
    }
}
</script>
