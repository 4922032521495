import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: '',
        isLoading: false,
        language: 'MN',
        user: {
            isAuthenticated: false,
            contributer: '',
            mainContributer: '',
            admin: '',
            critic: '',
            publisher: '',
            slug: '',
            name: '',
            image: '',
            adminBarHide: false,
            background: ''
        },
        snack: {
            message: '',
            color: ''
        },
        error: '',
        activateUser: false,
        activatePhone: false,
        mfiNavbar: true
    },
    mutations: {
        INITIALIZE_STORE(state){
            state.token = localStorage.getItem('token')
            axios.defaults.headers.common['Authorization'] = "Token " + state.token
            if (state.token) {
                state.user.isAuthenticated = true
            } else {
                state.user.isAuthenticated = false
                state.user.contributer = ''
                state.user.mainContributer = ''
                state.user.admin = ''
                state.user.critic = ''
                state.user.publisher = ''
                state.user.name = ''
                state.user.slug = ''
                state.user.image = ''
                state.user.background = ''
            }
        },
        SET_TOKEN(state, data){
            state.token = data
            state.user.isAuthenticated = true
            localStorage.setItem('token', data)
        },
        SET_USER_PROFILE(state, data){
            state.user.contributer = data.contributer
            state.user.mainContributer = data.main_contributer
            state.user.admin = data.admin
            state.user.critic = data.critic
            state.user.publisher = data.publisher
            state.user.name = data.name
            state.user.slug = data.slug
            state.user.image = data.image
            state.user.background = data.background
        },
        REMOVE_TOKEN(state){
            state.token = ''
            state.user.contributer = ''
            state.user.mainContributer = ''
            state.user.admin = ''
            state.user.critic = ''
            state.user.publisher = ''
            state.user.name = ''
            state.user.slug = ''
            state.user.image = ''
            state.user.background = ''
            state.user.isAuthenticated = false
            localStorage.removeItem('token')
            axios.defaults.headers.common['Authorization'] = ""
        },
        SET_USER_IMAGE(state, payload){
            state.user.image = payload
        },
        SET_USER_BACKGROUND(state, payload){
            state.user.background = payload
        },
        SET_ACTIVATE(state, status){
            state.activateUser = status
        },
        SET_PHONE_ACTIVATE(state, status){
            state.activatePhone = status
        },
        SET_ISLOADING(state, status){
            state.isLoading = status
        },
        SET_ERRORS(state, payload){
            state.activateUser = payload.status
            state.error = payload.message
        },
        SET_SNACK(state, {message, color}){
            state.snack.message = message
            state.snack.color = color
        },
        SET_MFINAVBAR(state, status){
            state.mfiNavbar = status
            localStorage.setItem('mfiNav', state.mfiNavbar)
        },
        SET_USER_ADMINBAR(state, payload){
            state.user.adminBarHide = payload
        },
        SET_LANGUAGE(state, payload){
            state.language = payload
        }
    },
    actions: {
        async userLogin({ dispatch, commit }, payload) {
            commit('SET_ERRORS', { message: "", status: false })
            commit('REMOVE_TOKEN')
            await axios
                    .post("/v1/token/login/", payload)
                    .then((response) => {
                        if (response && response.data) {
                            const data = response.data['auth_token']
                            commit('SET_TOKEN', data)
                            axios.defaults.headers.common['Authorization'] = "Token " + data
                            dispatch('getUserProfile')
                        }
                    })
                    .catch(error => {
                        if(error.response){
                            if(error.response.status == 400 && error.response.data.non_field_errors.indexOf("User account is disabled.") > -1){
                                commit('SET_ERRORS', { message: "Таны бүртгэл идэвхгүй байна", status: true })
                            } else if (error.response.status == 400 && error.response.data.non_field_errors.indexOf("Unable to log in with provided credentials.") > -1) {
                                commit('SET_ERRORS', { message: "Та хэрэглэгчийн нэр, нууц үгээ зөв оруулна уу!", status: false })
                            }
                        } else if(error.message){
                            commit('SET_ERRORS', { message: "Алдаа гарлаа, дахин оролдож үзнэ үү!", status: false })
                        }
                    })
        },
        getUserProfile({ commit }) {
            commit('SET_ERRORS', { message: "", status: false })
            axios
                .get("/user/api/v-user-profile-short/")
                .then((response) => {
                    if (response && response.data) {
                        commit('SET_USER_PROFILE', response.data.user_profile)
                    }
                })
                .catch((error) => {
                    if(error.response.status == 401 && error.response.data['detail'] == 'Invalid token.'){
                        localStorage.removeItem('token')
                        commit('REMOVE_TOKEN')
                        router.push('/').catch(()=>{})
                    } else commit('SET_ERRORS', { message: "Алдаа гарлаа, дахин оролдож үзнэ үү!", status: false }) 
                })
        },  
        async userLogout({ commit }){
            commit('SET_ERRORS', { message: "", status: false })
            await axios
                    .post("/v1/token/logout/")
                    .then((response) => {
                        if (response.status == 204) {
                            commit('SET_SNACK', { message: 'Та системээс гарлаа!', color: 'primary' })
                            localStorage.removeItem('token')
                            commit('REMOVE_TOKEN')
                            router.push('/').catch(()=>{})
                        }
                    })
                    .catch((error) => { 
                        if (error) {
                            commit('SET_ERRORS', { message: "Алдаа гарлаа, дахин оролдож үзнэ үү!", status: false })
                        } 
                    })
        },
        async userActivate({ commit }, payload){
            commit('SET_ERRORS', { message: "", status: false })
            await axios
                    .post('/user-activate/', payload)
                    .then(response => {
                            this.status = response.data.status
                            if(this.status === true){
                                commit('SET_SNACK', {message: 'Таны бүртгэл амжилттай идэвхжлээ. Та системд хэрэглэгчийн нэр нууц үгээрээ нэвтрэнэ үү!', color: 'success'})
                                router.push('/login')
                            } else if(this.status === false){
                                commit('SET_SNACK', {message: 'Таны бүртгэл идэвхжсэнгүй. Та кодоо зөв оруулна уу!', color: 'red'})
                            }
                    })
                    .catch(error => {
                        if(error.response){
                            commit('SET_ERRORS', { message: error.response.data, status: false })
                        } else if(error.message){
                            commit('SET_ERRORS', { message: "Алдаа гарлаа, дахин оролдож үзнэ үү!", status: false })
                        }
                    })
        },
        async resendCode({ commit }, payload){
            commit('SET_ERRORS', { message: "", status: false })
            await axios
                    .post('/user-resend-code/', payload)
                    .then( () => {
                        commit('SET_SNACK', {message: 'Таны утасруу дахин код илгээлээ, та өөрийн утсанд ирсэн кодоо оруулж бүртгэлээ идэвхжүүлэнэ үү!', color: 'success'})
                    })
                    .catch(error => {
                        if(error.response){
                            commit('SET_ERRORS', { message: error.response.data, status: false })
                        } else if(error.message){
                            commit('SET_ERRORS', { message: "Алдаа гарлаа, дахин оролдож үзнэ үү!", status: false })
                        }
                    })
        },
        async reActivateCode({ commit }, payload){
            commit('SET_ERRORS', { message: "", status: false })
            await axios
                    .post('/user-reactivate-code/', payload)
                    .then((response) => {
                        if(response.status === 200){
                            commit('SET_SNACK', {message: 'Таны утасруу дахин код илгээлээ, та өөрийн утсанд ирсэн кодоо оруулж бүртгэлээ идэвхжүүлэнэ үү!', color: 'success'})
                            commit('SET_ACTIVATE', false)
                        }
                    })
                    .catch(error => {
                        if(error.response){
                            if (error.response.status == 400 && error.response.data.username == false){
                                commit('SET_ERRORS', { message: "Та нэрээ зөв оруулна уу!", status: false })
                            } else if (error.response.status == 400 && error.response.data.password == false){
                                commit('SET_ERRORS', { message: "Та нууц үгээ зөв оруулна уу!", status: false })
                            } else if (error.response.status == 400 && error.response.data.phone == false){
                                commit('SET_ERRORS', { message: "Та бүртгэлтэй утасны дугаараа зөв оруулна уу!", status: false })
                            } else {
                                commit('SET_ERRORS', { message: "Алдаа гарлаа, дахин оролдож үзнэ үү!", status: false })
                            }
                        } else if(error.message){
                            commit('SET_ERRORS', { message: "Алдаа гарлаа, дахин оролдож үзнэ үү!", status: false })
                        }
                    })
        },
        async changePhoneCode({ commit }, payload){
            await axios
                    .post('/user-changephone-code/', payload)
                    .then((response) => {
                        if(response.status === 200){
                            commit('SET_SNACK', {message: 'Таны өөрчлөлт амжилттай хийгдлээ. Та өөрчлөлтөө баталгаажуулана уу!', color: 'success'})
                            commit('SET_PHONE_ACTIVATE', true)
                            commit('SET_ACTIVATE', true)
                        }
                    })
                    .catch(error => {
                        if(error.response){
                            if (error.response.status == 400 && error.response.data.username == false){
                                commit('SET_ERRORS', { message: "Та нэрээ зөв оруулна уу!", status: false })
                            } else if (error.response.status == 400 && error.response.data.not_active == false){
                                commit('SET_ERRORS', { message: "Таны бүртгэл идэвхгүй байна", status: true })
                            } else if (error.response.status == 400 && error.response.data.password == false){
                                commit('SET_ERRORS', { message: "Та нууц үгээ зөв оруулна уу!", status: false })
                            } else if (error.response.status == 400 && error.response.data.phone == false){
                                commit('SET_ERRORS', { message: "Та бүртгэлтэй утасны дугаараа зөв оруулна уу!", status: false })
                            } else {
                                commit('SET_ERRORS', { message: "Алдаа гарлаа, дахин оролдож үзнэ үү!", status: false })
                            }
                        } else if(error.message){
                            commit('SET_ERRORS', { message: "Алдаа гарлаа, дахин оролдож үзнэ үү!", status: false })
                        }
                    })
        },
        initStore({ commit }){
            commit('SET_ERRORS', { message: "", status: false })
            commit('INITIALIZE_STORE')
        }
    },
    modules: {
    }
})
