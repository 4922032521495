<template>
    <v-container class="journalListMargin" v-if="totalJournals != ''">
        <div class="artist__legend_bar"><strong>Холбоотой нийтлэлүүд</strong></div>
        <v-col class="row">
            <JournalCard v-for="(journal, index) in totalJournals" :key="index" :journal="journal"/>
        </v-col>
        <br>
        <div class="load-more-btn">
            <v-btn type="button" block class="loadMoreButton" v-if="endPoint" @click="artistJournals(loadMore += 1)">Бусад мэдээнүүд</v-btn>
        </div>
    </v-container>
</template>

<script>
import axios from 'axios'
import JournalCard from '@/components/artist/JournalCard.vue'

export default {
    props: {
        slug: String
    },
    components: {
        JournalCard
    },
    data(){
        return{
            artistSlug: this.slug,
            totalJournals: [],
            journalList: [],
            loadMore: 1,
            endPoint: '',
            computedHeight: 0
        }
    },
  methods:{
    artistJournals(){
        axios
            .get('/artist/api-artist-journals/', {params: {slug: this.artistSlug, loadMore: this.loadMore}})
            .then(response => {this.journalList = response.data.journals, this.endPoint = response.data.end_point, this.totalJournals = this.totalJournals.concat(this.journalList)})
    }
  },
    created(){
        this.artistJournals()
    },
    watch:{
        slug(){
            this.artistSlug = this.slug
            this.totalJournals = []
            this.journalList = []
            this.loadMore = 1
            this.endPoint = ''
            this.artistJournals()
        }
    },
    updated() {
        if(this.$refs.journalListRef){
            this.computedHeight = this.$refs.journalListRef.offsetHeight + "px"
        }
    }
}
</script>

<style scoped>

.journalListMargin {
    margin: 20px 5px 0;
}

.artist__legend_bar {
    color: #0066b5;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
}



.row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100% !important;
    padding: 0.5rem;
    margin: 0 auto;
    gap: 0.5rem;
}

.loadMoreButton {
    color:#FFFFFF; 
    background-image: linear-gradient(#FFFFFF, #696969);
}

.load-more-btn {
    text-align: center;
}

@media (min-width: 481px) {
    .row {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

</style>